import { memo } from "react";
import cn from "classnames";

import styles from './SwitchTabButton.module.css';

interface SwitchTabButtonProps {
  onButtonClicked: () => void
  name: string;
}

const SwitchTabButton = ({ onButtonClicked, name }: SwitchTabButtonProps) => (
  <button 
    className={cn("btn", "black", styles.button)}
    onClick={onButtonClicked}
  >
    {name}
    <i className={cn("fa-regular", "fa-arrow-right", styles.buttonIcon)} />
  </button>
);

export default memo(SwitchTabButton);