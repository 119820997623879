import {
  PropsWithChildren,
  SyntheticEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import CaseStudy from "../../../Shared/CaseStudy/CaseStudy";
import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import Answer from "../../../Shared/Answer/Answer";

import { getOpenQuestionAnswersAndVotes } from "../utils/activity-helper";
import { getEntryId, getTransitionData } from "../../../../utils";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { getGroupText } from "../utils/get-group-text";

import {
  ClosedQuestionActivity,
  ClosedQuestionAnswer,
} from "../../../../types/contentful/workshop/activities/closed-question";
import {
  OutcomeMode,
  ConferenceMode,
} from "../../../../types/contentful/enums";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { observerFooterData } from "../constants";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ActivityCommon } from "../../../../types/activity-common";
import { activityTypeFooterTextFactoryMap } from "../../../../utils/footer";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { normalConnectionThreshold } from "../../../../contexts/Apollo";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { AnswerType } from "../../../Shared/Answer/types";
import { ActivityComplexValue } from "../../../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../../../types/enums/activity-complex-value-type";
import { useDocMap } from "../../../../hooks/useDocMap";
import { debounce } from "lodash";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import CustomDefinition, {
  CustomDefinitionGroupValue,
} from "./components/CustomDefinition/CustomDefinition";
import AssignmentTitle from "../../../Shared/AssignmentTitle/AssignmentTitle";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import cn from "classnames";

import styles from "./Question.module.css";

const newDefinitionKey = "NEW_DEFINITION_KEY";
const editorKey = "groupDefinition";
const editorDeps = [editorKey] as const;

interface QuestionProps extends ActivityCommon {
  profileId: string;
  profileName: string;
  currentActiveParticipants: string[];
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
  isViewResults: boolean;
  allActivitiesResult?: IActivityResult[];
}

export default memo(function Question(props: PropsWithChildren<QuestionProps>) {
  const {
    activity: rawActivity,
    profileId,
    profileName,
    nextActivity,
    currentActiveParticipants,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    activityResultForCurrentProfile,
    activityResults,
    allActivitiesResult,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const activity = rawActivity as ClosedQuestionActivity;
  const playerSelectedValuesRef = useRef<{ [key: string]: string }>({});
  const { docs, generateComplexResultValueAsString, parseComplexResultValue } =
    useDocMap(editorDeps);

  const [expandedAnswerExplanations, setExpandedAnswerExplanations] = useState<
    string[]
  >([]);

  const referenceActivityId =
    activity["fields"]["referenceActivity"]?.["sys"]["contentType"]?.["sys"][
      "id"
    ];
  const isReferenceActivityOpenQuestion =
    referenceActivityId === WorkshopActivityType.OpenQuestion;
  const isReferenceActivityVoting =
    referenceActivityId === WorkshopActivityType.VotingActivity;
  const videoUrl = useMemo(() => activity.fields.videoUrl, [activity]);
  const isNextGoToDiscussion =
    activity.fields.activity.fields.conferenceMode === ConferenceMode.Solo &&
    nextActivity?.fields.activity.fields.conferenceMode === ConferenceMode.All;
  const answers = useMemo(() => {
    if (
      !activity.fields.answers &&
      !isReferenceActivityOpenQuestion &&
      !isReferenceActivityVoting
    ) {
      return activity.fields.referenceActivity
        ? (activity.fields.referenceActivity as ClosedQuestionActivity).fields
            .referenceActivity
          ? (
              (activity.fields.referenceActivity as ClosedQuestionActivity)
                .fields.referenceActivity as ClosedQuestionActivity
            ).fields.answers
          : (activity.fields.referenceActivity as ClosedQuestionActivity).fields
              .answers
        : [];
    }

    if (isReferenceActivityOpenQuestion || isReferenceActivityVoting) {
      return getOpenQuestionAnswersAndVotes(
        activity,
        allActivitiesResult,
        profileId
      );
    }

    return activity.fields.answers || [];
  }, [
    activity,
    allActivitiesResult,
    isReferenceActivityOpenQuestion,
    isReferenceActivityVoting,
    profileId,
  ]);

  useEffect(() => {
    if (expandedAnswerExplanations.length || !answers.length) return;
    setExpandedAnswerExplanations(answers.map((d) => d.sys.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const activityData = useMemo(
    () => ({
      id: getEntryId(activity),
      canAddNewAnswer: activity.fields.canAddNewAnswer,
      newAnswerTitle: activity.fields.newAnswerTitle,
      newAnswerPlaceholder: activity.fields.newAnswerPlaceholder,
      instructions: activity.fields.activity.fields.instructions,
      questionInstructions: activity.fields.questionInstructions,
      questionTitle: activity.fields.questionTitle,
      questionDescription: activity.fields.questionDescription,
    }),
    [activity]
  );
  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const onSelectAnswerHandler = useCallback(
    (answerId: string) => {
      if (isLoading) return;
      selectedAnswerIdRef.current = answerId;

      if (!expandedAnswerExplanations.includes(answerId)) {
        setExpandedAnswerExplanations([
          ...expandedAnswerExplanations,
          answerId,
        ]);
      }

      setActivityValueHandler({
        activityId: activityData.id,
        value:
          activityResultForCurrentProfile?.value === answerId
            ? ACTIVITY_TIMEOUT_VALUE
            : answerId,
      });
    },
    [
      activityData.id,
      activityResultForCurrentProfile?.value,
      isLoading,
      setActivityValueHandler,
      expandedAnswerExplanations,
    ]
  );

  const currentParsedValue =
    useMemo<null | ActivityComplexValue<CustomDefinitionGroupValue>>(() => {
      const valueCandidate = activityResults?.[0]?.value;
      if (!valueCandidate) {
        return null;
      }
      try {
        const parsedValue = JSON.parse(valueCandidate);
        if (
          parsedValue?.value === undefined ||
          parsedValue?.data === undefined
        ) {
          return null;
        }
        return parsedValue;
      } catch (e) {
        return null;
      }
    }, [activityResults]);

  const playerSelectedValues = useMemo(() => {
    playerSelectedValuesRef.current =
      currentParsedValue?.data?.playerSelectedValues || {};
    return playerSelectedValuesRef.current;
  }, [currentParsedValue?.data?.playerSelectedValues]);

  const prepDataAndSetActivityValueHandler = useCallback(
    (answerId: string) => {
      const activityId = activity.sys.id;
      if (!activityData.canAddNewAnswer) {
        onSelectAnswerHandler(answerId);
        return;
      }
      const newValue: ActivityComplexValue<CustomDefinitionGroupValue> = {
        type: ActivityComplexValueType.yjs,
        value: currentParsedValue?.value || "",
        data: {
          playerSelectedValues: {
            ...playerSelectedValues,
            [profileId]: answerId,
          },
        },
      };
      setActivityValueHandler({ activityId, value: JSON.stringify(newValue) });
    },
    [
      activity.sys.id,
      currentParsedValue?.value,
      activityData,
      onSelectAnswerHandler,
      playerSelectedValues,
      profileId,
      setActivityValueHandler,
    ]
  );

  const debouncedSetActivityValue = useMemo(
    () =>
      debounce((value: string) => {
        setActivityValueHandler({ activityId: activityData.id, value });
      }, 200),
    [activityData.id, setActivityValueHandler]
  );

  const onValueUpdate = useCallback(() => {
    const activityComplexValue: ActivityComplexValue<CustomDefinitionGroupValue> =
      {
        type: ActivityComplexValueType.yjs,
        value: generateComplexResultValueAsString(),
        data: {
          playerSelectedValues: {
            ...playerSelectedValuesRef.current,
            [profileId]: newDefinitionKey,
          },
        },
      };

    debouncedSetActivityValue(JSON.stringify(activityComplexValue));
  }, [
    debouncedSetActivityValue,
    generateComplexResultValueAsString,
    profileId,
  ]);

  useEffect(() => {
    if (!activityResultForCurrentProfile?.value) return;
    setExpandedAnswerExplanations([activityResultForCurrentProfile?.value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Finish the logic for author challenge
  // const [isChallengeModalVisible, setChallengeModalVisible] =
  //   useState<boolean>(false);
  const selectedAnswerIdRef = useRef<string | null>(null);
  const actionFooterDataRef = useRef<ActionFooterType>();

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const currentActivityResultsForOtherProfiles = useMemo(() => {
    return activityResults?.filter((d) => d.profileId !== profileId);
  }, [activityResults, profileId]);

  const selectedDefinitions = useMemo(
    () => Object.values(currentParsedValue?.data?.playerSelectedValues || {}),
    [currentParsedValue?.data?.playerSelectedValues]
  );

  const answerIds = useMemo(
    () =>
      (activityResults || [])
        .filter(({ profileId }) =>
          currentActiveParticipants.includes(profileId)
        )
        .map(({ value }) => value),
    [currentActiveParticipants, activityResults]
  );

  // will be used in simple Closed Question activities where Custom Definition isn't present
  const groupIsAligned = useMemo(
    () =>
      !selectedDefinitions?.length &&
      answerIds.every(
        (id: string) =>
          !!id && id !== ACTIVITY_TIMEOUT_VALUE && id === answerIds[0]
      ),
    [answerIds, selectedDefinitions]
  );

  // will be calculated in case Custom Definition is present
  const playersAreAligned = useMemo(() => {
    if (isViewResults) {
      return selectedDefinitions.every(
        (value) => value === selectedDefinitions[0]
      );
    }

    return (
      selectedDefinitions[0] &&
      selectedDefinitions[0] !== ACTIVITY_TIMEOUT_VALUE &&
      (selectedDefinitions[0] === newDefinitionKey
        ? docs[editorKey].getText("codemirror").toString().length > 0
        : true) &&
      selectedDefinitions.every((value) => value === selectedDefinitions[0])
    );
  }, [docs, isViewResults, selectedDefinitions]);

  const hasAlignment = useMemo(
    () => playersAreAligned || groupIsAligned,
    [playersAreAligned, groupIsAligned]
  );

  const isConsensusMode = useMemo(
    () => activity.fields.activity.fields.outcomeMode === OutcomeMode.Consensus,
    [activity]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isLoading && actionFooterDataRef.current)
      return actionFooterDataRef.current;
    if (isTransitioning) {
      actionFooterDataRef.current = getTransitionActionFooterData({
        text: "Everyone is ready. Continuing forward...",
        buttonText: !isConsensusMode ? "Go to discussion" : "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
      return actionFooterDataRef.current;
    }

    if (!isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      const playerHasAnswered =
        !!activityResultForCurrentProfile?.value &&
        activityResultForCurrentProfile?.value !== ACTIVITY_TIMEOUT_VALUE;

      actionFooterDataRef.current = {
        text: activityTypeFooterTextFactoryMap[
          WorkshopActivityType.ClosedQuestionActivity
        ](playersClicked, isNextGoToDiscussion),
        buttonText: "Go to discussion",
        disabledButton: false,
        type: FooterType.Notice,
      };

      if (!isConsensusMode) {
        actionFooterDataRef.current.disabledButton = !playerHasAnswered;
        actionFooterDataRef.current.text = playerHasAnswered
          ? actionFooterDataRef.current.text
          : activityTypeFooterTextFactoryMap[
              WorkshopActivityType.ClosedQuestionActivity
            ](playersClicked, isNextGoToDiscussion);

        actionFooterDataRef.current.buttonText = "Go to discussion";
      }

      if (isConsensusMode) {
        actionFooterDataRef.current.disabledButton =
          currentActiveParticipantCount === 1
            ? !hasAlignment || !playerHasAnswered
            : !hasAlignment;

        actionFooterDataRef.current.text = getGroupText(
          currentActiveParticipantCount === 1
            ? playerHasAnswered
            : !!hasAlignment,
          playersClicked,
          playerHasAnswered
        );
        actionFooterDataRef.current.buttonText = "Continue";
      }

      return actionFooterDataRef.current;
    }

    actionFooterDataRef.current = {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: !isConsensusMode ? "Go to discussion" : "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
    return actionFooterDataRef.current;
  }, [
    isParticipating,
    isLoading,
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    isConsensusMode,
    isActivityTimeout,
    currentActiveParticipantCount,
    activityResultForCurrentProfile?.value,
    isNextGoToDiscussion,
    hasAlignment,
  ]);

  const getGroupProfileIdsSelectedAnswer = useCallback(
    (answerId: string) => {
      if (!isConsensusMode) {
        return [];
      }
      const answerData = currentActivityResultsForOtherProfiles || [];
      return answerData
        .filter(({ value }) => value.includes(answerId))
        .map(({ profileId }) => profileId)
        .filter((profileId) => currentActiveParticipants.includes(profileId));
    },
    [
      currentActiveParticipants,
      isConsensusMode,
      currentActivityResultsForOtherProfiles,
    ]
  );

  // const onChallengeModalVisibility = useCallback(
  //   (visibility: boolean) => {
  //     setChallengeModalVisible(visibility && !isTransitioning);
  //   },
  //   [isTransitioning]
  // );

  // const onChallengeModalSubmit = useCallback(
  //   (challenge: string) => {
  //     if (isTransitioning) {
  //       return Promise.resolve();
  //     }
  //     return createChallenge(apolloContext.client, {
  //       activity_id: activityData.id,
  //       workshop_id: activity.workshop_id,
  //       challenge,
  //     });
  //   },
  //   [activityData.id, activity.workshop_id, apolloContext.client, isTransitioning]
  // );

  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );

  const hasCaseStudy = useMemo(
    () =>
      !!activityData.questionInstructions ||
      (!!activityData.questionTitle &&
        (!!activityData.questionDescription || !!videoUrl)),
    [activityData, videoUrl]
  );

  return (
    <>
      <ScrollIndicator key={activityData.id} className="activity-container">
        {/* {isChallengeModalVisible && (
          <ChallengeAuthorModal
            submitDialogHandler={onChallengeModalSubmit}
            closeDialogHandler={() => onChallengeModalVisibility(false)}
          />
        )} */}
        <div
          className={cn(
            styles.container,
            "main-container",
            isViewResults && styles.viewResultsContainer
          )}
        >
          <div className={styles.leftWrapper}>
            {activityData.questionInstructions && <AssignmentTitle />}
            {hasCaseStudy && (
              <CaseStudy
                questionInstructions={activityData.questionInstructions}
                questionTitle={activityData.questionTitle}
                questionDescription={activityData.questionDescription}
                videoUrl={videoUrl}
              />
            )}
          </div>
          <div className={styles.answersDataContainer}>
            {/* {!isViewResults && (
              <p className="text">
                {currentActivityPart === ActivityPart.Review
                  ? "Now that you know the group's and the author's choice, adjust your individual choice if needed."
                  : "Choose the most correct answer:"}
              </p>
            )} */}
            <ContentfulRichField
              content={activityData.instructions}
              className={cn(styles.instructions, "text")}
            />
            <div className={cn(styles.answersContainer)}>
              {answers.map((answer: ClosedQuestionAnswer) => {
                const loading =
                  isLoading && selectedAnswerIdRef.current === answer.sys.id;
                const isSelected =
                  !loading &&
                  (activityData.canAddNewAnswer
                    ? currentParsedValue?.data?.playerSelectedValues[
                        profileId
                      ] === answer.sys.id
                    : !!activityResultForCurrentProfile?.value.includes(
                        answer.sys.id
                      ));

                const answerData = {
                  id: answer.sys.id,
                  title: answer.fields.title,
                  text: answer.fields.text || undefined,
                };

                const shouldBeExpandable = !!answer.fields.text;
                const answerProps = shouldBeExpandable
                  ? {
                      type: AnswerType.EXPANDABLE as const,
                      expanded: expandedAnswerExplanations.includes(
                        answerData.id
                      ),
                      content: (
                        <ContentfulRichField
                          content={answerData.text!.replaceAll(/\\n|\\/gm, "")}
                          className={cn(
                            styles.answerExplanation,
                            expandedAnswerExplanations.includes(answerData.id)
                              ? "expanded"
                              : "",
                            "text",
                            "small"
                          )}
                        />
                      ),
                      onExpandCollapseHandler: (e: SyntheticEvent) => {
                        e?.stopPropagation();
                        if (
                          expandedAnswerExplanations.includes(answerData.id)
                        ) {
                          setExpandedAnswerExplanations(
                            expandedAnswerExplanations.filter(
                              (id) => id !== answerData.id
                            )
                          );
                        } else {
                          setExpandedAnswerExplanations([
                            ...expandedAnswerExplanations,
                            answerData.id,
                          ]);
                        }
                      },
                    }
                  : {
                      type: AnswerType.STATIC as const,
                    };

                if (isConsensusMode) {
                  const profilesSelectedAnswer =
                    getGroupProfileIdsSelectedAnswer(answer.sys.id);

                  const showIcon =
                    !loading &&
                    (profilesSelectedAnswer.length > 0 || !!isSelected);
                  const iconClass =
                    hasAlignment && profilesSelectedAnswer.length > 0
                      ? "fa-users"
                      : "fa-user";
                  const othersSelected = !isSelected && showIcon;

                  return (
                    <Answer
                      key={answer.sys.id + "-group"}
                      isLoading={loading}
                      isSelected={isSelected}
                      othersSelected={othersSelected}
                      groupSelected={
                        !!hasAlignment && profilesSelectedAnswer.length > 0
                      }
                      isTransitioning={isTransitioning}
                      onSelectAnswerHandler={() =>
                        prepDataAndSetActivityValueHandler(answer.sys.id)
                      }
                      iconClass={iconClass}
                      showIcon={showIcon}
                      answerTitle={answerData.title}
                      delayInMilliseconds={delayInMilliseconds}
                      {...answerProps}
                    />
                  );
                }

                return (
                  <Answer
                    key={answer.sys.id + "-individual"}
                    isLoading={loading}
                    isSelected={isSelected}
                    isTransitioning={isTransitioning}
                    onSelectAnswerHandler={() =>
                      prepDataAndSetActivityValueHandler(answer.sys.id)
                    }
                    iconClass="fa-user"
                    showIcon={
                      !loading &&
                      answer &&
                      !!activityResultForCurrentProfile?.value.includes(
                        answer.sys.id
                      )
                    }
                    answerTitle={answerData.title}
                    delayInMilliseconds={delayInMilliseconds}
                    {...answerProps}
                  />
                );
              })}
              {activityData.canAddNewAnswer && (
                <CustomDefinition
                  answerId={newDefinitionKey}
                  isParticipating={isParticipating}
                  profileId={profileId}
                  profileName={profileName}
                  activityData={activityData}
                  currentParsedValue={currentParsedValue}
                  loading={isLoading}
                  groupIsAligned={!!hasAlignment}
                  getGroupProfileIdsSelectedAnswer={
                    getGroupProfileIdsSelectedAnswer
                  }
                  isTransitioning={isTransitioning}
                  delayInMilliseconds={delayInMilliseconds}
                  activityResultForCurrentProfile={
                    activityResultForCurrentProfile
                  }
                  docs={docs}
                  parseComplexResultValue={parseComplexResultValue}
                  isViewResults={isViewResults}
                  onSelectAnswerHandler={prepDataAndSetActivityValueHandler}
                  onValueUpdate={onValueUpdate}
                  onExpandCollapseHandler={(e: SyntheticEvent) => {
                    e?.stopPropagation();
                    if (expandedAnswerExplanations.includes(newDefinitionKey)) {
                      setExpandedAnswerExplanations(
                        expandedAnswerExplanations.filter(
                          (id) => id !== newDefinitionKey
                        )
                      );
                    } else {
                      setExpandedAnswerExplanations([
                        ...expandedAnswerExplanations,
                        newDefinitionKey,
                      ]);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() => {
            setActivityReadyHandler({ activityId: activityData.id });
          }}
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
});
