import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import withRouteConfig from "../../../hocs/withRouteConfig";

import { calculateWorkshopDuration, getTransitionData } from "../../../utils";
import {
  StandardSessionActivity,
  standardSessionActivityMapper,
} from "../../../apollo-graphql/types/enums/standard-session-activity";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import { InvitationStatus } from "../../../types/enums/invitation-status";
import { Workspace } from "../../../apollo-graphql/types/workspace";
import { Slot } from "../../../apollo-graphql/types/slot";
import { WorkshopState } from "../../../+xstate/machines/session/workshop";
import { ConnectionStrength } from "../../../types/enums/connection-strength";
import { Profile } from "../../../apollo-graphql/types/profile";
import { Workshop } from "../../../types/contentful/workshop/workshop";
import { ActivityType } from "../../../types/contentful/workshop/activity-type";
import { ConferenceMode } from "../../../types/contentful/enums";

import ViewResults from "../Activities/ViewResults/ViewResults";
import WorkshopActivity from "../Activity/Activity";
import WarmUp from "../Activities/WarmUp/WarmUp";
import Opening from "../Activities/Opening/Opening";
import Emotion from "../Activities/Emotion/Emotion";
import NextStepTransition from "../NextStepTransition/NextStepTransition";
import Moment from "../Activities/Moment/Moment";

import styles from "./Workshop.module.css";
import { RemoteParticipantData } from "../../../types/jitsi";

const CONNECTION_LOSS_REDIRECT_TIMEOUT = 10_000;

function WorkshopInstance(
  props: PropsWithChildren<{
    isSessionCompleted: boolean;
    workshop: Workshop;
    workshopState: WorkshopState;
    connectionStrength: ConnectionStrength | null;
    workspace: Workspace;
    slot: Slot;
    profile: Profile;
    transition: number;
    isReadyToStart: boolean;
    isParticipating: boolean;
    isConnected: boolean;
    sessionState: SessionStateValue;
    currentActivity: ActivityType | undefined;
    nextActivity: ActivityType | null;
    currentActivityId: StandardSessionActivity | string | null;
    reconnectTimeouts: { profileId: string }[];
    millisecondsToStart: number | null;
    nextWorkshopRequested: boolean;
    token: string;
    teamName: string;
    journeyItems: Workshop[] | null;
    remoteParticipantsData: RemoteParticipantData[];
    toggleParticipationHandler: () => void;
    setReadyToStartHandler: () => void;
    setActivityValueHandler: (args: {
      activityId: string;
      value: string;
    }) => void;
    setActivityReadyHandler: (args: { activityId: string }) => void;
    requestNextWorkshop: (id: string) => void;
  }>
) {
  const {
    workshop,
    workshopState,
    connectionStrength,
    slot,
    profile,
    transition,
    sessionState,
    isReadyToStart,
    isParticipating,
    isConnected,
    isSessionCompleted,
    currentActivity,
    nextActivity,
    currentActivityId,
    reconnectTimeouts,
    millisecondsToStart,
    teamName,
    journeyItems,
    nextWorkshopRequested,
    requestNextWorkshop,
    token,
    workspace,
    remoteParticipantsData,
    toggleParticipationHandler,
    setReadyToStartHandler,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const lostConnectionIdRef = useRef<NodeJS.Timeout | null>(null);

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const isUnknownConnectionStrength = useMemo(
    () => connectionStrength === ConnectionStrength.Unknown,
    [connectionStrength]
  );

  const isConnectionWeak = useMemo(
    () => connectionStrength === ConnectionStrength.Slow,
    [connectionStrength]
  );

  const isConnectionLost = useMemo(
    () => !isConnected || isUnknownConnectionStrength,
    [isConnected, isUnknownConnectionStrength]
  );

  const isOpeningStage = useMemo(
    () => currentActivityId === StandardSessionActivity.Opening,
    [currentActivityId]
  );

  const isStartEmotionStage = useMemo(
    () => currentActivityId === StandardSessionActivity.StartEmotion,
    [currentActivityId]
  );

  const isWarmUpStage = useMemo(
    () => currentActivityId === StandardSessionActivity.WarmUp,
    [currentActivityId]
  );

  const isEndEmotionStage = useMemo(
    () => currentActivityId === StandardSessionActivity.EndEmotion,
    [currentActivityId]
  );

  const isViewResultsStage = useMemo(
    () => currentActivityId === StandardSessionActivity.ViewResults,
    [currentActivityId]
  );

  const isMomentStage = useMemo(
    () =>
      currentActivityId === StandardSessionActivity.MomentIndividual ||
      currentActivityId === StandardSessionActivity.MomentGroup,
    [currentActivityId]
  );

  const startEmotionActivity = useMemo(() => {
    return workshop?.fields?.startEmotionActivity;
  }, [workshop?.fields?.startEmotionActivity]);

  const endEmotionActivity = useMemo(() => {
    return workshop?.fields?.endEmotionActivity;
  }, [workshop?.fields?.endEmotionActivity]);

  const openingActivity = useMemo(() => {
    return workshop?.fields?.openning;
  }, [workshop?.fields?.openning]);

  const activityResult = useMemo(
    () => sessionState.context.activityResult,
    [sessionState.context.activityResult]
  );

  const currentActivityResults = useMemo(
    () => activityResult.find((a) => a.key === currentActivityId)?.value || [],
    [activityResult, currentActivityId]
  );

  const activityResultForCurrentProfile = useMemo(
    () =>
      activityResult
        .find((a) => a.key === currentActivityId)
        ?.value.find((a) => a.profileId === profile.id) || null,
    [activityResult, currentActivityId, profile]
  );

  const currentActiveParticipants = useMemo(() => {
    const currentActiveProfiles =
      sessionState.context.currentActiveProfiles.map(
        ({ profileId }) => profileId
      );

    if (!reconnectTimeouts.length) return currentActiveProfiles;

    return currentActiveProfiles.filter((id) =>
      reconnectTimeouts.find((t) => t.profileId === id)
    );
  }, [sessionState.context.currentActiveProfiles, reconnectTimeouts]);

  const currentActiveParticipantCount = useMemo(
    () => currentActiveParticipants.length,
    [currentActiveParticipants.length]
  );

  const notReadyProfilesCount = useMemo(
    () =>
      sessionState.value === StandardSessionActivity.Opening
        ? sessionState.context.currentActiveProfiles.length -
          sessionState.context.readyActiveProfiles.length
        : sessionState.context.currentActiveProfiles.filter(({ profileId }) => {
            const match = currentActivityResults.find(
              (r) => r.profileId === profileId
            );
            return match === undefined || match.ready === false;
          })?.length,
    [
      currentActivityResults,
      sessionState.context.currentActiveProfiles,
      sessionState.context.readyActiveProfiles.length,
      sessionState.value,
    ]
  );

  const isActivityReady = activityResultForCurrentProfile?.ready || false;
  const activityValue = activityResultForCurrentProfile?.value || null;

  const internalSetActivityValueHandler = useCallback(
    (args: { activityId: string; value: string; markAsReady?: boolean }) => {
      if (isTransitioning) {
        return;
      }
      setActivityValueHandler(args);
    },
    [isTransitioning, setActivityValueHandler]
  );

  const setEmotionHandler = useCallback(
    (emotionValue: string) => {
      if (!isParticipating) {
        toggleParticipationHandler();
        return;
      }
      internalSetActivityValueHandler({
        activityId: isStartEmotionStage
          ? StandardSessionActivity.StartEmotion
          : StandardSessionActivity.EndEmotion,
        value: emotionValue,
      });
    },
    [
      isParticipating,
      internalSetActivityValueHandler,
      isStartEmotionStage,
      toggleParticipationHandler,
    ]
  );

  const setEmotionReadyHandler = useCallback(() => {
    if (!isParticipating) {
      toggleParticipationHandler();
      return;
    }

    setActivityReadyHandler({
      activityId: isStartEmotionStage
        ? StandardSessionActivity.StartEmotion
        : StandardSessionActivity.EndEmotion,
    });
  }, [
    isParticipating,
    isStartEmotionStage,
    setActivityReadyHandler,
    toggleParticipationHandler,
  ]);

  const setTeamNameHandler = useCallback(
    (value: string) => {
      if (!isParticipating) return;
      internalSetActivityValueHandler({
        activityId: StandardSessionActivity.WarmUp,
        value,
      });
    },
    [internalSetActivityValueHandler, isParticipating]
  );

  const setTeamNameReadyHandler = useCallback(() => {
    if (!isParticipating) {
      toggleParticipationHandler();
      return;
    }

    setActivityReadyHandler({
      activityId: StandardSessionActivity.WarmUp,
    });
  }, [isParticipating, setActivityReadyHandler, toggleParticipationHandler]);

  const journey = useMemo(
    () => workshop.fields?.journey,
    [workshop.fields?.journey]
  );

  const workshopAuthor = useMemo(
    () => journey?.fields.author,
    [journey?.fields.author]
  );

  const workshopAuthorData = useMemo(
    () => ({
      id: workshopAuthor?.sys.id,
      name: workshopAuthor?.fields.name,
      headline: workshopAuthor?.fields.headline,
      bio: workshopAuthor.fields.bio,
      image: {
        url: workshopAuthor?.fields?.image?.fields?.file?.url,
        fileName: workshopAuthor?.fields?.image?.fields?.file?.fileName,
      },
    }),
    [
      workshopAuthor.fields.bio,
      workshopAuthor.fields.headline,
      workshopAuthor.fields?.image?.fields?.file?.fileName,
      workshopAuthor.fields?.image?.fields?.file?.url,
      workshopAuthor.fields.name,
      workshopAuthor?.sys.id,
    ]
  );

  const workshopData = useMemo(
    () => ({
      id: workshop.sys.id,
      title: workshop.fields.title,
      headline: workshop.fields.welcomeText,
      objectives: workshop.fields.objectives,
      duration: calculateWorkshopDuration(workshop),
      author: workshopAuthorData,
      journey: workshop.fields.journey,
    }),
    [workshop, workshopAuthorData]
  );

  const workshopActivities = useMemo(
    () => workshop.fields.activities,
    [workshop.fields.activities]
  );

  const invitedParticipantCount = useMemo(
    () =>
      slot.invitations.filter(
        ({ status }) => status !== InvitationStatus.AUTO_GENERATED
      ).length,
    [slot.invitations]
  );

  const hasAhaMoments = useMemo(
    () => workshop?.fields?.ahaMoments?.length > 0,
    [workshop?.fields?.ahaMoments?.length]
  );

  // TODO: pass nextActivityData variable to all activities.
  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const Connecting = (
    <>
      {isConnectionLost && !isSessionCompleted && (
        <div className={styles.connectionIssuesOverlay}>
          <h3>Connecting...</h3>
        </div>
      )}
    </>
  );

  useEffect(() => {
    // Show the Connection overlay is case of connection lost
    // After a few seconds we should redirect the user to /lost-connection page
    if (
      isConnectionLost &&
      !isSessionCompleted &&
      !lostConnectionIdRef.current
    ) {
      const newPath = `/session/connection-lost/${pathname.replace(
        "/session/instance/",
        ""
      )}`;
      lostConnectionIdRef.current = setTimeout(() => {
        navigate(newPath);
      }, CONNECTION_LOSS_REDIRECT_TIMEOUT);
    }

    // We must interrupt the redirect if the connection is established
    if (!isConnectionLost && lostConnectionIdRef.current) {
      clearTimeout(lostConnectionIdRef.current);
      lostConnectionIdRef.current = null;
    }
  }, [isConnectionLost, isSessionCompleted, navigate, pathname]);

  if (isOpeningStage) {
    return (
      <>
        {Connecting}
        <Opening
          activity={openingActivity}
          slot={slot}
          author={workshopAuthorData}
          transition={transition}
          workshopState={workshopState}
          workshop={workshopData}
          profileId={profile.id}
          isReady={isReadyToStart}
          isConnectionWeak={isConnectionWeak}
          isParticipating={isParticipating}
          notReadyProfilesCount={notReadyProfilesCount}
          invitedParticipantCount={invitedParticipantCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          minimumWorkshopParticipants={
            sessionState.context.minimumWorkshopParticipants
          }
          setActivityReadyToStartHandler={setReadyToStartHandler}
          millisecondsToStart={millisecondsToStart}
          journeyItems={journeyItems}
        />
        {isTransitioning && (
          <NextStepTransition
            nextStep={
              startEmotionActivity?.fields?.activity?.fields?.transitionText
            }
            sessionType={
              startEmotionActivity?.fields?.activity?.fields?.conferenceMode
            }
            transition={transition}
          />
        )}
      </>
    );
  }
  if (isStartEmotionStage || isEndEmotionStage) {
    return (
      <>
        {Connecting}
        <Emotion
          activity={
            isStartEmotionStage ? startEmotionActivity : endEmotionActivity
          }
          workshopHeadline={workshopData.title}
          isParticipating={isParticipating}
          isReady={isActivityReady}
          workshopState={workshopState}
          isConnectionWeak={isConnectionWeak}
          currentEmotion={activityValue}
          isEndEmotionStage={isEndEmotionStage}
          notReadyProfilesCount={notReadyProfilesCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          setEmotionHandler={setEmotionHandler}
          transition={transition}
          setActivityReadyHandler={setEmotionReadyHandler}
        />
        {isTransitioning &&
          (workshop?.fields.warmUpActivity ? (
            <NextStepTransition
              nextStep={
                isStartEmotionStage
                  ? workshop?.fields.warmUpActivity?.fields.activity.fields
                      .transitionText
                  : standardSessionActivityMapper[
                      StandardSessionActivity.ViewResults
                    ]
              }
              sessionType={ConferenceMode.All}
              transition={transition}
            />
          ) : (
            <NextStepTransition
              nextStep={
                isStartEmotionStage
                  ? nextActivityData.transitionText
                  : standardSessionActivityMapper[
                      StandardSessionActivity.ViewResults
                    ]
              }
              sessionType={
                isStartEmotionStage
                  ? nextActivityData.conferenceMode
                  : ConferenceMode.All
              }
              transition={transition}
            />
          ))}
      </>
    );
  }
  if (isWarmUpStage) {
    return (
      <>
        {Connecting}
        <WarmUp
          teamName={teamName}
          warmUpActivity={workshop.fields.warmUpActivity}
          isParticipating={isParticipating}
          workshopState={workshopState}
          isReady={isActivityReady}
          profile={profile}
          isConnectionWeak={isConnectionWeak}
          transition={transition}
          notReadyProfilesCount={notReadyProfilesCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          teamNameValue={activityValue || ""}
          changeTeamNameHandler={setTeamNameHandler}
          setActivityReadyHandler={setTeamNameReadyHandler}
        />
        {isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={ConferenceMode.Solo}
            transition={transition}
          />
        )}
      </>
    );
  }
  if (isViewResultsStage) {
    return (
      <>
        {Connecting}
        <ViewResults
          sessionState={sessionState}
          workshopActivities={workshopActivities}
          activityResult={activityResult}
          profile={profile}
          workshop={workshop}
          journeyItems={journeyItems}
          token={token}
          workspace={workspace}
          requestNextWorkshop={requestNextWorkshop}
          nextWorkshopRequested={nextWorkshopRequested}
          isConnectionWeak={isConnectionWeak}
          isParticipating={isParticipating}
        />
      </>
    );
  }
  if (isMomentStage) {
    const transitionText =
      currentActivityId === StandardSessionActivity.MomentIndividual
        ? "Reflection, Author’s Insights"
        : "Final Emotion";

    return (
      <>
        {Connecting}
        <Moment
          profileId={profile.id}
          isReady={isActivityReady}
          isConnectionWeak={isConnectionWeak}
          transition={transition}
          activityId={
            currentActivityId as
              | StandardSessionActivity.MomentIndividual
              | StandardSessionActivity.MomentGroup
          }
          activityResult={activityResult}
          notReadyProfilesCount={notReadyProfilesCount}
          isParticipating={isParticipating}
          isLoading={workshopState === WorkshopState.SettingValue}
          currentActiveParticipantCount={currentActiveParticipantCount}
          setActivityValueHandler={internalSetActivityValueHandler}
          setActivityReadyHandler={setActivityReadyHandler}
          workshopAuthor={workshopAuthorData}
          ahaMoments={workshop.fields.ahaMoments}
        />
        {isTransitioning && (
          <NextStepTransition
            nextStep={transitionText}
            sessionType={ConferenceMode.All}
            transition={transition}
          />
        )}
      </>
    );
  }

  if (!currentActivity || !currentActivityId) return null;

  return (
    <>
      {Connecting}
      <WorkshopActivity
        profile={profile}
        isReady={isActivityReady}
        isConnectionWeak={isConnectionWeak}
        transition={transition}
        workshopState={workshopState}
        activity={currentActivity}
        nextActivity={nextActivity}
        currentActivityId={currentActivityId}
        activityResult={activityResult}
        currentActivityResults={currentActivityResults}
        activityResultForCurrentProfile={activityResultForCurrentProfile}
        notReadyProfilesCount={notReadyProfilesCount}
        isParticipating={isParticipating}
        currentActiveParticipants={currentActiveParticipants}
        currentActiveParticipantCount={currentActiveParticipantCount}
        workshopActivities={workshopActivities}
        hasAhaMoments={hasAhaMoments}
        remoteParticipantsData={remoteParticipantsData}
        setActivityValueHandler={internalSetActivityValueHandler}
        setActivityReadyHandler={setActivityReadyHandler}
      />
    </>
  );
}

export default memo(withRouteConfig(WorkshopInstance));
