import { ActionFooterType } from "../../../types/action-footer";
import { ConferenceMode } from "../../../types/contentful/enums";
import { FooterType } from "../../../types/enums/activity-footer";

export const nextActivityTypeMapper = {
  [ConferenceMode.Solo]: "Individual",
  [ConferenceMode.All]: "Group",
};

export const observerFooterData: ActionFooterType = {
  buttonText: "Rejoin",
  disabledButton: false,
  text: "You are in observer mode! The other players can continue without you and you can watch them. To start playing again click “Rejoin”.",
  type: FooterType.Observer,
};
