import { PropsWithChildren, memo, useCallback, useMemo } from "react";

import debounce from "lodash/debounce";

import { useReadyRemovedDueToChange } from "../../../../hooks/useReadyRemovedDueToChange";
import { useAwareness } from "../../../../hooks/useAwareness";
import { useEditorClass } from "../../../../hooks/useEditorClass";
import { UseDocComplexValueType, useDocMap } from "../../../../hooks/useDocMap";

import {
  extractMultiValueComplexValue,
  extractMultiValueResult,
  generateDefaultMultiValue,
} from "../utils/ydoc-helpers";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { getEntryId, getTransitionData } from "../../../../utils";
import { ConceptualisationActivity } from "../../../../types/contentful/workshop/activities/conceptualisation";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { observerFooterData } from "../constants";

import { SessionStateValue } from "../../../../apollo-graphql/types/session-state";
import { ActionFooterType } from "../../../../types/action-footer";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActivityComplexValue } from "../../../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../../../types/enums/activity-complex-value-type";
import { FooterType } from "../../../../types/enums/activity-footer";
import { Profile } from "../../../../apollo-graphql/types/profile";

import InfoBox from "../../../InfoBox/InfoBox";
import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import Editor from "../../../Shared/Editor/Editor";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./Conceptualisation.module.css";
import cn from "classnames";

interface ConceptualisationProps extends ActivityCommon {
  profile: Profile;
  activityResults:
  | SessionStateValue["context"]["activityResult"][0]["value"]
  | null;
  isViewResults?: boolean;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Conceptualisation = (
  props: PropsWithChildren<ConceptualisationProps>
) => {
  const {
    transition,
    isReady,
    activity,
    nextActivity,
    isLoading,
    isConnectionWeak,
    activityResults,
    profile,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const { changeStateText, userValueChangeHandler } =
    useReadyRemovedDueToChange(isReady);

  const items = useMemo(() => {
    return (activity as ConceptualisationActivity)?.fields?.items?.map(
      (item) => {
        return {
          id: getEntryId(item),
          title: item?.fields?.title,
          placeholderText: item?.fields?.placeholderText,
        };
      }
    );
  }, [activity]);

  const editorDeps = useMemo(() => items?.map(({ id }) => id), [items]);

  const { awarenessState, onAwarenessUpdate } = useAwareness(editorDeps);
  const { docs, generateComplexResultValueAsString, parseComplexResultValue } =
    useDocMap(editorDeps);

  const activityData = useMemo(() => {
    return {
      id: getEntryId(activity),
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const editorClass = useEditorClass({
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
  });

  const parsedData = useMemo(() => {
    const defaultResult = generateDefaultMultiValue(
      editorDeps,
      isViewResults || !isParticipating
    );

    const resultValue = activityResults?.[0]?.value;

    if (!resultValue || resultValue === ACTIVITY_TIMEOUT_VALUE)
      return defaultResult;

    const complexValue = extractMultiValueComplexValue(resultValue, editorDeps);

    if (!complexValue) return defaultResult;

    if (isViewResults) {
      const extractedData = extractMultiValueResult(
        complexValue,
        editorDeps,
        isViewResults || !isParticipating
      );

      return extractedData || defaultResult;
    }

    const extractedData = parseComplexResultValue(
      complexValue,
      !isParticipating
        ? UseDocComplexValueType.String
        : UseDocComplexValueType.Uint8Array
    );

    return extractedData || defaultResult;
  }, [
    editorDeps,
    isViewResults,
    isParticipating,
    activityResults,
    parseComplexResultValue,
  ]);

  const debouncedSetActivityValue = useMemo(
    () =>
      debounce((value: string) => {
        setActivityValueHandler({ activityId: activityData.id, value });
        userValueChangeHandler();
      }, 200),
    [activityData.id, setActivityValueHandler, userValueChangeHandler]
  );

  const isButtonDisabled = editorDeps.every(
    (key) => docs[key]?.getText("codemirror")?.toString().length === 0
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready! Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;
    return {
      text: (
        <>
          {isButtonDisabled
            ? "Write your collective definitions before you can continue."
            : `${changeStateText}Everyone must agree to continue!`}{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} did it!
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: isButtonDisabled,
      type: FooterType.Notice,
    };
  }, [
    isActivityTimeout,
    isTransitioning,
    isParticipating,
    isButtonDisabled,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    changeStateText,
  ]);

  const onValueUpdate = useCallback(() => {
    const activityComplexValue: ActivityComplexValue = {
      value: generateComplexResultValueAsString(),
      type: ActivityComplexValueType.yjs,
    };

    debouncedSetActivityValue(JSON.stringify(activityComplexValue));
  }, [debouncedSetActivityValue, generateComplexResultValueAsString]);

  const titleContent = useMemo(() => {
    const html = activity?.fields?.activity?.fields?.instructions;
    if (!html) return null;

    return (
      <ContentfulRichField
        className={styles.title}
        content={html}
      />
    );
  }, [activity]);

  const content = useMemo(() => {
    if (!items || items?.length === 0) return null;
    return items.map((item) => {
      const value = parsedData[item.id];
      const awareness =
        awarenessState.find((a) => a.name === item.id)?.awareness || null;

      return (
        <div className={styles.textAreaContent} key={item.id}>
          <p className="text bold label">{item.title}</p>

          <div className={styles.textAreaWrapper}>
            {!isViewResults && isParticipating ? (
              <Editor
                doc={docs[item.id]}
                name={item.id}
                key={item.id}
                onValueUpdate={onValueUpdate}
                onAwarenessUpdate={onAwarenessUpdate}
                value={value as Uint8Array}
                awareness={awareness}
                profileName={profile.name}
                placeholder={item.placeholderText}
                isInput={false}
                className={editorClass}
              />
            ) : (
              <div
                className={cn("text", "textarea-sync-disabled", editorClass)}
                key={`${activityData.id}-${item.id}`}
              >
                {value as string}
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [
    activityData.id,
    awarenessState,
    docs,
    editorClass,
    isParticipating,
    isViewResults,
    items,
    onAwarenessUpdate,
    onValueUpdate,
    parsedData,
    profile.name,
  ]);

  return (
    <>
      <ScrollIndicator key={activityData.id} className="activity-container">
        <div className={cn(styles.container, "main-container")}>
          <div className={styles.content}>
            {titleContent}
            <div className={styles.textAreaContainer}>{content}</div>
            {!isViewResults && (
              <div className={styles.infoContainer}>
                <InfoBox
                  title="These are synchronized fields!"
                  description="Everyone can write together and everyone can make changes of the texts at the same time."
                  isDismissible
                />
              </div>
            )}
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default memo(Conceptualisation);
