export enum SectionTypes {
  SECTION_A = "sectionA",
  SECTION_B = "sectionB",
}

export type ISection = {
  [SectionTypes.SECTION_A]: IDragAndDropCard[];
  [SectionTypes.SECTION_B]: IDragAndDropCard[];
};

export interface IDragAndDropCard {
  id: string;
  profileId: string;
  text?: string;
  isDraggable: boolean;
  index?: number;
  title?: string;
  timestamp?: number;
}
