import { gql } from "@apollo/client";
import { LoginResult } from "../types/results/login-result";
import { AppApolloClient } from "../../contexts/Apollo";
import { ILogin } from "../types/login";

const mutation = gql`
  mutation Mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      profile {
        email
        create_date
        headline
        id
        image
        is_completed
        login_date
        update_date
        name
        journeyCompleteness {
          journey {
            sys {
              id
            }
            fields {
              title
            }
          }
          percentage
          completedWorkshops {
            sys {
              id
            }
            fields {
              title
            }
          }
        }
        workspace {
          access
          profile_id
          status
          title
          workspace_id
          workspace {
            create_date
            domains {
              domain
            }
            id
            image
            name
            update_date
            workspace_key
            availableJourneys {
              journey_id
            }
            certificates {
              id
              create_date
              update_date
              workspace_id
              journey_id
              profile_id
              is_valid
              last_invalidation_date
            }
            theme_id
            theme {
              id
              name
            }
          }
        }
      }
      token
    }
  }
`;

export function login(client: AppApolloClient, variables: ILogin) {
  return client
    .mutate<LoginResult>({ mutation, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.login;
    });
}
