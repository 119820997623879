import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { JourneyLeader } from "../types";

const mutation = gql`
  mutation SetJourneyLeaders(
    $journeyLeaders: [String]!
    $journeyId: String!
    $workspaceId: String
  ) {
    setJourneyLeaders(
      journeyLeaders: $journeyLeaders
      journeyId: $journeyId
      workspaceId: $workspaceId
    ) {
      id
      create_date
      update_date
      journey_id
      workspace_id
      profile_id
    }
  }
`;

export function setJourneyLeaders(
  client: AppApolloClient,
  variables: {
    journeyLeaders: string[];
    journeyId: string;
    workspaceId: string;
  }
) {
  return client
    .mutate<{ setJourneyLeaders: JourneyLeader[] }>({ mutation, variables })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors);
      return result.data!.setJourneyLeaders;
    });
}
