import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import JitsiList from "../JitsiList/JitsiList";
import TabSwitch from "../TabSwitch/TabSwitch";
import DeviceSelect from "../Shared/DeviceSelect/DeviceSelect";
import LeaderBoard from "./components/LeaderBoard/LeaderBoard";
import TranscriptList, {
  Transcript,
} from "./components/TranscriptList/TranscriptList";
import InviteTeamMembersModal from "../JitsiList/components/InviteTeamMembersModal";
import Tooltip from "../Shared/Tooltip/Tooltip";

import { RemoteParticipantData } from "../../types/jitsi";
import { Profile } from "../../apollo-graphql/types/profile";
import { SessionStateValue } from "../../apollo-graphql/types/session-state";
import { Slot } from "../../apollo-graphql/types/slot";
import { StandardSessionActivity } from "../../apollo-graphql/types/enums/standard-session-activity";
import { OutputDevice } from "../../types/output-device";
import { stepsSelectors } from "../Guide/steps";
import { ActivityType } from "../../types/contentful/workshop/activity-type";

import cn from "classnames";
import styles from "./SidePanel.module.css";
import { SourceData } from "../../types/source-data";

enum SidePanelTab {
  PARTICIPANTS = "participants",
  TRANSCRIPTS = "transcripts",
}

function SidePanel(
  props: PropsWithChildren<{
    hasLeaderBoard: boolean;
    hasTeamName: boolean;
    transcripts: Transcript[];
    transition?: number;
    currentActivity?: ActivityType | undefined;
    currentActivityId?: string | null;
    allActivityResults?: SessionStateValue["context"]["activityResult"];
    allQuestionActivitiesAnswersMap?: { [key: string]: any };
    profile?: Profile;
    isUserTalking?: boolean;
    isParticipating?: boolean;
    disableAudioAndVideo?: boolean;
    containerClass?: string;
    hideTabSwitched?: boolean;
    hideControlPanel?: boolean;
    selectedAudioDevice?: SourceData | null;
    selectedVideoDevice?: SourceData | null;
    availableAudioSources?: SourceData[] | null;
    availableVideoSources?: SourceData[] | null;
    remoteParticipantsData?: RemoteParticipantData[];
    teamName?: string;
    slot?: Slot | null;
    currentProfileIsObserver?: boolean;
    audioDeviceChangeHandler?: (newSourceId: string) => void;
    videoDeviceChangeHandler?: (newSourceId: string) => void;
    toggleParticipationHandler?: () => void;
    attachHtmlVideoElementToLocalTracks?: (
      videoElement: HTMLVideoElement
    ) => void;
    attachHtmlVideoElementToRemoteTracks?: (
      participantId: string,
      videoElement: HTMLVideoElement,
      audioElement: HTMLAudioElement
    ) => void;
    toggleAudioHandler?: (muted?: boolean) => void;
    toggleVideoHandler?: (muted?: boolean) => void;
  }>
) {
  const {
    transition,
    hasLeaderBoard,
    hasTeamName,
    transcripts,
    currentActivity,
    currentActivityId,
    allActivityResults,
    allQuestionActivitiesAnswersMap,
    profile,
    isUserTalking,
    selectedAudioDevice,
    selectedVideoDevice,
    availableAudioSources,
    availableVideoSources,
    disableAudioAndVideo,
    remoteParticipantsData,
    isParticipating,
    attachHtmlVideoElementToLocalTracks,
    attachHtmlVideoElementToRemoteTracks,
    toggleParticipationHandler,
    toggleAudioHandler,
    toggleVideoHandler,
    audioDeviceChangeHandler,
    videoDeviceChangeHandler,
    containerClass,
    hideTabSwitched = false,
    hideControlPanel = false,
    teamName,
    slot,
    currentProfileIsObserver,
  } = props;

  const [activeTab, setActiveTab] = useState<SidePanelTab>(
    hideTabSwitched ? SidePanelTab.TRANSCRIPTS : SidePanelTab.PARTICIPANTS
  );
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const isTransitioning = useMemo(() => (transition || 0) > 0, [transition]);

  const videoToggleHandlerWithDisable = useCallback(() => {
    toggleVideoHandler?.();
  }, [toggleVideoHandler]);

  const audioToggleHandlerWithDisable = useCallback(() => {
    toggleAudioHandler?.();
  }, [toggleAudioHandler]);

  // TODO: Update the logic for Question Activity
  const showLeaderBoard = useMemo(() => {
    if (!hasLeaderBoard) return false;
    let shouldShowLeaderBoard = false;
    const questionIds = Object.keys(allQuestionActivitiesAnswersMap || {});
    const activitiesWithReview = allActivityResults || [{ key: "" }];

    // allActivityResults?.filter((a) =>
    //   a.value.find((a) => a.key === "review")
    // ) || [];

    for (const { key } of activitiesWithReview) {
      if (questionIds.includes(key)) {
        shouldShowLeaderBoard = true;
        break;
      }
    }

    return shouldShowLeaderBoard;

    // return (
    //   shouldShowLeaderBoard ||
    //   (!!currentActivity &&
    //     (currentActivity.type === ActivityType.Question ||
    //       currentActivity.id === StandardSessionActivity.Rating))
    // );
  }, [allActivityResults, allQuestionActivitiesAnswersMap, hasLeaderBoard]);

  const showTabSwitcher = useMemo(() => {
    return !hideTabSwitched;
  }, [hideTabSwitched]);

  const tabs = useMemo(() => {
    if (!showTabSwitcher) {
      return [];
    }

    const tabs = [
      {
        id: SidePanelTab.PARTICIPANTS,
        label: "Participants",
      },
    ];

    if (!!currentActivity) {
      tabs.push({
        id: SidePanelTab.TRANSCRIPTS,
        label: "Transcripts",
      });
    }

    return tabs;
  }, [showTabSwitcher, currentActivity]);

  const parsedAvailableAudioSources = useMemo(
    () =>
      (availableAudioSources || [])
        .map((source) => ({
          label: source.label!,
          value: source.sourceId!,
          key: source.sourceId!,
        }))
        .filter((source) => !!source.value),
    [availableAudioSources]
  );

  const parsedAvailableVideoSources = useMemo(
    () =>
      (availableVideoSources || [])
        .map((source) => ({
          label: source.label!,
          value: source.sourceId!,
          key: source.sourceId!,
        }))
        .filter((source) => !!source.value),
    [availableVideoSources]
  );

  const hasVideo = useMemo(
    () => selectedVideoDevice && selectedVideoDevice.sourceId,
    [selectedVideoDevice]
  );

  const isCurrentUserAudioMutedOrUnavailable = useMemo(
    () => !!selectedAudioDevice?.isMuted || !selectedAudioDevice?.sourceId,
    [selectedAudioDevice]
  );

  useEffect(() => {
    if (!showTabSwitcher) {
      return;
    }

    if (currentActivity?.fields?.activity?.fields?.transcript) {
      setActiveTab(SidePanelTab.TRANSCRIPTS);
    } else {
      setActiveTab(SidePanelTab.PARTICIPANTS);
    }
  }, [
    currentActivity?.fields?.activity?.fields?.transcript,
    showTabSwitcher,
    transcripts,
  ]);

  return (
    <div className={cn(styles.sidePanelContainer, containerClass || "")}>
      {showLeaderBoard && (
        <LeaderBoard
          isTransitioning={isTransitioning}
          allActivityResults={allActivityResults}
          allQuestionActivitiesAnswersMap={allQuestionActivitiesAnswersMap}
        />
      )}
      <div
        className={cn(styles.container, "main-container", {
          [stepsSelectors.conferenceContainer]: !hideControlPanel,
          [styles.containerWithLeaderBoard]: showLeaderBoard,
        })}
      >
        {!hideTabSwitched && (
          <div className={styles.topControls}>
            {showTabSwitcher && (
              <TabSwitch
                tabs={tabs}
                activeTabId={activeTab}
                setActiveTab={(t) => setActiveTab(t as SidePanelTab)}
              />
            )}

            <div>
              {/* Temporary solution, until the feature is implemented */}
              <button
                className="btn ghost small"
                onClick={() => setInviteModalOpen(true)}
              >
                + Invite members
              </button>
              {inviteModalOpen && (
                <InviteTeamMembersModal
                  slot={slot || null}
                  closeDialogHandler={() => setInviteModalOpen(false)}
                />
              )}
            </div>
          </div>
        )}

        <div className={styles.content}>
          <div
            style={{
              visibility:
                activeTab === SidePanelTab.PARTICIPANTS ? "visible" : "hidden",
              height: activeTab === SidePanelTab.PARTICIPANTS ? "auto" : 0,
            }}
          >
            {attachHtmlVideoElementToLocalTracks &&
              attachHtmlVideoElementToRemoteTracks &&
              remoteParticipantsData && (
                <div className={cn(styles.participantsContainer)}>
                  <JitsiList
                    profile={profile!}
                    isUserTalking={isUserTalking!}
                    disableAudioAndVideo={disableAudioAndVideo}
                    isCurrentUserVideoMutedOrUnavailable={
                      !!selectedVideoDevice?.isMuted ||
                      !selectedVideoDevice?.sourceId
                    }
                    isCurrentUserAudioMutedOrUnavailable={
                      isCurrentUserAudioMutedOrUnavailable
                    }
                    remoteParticipantsData={remoteParticipantsData}
                    attachHtmlVideoElementToLocalTracks={
                      attachHtmlVideoElementToLocalTracks
                    }
                    attachHtmlVideoElementToRemoteTracks={
                      attachHtmlVideoElementToRemoteTracks
                    }
                    hasTeamName={hasTeamName}
                    teamName={teamName}
                    isParticipating={!!isParticipating}
                    currentProfileIsObserver={
                      !!currentProfileIsObserver &&
                      !!currentActivityId &&
                      currentActivityId !== StandardSessionActivity.ViewResults
                    }
                  />
                </div>
              )}
          </div>
          {activeTab === SidePanelTab.TRANSCRIPTS && (
            <TranscriptList
              transcripts={transcripts}
              showLeaderBoard={showLeaderBoard}
              inOverview={hideTabSwitched}
            />
          )}
        </div>
        {!hideControlPanel && (
          <div className={styles.controlsContainer}>
            {audioDeviceChangeHandler && videoDeviceChangeHandler && (
              <div className={styles.deviceControls}>
                <div
                  className={cn(
                    styles.deviceControl,
                    selectedAudioDevice?.isMuted && "device-muted",
                    disableAudioAndVideo && "deactivated"
                  )}
                >
                  <button
                    className={cn(
                      "btn small transparent-bg disable-disabled-opacity"
                    )}
                    onClick={audioToggleHandlerWithDisable}
                    disabled={disableAudioAndVideo}
                  >
                    <i
                      className={cn(
                        "icon fa",
                        styles.deviceControlIcon,
                        selectedAudioDevice?.isMuted || disableAudioAndVideo
                          ? "fa-microphone-slash red"
                          : "fa-microphone secondary"
                      )}
                    ></i>
                  </button>
                  <DeviceSelect
                    deviceName={OutputDevice.AUDIO}
                    deviceId={selectedAudioDevice?.sourceId}
                    values={parsedAvailableAudioSources}
                    disabled={
                      !!disableAudioAndVideo || !!selectedAudioDevice?.isMuted
                    }
                    onClickHandler={audioDeviceChangeHandler}
                    initialArrowDirection="up"
                  />
                </div>
                {hasVideo && (
                  <div
                    className={cn(
                      styles.deviceControl,
                      selectedVideoDevice?.isMuted && "device-muted",
                      disableAudioAndVideo && "deactivated"
                    )}
                  >
                    <button
                      className={cn(
                        "btn small secondary transparent-bg disable-disabled-opacity"
                      )}
                      onClick={videoToggleHandlerWithDisable}
                      disabled={disableAudioAndVideo}
                    >
                      <i
                        className={cn(
                          "icon fa",
                          styles.deviceControlIcon,
                          selectedVideoDevice?.isMuted || disableAudioAndVideo
                            ? "fa-video-slash red"
                            : "fa-video"
                        )}
                      ></i>
                    </button>
                    <DeviceSelect
                      deviceName={OutputDevice.VIDEO}
                      deviceId={selectedVideoDevice?.sourceId}
                      values={parsedAvailableVideoSources}
                      disabled={
                        !!disableAudioAndVideo || !!selectedVideoDevice?.isMuted
                      }
                      onClickHandler={videoDeviceChangeHandler}
                      initialArrowDirection="up"
                    />
                  </div>
                )}
              </div>
            )}

            {currentActivityId !== StandardSessionActivity.ViewResults && (
              <Tooltip
                containerClass={styles.tooltipContainer}
                message={
                  isParticipating
                    ? "Start observer mode to let the other players continue without you."
                    : "Rejoin to continue playing."
                }
              >
                <button
                  className={cn(
                    "btn",
                    "small",
                    isParticipating ? "ghost" : "primary"
                  )}
                  onClick={toggleParticipationHandler}
                >
                  {isParticipating ? (
                    <>
                      <i className="icon fa fa-eye" /> Observer mode
                    </>
                  ) : (
                    <>
                      <i className="icon fa fa-right-to-bracket" /> Rejoin
                    </>
                  )}
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(SidePanel);
