import "./App.css";
import Router from "./Router";
import { GlobalContextProvider } from "./contexts/Global";
import { ApolloProvider } from "./contexts/Apollo";
import { PropsWithChildren } from "react";
import { DocumentVisibilityContextProvider } from "./contexts/DocumentVisibility";
import { InternetConnectionContextProvider } from "./contexts/InternetConnection";
import { DevicePermissions } from "./utils/check-device-permissions";
import { AuthCtx } from "./types/auth-ctx";
import { TechnicalSetupConfig } from "./types/technical-setup-config";

function App(
  props: PropsWithChildren<{
    authCtx: AuthCtx | null;
    instanceUUID: string;
    devicePermissions: DevicePermissions;
    technicalSetupConfig: TechnicalSetupConfig | null;
    skipTechnicalSetup?: boolean;
  }>
) {
  const {
    authCtx,
    instanceUUID,
    devicePermissions,
    technicalSetupConfig,
    skipTechnicalSetup,
  } = props;
  return (
    <div className="App">
      <InternetConnectionContextProvider>
        <DocumentVisibilityContextProvider>
          <ApolloProvider>
            <GlobalContextProvider
              authCtx={authCtx}
              instanceUUID={instanceUUID}
              devicePermissions={devicePermissions}
              technicalSetupConfig={technicalSetupConfig}
              skipTechnicalSetup={skipTechnicalSetup}
            >
              <Router />
            </GlobalContextProvider>
          </ApolloProvider>
        </DocumentVisibilityContextProvider>
      </InternetConnectionContextProvider>
    </div>
  );
}

export default App;
