import { memo, PropsWithChildren, useCallback, useMemo } from "react";

import cn from "classnames";

import { formatDate } from "date-fns";
import { SectionItemType } from "../../../../types/contentful/workshop/summary";

import LoadingButton from "../../../Shared/Buttons/LoadingButton/LoadingButton";

import styles from "./JourneyCard.module.css";

export enum JourneyStatus {
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  LOCKED = "LOCKED",
}

const sectionImagePathMapper = {
  [SectionItemType.Link]: (status: JourneyStatus) =>
    status === JourneyStatus.COMPLETED
      ? "/images/unlock-link-icon.svg"
      : "/images/unlock-link-icon-gray.svg",
  [SectionItemType.Conversation]: (status: JourneyStatus) =>
    status === JourneyStatus.COMPLETED
      ? "/images/unlock-link-icon.svg"
      : "/images/unlock-link-icon-gray.svg",
  [SectionItemType.Downloadable]: (status: JourneyStatus) =>
    status === JourneyStatus.COMPLETED
      ? "/images/read-icon.svg"
      : "/images/unlock-read-icon.svg",
  [SectionItemType.FreeContent]: (status: JourneyStatus) =>
    status === JourneyStatus.COMPLETED
      ? "/images/read-icon.svg"
      : "/images/unlock-read-icon.svg",
  [SectionItemType.Video]: (status: JourneyStatus) =>
    status === JourneyStatus.COMPLETED
      ? "/images/unlock-video-icon-green.svg"
      : "/images/unlock-video-icon.svg",
};

const buttonTextMapper = {
  [JourneyStatus.COMPLETED]: "Open Conversation",
  [JourneyStatus.ONGOING]: "Start Conversation",
  [JourneyStatus.LOCKED]: null,
};

interface JourneyCardProps {
  status: JourneyStatus;
  title: string;
  headline: string;
  ahaMomentsCount: number;
  behavioursCount: number;
  link: string | null;
  sectionItemTypes: SectionItemType[];
  completedDate: Date | null;
  scheduleDate: Date | null;
}

const JourneyCard = (props: PropsWithChildren<JourneyCardProps>) => {
  const {
    status,
    title,
    headline,
    ahaMomentsCount,
    behavioursCount,
    link,
    sectionItemTypes,
    completedDate,
    scheduleDate,
  } = props;
  const buttonText = useMemo(() => buttonTextMapper[status], [status]);

  const sectionTypesContent = useMemo(() => {
    if (!sectionItemTypes?.length) return null;

    return <div className={styles.sectionTypes}>
      {sectionItemTypes.map((type, index) => {
        return (
          <img
            key={index}
            src={sectionImagePathMapper[type](status)}
            alt={type}
          />
        );
      })}
      < div className={styles.delimiter} />
    </div>
  }, [status, sectionItemTypes]);

  const formattedDate = useMemo(() => {
    if (status === JourneyStatus.COMPLETED && completedDate) {
      return formatDate(Number(completedDate) * 1000, "dd.MM.yyyy");
    }

    if (status === JourneyStatus.ONGOING && scheduleDate) {
      const date = formatDate(Number(scheduleDate) * 1000, "dd.MM.yyyy");
      const time = formatDate(Number(scheduleDate) * 1000, "HH:mm");

      return `Scheduled for ${date} at ${time}`;
    }

    return null;
  }, [completedDate, scheduleDate, status]);

  const buttonHandler = useCallback(() => {
    if (!link) return;

    window.open(link, "__blank");
  }, [link]);

  return (
    <div
      className={cn(styles.journeyCardContainer, status.toLocaleLowerCase())}
    >
      <div className={styles.iconContainer}>
        <div
          className={cn(
            styles.iconContainerInner,
            status === JourneyStatus.LOCKED && "locked"
          )}
        >
          <i
            className={
              status === JourneyStatus.COMPLETED
                ? "fa fa-check"
                : "fa fa-unlock"
            }
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>
            <h3 className="bold">{title}</h3>
            {headline}
          </div>
          {status !== JourneyStatus.LOCKED && formattedDate && (
            <div className={styles.journeyDate}>
              <span>{formattedDate}</span>
            </div>
          )}
        </div>

        {status !== JourneyStatus.LOCKED && (
          <div className={styles.footerContent}>
            <div className={styles.footerInfo}>
              {sectionTypesContent}
              <div className={styles.ahaMoments}>
                <img src="/images/aha-logo.svg" alt="aha-logo" />
                <span className="text bold">{ahaMomentsCount} Aha Moments</span>
              </div>
              <div className={styles.delimiter} />
              <div className={styles.behaviours}>
                <img src="/images/behavior-logo.svg" alt="aha-logo" />
                <span className="text bold">{behavioursCount} Behaviours</span>
              </div>
            </div>
            <div className={styles.actions}>
              <LoadingButton
                size="small"
                disabled={!link}
                className={styles.actionButton}
                onClick={buttonHandler}
                iconPosition="end"
                iconClass="fa fa-arrow-right"
              >
                {buttonText}
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(JourneyCard);
