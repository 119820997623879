import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";

const DEFAULT_ACTIVITY_TIMEOUT_TEXT = "Time is up! Continuing forward...";

export const getTransitionActionFooterData = (
  data: ActionFooterType & { isActivityTimeout: boolean }
) => {
  const { isActivityTimeout, ...others } = data;

  return !isActivityTimeout
    ? others
    : {
        ...others,
        text: DEFAULT_ACTIVITY_TIMEOUT_TEXT,
        type: FooterType.Error,
      };
};
