import { SyntheticEvent, ReactNode } from "react";
import { LoadingDelay } from "../../../hocs/withLoadingDelay";

export enum AnswerType {
  STATIC = "static",
  EXPANDABLE = "expandable",
}

export type StaticAnswer = {
  type: AnswerType.STATIC;
};

export type ExpandableAnswer = {
  type: AnswerType.EXPANDABLE;
  expanded: boolean;
  content: ReactNode | null;
  onExpandCollapseHandler: (e: SyntheticEvent) => void;
};

export type AnswerUnionType = StaticAnswer | ExpandableAnswer;

export type AnswerProps = {
  isSelected?: boolean;
  othersSelected?: boolean;
  groupSelected?: boolean;
  className?: string;
  answerTitle: string;
  isLoading?: boolean;
  isTransitioning?: boolean;
  iconClass?: string;
  showIcon?: boolean;
  badge?: ReactNode;
  isHorizontal?: boolean;
  onSelectAnswerHandler?: () => void;
} & AnswerUnionType & LoadingDelay;
