export const getGroupText = (
  groupIsAligned: boolean,
  playersClicked: number,
  hasSelectedAnswer: boolean
) =>
  !groupIsAligned ? (
    <>
      You have to <span className="accent">match your answers</span> to
      continue.{" "}
      {!hasSelectedAnswer && (
        <span className="accent">
          You have to select an answer to continue.
        </span>
      )}
    </>
  ) : (
    <>
      Your individual answers match! Click{" "}
      <span className="accent green">“Continue”</span> to confirm them as a team
      answer!{" "}
      {playersClicked > 0 && (
        <span className="accent green">
          {playersClicked} player{playersClicked > 1 && "s"} clicked.
        </span>
      )}
    </>
  );
