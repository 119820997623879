import { gql } from "@apollo/client";
import {
  MyProfileResult,
  ProfileResult,
  ProfilesResult,
} from "../types/results";
import { AppApolloClient } from "../../contexts/Apollo";
import { Pagination } from "../../types/pagination";
import { JOURNEY_PARTICIPATION_DATA } from "../constants";
import { Profile } from "../types";

const myProfileQuery = `
  create_date
  email
  id
  headline
  is_completed
  login_date
  name
  update_date
  journeyCompleteness {
    journey {
      sys {
        id
      }
      fields {
        title
      }
    }
    percentage
    completedWorkshops {
      sys {
        id
      }
      fields {
        title
      }
    }
  }
  workspace {
    access
    profile_id
    status
    title
    workspace_id
    workspace {
      create_date
      domains {
        domain
      }
      id
      name
      availableJourneys {
        journey_id
      }
      update_date
      workspace_key
      theme_id
      theme {
        id
        name
      }
      certificates {
        id
        create_date
        update_date
        workspace_id
        journey_id
        profile_id
        is_valid
        last_invalidation_date
      }
    }
  }
`;

const getMyProfileQuery = gql`
  query GetMyProfile {
    getMyProfile {
      ${myProfileQuery}
    }
  }
`;

const setProfileAsCompletedQuery = gql`
  query SetProfileAsCompleted {
    markProfileAsCompleted {
      email
      id
      is_completed
      name
      update_date
    }
  }
`;

const getMyProfileJourneyParticipationDataQuery = gql`
  query GetMyProfile {
    getMyProfile {
      workspace {
        workspace {
          availableJourneys {
            journey_id
          }
          journeyParticipationData ${JOURNEY_PARTICIPATION_DATA}
        }
      }
    }
  }
`;

export function getMyProfile(client: AppApolloClient) {
  return client
    .query<MyProfileResult>({
      query: getMyProfileQuery,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getMyProfile;
    });
}

export function setProfileAsCompleted(client: AppApolloClient) {
  return client
    .query<{ SetProfileAsCompleted: Profile }>({
      query: setProfileAsCompletedQuery,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.SetProfileAsCompleted;
    });
}

export function getJourneyParticipationData(client: AppApolloClient) {
  return client
    .query<MyProfileResult>({
      query: getMyProfileJourneyParticipationDataQuery,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getMyProfile;
    });
}

const getProfileQuery = gql`
  query GetProfile($id: String!) {
    getProfile(id: $id) {
      create_date
      email
      headline
      id
      is_completed
      name
      workspace {
        workspace_id
        access
        status
      }
    }
  }
`;

export function getProfile(client: AppApolloClient, variables: { id: string }) {
  return client
    .query<ProfileResult>({
      query: getProfileQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getProfile;
    });
}

const profileAllFragment = gql`
  fragment profileAll on Profile {
    create_date
    email
    headline
    id
    is_completed
    login_date
    name
    update_date
    workspace {
      access
      profile_id
      status
      title
      workspace {
        create_date
        domains {
          domain
        }
        id
        name
        update_date
        workspace_key
        certificates {
          id
          create_date
          update_date
          workspace_id
          journey_id
          profile_id
          is_valid
          last_invalidation_date
        }
      }
    }
  }
`;

const getProfilesQuery = gql`
  query GetProfiles(
    $workspaceId: String
    $pagination: OffsetPagination
    $query: String
  ) {
    getProfiles(
      workspace_id: $workspaceId
      pagination: $pagination
      query: $query
    ) {
      nodes {
        ...profileAll
      }
      pageInfo {
        total
      }
    }
  }
  ${profileAllFragment}
`;

export function getProfiles(
  client: AppApolloClient,
  variables: { workspaceId: string; pagination?: Pagination; query?: string }
) {
  return client
    .query<ProfilesResult>({
      query: getProfilesQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getProfiles;
    });
}
