import { createMachine, assign, spawnChild, sendTo } from "xstate";

import * as adminDashboardActions from "../../actions/dashboard/admin-dashboard";
import { AppApolloClient } from "../../../contexts/Apollo";
import { Workshop } from "../../../types/contentful/workshop/workshop";
import { getJourneyWorkshops } from "../../../apollo-graphql/queries/workshop";
import { IEditSlot, Slot } from "../../../apollo-graphql/types/slot";
import { getSlots } from "../../../apollo-graphql/queries/slot";
import { fetchMachineFactory } from "../fetch-factory";
import { SlotStatus } from "../../../apollo-graphql/types/enums";
import { SortDirection } from "../../../types/enums/sort-direction";
import { editSlot } from "../../../apollo-graphql/mutations/slot";
import { teamMembersMachine } from "../team-members";
import { Journey } from "../../../types/contentful/workshop/journey";
import {
  getJourney,
  getJourneys,
} from "../../../apollo-graphql/queries/journey";
import {} from "../../../apollo-graphql/queries/journey";
import { getJourneyLeaders } from "../../../apollo-graphql/queries/journey-leaders";
import { JourneyLeader } from "../../../apollo-graphql/types";
import { requestJourney } from "../../../apollo-graphql/mutations/request-journey";
import { requestWorkshop } from "../../../apollo-graphql/mutations/request-workshop";

export enum AdminDashboardState {
  Dashboard = "dashboard",
  Workshops = "workshops",
  Journeys = "journeys",
  Schedule = "schedule",
  TeamMembers = "teamMembers",
  JourneyDetails = "journeyDetails",
}

export interface AdminDashboardMachineContext {
  client: AppApolloClient;
  workshops: Workshop[] | null;
  slots: Slot[] | null;
  currentSlotsWorkspaceId: string | null;
  journeys: Journey[] | null;
  journeyDetails: Journey | null;
  journeyDetailsLeaders: JourneyLeader[] | null;
  error: any | null;
}

type AdminDashboardActionCreators = typeof adminDashboardActions;
type AdminDashboardActionCreatorKeys = keyof AdminDashboardActionCreators;
type AdminDashboardActions = ReturnType<
  AdminDashboardActionCreators[AdminDashboardActionCreatorKeys]
>;

export const {
  machine: getWorkshopsMachine,
  success: getWorkshopsSuccess,
  failure: getWorkshopsFailure,
  done: getWorkshopsDone,
} = fetchMachineFactory({
  id: "getWorkshops",
  invokeFn: ({
    client,
    searchText,
  }: {
    searchText: string;
    client: AppApolloClient;
  }) => {
    return getJourneyWorkshops(client, { searchText });
  },
  triggerOnCreation: true,
});

export const {
  machine: getJourneysMachine,
  success: getJourneysSuccess,
  failure: getJourneysFailure,
  done: getJourneysDone,
} = fetchMachineFactory({
  id: "getJourneys",
  invokeFn: ({ client }: { searchText: string; client: AppApolloClient }) => {
    return getJourneys(client, { includeWorkshops: true });
  },
  triggerOnCreation: true,
});

export const {
  machine: getSlotsMachine,
  success: getSlotsSuccess,
  trigger: getSlotTrigger,
  failure: getSlotsFailure,
  done: getSlotsDone,
  reTrigger: reTriggerGetSlots,
} = fetchMachineFactory({
  id: "getSlots",
  invokeFn: ({
    workspaceId,
    client,
    slotStatuses,
    sortDirection,
    emails,
  }: {
    workspaceId: string;
    client: AppApolloClient;
    slotStatuses: SlotStatus[];
    sortDirection: SortDirection;
    emails?: string[];
  }) => {
    return getSlots(client, {
      workspace_id: workspaceId,
      statuses: slotStatuses,
      sortDirection,
      emails,
    }).then((slots) => ({
      slots,
      workspaceId,
    }));
  },
  triggerOnCreation: true,
});

export const {
  machine: editSlotMachine,
  success: editSlotSuccess,
  trigger: editSlotTrigger,
  failure: editSlotFailure,
  done: editSlotDone,
} = fetchMachineFactory({
  id: "editSlot",
  invokeFn: ({
    client,
    ...others
  }: IEditSlot & { client: AppApolloClient }) => {
    return editSlot(client, others);
  },
  triggerOnCreation: false,
});

export const {
  machine: getJourneyMachine,
  success: getJourneySuccess,
  failure: getJourneyFailure,
  done: getJourneyDone,
} = fetchMachineFactory({
  id: "getJourney",
  invokeFn: ({
    client,
    journeyId,
  }: {
    journeyId: string;
    client: AppApolloClient;
  }) => {
    return getJourney(client, { journeyId, includeWorkshops: true });
  },
  triggerOnCreation: true,
});

export const {
  machine: requestJourneyMachine,
  success: requestJourneySuccess,
  failure: requestJourneyFailure,
  trigger: requestJourneyTrigger,
  done: requestJourneyDone,
} = fetchMachineFactory({
  id: "requestJourney",
  invokeFn: ({
    client,
    message,
    journeyId,
  }: {
    journeyId: string;
    message: string;
    client: AppApolloClient;
  }) => {
    return requestJourney(client, { journeyId, message });
  },
});

export const {
  machine: requestWorkshopMachine,
  success: requestWorkshopSuccess,
  failure: requestWorkshopFailure,
  trigger: requestWorkshopTrigger,
  done: requestWorkshopDone,
} = fetchMachineFactory({
  id: "requestWorkshop",
  invokeFn: ({
    client,
    id,
    message,
  }: {
    client: AppApolloClient;
    id: string;
    message: string;
  }) => {
    return requestWorkshop(client, { id, message });
  },
});

export const {
  machine: getJourneyLeadersMachine,
  success: getJourneyLeadersSuccess,
  failure: getJourneyLeadersFailure,
  done: getJourneyLeadersDone,
} = fetchMachineFactory({
  id: "getJourneyLeaders",
  invokeFn: ({
    client,
    journeyId,
    workspaceId,
  }: {
    journeyId: string;
    workspaceId?: string;
    client: AppApolloClient;
  }) => {
    return getJourneyLeaders(client, { journeyId, workspaceId });
  },
  triggerOnCreation: true,
});

type AdminDashboardMachineTypes = {
  context: AdminDashboardMachineContext;
  events:
    | AdminDashboardActions
    | ReturnType<typeof getWorkshopsSuccess>
    | ReturnType<typeof getWorkshopsFailure>
    | ReturnType<typeof getWorkshopsDone>
    | ReturnType<typeof getSlotsSuccess>
    | ReturnType<typeof getSlotsFailure>
    | ReturnType<typeof getSlotsDone>
    | ReturnType<typeof editSlotSuccess>
    | ReturnType<typeof editSlotFailure>
    | ReturnType<typeof editSlotDone>
    | ReturnType<typeof getJourneysSuccess>
    | ReturnType<typeof getJourneysFailure>
    | ReturnType<typeof getJourneysDone>
    | ReturnType<typeof getJourneySuccess>
    | ReturnType<typeof getJourneyFailure>
    | ReturnType<typeof getJourneyDone>
    | ReturnType<typeof getJourneyLeadersSuccess>
    | ReturnType<typeof getJourneyLeadersFailure>
    | ReturnType<typeof getJourneyLeadersDone>
    | ReturnType<typeof requestWorkshopSuccess>
    | ReturnType<typeof requestWorkshopFailure>
    | ReturnType<typeof requestWorkshopDone>;
};

export const adminDashboardMachine = createMachine(
  {
    types: {} as AdminDashboardMachineTypes,
    id: "admin-dashboard",
    initial: AdminDashboardState.Dashboard,
    context: ({ input }): AdminDashboardMachineContext => {
      const machineInput = input as
        | {
            client?: AppApolloClient;
          }
        | undefined;
      if (!machineInput?.client)
        throw new Error("Apollo client must be provided!");
      return {
        client: machineInput.client,
        workshops: null,
        slots: null,
        currentSlotsWorkspaceId: null,
        journeyDetails: null,
        journeyDetailsLeaders: null,
        error: null,
        journeys: null,
      };
    },
    states: {
      [AdminDashboardState.Dashboard]: {},
      [AdminDashboardState.Workshops]: {
        entry: [
          assign({
            error: null,
            workshops: null,
          }),
          spawnChild(getWorkshopsMachine.id, {
            id: getWorkshopsMachine.id,
            input: ({ event, context }: any) => {
              event = event as ReturnType<
                typeof adminDashboardActions.enterAdminWorkshops
              >;
              return {
                searchText: event.payload.searchText,
                client: context.client,
              };
            },
          }),
          spawnChild(requestWorkshopMachine, {
            id: requestWorkshopMachine.id,
          }),
        ],
        exit: sendTo(getWorkshopsMachine.id, () => {
          return getWorkshopsDone();
        }),
        on: {
          [getWorkshopsSuccess.type]: {
            actions: assign({
              workshops: ({ event }) => {
                return event.payload.output;
              },
            }),
          },
          [getWorkshopsFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
          [adminDashboardActions.requestWorkshop.type]: {
            actions: [
              sendTo(requestWorkshopMachine.id, ({ event, context }) => {
                const { client } = context;
                const { id, message } = event.payload;
                return requestWorkshopTrigger({ id, message, client });
              }),
            ],
          },
          [requestWorkshopFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
        },
      },
      [AdminDashboardState.Journeys]: {
        entry: [
          assign({
            error: null,
            journeys: null,
          }),
          spawnChild(getJourneysMachine.id, {
            id: getJourneysMachine.id,
            input: ({ context }: { context: AdminDashboardMachineContext }) => {
              return {
                client: context.client,
              };
            },
          }),
        ],
        exit: sendTo(getJourneysMachine.id, () => {
          return getJourneysDone();
        }),
        on: {
          [getJourneysSuccess.type]: {
            actions: assign({
              journeys: ({ event }) => {
                return event.payload.output;
              },
            }),
          },
          [getJourneysFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
        },
      },
      [AdminDashboardState.TeamMembers]: {
        entry: [
          assign({
            error: null,
          }),
          spawnChild(teamMembersMachine.id, {
            id: teamMembersMachine.id,
            input: ({
              event,
              context,
            }: {
              event: any;
              context: AdminDashboardMachineContext;
            }) => {
              event = event as ReturnType<
                typeof adminDashboardActions.enterAdminTeamMembers
              >;
              const { currentPage, pageSize, query, workspaceId } =
                event.payload;
              const pagination = {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
              };

              return {
                workspaceId,
                client: context.client,
                pagination,
                query,
              };
            },
          }),
        ],
      },
      [AdminDashboardState.Schedule]: {
        entry: [
          assign({
            error: null,
            currentSlotsWorkspaceId: null,
            slots: null,
          }),
          spawnChild(getSlotsMachine.id, {
            id: getSlotsMachine.id,
            input: ({ event, context }: any) => {
              const currentEvent = event as ReturnType<
                typeof adminDashboardActions.enterAdminSchedule
              >;
              return {
                workspaceId:
                  currentEvent.payload.workspaceId || context.workspaceId,
                sortDirection: currentEvent.payload.sortDirection,
                slotStatuses: currentEvent.payload.slotStatuses || [
                  SlotStatus.SCHEDULED,
                  SlotStatus.ONGOING,
                ], //TODO: use one constant that defines the filters for the different routes (we already have this inside the Schedule.tsx)
                client: context.client,
                emails: currentEvent.payload.emails,
              };
            },
          }),
          spawnChild(editSlotMachine.id, {
            id: editSlotMachine.id,
            input: ({ event, context }: any) => {
              const currentEvent = event as ReturnType<
                typeof adminDashboardActions.editSlot
              >;
              return {
                id: currentEvent.payload.id,
                status: currentEvent.payload.status,
                client: context.client,
              };
            },
          }),
        ],
        exit: sendTo(getSlotsMachine.id, () => {
          return getSlotsDone();
        }),
        on: {
          [getSlotsSuccess.type]: {
            actions: assign({
              slots: ({ event }) => {
                return event.payload.output.slots;
              },
              currentSlotsWorkspaceId: ({ event }) => {
                return event.payload.output.workspaceId;
              },
            }),
          },
          [getSlotsFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
          [adminDashboardActions.editSlot.type]: {
            actions: [
              sendTo(editSlotMachine.id, ({ event, context }: any) => {
                event = event as ReturnType<
                  AdminDashboardActionCreators["editSlot"]
                >;
                const payload = { client: context.client, ...event.payload };
                return editSlotTrigger(payload);
              }),
            ],
          },
          [editSlotSuccess.type]: {
            actions: [
              sendTo(getSlotsMachine.id, () => {
                return reTriggerGetSlots();
              }),
            ],
          },
          [editSlotFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
        },
      },
      [AdminDashboardState.JourneyDetails]: {
        entry: [
          assign({
            error: null,
            journeyDetails: null,
            journeyDetailsLeaders: null,
          }),
          spawnChild(getJourneyMachine.id, {
            id: getJourneyMachine.id,
            input: ({ event, context }: any) => {
              event = event as ReturnType<
                typeof adminDashboardActions.enterAdminJourneyDetails
              >;

              return {
                client: context.client,
                journeyId: event.payload.journeyId,
              };
            },
          }),
          spawnChild(requestJourneyMachine.id, {
            id: requestJourneyMachine.id,
            input: ({ event, context }: any) => {
              event = event as ReturnType<
                typeof adminDashboardActions.enterAdminJourneyDetails
              >;

              return {
                client: context.client,
                journeyId: event.payload.journeyId,
              };
            },
          }),
          spawnChild(getJourneyLeadersMachine.id, {
            id: getJourneyLeadersMachine.id,
            input: ({ event, context }: any) => {
              event = event as ReturnType<
                typeof adminDashboardActions.enterAdminJourneyDetails
              >;

              return {
                client: context.client,
                journeyId: event.payload.journeyId,
              };
            },
          }),
        ],
        exit: [
          sendTo(getJourneyMachine.id, () => {
            return getJourneyDone();
          }),
          sendTo(getJourneyLeadersMachine.id, () => {
            return getJourneyLeadersDone();
          }),
          sendTo(requestJourneyMachine.id, () => {
            return requestJourneyDone();
          }),
        ],
        on: {
          [adminDashboardActions.requestJourney.type]: {
            actions: [
              sendTo(requestJourneyMachine.id, ({ event, context }) => {
                const { client } = context;
                const { journeyId, message } = event.payload;
                return requestJourneyTrigger({ journeyId, message, client });
              }),
            ],
          },
          [getJourneySuccess.type]: {
            actions: assign({
              journeyDetails: ({ event }) => {
                return event.payload.output;
              },
            }),
          },
          [getJourneyLeadersSuccess.type]: {
            actions: assign({
              journeyDetailsLeaders: ({ event }) => {
                return event.payload.output;
              },
            }),
          },
          [getJourneyFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
        },
      },
    },
    on: {
      [adminDashboardActions.enterAdminDashboard.type]: {
        target: `.${AdminDashboardState.Dashboard}`,
      },
      [adminDashboardActions.enterAdminWorkshops.type]: {
        target: `.${AdminDashboardState.Workshops}`,
      },
      [adminDashboardActions.enterAdminJourneys.type]: {
        target: `.${AdminDashboardState.Journeys}`,
      },
      [adminDashboardActions.enterAdminSchedule.type]: {
        target: `.${AdminDashboardState.Schedule}`,
      },
      [adminDashboardActions.enterAdminTeamMembers.type]: {
        target: `.${AdminDashboardState.TeamMembers}`,
      },
      [adminDashboardActions.enterAdminJourneyDetails.type]: {
        target: `.${AdminDashboardState.JourneyDetails}`,
      },
    },
  },
  {
    actors: {
      [getWorkshopsMachine.id]: getWorkshopsMachine,
      [getSlotsMachine.id]: getSlotsMachine,
      [editSlotMachine.id]: editSlotMachine,
      [teamMembersMachine.id]: teamMembersMachine,
      [getJourneyMachine.id]: getJourneyMachine,
      [getJourneysMachine.id]: getJourneysMachine,
      [getJourneyLeadersMachine.id]: getJourneyLeadersMachine,
      [requestJourneyMachine.id]: requestJourneyMachine,
      [requestWorkshopMachine.id]: requestWorkshopMachine,
    },
  }
);
