import {
  memo,
  PropsWithChildren,
  useCallback,
  useState,
  CSSProperties,
} from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";

import styles from "./Tooltip.module.css";
import cn from "classnames";

const Tooltip = (
  props: PropsWithChildren<{
    message: string;
    delayDuration?: number;
    containerClass?: string;
    containerStyle?: CSSProperties;
    withClose?: boolean;
  }>
) => {
  const {
    message,
    containerClass,
    containerStyle,
    delayDuration,
    withClose,
    children,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <RadixTooltip.Provider delayDuration={delayDuration ?? 700}>
      <RadixTooltip.Root open={open} onOpenChange={setOpen}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={cn(styles.tooltipContent, containerClass)}
            style={containerStyle}
            sideOffset={5}
          >
            {message}
            {withClose && (
              <i
                className={cn(styles.closeBtn, "fa fa-xmark")}
                onClick={handleClose}
              />
            )}
            <RadixTooltip.Arrow className={styles.tooltipArrow} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default memo(Tooltip);
