import {
  setup,
  fromObservable,
  raise,
  fromPromise,
  assign,
  spawnChild,
  sendParent,
  stopChild,
  fromCallback,
  createActor,
} from "xstate";
import { openSessionStateSubscription } from "../../../apollo-graphql/subscriptions/session-state";
import { join } from "../../../apollo-graphql/mutations/join";
import * as actions from "../../actions/session/workshop";
import * as sessionActions from "../../actions/session/session";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import { disconnect } from "../../../apollo-graphql/mutations/disconnect";
import { readyToStart } from "../../../apollo-graphql/mutations/ready-to-start";
import { setActivityValue } from "../../../apollo-graphql/mutations/set-activity-value";
import { setActivityReady } from "../../../apollo-graphql/mutations/set-activity-ready";
import { SessionStateResult } from "../../../apollo-graphql/types/results/session-state-result";
import { getUnixTime } from "date-fns";
import { diff } from "deep-diff";
import { AppApolloClient, SocketEvents } from "../../../contexts/Apollo";
import { workshopClockMachine } from "./workshop-clock";
import { useMachine } from "@xstate/react";
import {
  StandardSessionActivity,
  standardSessionActivityList,
} from "../../../apollo-graphql/types/enums/standard-session-activity";
import {
  configureWorkshopClock,
  resetWorkshopClock,
  startWorkshopClock,
} from "../../actions/session/workshop-clock";
import { sessionMachine, SessionMachineContext } from "./session";
import { KickReason } from "../../../types/kick-reason";
import { WorkshopDisconnectType } from "../../../types/enums/workshop-disconnect-type";
import { fetchMachineFactory } from "../fetch-factory";
import { calculateWorkshopDuration, getEntryId } from "../../../utils";
import { getJourneyWorkshops } from "../../../apollo-graphql/queries/journey";
import {
  getNextWorkshop,
  getWorkshopsSessions,
} from "../../../apollo-graphql/queries/workshopsSessions";
import { ActivityType } from "../../../types/contentful/workshop/activity-type";
import { SlotStatus } from "../../../apollo-graphql/types/enums";
import {
  Workshop,
  WorkshopFields,
} from "../../../types/contentful/workshop/workshop";
import { WarmUpActivity } from "../../../types/contentful/workshop/activities/warm-up";
import { Slot } from "../../../apollo-graphql/types/slot";

const DEFAULT_TRANSITION_DELAY_IN_SECONDS = 5;
const SKIP_TRANSITIONS = false;

const mapJourneyWorkshops = (workshops: Workshop[], slots: Slot[]) => {
  return workshops.map((workshop) => {
    const currentWorkshop = (workshop.fields?.sessions || [])
      .reverse()
      .find(
        (workshopSession) =>
          workshopSession.workshop_id === getEntryId(workshop) &&
          workshopSession.complete_date
      );

    const isCompleted = !!currentWorkshop;
    const completedDate = currentWorkshop
      ? currentWorkshop.complete_date
      : null;

    const slot =
      (!completedDate &&
        slots?.find(
          (s) =>
            s.status === SlotStatus.SCHEDULED &&
            s.workshop_id === getEntryId(workshop)
        )) ||
      null;
    const scheduleDate = slot ? slot.schedule_date : null;

    let link: string | null = null;

    if (completedDate) {
      const [sessionGroup, sessionId] = currentWorkshop
        ? currentWorkshop.session_key.split(":")
        : [null, null];
      if (sessionId && sessionGroup) {
        link = `/session/instance/${sessionId}/${sessionGroup}`;
      }
    }

    if (scheduleDate) {
      link = `/session/slot/${slot!.id}`;
    }

    return {
      ...workshop,
      fields: {
        ...workshop.fields,
        isCompleted,
        completedDate,
        scheduleDate,
        link,
      },
    };
  });
};

export const {
  machine: getJourneyMachine,
  success: getJourneySuccess,
  failure: getJourneyFailure,
  done: getJourneyDone,
  trigger: getJourneyTrigger,
  reTrigger: getJourneyReTrigger,
} = fetchMachineFactory({
  id: "getJourney",
  invokeFn: ({
    client,
    journeyId,
  }: {
    client: AppApolloClient;
    journeyId: string;
  }) => {
    return getJourneyWorkshops(client, { journeyId }).then((workshops) => {
      const workshopIds = workshops.map((w) => getEntryId(w));
      return getWorkshopsSessions(client, { workshopIds }).then(
        (workshopSessions) =>
          workshops.reduce((acc, workshop) => {
            const sessions =
              workshopSessions.getSessionsByWorkshopIdsForCurrentProfile || [];

            return acc.concat({
              ...workshop,
              fields: {
                ...workshop.fields,
                sessions,
                isCompleted: !!sessions.find(
                  (workshopSession) =>
                    workshopSession.workshop_id === workshop.sys.id
                ),
              },
            });
          }, [] as Workshop[])
      );
    });
  },
  triggerOnCreation: false,
});

export const {
  machine: getNextJourneyWorkshopMachine,
  success: getNextJourneyWorkshopSuccess,
  failure: getNextJourneyWorkshopFailure,
  done: getNextJourneyWorkshopDone,
  trigger: getNextJourneyWorkshopTrigger,
  reTrigger: getNextJourneyWorkshopReTrigger,
} = fetchMachineFactory({
  id: "getNextJourneyWorkshop",
  invokeFn: ({
    client,
    workshopId,
    currentProfileEmail,
  }: {
    client: AppApolloClient;
    workshopId: string;
    currentProfileEmail: string;
  }) => {
    return getNextWorkshop(client, {
      emails: [currentProfileEmail],
      workshop_id: workshopId,
    });
  },
  triggerOnCreation: false,
});

export enum WorkshopState {
  Initial = "initial",
  Start = "start",
  Subscribing = "subscribing",
  Joining = "joining",
  Disconnecting = "disconnecting",
  Ready = "ready",
  SettingReadyToStart = "setting-ready-to-start",
  SettingValue = "setting-value",
  NewSetValueWhileSettingValue = "new-set-value-while-setting-value",
  SettingReady = "setting-ready",
  Ending = "Ending",
  Ended = "ended",
  Kicked = "kicked",
  Error = "error",
}

interface WorkshopContext {
  client: AppApolloClient;
  socketEventTarget: EventTarget;
  isSubscribed: boolean;
  autoJoinCompleted: boolean;
  sessionState: SessionStateValue | null;
  currentProfileId: string | null;
  currentProfileEmail: string | null;
  journeyWorkshops: Workshop[];
  transition: number;
  transitionIntervalId: number | null;
  delayedStateData: SessionStateResult[];
  connectionUUID: string | null;
  sessionId: string | null;
  errors: any | null;
  sessionActor: ReturnType<typeof useMachine<typeof sessionMachine>>["2"];
}

type WorkshopMachineTypes = {
  context: WorkshopContext;
  events:
    | ReturnType<(typeof actions)[keyof typeof actions]>
    | ReturnType<typeof getJourneySuccess>
    | ReturnType<typeof getJourneyFailure>
    | ReturnType<typeof getNextJourneyWorkshopSuccess>
    | ReturnType<typeof getNextJourneyWorkshopFailure>;
};

function calculateTransition(
  prevData: SessionStateResult | null | undefined,
  currData: SessionStateResult | null | undefined,
  workshopActor: ReturnType<typeof useMachine<typeof workshopMachine>>["2"]
): { transition: number; context: WorkshopContext } {
  const { context } = workshopActor.getSnapshot();
  if (SKIP_TRANSITIONS) return { transition: 0, context };

  if (!prevData || !currData) return { transition: -1, context };
  const {
    sessionState: {
      context: { lastActivityTimestamp: prevLastActivityTimestamp },
    },
  } = prevData;
  const {
    sessionState: {
      context: { lastActivityTimestamp: currLastActivityTimestamp },
    },
  } = currData;
  if (prevLastActivityTimestamp === currLastActivityTimestamp)
    return { transition: -1, context };

  const timestamp = Math.max(currLastActivityTimestamp || 0);
  if (timestamp === 0) return { transition: -1, context };

  const activities = (context.sessionActor?.getSnapshot().context?.slot
    ?.workshop?.fields?.activities || []) as ActivityType[];
  const currentActivityId = context?.sessionState?.value || null;
  const currentActivityIndex = activities.findIndex(
    (a) => getEntryId(a) === currentActivityId
  );

  const isCurrentActivityStandard = standardSessionActivityList.includes(
    currentActivityId as any
  );

  const nextActivity = getNextActivity(
    activities,
    currentActivityIndex,
    currentActivityId,
    context.sessionActor?.getSnapshot().context?.slot?.workshop?.fields
  );

  const hasTransition = !!nextActivity?.fields?.activity?.fields?.hasTransition;
  const transitionDuration = !hasTransition
    ? 0
    : nextActivity?.fields?.activity?.fields?.transitionDuration || 0;
  const transition =
    currentActivityId && !nextActivity
      ? DEFAULT_TRANSITION_DELAY_IN_SECONDS
      : (!currentActivityId || isCurrentActivityStandard) && !nextActivity
      ? DEFAULT_TRANSITION_DELAY_IN_SECONDS
      : transitionDuration;

  return { transition, context };
}
/**
 * @description get next activity based on contentful config
 * between standard activities and activities list
 * 1. conversationalAgreementActivity
 * 2. openning
 * 3. startEmotionActivity
 * 4. warmUpActivity
 * ...activities
 * 5. endEmotionActivity
 * 6. summary
 */
const getNextActivity = (
  activities: ActivityType[],
  currentActivityIndex: number,
  currentActivityId: string | null,
  workshopFields?: WorkshopFields
): ActivityType | WarmUpActivity | undefined => {
  const map = {
    [StandardSessionActivity.StartEmotion as string]:
      workshopFields?.warmUpActivity,
    [StandardSessionActivity.WarmUp as string]: activities[0],
  };

  return (
    (currentActivityId && map[currentActivityId]) ||
    activities[currentActivityIndex + 1]
  );
};

export const workshopMachine = setup({
  types: {} as WorkshopMachineTypes,
  guards: {
    isCurrentProfileParticipating: ({ context }) =>
      !!context.sessionState?.context.currentActiveProfiles.find(
        ({ profileId }) => profileId === context.currentProfileId
      ),
    isCurrentProfileNotParticipating: ({ context }) =>
      !context.sessionState?.context.currentActiveProfiles.find(
        ({ profileId }) => profileId === context.currentProfileId
      ),
  },
  actors: {
    subscription: fromObservable(({ input }) => {
      const {
        payload: { sessionId, workshopActor, uuid },
        client,
        socketEventTarget,
      } = input as {
        payload: ReturnType<typeof actions.workshopSubscribe>["payload"];
        client: AppApolloClient;
        socketEventTarget: EventTarget;
      };

      const parentActor = workshopActor as ReturnType<
        typeof useMachine<typeof workshopMachine>
      >["2"];

      const connectedHandler = () => {
        parentActor.send(actions.workshopJoin({ sessionId, uuid }));
      };
      socketEventTarget.addEventListener(
        SocketEvents.CONNECTED,
        connectedHandler
      );

      let prevData: SessionStateResult | null | undefined = undefined;
      return openSessionStateSubscription(client, {
        sessionId,
        connectionUUID: uuid,
      }).map(({ data }) => {
        console.log("New subscription data", data);
        const { transition, context } = calculateTransition(
          prevData,
          data,
          parentActor
        );
        const { transitionIntervalId } = context;

        const participantsDiff = diff(
          prevData?.sessionState.context.currentActiveProfiles.map(
            ({ profileId }) => profileId
          ) || [],
          data?.sessionState.context.currentActiveProfiles.map(
            ({ profileId }) => profileId
          ) || []
        );
        if (participantsDiff) {
          parentActor.send(
            actions.workshopParticipantChange({
              participantIds: (
                data?.sessionState.context.currentActiveProfiles.map(
                  ({ profileId }) => profileId
                ) || []
              ).concat(data?.sessionState.context?.observers || []),
            })
          );
        }

        prevData = data;

        if (transition <= 0) {
          parentActor.send(
            !transitionIntervalId
              ? actions.workshopSubscriptionData({ data: data! })
              : actions.pushDelayedStateData({ data: data! })
          );
        } else if (!transitionIntervalId) {
          parentActor.send(
            actions.workshopSubscriptionDataTransition({ transition })
          );

          const intervalStartTimestamp = getUnixTime(new Date());
          const intervalId = setInterval(() => {
            const { delayedStateData } = parentActor.getSnapshot().context;
            const currentTimestamp = getUnixTime(new Date());
            const elapsedSeconds = currentTimestamp - intervalStartTimestamp;

            const diff = transition - elapsedSeconds;

            parentActor.send(
              actions.workshopSubscriptionDataTransition({
                transition: diff > 0 ? diff : 0,
              })
            );
            if (diff <= 0) {
              clearInterval(intervalId);
              delayedStateData.forEach((data) => {
                parentActor.send(
                  actions.workshopSubscriptionData({ data: data! })
                );
              });

              parentActor.send(
                actions.workshopSubscriptionData({ data: data! })
              );
              parentActor.send(
                actions.setTransitionIntervalId({
                  transitionIntervalId: null,
                })
              );

              parentActor.send(actions.clearDelayedStateData());
            }
          }, 1000);

          parentActor.send(
            actions.setTransitionIntervalId({
              transitionIntervalId: intervalId as unknown as number,
            })
          );
        }
        return data;
      });
    }),
    join: fromPromise(({ input }) => {
      const {
        payload: { sessionId, uuid },
        client,
      } = input as {
        payload: ReturnType<typeof actions.workshopJoin>["payload"];
        client: AppApolloClient;
      };
      return join(client, { sessionId, uuid });
    }),
    disconnect: fromPromise(({ input }) => {
      const {
        client,
        connectionUUID,
        payload: { sessionId, intended, type, reason },
      } = input as {
        payload: ReturnType<typeof actions.workshopDisconnect>["payload"];
        client: AppApolloClient;
        connectionUUID: string;
      };
      return disconnect(client, {
        sessionId,
        intended,
        connectionUUID,
        observe: type === WorkshopDisconnectType.Observe,
      }).then(() => ({
        intended,
        type,
        reason,
      }));
    }),
    readyToStart: fromPromise(({ input }) => {
      const {
        client,
        payload: { sessionId },
      } = input as {
        payload: ReturnType<typeof actions.workshopReadyToStart>["payload"];
        client: AppApolloClient;
      };
      return readyToStart(client, { sessionId });
    }),
    setActivityValue: fromPromise(({ input }) => {
      const {
        client,
        payload: { sessionId, activityId, value, markAsReady },
      } = input as {
        payload: ReturnType<typeof actions.workshopSetActivityValue>["payload"];
        client: AppApolloClient;
      };
      return setActivityValue(client, {
        sessionId,
        activityId,
        value,
        markAsReady,
      });
    }),
    setActivityReady: fromPromise(({ input }) => {
      const {
        client,
        payload: { sessionId, activityId },
      } = input as {
        payload: ReturnType<typeof actions.workshopSetActivityReady>["payload"];
        client: AppApolloClient;
      };
      return setActivityReady(client, { sessionId, activityId });
    }),
    cleanUp: fromCallback(({ input }) => {
      const { parent } = input as {
        parent: ReturnType<typeof createActor<typeof workshopMachine>>;
      };
      return () => {
        const snapshot = parent.getSnapshot();
        const gamePlayStates = [
          WorkshopState.Ready,
          WorkshopState.SettingReadyToStart,
          WorkshopState.NewSetValueWhileSettingValue,
          WorkshopState.SettingValue,
          WorkshopState.SettingReady,
        ];
        const { client, sessionId, connectionUUID } = snapshot.context;
        const isInGamePlayState = gamePlayStates
          .map((state) => snapshot.matches(state))
          .includes(true);
        if (!isInGamePlayState || !client || !sessionId || !connectionUUID)
          return;
        disconnect(client, { intended: true, sessionId, connectionUUID });
      };
    }),
    [getJourneyMachine.id]: getJourneyMachine,
    [getNextJourneyWorkshopMachine.id]: getNextJourneyWorkshopMachine,
  },
}).createMachine({
  id: "workshop",
  initial: WorkshopState.Initial,
  context: ({ input }): WorkshopContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
          socketEventTarget?: EventTarget;
          sessionActor: ReturnType<
            typeof useMachine<typeof sessionMachine>
          >["2"];
        }
      | undefined;
    if (
      !machineInput?.client ||
      !machineInput?.socketEventTarget ||
      !machineInput?.sessionActor
    )
      throw new Error(
        "All workshop machine input dependencies must be provided!"
      );

    return {
      client: machineInput.client,
      socketEventTarget: machineInput.socketEventTarget,
      isSubscribed: false,
      autoJoinCompleted: false,
      sessionState: null,
      currentProfileId: null,
      currentProfileEmail: null,
      transition: 0,
      transitionIntervalId: null,
      delayedStateData: [],
      journeyWorkshops: [],
      connectionUUID: null,
      sessionId: null,
      errors: null,
      sessionActor: machineInput.sessionActor,
    };
  },
  entry: [
    spawnChild(getJourneyMachine.id, {
      id: getJourneyMachine.id,
      systemId: getJourneyMachine.id,
    }),
    spawnChild(getNextJourneyWorkshopMachine.id, {
      id: getNextJourneyWorkshopMachine.id,
      systemId: getNextJourneyWorkshopMachine.id,
    }),
    spawnChild("cleanUp", { input: ({ self }: any) => ({ parent: self }) }),
  ],
  states: {
    [WorkshopState.Initial]: {
      on: {
        [actions.startWorkshop.type]: {
          target: WorkshopState.Start,
          actions: assign({
            currentProfileId: ({ event }) => event.payload.currentProfileId,
            currentProfileEmail: ({ event }) =>
              event.payload.currentProfileEmail,
          }),
        },
        [actions.workshopEnd.type]: {
          actions: [
            assign({
              currentProfileEmail: ({ event }) =>
                event.payload.currentProfileEmail,
            }),
            raise(({ event, context, self }) => {
              const getJourneyMachineActor = self.getSnapshot().children[
                getJourneyMachine.id
              ] as ReturnType<typeof useMachine<typeof getJourneyMachine>>["2"];

              const currentProfileEmail = event.payload.currentProfileEmail;
              const sessionSnapshot = context.sessionActor.getSnapshot()
                .context as SessionMachineContext;

              const workshopId = (sessionSnapshot.session?.workshop ||
                sessionSnapshot.slot?.workshop)!.sys.id;

              if (getJourneyMachineActor.getSnapshot().context.data?.length)
                return actions.getNextJourneyWorkshop({
                  workshopId,
                  currentProfileEmail,
                });

              return actions.getJourney({
                journeyId: event.payload.journeyId,
              });
            }),
          ],
        },
        [getJourneySuccess.type]: {
          actions: [
            raise(({ context }) => {
              const sessionContext = context.sessionActor.getSnapshot()
                .context as SessionMachineContext;
              const workshopId = (
                sessionContext.session?.workshop ||
                sessionContext.slot?.workshop
              )?.sys.id!;
              const currentProfileEmail = context.currentProfileEmail!;

              return actions.getNextJourneyWorkshop({
                workshopId,
                currentProfileEmail,
              });
            }),
            assign({ journeyWorkshops: ({ event }) => event.payload.output }),
          ],
        },
        [getJourneyFailure.type]: {
          actions: [
            raise(() => actions.workshopEnded()),
            assign({ journeyWorkshops: () => [] }),
          ],
        },
        [getNextJourneyWorkshopSuccess.type]: {
          actions: [
            assign({
              journeyWorkshops: ({ event, context }) => {
                const journeyWorkshops = context.journeyWorkshops || [];
                const slots = event.payload.output || [];

                return mapJourneyWorkshops(journeyWorkshops, slots);
              },
            }),
            raise(() => actions.workshopEnded()),
          ],
        },
        [getNextJourneyWorkshopFailure.type]: {
          actions: [raise(() => actions.workshopEnded())],
        },
        [actions.workshopEnded.type]: {
          target: WorkshopState.Ended,
        },
      },
    },
    [WorkshopState.Start]: {
      entry: [
        raise(({ context, event, self }) => {
          const startEvent = event as ReturnType<typeof actions.startWorkshop>;

          if (!context.isSubscribed)
            return actions.workshopSubscribe({
              ...startEvent.payload,
              workshopActor: self,
            });
          if (!context.autoJoinCompleted)
            return actions.workshopJoin({
              sessionId: startEvent.payload.sessionId,
              uuid: startEvent.payload.uuid,
            });
          return actions.workshopReady();
        }),
        raise(({ event }) => {
          const startEvent = event as ReturnType<typeof actions.startWorkshop>;

          return actions.getJourney({
            journeyId: startEvent.payload.journeyId,
          });
        }),
      ],
      on: {
        [actions.workshopSubscribe.type]: {
          target: WorkshopState.Subscribing,
          guard: ({ context }) => !context.isSubscribed,
        },
        [actions.workshopJoin.type]: {
          target: WorkshopState.Joining,
        },
      },
    },
    [WorkshopState.Subscribing]: {
      entry: [
        spawnChild("subscription", {
          id: "apolloSubscription",
          input: ({ event, context }: any) => {
            const subscribeEvent = event as ReturnType<
              typeof actions.workshopSubscribe
            >;
            const payload = subscribeEvent.payload;
            return {
              payload,
              client: context.client,
              socketEventTarget: context.socketEventTarget,
              uuid: context.connectionUUID,
            };
          },
          syncSnapshot: true,
        }),
      ],
      on: {
        [actions.workshopJoin.type]: [
          {
            target: WorkshopState.Joining,
          },
        ],
      },
      exit: assign({
        isSubscribed: true,
      }),
    },
    [WorkshopState.Joining]: {
      invoke: {
        src: "join",
        onDone: {
          target: WorkshopState.Ready,
          actions: assign({
            sessionState: ({ event }) => event.output as SessionStateValue,
          }),
        },
        onError: {
          target: WorkshopState.Error,
          actions: assign({
            errors: ({ event }) => event.error,
          }),
        },
        input: ({ event, context }) => ({
          payload: (event as ReturnType<typeof actions.workshopJoin>).payload,
          client: context.client,
        }),
      },
      entry: assign({
        connectionUUID: ({ event }) =>
          (event as ReturnType<typeof actions.workshopJoin>).payload.uuid,
        sessionId: ({ event }) =>
          (event as ReturnType<typeof actions.workshopJoin>).payload.sessionId,
      }),
      exit: assign({
        autoJoinCompleted: true,
      }),
    },
    [WorkshopState.Disconnecting]: {
      invoke: {
        src: "disconnect",
        onDone: [
          {
            target: WorkshopState.Ready,
            guard: ({ event }) =>
              (
                event.output as {
                  intended: boolean;
                  type: WorkshopDisconnectType;
                }
              ).type === WorkshopDisconnectType.Observe,
          },
          {
            target: WorkshopState.Ending,
            guard: ({ event }) =>
              (
                event.output as {
                  intended: boolean;
                  type: WorkshopDisconnectType;
                }
              ).type === WorkshopDisconnectType.Default,
          },
          {
            target: WorkshopState.Kicked,
            guard: ({ event }) =>
              (
                event.output as {
                  intended: boolean;
                  type: WorkshopDisconnectType;
                  reason: KickReason;
                }
              ).type === WorkshopDisconnectType.Kick,
          },
        ],
        input: ({ event, context }) => ({
          payload: (event as ReturnType<typeof actions.workshopDisconnect>)
            .payload,
          client: context.client,
          connectionUUID: context.connectionUUID,
        }),
      },
    },
    [WorkshopState.Ready]: {
      on: {
        [actions.workshopSetActivityValue.type]: {
          target: WorkshopState.SettingValue,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopSetActivityReady.type]: {
          target: WorkshopState.SettingReady,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopReadyToStart.type]: {
          target: WorkshopState.SettingReadyToStart,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopDisconnect.type]: {
          target: WorkshopState.Disconnecting,
        },
        [actions.workshopJoin.type]: {
          target: WorkshopState.Joining,
        },
        [actions.workshopEnd.type]: {
          target: WorkshopState.Ending,
        },
      },
    },
    [WorkshopState.SettingReadyToStart]: {
      invoke: {
        src: "readyToStart",
        input: ({ event, context }) => {
          const readyToStartEvent = event as ReturnType<
            typeof actions.workshopReadyToStart
          >;
          return { payload: readyToStartEvent.payload, client: context.client };
        },
        onDone: {
          target: WorkshopState.Ready,
        },
        onError: {
          target: WorkshopState.Ready, // TODO: Must handle error
        },
      },
    },
    [WorkshopState.NewSetValueWhileSettingValue]: {
      entry: [
        raise(({ event }) => {
          return event;
        }),
      ],
      on: {
        [actions.workshopSetActivityValue.type]: {
          target: WorkshopState.SettingValue,
        },
      },
    },
    [WorkshopState.SettingValue]: {
      on: {
        [actions.workshopSetActivityValue.type]: {
          target: WorkshopState.NewSetValueWhileSettingValue,
        },
      },
      invoke: {
        src: "setActivityValue",
        input: ({ event, context }) => {
          const setActivityValueEvent = event as ReturnType<
            typeof actions.workshopSetActivityValue
          >;
          return {
            payload: setActivityValueEvent.payload,
            client: context.client,
          };
        },
        onDone: {
          target: WorkshopState.Ready,
        },
        onError: {
          target: WorkshopState.Ready, // TODO: Must handle error
        },
      },
    },
    [WorkshopState.SettingReady]: {
      invoke: {
        src: "setActivityReady",
        input: ({ event, context }) => {
          const setActivityReadyEvent = event as ReturnType<
            typeof actions.workshopSetActivityReady
          >;
          return {
            payload: setActivityReadyEvent.payload,
            client: context.client,
          };
        },
        onDone: {
          target: WorkshopState.Ready,
        },
        onError: {
          target: WorkshopState.Ready, // TODO: Must handle error
        },
      },
    },
    [WorkshopState.Ending]: {
      entry: [
        raise(({ event, context, self }) => {
          const endEvent = event as ReturnType<typeof actions.workshopEnd>;
          const getJourneyMachineActor = self.getSnapshot().children[
            getJourneyMachine.id
          ] as ReturnType<typeof useMachine<typeof getJourneyMachine>>["2"];

          const currentProfileEmail = context.currentProfileEmail!;
          const sessionSnapshot = context.sessionActor.getSnapshot()
            .context as SessionMachineContext;

          const workshopId = (sessionSnapshot.session?.workshop ||
            sessionSnapshot.slot?.workshop)!.sys.id;

          if (getJourneyMachineActor.getSnapshot().context.data?.length)
            return actions.getNextJourneyWorkshop({
              workshopId,
              currentProfileEmail,
            });

          return actions.getJourney({
            journeyId: endEvent.payload.journeyId,
          });
        }),
      ],
      on: {
        [getJourneySuccess.type]: {
          actions: [
            raise(({ context }) => {
              const sessionContext = context.sessionActor.getSnapshot()
                .context as SessionMachineContext;
              const workshopId = (
                sessionContext.session?.workshop ||
                sessionContext.slot?.workshop
              )?.sys.id!;

              const currentProfileEmail = context.currentProfileEmail!;

              return actions.getNextJourneyWorkshop({
                workshopId,
                currentProfileEmail,
              });
            }),
            assign({ journeyWorkshops: ({ event }) => event.payload.output }),
          ],
        },
        [getJourneyFailure.type]: {
          actions: [
            raise(() => actions.workshopEnded()),
            assign({ journeyWorkshops: () => [] }),
          ],
        },
        [getNextJourneyWorkshopSuccess.type]: {
          actions: [
            assign({
              journeyWorkshops: ({ event, context }) => {
                const journeyWorkshops = context.journeyWorkshops || [];
                const slots = event.payload.output || [];

                return mapJourneyWorkshops(journeyWorkshops, slots);
              },
            }),
            raise(() => actions.workshopEnded()),
          ],
        },
        [getNextJourneyWorkshopFailure.type]: {
          actions: [raise(() => actions.workshopEnded())],
        },
        [actions.workshopEnded.type]: {
          target: WorkshopState.Ended,
        },
      },
    },
    [WorkshopState.Ended]: {
      type: "final",
      entry: [
        stopChild("subscription"),
        sendParent(() => {
          console.log("reset clock");
          return resetWorkshopClock();
        }),
      ],
    },
    [WorkshopState.Kicked]: {
      type: "final",
      entry: [
        stopChild("subscription"),
        sendParent(() => {
          console.log("reset clock");
          return resetWorkshopClock();
        }),
      ],
    },
    [WorkshopState.Error]: {
      type: "final",
      entry: [
        stopChild("subscription"),
        sendParent(() => {
          console.log("reset clock");
          return resetWorkshopClock();
        }),
      ],
    },
  },
  on: {
    [actions.getJourney.type]: {
      actions: [
        ({ event, system, context }) => {
          const getJourneyActor = system.get(getJourneyMachine.id);

          getJourneyActor.send(
            getJourneyTrigger({
              client: context.client,
              journeyId: event.payload.journeyId,
            })
          );
        },
      ],
    },
    [actions.getNextJourneyWorkshop.type]: {
      actions: [
        ({ event, system, context }) => {
          const getNextJourneyWorkshopActor = system.get(
            getNextJourneyWorkshopMachine.id
          );

          getNextJourneyWorkshopActor.send(
            getNextJourneyWorkshopTrigger({
              client: context.client,
              workshopId: event.payload.workshopId,
              currentProfileEmail: event.payload.currentProfileEmail,
            })
          );
        },
      ],
    },
    [getJourneySuccess.type]: {
      actions: [
        raise(({ context }) => {
          const sessionContext = context.sessionActor.getSnapshot()
            .context as SessionMachineContext;
          const workshopId = (
            sessionContext.session?.workshop || sessionContext.slot?.workshop
          )?.sys.id!;

          const currentProfileEmail = context.currentProfileEmail!;

          return actions.getNextJourneyWorkshop({
            workshopId,
            currentProfileEmail,
          });
        }),
        assign({ journeyWorkshops: ({ event }) => event.payload.output }),
      ],
    },
    [getNextJourneyWorkshopSuccess.type]: {
      actions: [
        assign({
          journeyWorkshops: ({ event, context }) => {
            const journeyWorkshops = context.journeyWorkshops || [];
            const slots = event.payload.output || [];

            return mapJourneyWorkshops(journeyWorkshops, slots);
          },
        }),
      ],
    },
    [getNextJourneyWorkshopFailure.type]: {
      actions: [raise(() => actions.workshopEnded())],
    },
    [actions.workshopSubscriptionData.type]: {
      actions: assign({
        sessionState: ({ event, self, context, system }) => {
          const subscriptionDataEvent = event as ReturnType<
            typeof actions.workshopSubscriptionData
          >;
          const sessionState = subscriptionDataEvent.payload.data.sessionState;

          const incomingActiveProfile =
            sessionState.context.currentActiveProfiles.find(
              (entry) => entry.profileId === context.currentProfileId
            ) || null;
          const contextActiveProfile =
            context.sessionState?.context.currentActiveProfiles.find(
              (entry) => entry.profileId === context.currentProfileId
            ) || null;

          if (
            incomingActiveProfile &&
            contextActiveProfile &&
            incomingActiveProfile.uuid !== contextActiveProfile.uuid
          ) {
            self.send(
              actions.workshopDisconnect({
                sessionId: context.sessionId!,
                intended: true,
                type: WorkshopDisconnectType.Kick,
                reason: KickReason.MULTIPLE_CONNECTIONS,
              })
            );
            return sessionState;
          }

          const workshopClockActor = context.sessionActor.getSnapshot()
            .children["workshopClock"] as ReturnType<
            typeof useMachine<typeof workshopClockMachine>
          >["2"];
          let clockInstance =
            workshopClockActor.getSnapshot().context.clockInstance;

          const sessionContext = context.sessionActor.getSnapshot()
            .context as SessionMachineContext;
          const workshop =
            sessionContext.session?.workshop || sessionContext.slot?.workshop;

          if (
            !clockInstance?.isTicking &&
            sessionState.value !== StandardSessionActivity.Opening &&
            sessionState.value !== StandardSessionActivity.ViewResults
          ) {
            const serverUnixStartTimestamp =
              sessionState.context.startTimestamp!;

            const durationInMinutes = calculateWorkshopDuration(workshop);

            workshopClockActor.send(
              configureWorkshopClock({ durationInMinutes })
            );
            workshopClockActor.send(
              startWorkshopClock({ serverUnixStartTimestamp })
            );
          }

          const isStandardActivityState = standardSessionActivityList.includes(
            sessionState.value as StandardSessionActivity
          );
          if (!isStandardActivityState) {
            try {
              const activityId = sessionState?.value;
              const activity = workshop?.fields?.activities.find(
                (d) => d.sys.id === activityId
              );

              const activityDuration =
                activity?.fields?.activity?.fields?.duration || 0;

              clockInstance =
                workshopClockActor.getSnapshot().context.clockInstance;
              if (activityDuration) {
                const activityStartTimestamp =
                  sessionState.context.lastActivityTimestamp;
                clockInstance?.startActivity(
                  activityId,
                  activityStartTimestamp,
                  activityDuration
                );
              } else if (clockInstance?.hasRunningActivity) {
                clockInstance?.resetActivity();
              }
            } catch (e) {
              console.error("Error parsing state value");
            }
          } else if (
            (sessionState.value as StandardSessionActivity) ===
            StandardSessionActivity.ViewResults
          ) {
            const sessionSnapshot = context.sessionActor.getSnapshot()
              .context as SessionMachineContext;
            const currentProfileEmail = context.currentProfileEmail!;

            const journeyId = (sessionSnapshot.session?.workshop ||
              sessionSnapshot.slot?.workshop)!.fields.journey.sys.id;
            self.send(
              actions.workshopEnd({
                journeyId,
                currentProfileEmail,
              })
            );
          }
          return sessionState;
        },
      }),
    },
    [actions.workshopSubscriptionDataTransition.type]: {
      actions: assign({
        transition: ({ event }) => {
          const subscriptionDataEvent = event as ReturnType<
            typeof actions.workshopSubscriptionDataTransition
          >;
          return subscriptionDataEvent.payload.transition;
        },
      }),
    },
    [actions.workshopParticipantChange.type]: {
      actions: sendParent(({ event: { payload } }) => {
        return sessionActions.sessionParticipantChange(payload);
      }),
    },
    [actions.setTransitionIntervalId.type]: {
      actions: assign({
        transitionIntervalId: ({ event }) => {
          const setTransitionIntervalIdEvent = event as ReturnType<
            typeof actions.setTransitionIntervalId
          >;
          return setTransitionIntervalIdEvent.payload.transitionIntervalId;
        },
      }),
    },
    [actions.pushDelayedStateData.type]: {
      actions: assign({
        delayedStateData: ({ event, context }) => {
          const pushDelayedStateDataEvent = event as ReturnType<
            typeof actions.pushDelayedStateData
          >;
          const {
            payload: {
              data: {
                sessionState: {
                  context: { currentActiveProfiles, reconnectTimeouts },
                },
              },
            },
          } = event;

          return [
            ...context.delayedStateData.map((prevEventData) => ({
              ...prevEventData,
              sessionState: {
                ...prevEventData.sessionState,
                context: {
                  ...prevEventData.sessionState.context,
                  currentActiveProfiles,
                  reconnectTimeouts,
                },
              },
            })),
            pushDelayedStateDataEvent.payload.data,
          ];
        },
        sessionState: ({ event, context }) => {
          // NOTE: Even if we have delayed state data that we have to apply
          // we need to apply the important things because while we are transitioning
          // someone might disconnect or connect and we need to apply the changes to the
          // state.
          const {
            payload: {
              data: {
                sessionState: {
                  context: { currentActiveProfiles, reconnectTimeouts },
                },
              },
            },
          } = event;

          return {
            ...context.sessionState!,
            context: {
              ...context.sessionState!.context,
              currentActiveProfiles,
              reconnectTimeouts,
            },
          };
        },
      }),
    },
    [actions.clearDelayedStateData.type]: {
      actions: assign({
        delayedStateData: [],
      }),
    },
    [actions.kick.type]: {
      target: `.${WorkshopState.Kicked}`,
    },
  },
});
