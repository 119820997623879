import { FC, useContext, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../contexts/Global";

const DEFAULT_PAGE_DESCRIPTION = "AhaPlay";

export type RouteConfigData = {
  hideWorkspaceName?: boolean;
  hideUserDropdown?: boolean;
  hideHeaderTheme?: boolean;
  headerLogoBig?: boolean;
};

export type RouteConfigProps = {
  title: string;
  description?: string;
  data?: RouteConfigData;
};

function withRouteConfig<P>(Comp: FC<P>) {
  const TitleHoc = (props: P & RouteConfigProps) => {
    const { title, description, data } = props;
    const globalContext = useContext(GlobalContext);
    const setPageConfig = useMemo(
      () => globalContext.auth.setPageConfig,
      [globalContext.auth.setPageConfig]
    );

    useEffect(() => {
      setPageConfig({ pageData: data, pageTitle: title });
    }, [title, data, setPageConfig]);

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content={description || DEFAULT_PAGE_DESCRIPTION}
          />
        </Helmet>
        <Comp {...props} />
      </>
    );
  };

  TitleHoc.displayName = "TitleHoc";
  return TitleHoc;
}

export default withRouteConfig;
