import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { GetSlotsResult } from "../types/results/get-slots-result";
import { IGetSlots } from "../types/slot";
import { GET_WORKSHOP_QUERY } from "../constants";
import { SlotStatus } from "../types/enums";

const getSlotsForEmailQuery = gql`
  query GetSlots($workspaceId: String, $emails: [String]) {
    getSlots(workspace_id: $workspaceId, emails: $emails) {
      id
      key
      creator_id
      ics
      ics_uid
      reminder_status
      schedule_date
      status
      type
      workshop_id
      workspace_id
      workshop {
        ${GET_WORKSHOP_QUERY}
      }
    }
  }
`;

export function getSlotsForEmails(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    emails: string[];
  }
) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsForEmailQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

const getSlotsForWorkshopIdQuery = gql`
  query GetSlots(
    $workspaceId: String
    $workshopId: String
    $statuses: [SlotStatus]
  ) {
    getSlots(
      workspace_id: $workspaceId
      workshop_id: $workshopId
      statuses: $statuses
    ) {
      id
      key
      creator_id
      ics
      ics_uid
      reminder_status
      schedule_date
      status
      type
      workshop_id
      workspace_id
      workshop {
        fields {
          title
        }
      }
      invitations {
        profile {
          id
          name
          email
          workspace {
            workspace_id
          }
        }
        status
        email
      }
    }
  }
`;

export function getSlotsForWorkshopId(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    workshopId: string;
    statuses: SlotStatus[];
  }
) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsForWorkshopIdQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

const getSlotsQuery = gql`
  query GetSlots(
    $workspaceId: String
    $statuses: [SlotStatus]
    $sortDirection: SortDirection
    $emails: [String]
  ) {
    getSlots(
      workspace_id: $workspaceId
      sortDirection: $sortDirection
      statuses: $statuses
      emails: $emails
    ) {
      id
      create_date
      creator_id
      ics
      ics_uid
      invitations {
        email
        emails_count
        profile {
          id
          name
          image
          headline
          email
          workspace {
            workspace_id
          }
        }
        slot_id
        status
      }
      key
      profile {
        create_date
        email
        headline
        id
        image
        login_date
        name
        update_date
      }
      reminder_status
      schedule_date
      status
      type
      sessions {
        id
        create_date
        update_date
        complete_date
        session_key
        state
        status
        slot_id
        creator_id
        workshop_id
        workspace_id
      }
      workshop_id
      workspace_id
      workshop {
        ${GET_WORKSHOP_QUERY}
      }
    }
  }
`;

export function getSlots(client: AppApolloClient, variables: IGetSlots) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}
