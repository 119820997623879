export const getSeenSteps = () => JSON.parse(localStorage.getItem('seenGuideSteps') || '[]');

export const addSeenStep = (stepId: string) => {
  const seenSteps = getSeenSteps();

  if (!seenSteps.includes(stepId)) {
    seenSteps.push(stepId);
    localStorage.setItem('seenGuideSteps', JSON.stringify(seenSteps));
  }
};

export const markGuideClosed = () => {
  localStorage.setItem('guideClosed', 'true');
};

export const isGuideClosed = () => localStorage.getItem('guideClosed') === 'true';