import { differenceInMilliseconds, getUnixTime, startOfSecond } from "date-fns";

export enum WorkshopClockEvent {
  TICK = "tick",
  TIMEOUT = "timeout",
}
export class WorkshopClock extends EventTarget {
  private intervalId: number | null = null;
  private timeoutId: number | null = null;

  private serverUnixStartTimestamp: number | null = null;
  private currentTick = 0;
  private _totalTicks = 0;
  get totalTicks() {
    return this._totalTicks;
  }

  public activityId = "";
  private activityStartTick = 0;
  private activityLastTick = 0;

  public workshopProgress = 0;
  public workshopParsedTimeRemaining = "";
  public workshopParsedTimeElapsed: string | null = "";
  public activityProgress = 0;
  public activityParsedTimeRemaining = "";

  get workshopRemainingTimeInMilliseconds(): number {
    const [minutes = "0", seconds = "0"] =
      this.workshopParsedTimeRemaining.split(":");
    return (+minutes * 60 + +seconds) * 1000;
  }

  get activityRemainingTimeInMilliseconds(): number {
    const [minutes = "0", seconds = "0"] =
      this.activityParsedTimeRemaining.split(":");
    return (+minutes * 60 + +seconds) * 1000;
  }

  get isTicking() {
    return !!this.intervalId || !!this.timeoutId;
  }

  get hasRunningActivity() {
    return !!this.activityStartTick;
  }

  get workshopUnixEndTimestamp() {
    return this.serverUnixStartTimestamp !== null
      ? this.serverUnixStartTimestamp + this._totalTicks
      : null;
  }

  constructor(config: {
    durationInMinutes?: number;
    durationInSeconds?: number;
  }) {
    super();
    this.setup(config);
  }

  setup({
    durationInSeconds,
    durationInMinutes,
  }: {
    durationInMinutes?: number;
    durationInSeconds?: number;
  }) {
    const totalSeconds = durationInSeconds
      ? durationInSeconds
      : durationInMinutes
      ? durationInMinutes * 60
      : 0;
    if (totalSeconds <= 0) return;
    this._totalTicks = totalSeconds;
  }

  dispose() {
    if (this.intervalId) clearInterval(this.intervalId);
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  start(serverUnixStartTimestamp: number) {
    if (!this._totalTicks || this.intervalId) {
      console.error(
        `Not starting the timer because ${
          this.intervalId
            ? "timer is already running"
            : "it's not set up properly"
        }`
      );
      return;
    }
    this.serverUnixStartTimestamp = serverUnixStartTimestamp;

    let lastTickUnixTimestamp = getUnixTime(new Date());
    const tick = () => {
      const newUnixTimestamp = getUnixTime(new Date());
      const ticksIncrement = newUnixTimestamp - lastTickUnixTimestamp;
      lastTickUnixTimestamp = newUnixTimestamp;

      this.currentTick += ticksIncrement;

      this.calculateWorkshopProgress();
      this.calculateWorkshopTimeRemaining();

      if (this.hasRunningActivity) {
        this.calculateActivityProgress();
        this.calculateActivityTimeRemaining();
        if (this.activityProgress === 100) {
          this.resetActivity();
        }
      }

      this.dispatchEvent(new CustomEvent(WorkshopClockEvent.TICK));
      if (this.currentTick >= this._totalTicks) {
        this.dispose();
        this.intervalId = null;
        this.dispatchEvent(new CustomEvent(WorkshopClockEvent.TIMEOUT));
      }
    };

    const currentTime = new Date();
    const nextSecond = startOfSecond(new Date(currentTime.getTime() + 1000));
    const differenceMs = differenceInMilliseconds(nextSecond, currentTime);

    tick();
    this.timeoutId = setTimeout(() => {
      tick();
      this.timeoutId = null;
      this.intervalId = setInterval(tick, 1000) as unknown as number;
    }, Math.max(differenceMs, 0)) as unknown as number;
  }

  adjustTime(currentServerUnixStartTimestamp: number): void {
    if (!this.serverUnixStartTimestamp) return;
    const newCurrentTick =
      currentServerUnixStartTimestamp - this.serverUnixStartTimestamp;
    this.currentTick = newCurrentTick;
  }

  private calculateWorkshopProgress(): void {
    const progress = this.currentTick / (this._totalTicks / 100);
    this.workshopProgress = Math.min(100, progress);
  }
  private calculateWorkshopTimeRemaining(): void {
    const remainingTicks = this._totalTicks - this.currentTick;
    this.workshopParsedTimeRemaining =
      this.parseTickCountToString(remainingTicks);
    this.workshopParsedTimeElapsed = this.parseTickCountToPassedTime(
      this.currentTick
    );
  }

  startActivity(
    activityId: string,
    activityServerUnixStartTimestamp: number,
    activityDurationInMinutes: number
  ): void {
    if (
      !this.isValidActivity(
        activityServerUnixStartTimestamp,
        activityDurationInMinutes
      )
    ) {
      console.error("Invalid activity start time");
      return;
    }
    if (activityDurationInMinutes <= 0 || !this.serverUnixStartTimestamp) {
      return;
    }

    this.activityId = activityId;

    this.resetActivity();
    const activityTicks = activityDurationInMinutes * 60;
    this.activityStartTick =
      activityServerUnixStartTimestamp - this.serverUnixStartTimestamp;
    this.activityLastTick = this.activityStartTick + activityTicks;

    this.calculateActivityProgress();
    this.calculateActivityTimeRemaining();
    if (this.activityProgress === 100) {
      this.resetActivity();
    }

    this.dispatchEvent(new CustomEvent(WorkshopClockEvent.TICK));
  }

  private isValidActivity(
    activityServerUnixStartTimestamp: number,
    activityDurationInMinutes: number
  ): boolean {
    return !(
      activityServerUnixStartTimestamp < (this.serverUnixStartTimestamp || 0) ||
      activityServerUnixStartTimestamp > (this.workshopUnixEndTimestamp || 0) ||
      activityServerUnixStartTimestamp + activityDurationInMinutes * 60 >
        (this.workshopUnixEndTimestamp || 0)
    );
  }

  private calculateActivityProgress(): void {
    const totalActivityTicks = this.activityLastTick - this.activityStartTick;
    const currentActivityTick = this.currentTick - this.activityStartTick;

    const progress = currentActivityTick / (totalActivityTicks / 100);
    this.activityProgress = Math.min(100, progress);
  }
  private calculateActivityTimeRemaining(): void {
    const totalActivityTicks = this.activityLastTick - this.activityStartTick;
    const currentActivityTick = this.currentTick - this.activityStartTick;

    const remainingTicks = totalActivityTicks - currentActivityTick;
    this.activityParsedTimeRemaining =
      this.parseTickCountToString(remainingTicks);
  }

  resetActivity() {
    this.activityStartTick = 0;
    this.activityLastTick = 0;
    this.activityProgress = 0;
    this.activityParsedTimeRemaining = "";
    this.dispatchEvent(new CustomEvent(WorkshopClockEvent.TICK));
  }

  parseTickCountToString(ticks: number): string {
    if (ticks <= 0) return "00:00";

    const remainingMinutes = Math.floor(ticks / 60);
    const remainingSeconds = ticks - remainingMinutes * 60;
    return this.formatTime(remainingMinutes, remainingSeconds);
  }

  parseTickCountToPassedTime(ticks: number): string | null {
    if (ticks <= 0) return "";
    const minutes = Math.floor(ticks / (this._totalTicks / 60));

    return minutes < 1
      ? ""
      : `${minutes} min. passed`;
  }

  formatTime(minutes: number, seconds: number) {
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  }
}
