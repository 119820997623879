import { PropsWithChildren, memo, useMemo, useRef } from "react";

import { calculateLeaderBoardStats } from "../../../../utils";
import { SessionStateValue } from "../../../../apollo-graphql/types/session-state";

import cn from "classnames";
import styles from "./LeaderBoard.module.css";

const LeaderBoard = (
  props: PropsWithChildren<{
    isTransitioning: boolean;
    allActivityResults?: SessionStateValue["context"]["activityResult"];
    allQuestionActivitiesAnswersMap?: { [key: string]: any };
  }>
) => {
  const {
    allActivityResults = [],
    allQuestionActivitiesAnswersMap = {},
    isTransitioning,
  } = props;

  const currentStatsRef = useRef<{
    consensusReached: number;
    authorMatches: number;
    changedOpinions: number;
  }>({ consensusReached: 0, authorMatches: 0, changedOpinions: 0 });

  const { consensusReached, authorMatches, changedOpinions } = useMemo(() => {
    if (!isTransitioning) return currentStatsRef.current;

    currentStatsRef.current = calculateLeaderBoardStats(
      allActivityResults,
      allQuestionActivitiesAnswersMap,
      isTransitioning
    );

    return currentStatsRef.current;
  }, [allActivityResults, allQuestionActivitiesAnswersMap, isTransitioning]);

  return (
    <div className={cn(styles.leaderBoardContainer, "main-container")}>
      <div className={styles.leaderBoardStatsContainer}>
        <div className={styles.leaderBoardStat}>
          <i className={cn(styles.leaderBoardStatIcon, "icon fa fa-users")} />
          <span className={cn(styles.leaderBoardStats, "primary")}>
            {consensusReached}
          </span>
          <span
            className={cn(styles.leaderBoardStatLabel, "text", "tiny", "bold")}
          >
            Consensus reached
          </span>
        </div>

        <div className="separator"></div>

        <div className={styles.leaderBoardStat}>
          <i className={cn(styles.leaderBoardStatIcon, "icon fa fa-star")} />
          <span className={cn(styles.leaderBoardStats, "primary")}>
            {authorMatches}
          </span>
          <span
            className={cn(styles.leaderBoardStatLabel, "text", "tiny", "bold")}
          >
            Author’s matches
          </span>
        </div>

        <div className="separator"></div>

        <div className={styles.leaderBoardStat}>
          <i className={cn(styles.leaderBoardStatIcon, "icon fa fa-trophy")} />
          <span className={cn(styles.leaderBoardStats, "primary")}>
            {changedOpinions}
          </span>
          <span
            className={cn(styles.leaderBoardStatLabel, "text", "tiny", "bold")}
          >
            Changed opinions
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(LeaderBoard);
