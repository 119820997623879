import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import cn from "classnames";
import * as Form from "@radix-ui/react-form";

import withRouteConfig from "../../hocs/withRouteConfig";

import { GlobalContext } from "../../contexts/Global";
import { AuthState } from "../../+xstate/machines/auth";
import { FetchState } from "../../+xstate/machines/fetch-factory";
import { IChangePassword } from "../../apollo-graphql/types/change-password";
import { ChangePasswordErrors } from "../../types/enums/errors";
import { mappedErrorMessages } from "../../constants/mapped-error-messages";

import Password from "../../components/Shared/Password/Password";
import SkeletonLoader from "../../components/Shared/SkeletonLoader/SkeletonLoader";
import Copyright from "../../components/Copyright/Copyright";

import styles from "./ChangePassword.module.css";

const ChangePassword = () => {
  const {
    auth: {
      changePassword,
      context,
      changePasswordState,
      validateTokenState,
      matches,
      validateToken,
    },
  } = useContext(GlobalContext);

  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");

  const isLoggedIn = useMemo(
    () => matches(AuthState.Authenticated) || matches(AuthState.TechnicalSetup),
    [matches]
  );
  const isLoading = useMemo(
    () => changePasswordState === FetchState.Fetching,
    [changePasswordState]
  );
  const isValidating = useMemo(
    () => validateTokenState === FetchState.Fetching,
    [validateTokenState]
  );

  const token = useMemo(() => searchParams.get("token") || "", [searchParams]);
  const email = useMemo(
    () => context.unauthenticatedUserEmail,
    [context.unauthenticatedUserEmail]
  );
  const canAccess = useMemo(() => !!token && !isLoggedIn, [token, isLoggedIn]);
  const redirectUrl = useMemo(() => {
    return !canAccess
      ? "/"
      : changePasswordState === FetchState.Success
      ? "/login"
      : null;
  }, [canAccess, changePasswordState]);

  const submitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setErrorMessage("");
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as IChangePassword;

      if (!payload.token) return;

      if (!payload.newPassword || !payload.repeatPassword) {
        setErrorMessage(mappedErrorMessages[ChangePasswordErrors.MISSING_DATA]);
        return;
      }

      if (payload.newPassword !== payload.repeatPassword) {
        setErrorMessage(
          mappedErrorMessages[ChangePasswordErrors.PASSWORDS_MISMATCH]
        );
        return;
      }
      changePassword({ variables: payload });
    },
    [changePassword]
  );

  useEffect(() => {
    if (!token) return;
    validateToken({ variables: { token } });
  }, [validateToken, token]);

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <div className={styles.changePasswordContainer}>
      <div className={cn("FormContainer", styles.formContainer)}>
        <div className={styles.titleContainer}>
          <h2 className="thin">
            Step 1 <span className={styles.steps}>(of 2)</span>{" "}
          </h2>
          <h2 className="thin">Setup your password</h2>
          <div className={cn("text", styles.emailInfo)}>
            Set the password for your account at <b>{email}</b>
          </div>
        </div>
        <Form.Root className="Form" onSubmit={submitHandler}>
          <div className="FormFieldsContainer">
            {isValidating ? (
              <>
                <div>
                  <SkeletonLoader
                    height={15}
                    width={70}
                    containerClassName={styles.label}
                  />
                  <SkeletonLoader height={54} />
                </div>
                <div>
                  <SkeletonLoader
                    height={15}
                    width={130}
                    containerClassName={styles.label}
                  />
                  <SkeletonLoader height={54} />
                </div>
              </>
            ) : (
              <>
                <Password
                  name="newPassword"
                  label="Password"
                  placeholder="Enter your new password"
                />
                <Password
                  name="repeatPassword"
                  label="Confirm password"
                  placeholder="Confirm your password"
                />
              </>
            )}
            <Form.Field name="token" style={{ display: "none", height: 0 }}>
              <Form.Control value={token} />
            </Form.Field>
          </div>
          {isValidating || isLoading ? (
            <>
              <SkeletonLoader height={38} />
            </>
          ) : (
            <Form.Submit className="btn">Setup Password</Form.Submit>
          )}
          {(errorMessage || context?.error) && !isLoading && (
            <div className="error">
              {errorMessage ||
                mappedErrorMessages[
                  context?.error as keyof typeof mappedErrorMessages
                ]}
            </div>
          )}
          {isLoading && <div className="loading">Loading...</div>}
        </Form.Root>
      </div>

      <Copyright className={styles.copyright} />
    </div>
  );
};

export default withRouteConfig(ChangePassword);
