import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./ScrollIndicator.module.css";
import cn from "classnames";

type ScrollIndicatorProps = PropsWithChildren<{
  className?: string;
  externalContentRef?: React.RefObject<HTMLDivElement>;
}>;

const ScrollIndicator = ({
  children,
  className,
  externalContentRef,
}: ScrollIndicatorProps) => {
  const [showArrow, setShowArrow] = useState(false);
  const internalContentRef = useRef<HTMLDivElement>(null);

  const contentRef = useMemo(
    () => externalContentRef || internalContentRef,
    [externalContentRef]
  );

  const checkScroll = useCallback(() => {
    if (!contentRef.current) return;

    const { scrollHeight, clientHeight, scrollTop } = contentRef.current;
    const hasVerticalScroll = scrollHeight > clientHeight;
    const isBottomReached = scrollTop + clientHeight + 1 >= scrollHeight;

    setShowArrow(hasVerticalScroll && !isBottomReached);
  }, [contentRef]);

  useEffect(() => {
    const observer = new MutationObserver(() => checkScroll());
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    window.addEventListener("resize", checkScroll);
    checkScroll();

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkScroll);
    };
  }, [checkScroll, contentRef]);

  return (
    <div
      className={cn(styles.container, className)}
      ref={contentRef}
      onScroll={checkScroll}
    >
      <div className={cn(styles.children, "children-content")}>{children}</div>
      <div className={styles.arrowContainer}>
        <div className={cn(styles.arrow, { [styles.arrowHidden]: !showArrow })}>
          <i className="fa-thin fa-chevrons-down" />
        </div>
      </div>
    </div>
  );
};

export default memo(ScrollIndicator);
