import { PropsWithChildren, memo, useMemo } from "react";

import { getEntryId, getTitle } from "../../../../utils";

import {
  IActivityResult,
  SessionStateValue,
} from "../../../../apollo-graphql/types/session-state";
import { SummarySectionType } from "../../../../types/contentful/workshop/summary";
import { ActivityType } from "../../../../types/contentful/workshop/activity-type";
import { Workspace } from "../../../../apollo-graphql/types/workspace";
import { Profile } from "../../../../apollo-graphql/types/profile";
import { Workshop } from "../../../../types/contentful/workshop/workshop";

import Congratulations from "../../Congratulations/Congratulations";
import AhaMoment from "./components/AhaMoment/AhaMoment";
import JourneyInformation from "../../JourneyInformation/JourneyInformation";
import NextStep from "./components/NextStep/NextStep";
import SectionCard from "./components/SectionCard/SectionCard";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import Overview from "./components/Overview/Overview";

import styles from "./ViewResults.module.css";
import cn from "classnames";

interface IViewResultProps {
  sessionState: SessionStateValue;
  activityResult: IActivityResult[];
  workshopActivities: ActivityType[];
  profile: Profile;
  workshop: Workshop;
  isConnectionWeak: boolean;
  isParticipating: boolean;
  workspace: Workspace;
  journeyItems: Workshop[] | null;
  token: string;
  requestNextWorkshop: (id: string) => void;
  nextWorkshopRequested: boolean;
}

const ViewResults = (props: PropsWithChildren<IViewResultProps>) => {
  const {
    sessionState,
    workshopActivities,
    activityResult,
    profile,
    workshop,
    journeyItems,
    requestNextWorkshop,
    nextWorkshopRequested,
    isConnectionWeak,
    isParticipating,
  } = props;

  const profileId = useMemo(() => profile.id, [profile]);

  const journey = useMemo(() => {
    return workshop?.fields?.journey?.fields || null;
  }, [workshop?.fields?.journey?.fields]);

  const workshopAuthor = useMemo(() => {
    return {
      id: journey?.author?.sys?.id,
      name: journey?.author?.fields?.name,
      imageUrl: journey?.author?.fields?.image?.fields?.file?.url,
      headline: journey?.author?.fields?.headline,
    };
  }, [
    journey?.author?.fields?.headline,
    journey?.author?.fields?.image?.fields?.file?.url,
    journey?.author?.fields?.name,
    journey?.author?.sys?.id,
  ]);

  const summary = useMemo(() => {
    const rawSummary = workshop?.fields?.summary;

    return {
      id: getEntryId(rawSummary),
      title: rawSummary?.fields?.title,
      journey: {
        id: getEntryId(workshop?.fields?.journey),
        title: journey?.title,
        imageUrl: journey?.smallBannerImage?.fields?.file?.url,
      },
      sections: (rawSummary?.fields?.sections || []).map((s) => ({
        id: getEntryId(s),
        title: s?.fields?.title,
        type: s?.fields?.type,
        items: s?.fields?.items || [],
      })),
    };
  }, [
    journey?.smallBannerImage?.fields?.file?.url,
    journey?.title,
    workshop?.fields?.journey,
    workshop?.fields?.summary,
  ]);

  const content = useMemo(() => {
    return summary.sections
      .map(({ id, title, type, items }) => {
        const hasAhaMoments = type === SummarySectionType.AhaMoments;
        const hasJourneys =
          type === SummarySectionType.Journey &&
          journeyItems?.length &&
          profileId;
        const hasOverview =
          type === SummarySectionType.Overview

        if (hasAhaMoments) {
          return (
            <AhaMoment
              key={`aha-moment-${id}`}
              title={title}
              activityResult={activityResult}
              profileId={profileId}
              workshopAuthor={workshopAuthor}
            />
          );
        }

        if (hasJourneys) {
          return (
            <SectionCard key={`journey-${id}`} title={summary.title}>
              <JourneyInformation
                currentWorkshopId={getEntryId(workshop)}
                currentWorkshopTitle={getTitle(workshop)}
                journeyTitle={summary.title}
                image={summary.journey.imageUrl || null}
                journeyItems={journeyItems}
                profileId={profileId}
                requestNextWorkshop={requestNextWorkshop}
                nextWorkshopRequested={nextWorkshopRequested}
                isViewResults
              />
            </SectionCard>
          );
        }

        if (hasOverview) {
          return <Overview
            key={`overview-${id}`}
            workshop={workshop}
            title={title}
            activityResult={activityResult}
            profile={profile}
            sessionState={sessionState}
            isConnectionWeak={isConnectionWeak}
            activities={workshopActivities}
            isParticipating={isParticipating}
          />
        }

        return (
          <NextStep
            key={`next-step-${id}`}
            sectionId={id}
            title={title}
            items={items}
            workshopAuthor={workshopAuthor}
          />
        );
      })
  }, [
    summary.sections,
    summary.title,
    summary.journey.imageUrl,
    workshop,
    activityResult,
    profile,
    sessionState,
    isConnectionWeak,
    workshopActivities,
    isParticipating,
    journeyItems,
    profileId,
    workshopAuthor,
    requestNextWorkshop,
    nextWorkshopRequested,
  ]);

  return (
    <ScrollIndicator className={cn(styles.resultsContainer, "main-container")}>
      <div className={styles.resultsDataContainer}>
        <Congratulations
          congratulationImageSrc="/images/congratulations_view_results.svg"
          showAnimation
        />
        <div className={styles.resultsContent}>
          <div className={styles.content}>{content}</div>
        </div>
      </div>
    </ScrollIndicator>
  );
};

export default memo(ViewResults);
