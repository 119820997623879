import { PropsWithChildren, memo } from "react";

import InvitationStatusIcon from "../../../../../Shared/InvitationStatusIcon/InvitationStatusIcon";
import { InvitationStatus } from "../../../../../../types/enums/invitation-status";
import styles from "./InvitationContent.module.css";

const InvitationContent = (
  props: PropsWithChildren<{ count: number; status: InvitationStatus }>
) => {
  const { children, count, status } = props;

  return (
    <>
      <div className={styles.invitationContent}>
        <div className="invitation-status">
          <InvitationStatusIcon status={status} />
          <h5>
            {count} {status.toLocaleLowerCase()}
          </h5>
        </div>
        <div className="invited-users">{children}</div>
      </div>
      <div className={styles.spacer} />
    </>
  );
};

export default memo(InvitationContent);
