import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import * as Form from "@radix-ui/react-form";
import { debounce } from "lodash";

import Editor from "../../../../../Shared/Editor/Editor";
import ActionFooter from "../../../../ActionFooter/ActionFooter";

import { useDocMap } from "../../../../../../hooks/useDocMap";
import { useEditorClass } from "../../../../../../hooks/useEditorClass";
import { useAwareness } from "../../../../../../hooks/useAwareness";

import { ActionFooterType } from "../../../../../../types/action-footer";
import { FooterType } from "../../../../../../types/enums/activity-footer";
import { deserializeUint8Array } from "../../../../../../utils/uint8-array-serializers";
import { ActivityComplexValue } from "../../../../../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../../../../../types/enums/activity-complex-value-type";
import { WorkshopState } from "../../../../../../+xstate/machines/session/workshop";
import { Profile } from "../../../../../../apollo-graphql/types/profile";
import { stepsSelectors } from "../../../../../Guide/steps";
import { observerFooterData } from "../../../constants";
import ContentfulRichField from "../../../../../Shared/ContentfulRichField/ContentfulRichField";

import cn from "classnames";
import styles from "./TeamName.module.css";

const editorKey = "teamName";
const editorDeps = [editorKey] as const;

export default memo(function TeamName(
  props: PropsWithChildren<{
    workshopState: WorkshopState;
    isConnectionWeak: boolean;
    teamNameValue: string;
    isReady: boolean;
    transition: number;
    isParticipating: boolean;
    notReadyProfilesCount: number;
    currentActiveParticipantCount: number;
    changeTeamNameHandler: (inputValue: string) => void;
    setActivityReadyHandler: () => void;
    profile: Profile;
    teamName: string;
    title: string;
    instructions: string;
  }>
) {
  const {
    isReady,
    transition,
    teamNameValue,
    workshopState,
    isConnectionWeak,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    isParticipating,
    profile,
    teamName,
    title,
    instructions,
    changeTeamNameHandler,
    setActivityReadyHandler,
  } = props;

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const editorClass = useEditorClass({
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
  });
  const { awarenessState, onAwarenessUpdate } = useAwareness(editorDeps);
  const { docs, generateComplexResultValueAsString } = useDocMap(editorDeps);

  const isLoading = useMemo(
    () => workshopState === WorkshopState.SettingValue,
    [workshopState]
  );

  const debouncedChangeTeamNameHandler = useMemo(
    () => debounce(changeTeamNameHandler, 100),
    [changeTeamNameHandler]
  );

  const teamNameData = useMemo(() => {
    if (!teamNameValue.includes(`"value":"${editorKey}:[`)) return null;
    try {
      return JSON.parse(teamNameValue) as ActivityComplexValue;
    } catch {
      console.error("Cannot parse teamNameValue to extract data");
      return null;
    }
  }, [teamNameValue]);

  const teamNameUIntValue = useMemo(() => {
    if (teamNameData === null) return new Uint8Array([]);
    const value = teamNameData.value.split(":")[1];
    return deserializeUint8Array(value) || new Uint8Array([]);
  }, [teamNameData]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return {
        text: (
          <>
            Everyone agreed{teamName && <> on “{teamName}”</>}! Continuing
            forward...
          </>
        ),
        buttonText: "I agree",
        disabledButton: true,
        type: FooterType.Ready,
      };
    }
    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "I agree",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;
    return {
      text: (
        <>
          {!!playersClicked
            ? "A new team name was proposed. Click on “I agree” to continue!"
            : "Everyone must agree on the team name to continue!"}{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText: "I agree",
      disabledButton: !teamName.length,
      type: FooterType.Notice,
    };
  }, [
    isTransitioning,
    isParticipating,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    teamName,
  ]);

  const onValueUpdate = useCallback(() => {
    const activityComplexValue: ActivityComplexValue = {
      value: generateComplexResultValueAsString(),
      type: ActivityComplexValueType.yjs,
    };
    debouncedChangeTeamNameHandler(JSON.stringify(activityComplexValue));
  }, [debouncedChangeTeamNameHandler, generateComplexResultValueAsString]);

  return (
    <Form.Root onSubmit={(e) => e.preventDefault()}>
      <div className={styles.container}>
        <div
          className={cn(
            styles.teamNameContainer,
            "main-container",
            !isParticipating && "cursor-not-allowed"
          )}
        >
          <h1>{title}</h1>
          <p className="text secondary">
            <i className="icon fa fa-regular fa-circle-info" />{" "}
            <ContentfulRichField
              content={instructions}
              className={styles.instructions}
            />
            {}
          </p>
          <div
            className={cn(
              styles.textAreaWrapper,
              stepsSelectors.teamNameInput,
              !isParticipating && "no-interaction-section"
            )}
          >
            {isParticipating ? (
              <Editor
                doc={docs[editorKey]}
                key={editorKey}
                name={editorKey}
                onValueUpdate={onValueUpdate}
                onAwarenessUpdate={onAwarenessUpdate}
                value={teamNameUIntValue}
                awareness={awarenessState[0]?.awareness}
                profileName={profile.name}
                placeholder="Name this team"
                isInput={true}
                className={editorClass}
                maxLength={30}
              />
            ) : (
              <div
                className={cn(
                  "input-sync-disabled",
                  !teamName && "placeholder"
                )}
                key={editorKey}
              >
                {teamName || "Name this team"}
              </div>
            )}
          </div>
        </div>
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          buttonClickHandler={setActivityReadyHandler}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
        >
          {actionFooterData.text}
        </ActionFooter>
      </div>
    </Form.Root>
  );
});
