import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useMachine } from "@xstate/react";
import {
  AuthMachineContext,
  AuthState,
  authMachine,
  changePasswordMachine,
  getJourneyParticipationDataMachine,
  resetPasswordMachine,
  updateProfileMachine,
  validateTokenMachine,
} from "../+xstate/machines/auth";
import * as authActions from "../+xstate/actions/auth";
import { ApolloContext, graphQLError } from "./Apollo";
import { Entries, Writeable } from "../types/helpers";
import { useChildMachine } from "../hooks/useChildMachine";
import { FetchState } from "../+xstate/machines/fetch-factory";
import { JourneyCertificationsWithCertificate } from "../types/journey-certificates";
import { GraphQLError } from "graphql";
import { CertificationType } from "../types/enums/certification-type";
import { useIndexedDBState } from "../hooks/useIndexedDb";
import { TechnicalSetupConfig } from "../types/technical-setup-config";
import { DevicePermissions } from "../utils/check-device-permissions";
import { AuthCtx } from "../types/auth-ctx";

type AuthActions = typeof authActions;
type ActionDispatchers = {
  [K in keyof AuthActions]: (
    payload: ReturnType<AuthActions[K]>["payload"]
  ) => void;
};

export const GlobalContext = createContext<{
  instanceUUID: string;
  auth: {
    state: AuthState;
    context: AuthMachineContext;
    matches: ReturnType<typeof useMachine<typeof authMachine>>["0"]["matches"];
    resetPasswordState: FetchState;
    changePasswordState: FetchState;
    updateProfileState: FetchState;
    validateTokenState: FetchState;
    journeyParticipationDataState: FetchState;
    journeyCertificationsWithCertificate: JourneyCertificationsWithCertificate;
  } & ActionDispatchers;
}>({} as any);

const actionEntries = Object.entries(authActions) as Entries<
  typeof authActions
>;

export const GlobalContextProvider = (
  props: PropsWithChildren<{
    instanceUUID: string;
    devicePermissions: DevicePermissions;
    authCtx: AuthCtx | null;
    technicalSetupConfig: TechnicalSetupConfig | null;
    skipTechnicalSetup?: boolean;
  }>
) => {
  const {
    authCtx,
    instanceUUID,
    devicePermissions,
    technicalSetupConfig,
    skipTechnicalSetup,
  } = props;
  const apolloContext = useContext(ApolloContext);

  const [deviceConfig] = useIndexedDBState<TechnicalSetupConfig | null>(
    "deviceConfig",
    null
  );

  const [state, send] = useMachine(authMachine, {
    input: {
      client: apolloContext.client,
      token: authCtx?.token,
      profile: authCtx?.profile,
      instanceUUID: props.instanceUUID,
      deviceConfig:
        devicePermissions && devicePermissions.microphone === "granted"
          ? deviceConfig
          : null,
      selectedAudioDevice: technicalSetupConfig?.selectedAudioDevice,
      selectedVideoDevice: technicalSetupConfig?.selectedVideoDevice,
      connectionSuccess: technicalSetupConfig?.connectionSuccessful,
      skipTechnicalSetup: skipTechnicalSetup,
    },
  });

  const graphqlErrorEventTarget = useMemo(
    () => apolloContext.graphqlErrorEventTarget,
    [apolloContext.graphqlErrorEventTarget]
  );

  useEffect(() => {
    const graphQLErrorHandler = (event: Event | null) => {
      if (!event || !("detail" in event)) return;
      const detail = event.detail as GraphQLError;
      if (detail.message === "TOKEN_EXPIRED") {
        return void send(authActions.logout());
      }
    };
    graphqlErrorEventTarget.addEventListener(graphQLError, graphQLErrorHandler);
    return () =>
      graphqlErrorEventTarget.removeEventListener(
        graphQLError,
        graphQLErrorHandler
      );
  }, [graphqlErrorEventTarget, send]);

  const [{ value: changePasswordState }] = useChildMachine(
    state,
    changePasswordMachine.id
  ) as any; // TODO: fix useChildMachine @iliya
  const [{ value: journeyParticipationDataState }] = useChildMachine(
    state,
    getJourneyParticipationDataMachine.id
  ) as any; // TODO: fix useChildMachine @iliya
  const [{ value: resetPasswordState }] = useChildMachine(
    state,
    resetPasswordMachine.id
  ) as any; // TODO: fix useChildMachine @iliya
  const [{ value: updateProfileState }] = useChildMachine(
    state,
    updateProfileMachine.id
  ) as any; // TODO: fix useChildMachine @iliya
  const [{ value: validateTokenState }] = useChildMachine(
    state,
    validateTokenMachine.id
  ) as any;

  const dispatchers = useMemo(
    () =>
      actionEntries.reduce((acc, [key, creator]) => {
        acc[key] = (payload) => {
          const action = creator(payload as any);
          send(action);
        };
        return acc;
      }, {} as Writeable<ActionDispatchers>),
    [send]
  );

  const currentProfileId = state.context.profile?.id;
  const journeyParticipationDataResults =
    state.context.profile?.workspace?.workspace.journeyParticipationData
      ?.journeyResults;
  const workspaceCertificates = useMemo(() => {
    return state.context.profile?.workspace?.workspace.certificates || [];
  }, [state.context.profile?.workspace?.workspace.certificates]);

  const journeyCertificationsWithCertificate: JourneyCertificationsWithCertificate =
    useMemo(() => {
      const journeyCertificates: JourneyCertificationsWithCertificate = {};

      for (const certificate of workspaceCertificates) {
        const currentJourneyParticipationData =
          journeyParticipationDataResults?.find(
            (jr) => jr.journey?.sys?.id === certificate.journey_id
          );
        const journeyCertificatesItem = journeyCertificates[
          certificate.journey_id
        ] || {
          [CertificationType.Workspace]: false,
          [CertificationType.Personal]: false,
          [CertificationType.Leader]: false,
          certificate,
        };
        if (!!certificate.profile_id) journeyCertificatesItem.personal = true;
        if (!!certificate.workspace_id)
          journeyCertificatesItem.workspace = true;
        if (
          certificate.workspace_id &&
          !!currentJourneyParticipationData &&
          !!currentJourneyParticipationData.journeyLeaders.find(
            (jl) => jl.id === currentProfileId
          )
        )
          journeyCertificatesItem.leader = true;
        journeyCertificates[certificate.journey_id] = journeyCertificatesItem;
      }
      return journeyCertificates;
    }, [
      currentProfileId,
      journeyParticipationDataResults,
      workspaceCertificates,
    ]);

  const value = useMemo(
    () => ({
      auth: {
        state: state.value as AuthState,
        context: state.context as AuthMachineContext,
        matches: (value: any) => state.matches(value),
        ...dispatchers,
        changePasswordState,
        resetPasswordState,
        validateTokenState,
        updateProfileState,
        journeyParticipationDataState,
        journeyCertificationsWithCertificate,
      },
      instanceUUID,
    }),
    [
      dispatchers,
      instanceUUID,
      state,
      changePasswordState,
      resetPasswordState,
      validateTokenState,
      updateProfileState,
      journeyParticipationDataState,
      journeyCertificationsWithCertificate,
    ]
  );

  useEffect(() => {
    apolloContext.setToken(state.context.token);
  }, [apolloContext, state.context.token]);

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
};
