import { Sys } from "../types/contentful/common";
import { ConferenceMode } from "../types/contentful/enums";
import { ActivityType } from "../types/contentful/workshop/activity-type";
import { Workshop } from "../types/contentful/workshop/workshop";
import { WorkshopActivityType } from "../types/enums/activity-type";

export const getEntryId = (entry?: { sys: Sys }) => {
  if (!entry) return "";

  return entry["sys"]["id"];
};

export const getTitle = (entry?: Workshop | ActivityType | null) => {
  if (!entry) return "";

  return entry["fields"]["title"];
};

export const getActivities = (workshop?: Workshop) => {
  if (!workshop) return [];
  return workshop["fields"]["activities"];
};

export const getType = <T = WorkshopActivityType>(item?: { sys: Sys }) => {
  if (!item) return "";
  return item["sys"]["contentType"]["sys"]["id"] as T;
};

export const getDuration = (activity?: ActivityType | null) => {
  if (!activity) return 0;
  return activity["fields"]["activity"]["fields"]["duration"] || 0;
};

export const getTransitionData = (
  activity: ActivityType | null | undefined,
  hasAhaMoments: boolean
) => {
  const activityType = activity?.sys?.contentType?.sys
    ?.id as WorkshopActivityType;
  const transitionText =
    activity?.fields?.activity?.fields?.transitionText ||
    (hasAhaMoments ? "Aha Moments" : "Final Emotion");

  return {
    activityType,
    conferenceMode:
      activity?.fields?.activity?.fields?.conferenceMode || ConferenceMode.All,
    transitionText,
    hasTransition: !!activity?.fields?.activity?.fields?.hasTransition,
  };
};
