import { PropsWithChildren, useContext, useMemo } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useMatches,
  useSearchParams,
} from "react-router-dom";

import { GlobalContext } from "./contexts/Global";
import { AuthState } from "./+xstate/machines/auth";
import { SessionContextProvider } from "./contexts/Session";
import { JitsiContextProvider } from "./contexts/Jitsi";
import { HeaderContextProvider } from "./contexts/Header";
import { RouteConfigData } from "./hocs/withRouteConfig";

import Header from "./components/Header/Header";
import Login from "./pages/Login/Login";
import DashboardLayout from "./pages/DashboardLayout";
import Session from "./pages/Session";
import Logout from "./pages/Logout";
import Disconnected from "./pages/Disconnected";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import WorkshopRedirect from "./components/Workshop/WorkshopRedirect/WorkshopRedirect";
import Dashboard from "./components/Dashboard/Admin/Dashboard/Dashboard";
import TeamMembers from "./components/Dashboard/Admin/TeamMembers/TeamMembers";
import Workshops from "./components/Dashboard/Workshops/Workshops";
import JourneyDetails from "./components/Dashboard/Admin/Journey/JourneyDetails/JourneyDetails";
import JourneyList from "./components/Dashboard/Admin/Journey/JourneyList";
import Schedule from "./components/Dashboard/Admin/Schedule/Schedule";
import TechnicalSetup from "./pages/TechnicalSetup/TechnicalSetup";

import "./Router.module.css";

const authRequired = ["dashboard", "session", "logout", "technical-setup"];
const noAuthRequired = ["login"];

const technicalSetupRouterConfigData: RouteConfigData = {
  hideWorkspaceName: true,
  hideUserDropdown: true,
  hideHeaderTheme: true,
  headerLogoBig: true,
};

const Root = () => {
  const {
    auth: { state, matches },
  } = useContext(GlobalContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const redirectUrl = useMemo(
    () => searchParams.get("redirectUrl") || "/",
    [searchParams]
  );
  const loginUrl = useMemo(() => {
    return location.pathname &&
      !location.pathname.includes("logout") &&
      location.pathname !== "/"
      ? `/login?redirectUrl=${location.pathname}`
      : `/login`;
  }, [location.pathname]);

  const [, main] = useMatches();

  const isValidating = state === AuthState.Validating;

  // NOTE: Technical setup is not required when accessing the
  // sessions instance because will have to go trough technical
  // setup anyways
  const isTechnicalSetupRequired = matches(AuthState.TechnicalSetup);

  const isLoggedIn =
    matches(AuthState.Authenticated) || isTechnicalSetupRequired;

  if (isValidating) return null;

  const redirect =
    isLoggedIn && isTechnicalSetupRequired && main.id !== "technical-setup" ? (
      <Navigate
        to={`/technical-setup${
          !["/", "/technical-setup"].includes(redirectUrl)
            ? `?redirectUrl=${redirectUrl}`
            : ""
        }`}
      />
    ) : isLoggedIn && noAuthRequired.includes(main.id) ? (
      <Navigate to={redirectUrl} />
    ) : !isLoggedIn && authRequired.includes(main.id) ? (
      <Navigate to={loginUrl} />
    ) : main.id === "technical-setup" && isTechnicalSetupRequired === false ? (
      <Navigate to={redirectUrl || "/"} />
    ) : null;

  const routerContainerClassName = [
    "RouterContainer",
    isLoggedIn ? "" : "LoggedOut",
  ]
    .filter(Boolean)
    .join(" ");

  return redirect ? (
    redirect
  ) : (
    <HeaderContextProvider>
      <div className={routerContainerClassName}>
        <div>
          <Header />
        </div>
        <Outlet />
      </div>
    </HeaderContextProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    id: "root",
    children: [
      {
        id: "technical-setup",
        path: "technical-setup",
        element: (
          <TechnicalSetup
            title="Technical setup"
            data={technicalSetupRouterConfigData}
          />
        ),
      },
      {
        path: "/",
        element: <DashboardLayout title="Dashboard - AhaPlay" />,
        id: "dashboard",
        children: [
          {
            id: "dashboard-main",
            path: "",
            element: <Dashboard />,
          },
          {
            id: "dashboard-schedule",
            path: "schedule",
            element: <Schedule title="Schedule - AhaPlay" />,
            children: [
              {
                id: "dashboard-schedule-team",
                path: "team",
                children: [
                  {
                    id: "dashboard-schedule-team-upcoming",
                    path: "upcoming",
                  },
                  {
                    id: "dashboard-schedule-team-history",
                    path: "history",
                  },
                ],
              },
              {
                id: "dashboard-schedule-my",
                path: "my",
                children: [
                  {
                    id: "dashboard-schedule-my-upcoming",
                    path: "upcoming",
                  },
                  {
                    id: "dashboard-schedule-my-history",
                    path: "history",
                  },
                ],
              },
              {
                id: "dashboard-schedule-upcoming",
                path: "upcoming",
              },
              {
                id: "dashboard-schedule-history",
                path: "history",
              },
            ],
          },
          {
            id: "dashboard-team-members",
            path: "team-members",
            element: <TeamMembers title="Team Members - AhaPlay" />,
            children: [
              {
                id: "dashboard-team-members-edit",
                path: "/team-members/edit/:id",
              },
              {
                id: "dashboard-team-members-delete",
                path: "/team-members/delete/:id",
              },
            ],
          },
          {
            id: "dashboard-workshops",
            path: "workshops",
            element: <Workshops title="Conversations - AhaPlay" />,
            children: [
              {
                id: "dashboard-workshop-dialog",
                path: "schedule/:workshopId",
                index: true,
              },
              {
                id: "dashboard-workshop-request",
                path: "request/:workshopId",
                index: true,
              },
            ],
          },
          {
            id: "dashboard-journeys",
            path: "journeys",
            element: <JourneyList title="Journeys - AhaPlay" />,
          },
          {
            id: "dashboard-journey",
            path: "journeys/:journeyId",
            element: <JourneyDetails />,
            children: [
              {
                id: "dashboard-journeys-workshop-dialog",
                path: "schedule/:workshopId",
                index: true,
              },
            ],
          },
        ],
      },
      {
        id: "session",
        path: "/session",
        element: (
          <SessionContextProvider>
            <JitsiContextProvider>
              <Session />
            </JitsiContextProvider>
          </SessionContextProvider>
        ),
        children: [
          {
            id: "session-slot",
            path: "slot/:slotId",
            index: true,
          },
          {
            id: "session-instance",
            path: "instance/:sessionKey",
            index: true,
          },
          {
            id: "session-instance-group",
            path: "instance/:sessionKey/:group",
            index: true,
          },
          {
            id: "reschedule-redirect",
            path: "waiting-room/reschedule-redirect/:slotId/:newSlotId",
            index: true,
          },
          {
            id: "waiting-room",
            path: "waiting-room/:slotId",
            index: true,
          },
          {
            id: "ongoing-sessions",
            path: "ongoing-sessions/:slotId",
            index: true,
          },
          {
            id: "thank-you",
            path: "thank-you/:slotId/:group",
            index: true,
          },
          {
            id: "error",
            path: "error/:slotId/:group",
            index: true,
          },
          {
            id: "connection-lost",
            path: "connection-lost/:slotId/:group",
            index: true,
          },
        ],
      },
      {
        path: "workshop-redirect/:slotId/:group",
        element: <WorkshopRedirect />,
      },
      {
        path: "/disconnected",
        element: <Disconnected />,
      },
      {
        path: "/login",
        element: <Login title="Login - AhaPlay" />,
        id: "login",
      },
      {
        path: "/logout",
        element: <Logout />,
        id: "logout",
      },
      {
        path: "/reset-password",
        element: <ResetPassword title="Reset Password - AhaPlay" />,
        id: "reset-password",
      },
      {
        path: "/change-password",
        element: <ChangePassword title="Change Password - AhaPlay" />,
        id: "change-password",
      },
    ],
  },
]);

export default function Router(props: PropsWithChildren) {
  return <RouterProvider router={router} />;
}
