import { PropsWithChildren, memo, useCallback, useContext } from "react";
import cn from "classnames";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import LoadingButton from "../../../../../Shared/Buttons/LoadingButton/LoadingButton";

import {
  Profile,
  ProfileDelete,
} from "../../../../../../apollo-graphql/types/profile";

import { mappedErrorMessages } from "../../../../../../constants/mapped-error-messages";
import { UpdateProfileErrors } from "../../../../../../types/enums/errors";
import { GlobalContext } from "../../../../../../contexts/Global";

import styles from "./DeleteTeamMemberModal.module.css";

const DeleteTeamMemberModal = (
  props: PropsWithChildren<{
    memberProfile: Profile;
    errorMessage: string | null;
    isLoading: boolean;
    deleteTeamMember: (params: ProfileDelete) => void;
    onClose: () => void;
  }>
) => {
  const { memberProfile, errorMessage, deleteTeamMember, isLoading, onClose } =
    props;

  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);

  const onClick = useCallback(() => {
    deleteTeamMember({
      id: memberProfile.id,
      workspace_id: profile!.workspace.workspace_id,
    });
  }, [deleteTeamMember, profile, memberProfile.id]);

  return (
    <Dialog.Root open onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn(styles.editModal, "DialogContent")}>
          <Dialog.Title className="DialogTitle">
            Delete team member
          </Dialog.Title>
          <p>
            <span>Are you sure you want to delete </span>{" "}
            <b>{memberProfile.name}</b> with email <b>{memberProfile.email}</b>?
          </p>
          {errorMessage && (
            <div>
              {mappedErrorMessages[errorMessage as UpdateProfileErrors]}
            </div>
          )}
          <div className={styles.saveBtnContainer}>
            <button className={cn("btn", "ghost")} onClick={onClose}>
              Cancel
            </button>
            <LoadingButton
              isLoading={isLoading}
              className={"btn destructive"}
              onClick={onClick}
            >
              Confirm
            </LoadingButton>
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(DeleteTeamMemberModal);
