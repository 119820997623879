import { memo } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from './StartJourneyButton.module.css';

interface StartJourneyButtonProps {
  conversationId: string | null;
  journeyId: string;
}

const StartJourneyButton = ({ conversationId, journeyId }: StartJourneyButtonProps) => (
  conversationId && <Link 
    className={cn("btn", styles.button)}
    to={`/journeys/${journeyId}/schedule/${conversationId}`}
  >
    Start your journey
    <i className={cn("fa-regular", "fa-arrow-right", styles.buttonIcon)} />
  </Link>
);

export default memo(StartJourneyButton);