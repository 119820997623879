import {
  createMachine,
  fromPromise,
  assign,
  spawnChild,
  sendTo,
  raise,
  fromCallback,
} from "xstate";
import * as actions from "../actions/auth";
import { login } from "../../apollo-graphql/mutations/login";
import { updateProfile } from "../../apollo-graphql/mutations/update-profile";
import { Profile } from "../../apollo-graphql/types/profile";
import {
  getJourneyParticipationData,
  getMyProfile,
  setProfileAsCompleted,
} from "../../apollo-graphql/queries/profile";
import { AppApolloClient } from "../../contexts/Apollo";
import { fetchMachineFactory, FetchState } from "./fetch-factory";
import { changePassword } from "../../apollo-graphql/mutations/change-password";
import { resetPassword } from "../../apollo-graphql/queries/reset-password";
import {
  deleteImageFromS3,
  getPreSignedUrl,
  uploadImageToS3,
} from "../../fetch/image";
import { generateWorkspaceProfileImageKey } from "../../utils";
import { getUnixTime } from "date-fns";
import { refreshMyProfileChannel } from "../../constants/channels";
import {
  technicalSetupConfig,
  authContextLocalStorageName,
} from "../../constants/environment";
import { useMachine } from "@xstate/react";
import { Jitsi, JitsiEvents } from "../../jitsi";
import { putItem, deleteItem } from "../../utils/db";
import { SourceData } from "../../types/source-data";
import { TechnicalSetupConfig } from "../../types/technical-setup-config";
import { RouteConfigData } from "../../hocs/withRouteConfig";
import { technicalSetupHelp } from "../../apollo-graphql/queries/technical-setup-help";
import { validateChangePasswordToken } from "../../apollo-graphql/queries/validateChangePasswordToken";

export enum AuthState {
  Authenticated = "authenticated",
  Validating = "validating",
  Unauthenticated = "unauthenticated",
  Authenticating = "authenticating",
  UpdateProfile = "updateProfile",
  TechnicalSetup = "technicalSetup",
}

export enum TechnicalSetupHelpOutcome {
  Success = "success",
  Failure = "failure",
}

export interface AuthMachineContext {
  client: AppApolloClient;
  profile: Profile | null;
  unauthenticatedUserEmail: string | null;
  token: string | null;
  error: null | string;
  presignedUrl: null | string;
  fileForUpload: null | File;
  imageLastUpdatedTimeStamp: null | number;
  jitsiInstance: Jitsi | null;
  availableAudioDevices: SourceData[] | null;
  selectedAudioDevice: SourceData | null;
  availableVideoDevices: SourceData[] | null;
  selectedVideoDevice: SourceData | null;
  connectionSuccess: boolean | null;
  routeTitle: string | null;
  routeData: RouteConfigData | null;
  skipTechnicalSetup: boolean;
  technicalSetupHelpOutcome: TechnicalSetupHelpOutcome | null;
}

export enum ProfileSettings {
  Default = "DEFAULT",
  UpdateProfile = "UPDATE_PROFILE",
  UpdateProfile_Default = "UPDATE_PROFILE__DEFAULT",
  UpdateProfile_GettingPresignedUrl = "UPDATE_PROFILE__GETTING_PRESIGNED_URL",
  UpdateProfile_UploadingProfileImage = "UPDATE_PROFILE__UPLOADING_PROFILE_IMAGE",
  UpdateProfile_DeleteProfileImage = "UPDATE_PROFILE__DELETE_PROFILE_IMAGE",
  ChangePassword = "CHANGE_PROFILE",
}

type AllAuthActionCreators = typeof actions;
type AllAuthActionCreatorKeys = keyof AllAuthActionCreators;
type AllAuthActions =
  | ReturnType<AllAuthActionCreators[AllAuthActionCreatorKeys]>
  | ReturnType<typeof validateTokenSuccess>
  | ReturnType<typeof validateTokenFailure>
  | ReturnType<typeof updateProfileSuccess>
  | ReturnType<typeof updateProfileFailure>
  | ReturnType<typeof resetPasswordSuccess>
  | ReturnType<typeof resetPasswordFailure>
  | ReturnType<typeof changePasswordSuccess>
  | ReturnType<typeof changePasswordFailure>
  | ReturnType<typeof getPreSignedUrlSuccess>
  | ReturnType<typeof getPreSignedUrlFailure>
  | ReturnType<typeof uploadProfileImageSuccess>
  | ReturnType<typeof uploadProfileImageFailure>
  | ReturnType<typeof deleteProfileImageSuccess>
  | ReturnType<typeof deleteProfileImageFailure>
  | ReturnType<typeof getMyProfileSuccess>
  | ReturnType<typeof getMyProfileFailure>
  | ReturnType<typeof getJourneyParticipationDataSuccess>
  | ReturnType<typeof getJourneyParticipationDataFailure>
  | ReturnType<typeof technicalSetupHelpSuccess>
  | ReturnType<typeof technicalSetupHelpFailure>
  | ReturnType<typeof setProfileAsCompletedSuccess>
  | ReturnType<typeof setProfileAsCompletedFailure>;

type AuthMachineTypes = {
  context: AuthMachineContext;
  events: AllAuthActions;
};

export const {
  machine: validateTokenMachine,
  success: validateTokenSuccess,
  failure: validateTokenFailure,
  trigger: validateTokenTrigger,
} = fetchMachineFactory({
  id: "validateToken",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["validateToken"]>["payload"];
  }) => {
    return validateChangePasswordToken(client, payload.variables);
  },
});

export const {
  machine: getMyProfileMachine,
  success: getMyProfileSuccess,
  failure: getMyProfileFailure,
  trigger: getMyProfileTrigger,
} = fetchMachineFactory({
  id: "getMyProfile",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return getMyProfile(client);
  },
});

export const {
  machine: setProfileAsCompletedMachine,
  success: setProfileAsCompletedSuccess,
  failure: setProfileAsCompletedFailure,
  trigger: setProfileAsCompletedTrigger,
} = fetchMachineFactory({
  id: "setProfileAsCompleted",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return setProfileAsCompleted(client);
  },
});

export const {
  machine: getJourneyParticipationDataMachine,
  success: getJourneyParticipationDataSuccess,
  failure: getJourneyParticipationDataFailure,
  trigger: getJourneyParticipationDataTrigger,
} = fetchMachineFactory({
  id: "getJourneyParticipationData",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return getJourneyParticipationData(client);
  },
});

export const {
  machine: updateProfileMachine,
  success: updateProfileSuccess,
  failure: updateProfileFailure,
  trigger: updateProfileTrigger,
} = fetchMachineFactory({
  id: "updateProfile",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["updateProfile"]>["payload"];
  }) => {
    return updateProfile(client, payload.variables);
  },
});

export const {
  machine: resetPasswordMachine,
  success: resetPasswordSuccess,
  failure: resetPasswordFailure,
  trigger: resetPasswordTrigger,
} = fetchMachineFactory({
  id: "resetPassword",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["resetPassword"]>["payload"];
  }) => {
    return resetPassword(client, payload.variables);
  },
});

export const {
  machine: changePasswordMachine,
  success: changePasswordSuccess,
  failure: changePasswordFailure,
  trigger: changePasswordTrigger,
} = fetchMachineFactory({
  id: "changePassword",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["changePassword"]>["payload"];
  }) => {
    return changePassword(client, payload.variables);
  },
});

const {
  machine: getPreSignedUrlMachine,
  success: getPreSignedUrlSuccess,
  failure: getPreSignedUrlFailure,
  trigger: getPreSignedUrlTrigger,
} = fetchMachineFactory({
  id: "getPreSignedUrl",
  invokeFn: ({ token, key }: { token: string; key: string }) => {
    return getPreSignedUrl(token, key);
  },
  triggerOnCreation: false,
});

const {
  machine: uploadProfileImageMachine,
  success: uploadProfileImageSuccess,
  failure: uploadProfileImageFailure,
  trigger: uploadProfileImageTrigger,
} = fetchMachineFactory({
  id: "uploadProfileImage",
  invokeFn: ({ url, body }: { url: string; body: File | ReadableStream }) => {
    return uploadImageToS3(url, body);
  },
  triggerOnCreation: false,
});

const {
  machine: deleteProfileImageMachine,
  success: deleteProfileImageSuccess,
  failure: deleteProfileImageFailure,
  trigger: deleteProfileImageTrigger,
} = fetchMachineFactory({
  id: "deleteProfileImage",
  invokeFn: ({ key, token }: { key: string; token: string }) => {
    return deleteImageFromS3(key, token);
  },
  triggerOnCreation: false,
});

const {
  machine: technicalSetupHelpMachine,
  success: technicalSetupHelpSuccess,
  failure: technicalSetupHelpFailure,
  trigger: technicalSetupHelpTrigger,
} = fetchMachineFactory({
  id: "technicalSetupHelp",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return technicalSetupHelp(client);
  },
  triggerOnCreation: false,
});

export const authMachine = createMachine(
  {
    types: {} as AuthMachineTypes,
    id: "auth",
    initial: AuthState.Validating,
    context: ({ input }): AuthMachineContext => {
      const machineInput = input as
        | {
            client?: AppApolloClient;
            token?: string | null;
            profile?: Profile | null;
            selectedAudioDevice?: SourceData | null;
            selectedVideoDevice?: SourceData | null;
            connectionSuccess?: boolean | null;
            skipTechnicalSetup?: boolean;
          }
        | undefined;
      if (!machineInput?.client)
        throw new Error("Apollo client must be provided!");
      return {
        client: machineInput.client,
        profile: machineInput?.profile || null,
        token: machineInput?.token || null,
        unauthenticatedUserEmail: null,
        error: null,
        presignedUrl: null,
        fileForUpload: null,
        imageLastUpdatedTimeStamp: null,
        jitsiInstance: null,
        availableAudioDevices: null,
        availableVideoDevices: null,
        selectedAudioDevice: machineInput.selectedAudioDevice || null,
        selectedVideoDevice: machineInput.selectedVideoDevice || null,
        connectionSuccess: machineInput?.connectionSuccess || null,
        routeTitle: null,
        routeData: null,
        skipTechnicalSetup: machineInput.skipTechnicalSetup || false,
        technicalSetupHelpOutcome: null,
      };
    },
    entry: [
      spawnChild(validateTokenMachine, {
        id: validateTokenMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(updateProfileMachine, {
        id: updateProfileMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(resetPasswordMachine, {
        id: resetPasswordMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(changePasswordMachine, {
        id: changePasswordMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(getMyProfileMachine, {
        id: getMyProfileMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(setProfileAsCompletedMachine, {
        id: setProfileAsCompletedMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(getJourneyParticipationDataMachine, {
        id: getJourneyParticipationDataMachine.id,
        input: ({ context }: any) => ({ client: context.client } as any),
      }),
      spawnChild(getPreSignedUrlMachine, {
        id: getPreSignedUrlMachine.id,
      }),
      spawnChild(uploadProfileImageMachine, {
        id: uploadProfileImageMachine.id,
      }),
      spawnChild(deleteProfileImageMachine, {
        id: deleteProfileImageMachine.id,
      }),
      spawnChild(technicalSetupHelpMachine, {
        id: technicalSetupHelpMachine.id,
      }),
    ],
    states: {
      [AuthState.Validating]: {
        entry: [
          sendTo(getMyProfileMachine.id, ({ context }: any) => {
            return getMyProfileTrigger({
              client: context.client,
            });
          }),
        ],
        on: {
          [getMyProfileSuccess.type]: [
            {
              target: AuthState.Authenticated,
              guard: ({ event, context }) => {
                return (
                  event.payload.output !== null &&
                  ((context.selectedAudioDevice !== null &&
                    context.selectedVideoDevice !== null &&
                    context.connectionSuccess === true) ||
                    context.skipTechnicalSetup)
                );
              },
              actions: assign({
                profile: ({ event }) => event.payload.output,
                error: null,
              }),
            },
            {
              target: AuthState.TechnicalSetup,
              guard: ({ event, context }) => {
                return (
                  event.payload.output !== null &&
                  (context.selectedAudioDevice === null ||
                    context.selectedVideoDevice === null ||
                    !context.connectionSuccess) &&
                  !context.skipTechnicalSetup
                );
              },
              actions: assign({
                profile: ({ event }) => event.payload.output,
                error: null,
              }),
            },
            {
              target: AuthState.Unauthenticated,
              actions: assign({
                profile: null,
                token: null,
                error: null,
              }),
            },
          ],
          [getMyProfileFailure.type]: {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              error: ({ event }) => `${(event.payload.error as any).message}`,
            }),
          },
        },
      },
      [AuthState.Unauthenticated]: {
        entry: [
          () => {
            deleteItem(authContextLocalStorageName);
          },
        ],
        on: {
          [actions.login.type]: {
            target: AuthState.Authenticating,
          },
          [actions.validateToken.type]: {
            actions: [
              sendTo(validateTokenMachine.id, ({ event, context }: any) => {
                event = event as ReturnType<
                  AllAuthActionCreators["validateToken"]
                >;
                return validateTokenTrigger({
                  client: context.client,
                  payload: event.payload,
                });
              }),
            ],
          },
          [validateTokenSuccess.type]: {
            actions: [
              assign({
                unauthenticatedUserEmail: ({ event }) =>
                  event.payload.output.email,
                error: () => null,
              }),
            ],
          },
          [validateTokenFailure.type]: {
            actions: [
              assign({
                error: ({ event }) => `${event.payload.error.message}`,
              }),
            ],
          },
          [actions.resetPassword.type]: {
            actions: [
              sendTo(resetPasswordMachine.id, ({ event, context }: any) => {
                event = event as ReturnType<
                  AllAuthActionCreators["resetPassword"]
                >;
                return resetPasswordTrigger({
                  client: context.client,
                  payload: event.payload,
                });
              }),
            ],
          },
          [resetPasswordSuccess.type]: {
            actions: [
              assign({
                error: null,
              }),
            ],
          },
          [resetPasswordFailure.type]: {
            actions: [
              assign({
                error: ({ event }) => `${event.payload.error.message}`,
              }),
            ],
          },
          [actions.changePassword.type]: {
            actions: [
              sendTo(changePasswordMachine.id, ({ event, context }: any) => {
                event = event as ReturnType<
                  AllAuthActionCreators["changePassword"]
                >;
                return changePasswordTrigger({
                  client: context.client,
                  payload: event.payload,
                });
              }),
            ],
          },
          [changePasswordSuccess.type]: {
            actions: [
              assign({
                error: null,
              }),
            ],
          },
          [changePasswordFailure.type]: {
            actions: [
              assign({
                error: ({ event }) => `${event.payload.error.message}`,
              }),
            ],
          },
        },
      },
      [AuthState.Authenticating]: {
        invoke: {
          src: fromPromise(({ input }) => {
            const data = input as {
              payload: ReturnType<AllAuthActionCreators["login"]>["payload"];
              client: AppApolloClient;
            };
            return login(data.client, data.payload.variables);
          }),
          onDone: [
            {
              target: AuthState.Authenticated,
              guard: ({ event, context }) => {
                return (
                  event.output.profile !== null &&
                  event.output.token !== null &&
                  ((context.selectedAudioDevice !== null &&
                    context.selectedVideoDevice !== null &&
                    !!context.connectionSuccess) ||
                    context.skipTechnicalSetup)
                );
              },
              actions: [
                assign({
                  profile: ({ event }) => event.output.profile,
                  token: ({ event }) => event.output.token,
                  unauthenticatedUserEmail: () => null,
                  error: null,
                }),
                "asyncSaveLoggedUserData",
              ],
            },
            {
              target: AuthState.TechnicalSetup,
              guard: ({ event, context }) => {
                return (
                  event.output.profile !== null &&
                  event.output.token !== null &&
                  (context.selectedAudioDevice === null ||
                    context.selectedVideoDevice === null ||
                    !context.connectionSuccess) &&
                  !context.skipTechnicalSetup
                );
              },
              actions: [
                assign({
                  profile: ({ event }) => event.output.profile,
                  token: ({ event }) => event.output.token,
                  error: null,
                }),
                "asyncSaveLoggedUserData",
              ],
            },
            {
              target: AuthState.Unauthenticated,
            },
          ],
          onError: {
            target: AuthState.Unauthenticated,
            actions: [
              assign({
                error: ({ event }) => `${(event.error as any).message}`,
              }),
            ],
          },
          input: ({ event, context }) => ({
            payload: event.payload,
            client: context.client,
          }),
        },
      },
      [AuthState.Authenticated]: {
        initial: ProfileSettings.Default,
        entry: [
          sendTo(
            getJourneyParticipationDataMachine.id,
            ({ context }: { context: AuthMachineContext }) => {
              return getJourneyParticipationDataTrigger({
                client: context.client,
              });
            }
          ),
        ],
        states: {
          [ProfileSettings.Default]: {
            on: {
              [actions.updateProfileDialog.type]: [
                {
                  target: ProfileSettings.UpdateProfile,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "profile",
                },
                {
                  target: ProfileSettings.ChangePassword,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "password",
                },
              ],
            },
          },
          [ProfileSettings.UpdateProfile]: {
            initial: ProfileSettings.UpdateProfile_Default,
            states: {
              [ProfileSettings.UpdateProfile_Default]: {
                on: {
                  [actions.uploadProfileImage.type]: [
                    {
                      target: ProfileSettings.UpdateProfile_GettingPresignedUrl,
                      guard: ({ context }) => !context.presignedUrl,
                      actions: [
                        assign({
                          fileForUpload: ({ event }) => event.payload.file,
                        }),
                      ],
                    },
                  ],
                  [actions.deleteProfileImage.type]: [
                    {
                      target: ProfileSettings.UpdateProfile_DeleteProfileImage,
                    },
                  ],
                },
              },
              [ProfileSettings.UpdateProfile_GettingPresignedUrl]: {
                entry: [
                  sendTo(getPreSignedUrlMachine.id, ({ context }) => {
                    return getPreSignedUrlTrigger({
                      token: context.token!,
                      key: generateWorkspaceProfileImageKey(
                        context.profile!.id,
                        context.profile!.workspace.workspace_id
                      ),
                    });
                  }),
                ],
                on: {
                  [getPreSignedUrlSuccess.type]: {
                    target: ProfileSettings.UpdateProfile_UploadingProfileImage,
                    actions: assign({
                      presignedUrl: ({ event }) => event.payload.output,
                    }),
                  },
                  [getPreSignedUrlFailure.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    // TODO: Handler error message
                    actions: [
                      assign({
                        presignedUrl: null,
                        fileForUpload: null,
                      }),
                    ],
                  },
                },
              },
              [ProfileSettings.UpdateProfile_UploadingProfileImage]: {
                entry: [
                  sendTo(uploadProfileImageMachine.id, ({ context, self }) => {
                    const file = context.fileForUpload!;

                    return uploadProfileImageTrigger({
                      url: context.presignedUrl!,
                      body: file,
                    });
                  }),
                ],
                on: {
                  [uploadProfileImageSuccess.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    actions: [
                      assign({
                        presignedUrl: null,
                        fileForUpload: null,
                        imageLastUpdatedTimeStamp: () =>
                          getUnixTime(new Date()),
                      }),
                    ],
                  },
                  [uploadProfileImageFailure.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    // TODO: Handler error message
                    actions: [
                      assign({
                        presignedUrl: null,
                        fileForUpload: null,
                      }),
                    ],
                  },
                },
              },
              [ProfileSettings.UpdateProfile_DeleteProfileImage]: {
                entry: [
                  sendTo(deleteProfileImageMachine.id, ({ context, self }) => {
                    return deleteProfileImageTrigger({
                      token: context.token!,
                      key: generateWorkspaceProfileImageKey(
                        context.profile!.id,
                        context.profile!.workspace.workspace_id
                      ),
                    });
                  }),
                ],
                on: {
                  [deleteProfileImageSuccess.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    actions: [
                      assign({
                        imageLastUpdatedTimeStamp: () =>
                          getUnixTime(new Date()),
                      }),
                    ],
                  },
                  [deleteProfileImageFailure.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    // TODO: Handler error message
                  },
                },
              },
            },
            exit: [
              assign({
                presignedUrl: null,
              }),
            ],
            on: {
              [actions.updateProfileDialog.type]: [
                {
                  target: ProfileSettings.ChangePassword,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "password",
                },
                {
                  target: ProfileSettings.Default,
                  guard: ({ event }) => !event.payload.open,
                },
              ],
            },
          },
          [ProfileSettings.ChangePassword]: {
            on: {
              [actions.updateProfileDialog.type]: [
                {
                  target: ProfileSettings.UpdateProfile,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "profile",
                },
                {
                  target: ProfileSettings.Default,
                  guard: ({ event }) => !event.payload.open,
                },
              ],
            },
          },
        },
        invoke: {
          input: ({ self }) => ({ authActor: self }),
          src: fromCallback(({ input }) => {
            const authActor = input.authActor as ReturnType<
              typeof useMachine<typeof authMachine>
            >["2"];
            const messageHandler = () => {
              authActor.send(actions.refreshJourneyData());
            };

            refreshMyProfileChannel.addEventListener("message", messageHandler);

            return () => {
              refreshMyProfileChannel.removeEventListener(
                "message",
                messageHandler
              );
            };
          }),
        },
        on: {
          [getJourneyParticipationDataSuccess.type]: {
            actions: [
              assign({
                profile: ({ event, context }) => ({
                  ...context.profile!,
                  workspace: {
                    ...context.profile!.workspace,
                    workspace: {
                      ...context.profile!.workspace.workspace,
                      journeyParticipationData:
                        event.payload.output.workspace.workspace
                          .journeyParticipationData,
                      availableJourneys:
                        event.payload.output.workspace.workspace
                          .availableJourneys,
                    },
                  },
                }),
              }),
            ],
          },
          [actions.logout.type]: {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              token: null,
              error: null,
            }),
          },
          [actions.updateProfile.type]: {
            actions: [
              sendTo(updateProfileMachine.id, ({ event, context }: any) => {
                event = event as ReturnType<
                  AllAuthActionCreators["updateProfile"]
                >;
                return updateProfileTrigger({
                  client: context.client,
                  payload: event.payload,
                });
              }),
            ],
          },
          [updateProfileSuccess.type]: {
            actions: [
              assign({
                profile: ({ event, context }) => {
                  return {
                    ...context.profile,
                    ...event.payload.output,
                  };
                },
              }),
              raise(() => actions.updateProfileDialog({ open: false })),
              "asyncSaveLoggedUserData",
            ],
          },
          [updateProfileFailure.type]: {
            actions: [
              assign({
                error: ({ event }) => `${event.payload.error.message}`,
              }),
            ],
          },
          [actions.refreshMyProfile.type]: {
            actions: [
              sendTo(getMyProfileMachine.id, ({ context }: any) => {
                return getMyProfileTrigger({
                  client: context.client,
                });
              }),
            ],
          },
          [actions.refreshJourneyData.type]: {
            actions: [
              sendTo(
                getJourneyParticipationDataMachine.id,
                ({ context }: any) => {
                  return getJourneyParticipationDataTrigger({
                    client: context.client,
                  });
                }
              ),
            ],
          },
          [getMyProfileSuccess.type]: [
            {
              target: AuthState.Authenticated,
              guard: ({ event }) => {
                return event.payload.output !== null;
              },
              actions: assign({
                profile: ({ event }) => event.payload.output,
                error: null,
              }),
            },
            {
              target: AuthState.Unauthenticated,
              actions: assign({
                profile: null,
                token: null,
                error: null,
              }),
            },
          ],
          [getMyProfileFailure.type]: {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              error: ({ event }) => `${(event.payload.error as any).message}`,
            }),
          },
        },
      },
      [AuthState.TechnicalSetup]: {
        entry: [
          assign({
            jitsiInstance: () => new Jitsi(),
          }),
        ],
        on: {
          [actions.configureAudio.type]: {
            actions: [
              assign({
                availableAudioDevices: ({ event }) =>
                  event.payload.availableAudioSources,
                selectedAudioDevice: ({ event, context }) => {
                  const current = context.selectedAudioDevice;
                  const update = event.payload.selectedAudioSourceData;
                  if (current && current.isMuted !== update.isMuted) {
                    const audioTrack =
                      context.jitsiInstance?.getLocalAudioTrack();
                    if (update.isMuted) {
                      audioTrack.mute();
                    } else {
                      audioTrack.unmute();
                    }
                  }
                  return update;
                },
              }),
            ],
          },
          [actions.configureVideo.type]: {
            actions: [
              assign({
                availableVideoDevices: ({ event }) =>
                  event.payload.availableVideoSources,
                selectedVideoDevice: ({ event, context }) => {
                  const current = context.selectedVideoDevice;
                  const update = event.payload.selectedVideoSourceData;
                  if (current && current.isMuted !== update.isMuted) {
                    const videoTrack =
                      context.jitsiInstance?.getLocalVideoTrack();
                    if (update.isMuted) {
                      videoTrack.mute();
                    } else {
                      videoTrack.unmute();
                    }
                  }
                  return update;
                },
              }),
            ],
          },
          [actions.technicalSetupInit.type]: {
            actions: [
              ({ context, event, self }) => {
                const { jitsiInstance } = context;
                if (!jitsiInstance) return;

                const onLocalVideoTrackReady = () => {
                  jitsiInstance
                    .getAvailableSources()
                    .then((availableSources) => {
                      const availableVideoSources: SourceData[] =
                        availableSources.video.map((s) => ({
                          label: s.label,
                          sourceId: s.deviceId,
                          isMuted: undefined,
                        }));
                      const currentVideo = jitsiInstance.localTracks.find(
                        (t) => t.getType() === "video"
                      );
                      if (!currentVideo) return;
                      const videoMatch = availableVideoSources.find(
                        (t) => currentVideo?.track.label === t.label
                      );
                      const selectedVideoSourceData = {
                        label: videoMatch?.label || null,
                        isMuted: currentVideo?.isMuted() || null,
                        sourceId: videoMatch?.sourceId || null,
                      };

                      jitsiInstance.removeEventListener(
                        JitsiEvents.LOCAL_VIDEO_TRACK_READY,
                        onLocalVideoTrackReady
                      );

                      const data = {
                        availableVideoSources,
                        selectedVideoSourceData,
                      };

                      self.send(actions.configureVideo(data));
                    });
                };
                const onLocalAudioTrackReady = async () => {
                  const availableSources =
                    await jitsiInstance.getAvailableSources();
                  const availableAudioSources: SourceData[] =
                    availableSources.audio.map((s) => ({
                      label: s.label,
                      sourceId: s.deviceId,
                      isMuted: undefined,
                    }));
                  const currentAudio = jitsiInstance.localTracks.find(
                    (t) => t.getType() === "audio"
                  );
                  if (!currentAudio) return;
                  const audioMatch = availableAudioSources.find(
                    (t) => currentAudio.track.label === t.label
                  );
                  const selectedAudioSourceData: SourceData = {
                    label: audioMatch?.label || null,
                    isMuted: currentAudio?.isMuted() || null,
                    sourceId: audioMatch?.sourceId || null,
                  };

                  jitsiInstance.removeEventListener(
                    JitsiEvents.LOCAL_AUDIO_TRACK_READY,
                    onLocalAudioTrackReady
                  );

                  const data = {
                    availableAudioSources,
                    selectedAudioSourceData,
                  };

                  self.send(actions.configureAudio(data));
                };
                const onConnectionEstablished = () => {
                  self.send(actions.setConnectionStatus({ success: true }));
                };
                const onConnectionFailure = () => {
                  self.send(actions.setConnectionStatus({ success: false }));
                };

                jitsiInstance.addEventListener(
                  JitsiEvents.LOCAL_AUDIO_TRACK_READY,
                  onLocalAudioTrackReady
                );
                jitsiInstance.addEventListener(
                  JitsiEvents.LOCAL_VIDEO_TRACK_READY,
                  onLocalVideoTrackReady
                );

                jitsiInstance.addEventListener(
                  JitsiEvents.CONNECTION_ESTABLISHED,
                  onConnectionEstablished
                );

                jitsiInstance.addEventListener(
                  JitsiEvents.CONNECTION_FAILURE,
                  onConnectionFailure
                );

                jitsiInstance.init(
                  event.payload.profileId,
                  event.payload.htmlVideoElement
                );

                // TODO: Check this logic if we using v4 an error was occur
                jitsiInstance.setupConnection(
                  `technical-setup-${event.payload.profileId}`
                );
              },
            ],
          },
          [actions.setConnectionStatus.type]: {
            actions: [
              assign({
                connectionSuccess: ({
                  event: {
                    payload: { success },
                  },
                }) => success,
              }),
            ],
          },
        },
      },
    },
    on: {
      [actions.technicalSetupClear.type]: [
        {
          actions: [
            raise(({ context }) => {
              const { selectedAudioDevice, selectedVideoDevice } = context;
              // Maybe we should display message to the user before we redirect

              return actions.saveDeviceSetup({
                selectedAudioDevice: selectedAudioDevice || null,
                selectedVideoDevice: selectedVideoDevice || null,
              });
            }),
            assign({
              technicalSetupHelpOutcome: null,
            }),
          ],
          guard: ({ context }) =>
            context.technicalSetupHelpOutcome ===
            TechnicalSetupHelpOutcome.Success,
        },
        {
          actions: assign({
            technicalSetupHelpOutcome: null,
          }),
          guard: ({ context }) =>
            context.technicalSetupHelpOutcome ===
            TechnicalSetupHelpOutcome.Failure,
        },
      ],
      [actions.technicalSetupHelp.type]: {
        actions: [
          sendTo(technicalSetupHelpMachine.id, ({ context }) => {
            return technicalSetupHelpTrigger({
              client: context.client,
            });
          }),
        ],
      },
      [technicalSetupHelpSuccess.type]: {
        actions: [
          assign({
            technicalSetupHelpOutcome: TechnicalSetupHelpOutcome.Success,
          }),
        ],
      },
      [technicalSetupHelpFailure.type]: {
        actions: [
          assign({
            error: ({ event }) => event.payload.error.message,
            technicalSetupHelpOutcome: TechnicalSetupHelpOutcome.Failure,
          }),
        ],
      },
      [actions.saveDeviceSetup.type]: {
        actions: [
          ({
            event: {
              payload: {
                selectedAudioDevice,
                selectedVideoDevice,
                connectionSuccessful,
              },
            },
            self,
            context,
          }) => {
            const data: TechnicalSetupConfig = {
              selectedAudioDevice,
              selectedVideoDevice,
              connectionSuccessful:
                connectionSuccessful || context.connectionSuccess,
            };

            putItem(data, technicalSetupConfig).then(() => {
              const snapshot = self.getSnapshot();
              if (snapshot.value !== AuthState.TechnicalSetup) return;
              const markProfileAsCompletedActor = snapshot.children[
                setProfileAsCompletedMachine.id
              ] as ReturnType<
                typeof useMachine<typeof setProfileAsCompletedMachine>
              >["2"];

              if (context.profile?.is_completed)
                return void self.send(actions.saveDeviceSetupComplete());

              const subscription = markProfileAsCompletedActor.subscribe(
                ({ value }) => {
                  if (value !== FetchState.Success) return;
                  subscription.unsubscribe();
                  self.send(actions.saveDeviceSetupComplete());
                }
              );
              markProfileAsCompletedActor.send(
                setProfileAsCompletedTrigger({ client: context.client })
              );
            });
          },
        ],
      },
      [actions.saveDeviceSetupComplete.type]: {
        target: `.${AuthState.Authenticated}`,
        actions: [
          assign({
            jitsiInstance: ({ context }) => {
              context.jitsiInstance?.cleanup();
              return null;
            },
          }),
        ],
      },
      [setProfileAsCompletedSuccess.type]: {
        actions: [
          assign(({ context }) => {
            return {
              ...context,
              profile: { ...context.profile!, is_completed: true },
            };
          }),
        ],
      },
      [actions.setPageConfig.type]: {
        actions: assign(({ event, context }) => {
          const { pageData, pageTitle } = event.payload;
          return { ...context, routeData: pageData, routeTitle: pageTitle };
        }),
      },
    },
  },
  {
    actions: {
      asyncSaveLoggedUserData: ({ self }) => {
        setTimeout(() => {
          const snapshot = self.getSnapshot();
          const data = {
            token: snapshot.context.token,
            profile: snapshot.context.profile,
          };

          putItem(data, authContextLocalStorageName);
        }, 0);
      },
    },
  }
);
