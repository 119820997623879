import { WorkshopActivityType } from "../types/enums/activity-type";

const defaultFooterTextFactory = (cnt: number) => (
  <>
    Click “Continue” when ready!{" "}
    {cnt > 0 && (
      <span className="accent">
        {cnt} player{cnt > 1 && "s"} clicked.
      </span>
    )}
  </>
);

const questionFooterTextFactory = (cnt: number, isGoToDiscussion?: boolean) => (
  <>
    Click {isGoToDiscussion ? '"Go to discussion"' : '"Continue"'} when ready!{" "}
    {cnt > 0 && (
      <span className="accent">
        {cnt} player{cnt > 1 && "s"} clicked.
      </span>
    )}
  </>
);

const selfDirectedTextFactory = (cnt: number) => (
  <>
    Everyone has to complete the assignment to continue.{" "}
    {cnt > 0 && (
      <span className="accent">
        {cnt} player{cnt > 1 && "s"} clicked.
      </span>
    )}
  </>
);

export const activityTypeFooterTextFactoryMap = {
  [WorkshopActivityType.BrainstormingActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.ClosedQuestionActivity]: questionFooterTextFactory,
  [WorkshopActivityType.ComparisonActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.ConceptualisationActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.PresentationActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.TranslationActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.FilteringActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.VotingActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.GroupingActivity]: defaultFooterTextFactory,
  [WorkshopActivityType.RatingActivity]: questionFooterTextFactory,
  [WorkshopActivityType.SelfDirectedDiscussionActivity]: selfDirectedTextFactory,
  [WorkshopActivityType.OpenQuestion]: (cnt: number) => (
    <>
      Write your individual opinion.{" "}
      {cnt > 0 && (
        <span className="accent">
          {cnt} player{cnt > 1 && "s"} clicked.
        </span>
      )}
    </>
  ),
};
