import cn from "classnames";
import { memo } from "react";
import { ButtonSize, ButtonVariant } from "../../../../types/buttons";

export default memo(function IconButton(
  props: {
    variant?: ButtonVariant;
    iconName: string;
    size?: ButtonSize;
    onClick: () => void;
  }
) {
  const { variant = "primary", iconName, size = 'medium', onClick } = props;

  return (
    <button className={cn("btn", variant, size)} onClick={onClick}>
      <i className={cn("fa", iconName)} />
    </button>
  );
});