import { Link, useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import Skeleton from "react-loading-skeleton";

import styles from "./Breadcrumb.module.css";
import { useCallback, useContext, useMemo } from "react";
import { AdminDashboardContext } from "../../contexts/AdminDashboard";
import { AdminDashboardState } from "../../+xstate/machines/dashboard/admin-dashboard";

const Breadcrumb = () => {
  const location = useLocation();
  const { journeyId } = useParams();

  const { state: adminDashboardState } = useContext(AdminDashboardContext);

  // TODO: This is handling only the journeys case, because it is the only page
  // with nested routes; if we add more nested routes, we should make this more generic.
  const isJourneyDashboard = useMemo(
    () => adminDashboardState.matches(AdminDashboardState.Journeys),
    [adminDashboardState]
  );

  const isJourneyDetails = useMemo(
    () => adminDashboardState.matches(AdminDashboardState.JourneyDetails),
    [adminDashboardState]
  );

  const pathnames = useMemo(() => {
    const cleared = location.pathname.split("/").filter((x) => x);
    return isJourneyDashboard || isJourneyDetails ? cleared : [cleared[0]];
  }, [isJourneyDashboard, isJourneyDetails, location.pathname]);

  const journey = useMemo(
    () => adminDashboardState.context.journeyDetails || null,
    [adminDashboardState.context.journeyDetails]
  );

  const renderJourneyLastItem = useCallback(
    (displayName: string) =>
      !journey ? <Skeleton width={150} borderRadius={4} /> : displayName,
    [journey]
  );

  return (
    <nav>
      <ol className={styles.breadcrumb}>
        <li className={styles.breadcrumbItem}>
          <Link to="/">Home</Link>
          {pathnames.length > 0 && (
            <span className={styles.breadcrumbSeparator}> &gt; </span>
          )}
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          const displayName =
            value === journeyId && journey
              ? journey?.fields?.title
              : decodeURIComponent(
                  value === "workshops" ? "conversations" : value
                ).replace(/-/g, " ");

          return isLast ? (
            <li
              key={to}
              className={cn(styles.breadcrumbItem, styles.breadcrumbItemActive)}
            >
              {isJourneyDetails
                ? renderJourneyLastItem(displayName)
                : displayName}
            </li>
          ) : (
            <li key={to} className={styles.breadcrumbItem}>
              <Link to={to}>{decodeURIComponent(value)}</Link>
              <span className={styles.breadcrumbSeparator}> &gt; </span>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
