import { FC, memo, useEffect, useMemo, useState } from "react";

export type LoadingDelay = {
  delayInMilliseconds?: number;
  isLoading?: boolean;
};

type WithLoadingDelayProps = LoadingDelay;

function withLoadingDelay<T extends WithLoadingDelayProps & object>(
  Component: FC<T>
): FC<T & { isLoading?: boolean } & Omit<T, keyof LoadingDelay>> {
  const LoadingDelayHoc: FC<T> = ({
    isLoading = false,
    delayInMilliseconds,
    ...rest
  }) => {
    const [showLoading, setShowLoading] = useState(isLoading);

    const props = useMemo(
      () => ({
        ...rest,
        isLoading: !delayInMilliseconds ? isLoading : showLoading,
      }),
      [delayInMilliseconds, isLoading, rest, showLoading]
    ) as unknown as T;

    useEffect(() => {
      if (!isLoading && showLoading) {
        setShowLoading(false);
        return;
      }
      if (!delayInMilliseconds) return;

      const timerId = setTimeout(
        () => setShowLoading(isLoading),
        delayInMilliseconds
      );

      return () => {
        clearTimeout(timerId);
      };
    }, [delayInMilliseconds, isLoading, showLoading]);

    return <Component {...props} />;
  };

  LoadingDelayHoc.displayName = "LoadingDelayHoc";

  return memo(LoadingDelayHoc);
}

export default withLoadingDelay;
