import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import ConfirmationDialog from "./components/ConfirmationDialog/ConfirmationDialog";

export const GlobalErrorBoundary = ({ children }: PropsWithChildren) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleChunkError = (error: ErrorEvent) => {
      if (error.message.includes("Loading chunk")) {
        setHasError(true);
      }
    };

    window.addEventListener("error", handleChunkError);

    return () => {
      window.removeEventListener("error", handleChunkError);
    };
  }, []);

  const reloadApplicationHandler = useCallback(() => {
    window.location.reload();
  }, []);

  if (hasError) {
    return (
      <ConfirmationDialog
        title="Update required!"
        description="An updated version of the application is available. Please reload the page to continue using the latest features. Thank you for your understanding!"
        continueButtonText="Reload"
        confirmationHandler={reloadApplicationHandler}
      ></ConfirmationDialog>
    );
  }

  return children;
};
