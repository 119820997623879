import { PropsWithChildren, memo } from "react";

import styles from "./NextStepsHeader.module.css";

const NextStepsHeader = ({
  title,
  imagePath,
  imageAlt,
}: PropsWithChildren<{
  title:string;
  imagePath?: string;
  imageAlt?: string;
}>) => {

  return (
    <div className={styles.headerContainer}>
      {imagePath && imageAlt && <img src={imagePath} alt={imageAlt} />}
      <h1>{title}</h1>
    </div>
  );
};

export default memo(NextStepsHeader);