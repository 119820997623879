import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useEffect, useState } from "react";

type DTRProps = {
  animationSrc: string;
  setDotLottieInstance: (instance: DotLottieInstance) => void;
}

export interface DotLottieInstance {
  addEventListener(event: string, callback: () => void): void;
  removeEventListener(event: string, callback: () => void): void;
  stop(): void;
  pause(): void;
}

const GuideDotLottie = ({ animationSrc, setDotLottieInstance }: DTRProps) => {
  const [dotLottie, setDotLottie] = useState<DotLottieInstance | null>(null);

  useEffect(() => {
    if (dotLottie) {
      setDotLottieInstance(dotLottie);
    }
  }, [dotLottie, setDotLottieInstance])

  const dotLottieRefCallback = (dotLottie: any) => {
    setDotLottie(dotLottie);
  };

  return ( 
    <DotLottieReact
      src={animationSrc}
      autoplay
      dotLottieRefCallback={dotLottieRefCallback}
      style={{ marginTop: "10px"}}
    />
  );
};

export default GuideDotLottie;