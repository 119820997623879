import cn from 'classnames';

import styles from './AhaBubble.module.css';

interface AhaBubbleProps {
  className?: string;
}

const AhaBubble = ({ className }: AhaBubbleProps) => (
  <span className={cn(styles.container, className)}>
    Aha
  </span>
);

export default AhaBubble;