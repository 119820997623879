import { PropsWithChildren, memo, useEffect, useRef } from "react";

import withLoadingDelay from "../../../hocs/withLoadingDelay";

import CircledIcon from "../CircledIcon/CircledIcon";
import ToggleChevron from "../ToggleChevron/ToggleChevron";

import { AnswerProps, AnswerType } from "./types";

import cn from "classnames";
import styles from "./Answer.module.css";

const Answer = (props: PropsWithChildren<AnswerProps>) => {
  const {
    type,
    className,
    answerTitle,
    isLoading,
    isTransitioning,
    iconClass,
    onSelectAnswerHandler,
    isHorizontal,
    isSelected = false,
    othersSelected = false,
    groupSelected = false,
    showIcon = true,
  } = props;

  const { expanded = false, content = null, onExpandCollapseHandler = () => {}, badge = null } = type === 'expandable' ? props : {};

  const previousValues = useRef({
    isSelected,
    showIcon,
    iconClass,
    othersSelected,
    groupSelected
  });

  // Update previous values only when isLoading is false
  useEffect(() => {
    if (!isLoading) {
      previousValues.current = { isSelected, showIcon, iconClass, othersSelected, groupSelected };
    }
  }, [isLoading, isSelected, showIcon, iconClass, othersSelected, groupSelected]);

  // Use previous values when isLoading is true
  const displayedSelected = isLoading ? previousValues.current.isSelected : isSelected;
  const displayedShowIcon = isLoading ? previousValues.current.showIcon : showIcon;
  const displayedIconClass = isLoading ? previousValues.current.iconClass : iconClass;
  const displayedOthersSelected = isLoading ? previousValues.current.othersSelected : othersSelected;
  const displayedGroupSelected = isLoading ? previousValues.current.groupSelected : groupSelected;

  return (
    <div
      className={cn(styles.answer, className, isHorizontal && "horizontal", {
        [styles.loading]: isLoading,
        [styles.otherSelected]: displayedOthersSelected,
        [styles.groupSelected]: displayedGroupSelected,
        selected: displayedSelected,
        disabled: isTransitioning && displayedGroupSelected,
      })}
      onClick={onSelectAnswerHandler}
    >
      <div className={styles.answerTopLine}>
        <div className={styles.answerTopLineData}>
          <div className={cn(styles.answerText, "text", "bold")}>
            {answerTitle}
          </div>
          {displayedShowIcon && displayedIconClass && (
            <CircledIcon
              iconClass={displayedIconClass}
              isSelected={displayedSelected}
              className={styles.answerIcon}
            />
          )}
          {!!badge && badge}
        </div>
        {type === AnswerType.EXPANDABLE && (
          <ToggleChevron
            expanded={expanded}
            onClick={onExpandCollapseHandler}
            containerClass={styles.answerChevron}
          />
        )}
      </div>
      {expanded && (
        <div className={cn(styles.textContent, "text", "small", "answer-text-content")}>
          {content}
        </div>
      )}
    </div>
  );
};

export default memo(withLoadingDelay(Answer));
