import * as Tabs from "@radix-ui/react-tabs";
import cn from "classnames";
import JourneyGoals from "../JourneyGoals/JourneyGoals";
import {
  Certificate,
  Journey,
} from "../../../../../../types/contentful/workshop/journey";
import JourneyCertificates from "../JourneyAchievements/JourneyCertificates";
import { JourneyCertificationWithCertificate } from "../../../../../../types/journey-certificates";
import JourneyConversationsSteps from "../JourneyConversationsSteps/JourneyConversationsSteps";
import { JourneyLeader } from "../../../../../../apollo-graphql/types";
import JourneyAuthor from "../JourneyAuthor/JourneyAuthor";
import JourneyBottomNavigation from "../JourneyBottomNavigation/JourneyBottomNavigation";
import { memo, useCallback, useMemo, useState } from "react";
import SwitchTabButton from "../JourneyBottomNavigation/SwitchTabButton/SwitchTabButton";
import StartJourneyButton from "../JourneyBottomNavigation/StartJourneyButton/StartJourneyButton";
import ContentfulRichField from "../../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./JourneyTabs.module.css";

enum TabItems {
  overview = "overview",
  conversations = "conversations",
  certificates = "certificates",
}

interface JourneyTabsProps {
  longDescription: string;
  topic: string;
  certificates: Certificate[];
  companyName: string;
  currentJourneyCertificationWithCertificate: JourneyCertificationWithCertificate | null;
  journey: Journey;
  journeyLeaders: JourneyLeader[] | null;
  isJourneyAvailable: boolean;
}

const JourneyTabs = ({
  longDescription,
  topic,
  certificates,
  companyName,
  currentJourneyCertificationWithCertificate,
  journey,
  journeyLeaders,
  isJourneyAvailable,
}: JourneyTabsProps) => {
  const [activeTab, setActiveTab] = useState(TabItems.overview as string);
  const conversationId = useMemo(
    () =>
      journey.fields.workshops ? journey.fields.workshops[0]?.sys?.id : null,
    [journey.fields.workshops]
  );

  const tabValueChangeHandler = useCallback(
    (value: string) => setActiveTab(value),
    []
  );

  return (
    <Tabs.Root
      className={styles.container}
      defaultValue={TabItems.overview}
      value={activeTab}
      onValueChange={tabValueChangeHandler}
    >
      <Tabs.List className={styles.tabsList}>
        <Tabs.Trigger
          className={cn(styles.tabsTrigger, "secondary")}
          value={TabItems.overview}
        >
          Overview
        </Tabs.Trigger>
        <Tabs.Trigger
          className={cn(styles.tabsTrigger, "secondary")}
          value={TabItems.conversations}
        >
          Conversations
        </Tabs.Trigger>
        {isJourneyAvailable && certificates && (
          <Tabs.Trigger
            className={cn(styles.tabsTrigger, "secondary")}
            value={TabItems.certificates}
          >
            Certificates
          </Tabs.Trigger>
        )}
      </Tabs.List>
      <Tabs.Content
        className={cn(styles.tabsContent, "primary")}
        value={TabItems.overview}
      >
        {longDescription && (
          <ContentfulRichField
            content={longDescription}
            className={styles.descriptionContent}
          />
        )}
        <JourneyGoals topic={topic} />
        <JourneyCertificates
          companyName={companyName}
          certificates={certificates}
          currentJourneyCertificationWithCertificate={
            currentJourneyCertificationWithCertificate
          }
          title="Complete this journey and showcase your achievement as"
        />
        <JourneyAuthor author={journey.fields.author} />
        <JourneyBottomNavigation>
          <StartJourneyButton
            conversationId={conversationId}
            journeyId={journey.sys.id}
          />
          <span className={styles.orElement}>or</span>
          <SwitchTabButton
            onButtonClicked={() => setActiveTab(TabItems.conversations)}
            name="Go to Journey Conversations"
          />
        </JourneyBottomNavigation>
      </Tabs.Content>
      <Tabs.Content
        className={styles.tabsContent}
        value={TabItems.conversations}
      >
        <JourneyConversationsSteps
          journey={journey}
          journeyLeaders={journeyLeaders}
          isJourneyAvailable={!!isJourneyAvailable}
        />
        <JourneyBottomNavigation>
          <StartJourneyButton
            conversationId={conversationId}
            journeyId={journey.sys.id}
          />
          <span className={styles.orElement}>or</span>
          <SwitchTabButton
            onButtonClicked={() => setActiveTab(TabItems.certificates)}
            name="Go to Journey Certificates"
          />
        </JourneyBottomNavigation>
      </Tabs.Content>
      <Tabs.Content
        className={styles.tabsContent}
        value={TabItems.certificates}
      >
        <JourneyCertificates
          companyName={companyName}
          certificates={certificates}
          currentJourneyCertificationWithCertificate={
            currentJourneyCertificationWithCertificate
          }
          locked
          title="Showcase your excellence. How to unlock your certificates?"
        />
        <JourneyBottomNavigation>
          <SwitchTabButton
            onButtonClicked={() => setActiveTab(TabItems.conversations)}
            name="Go to Journey Conversations"
          />
        </JourneyBottomNavigation>
      </Tabs.Content>
    </Tabs.Root>
  );
};

export default memo(JourneyTabs);
