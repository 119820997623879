import { PropsWithChildren, memo } from "react";

import cn from "classnames";

import withLoadingDelay, {
  LoadingDelay,
} from "../../../../../hocs/withLoadingDelay";

import styles from "./EmotionItem.module.css";

function SingleEmotion(
  props: PropsWithChildren<{
    src: string;
    alt: string;
    emotionId: string | null;
    isLoading: boolean;
    disabled: boolean;
    selectedEmotion: string | null;
    onClick: () => void;
  }> &
    LoadingDelay
) {
  const { src, alt, emotionId, isLoading, selectedEmotion, disabled, onClick } =
    props;

  return (
    <div
      className={cn(
        styles.emotion,
        selectedEmotion === emotionId && "active",
        disabled && "disabled",
        isLoading && "is-loading"
      )}
      onClick={onClick}
    >
      <div className={styles.emotionInner}>
        <img src={src} alt={alt} />
      </div>
    </div>
  );
}

export default memo(withLoadingDelay(SingleEmotion));
