import { PropsWithChildren, memo } from "react";

import { IDragAndDropCard, SectionTypes } from "../types";
import DragAndDropList from "../List/DragAndDropList";
import ContentfulRichField from "../../ContentfulRichField/ContentfulRichField";

import cn from "classnames";
import styles from "./DragAndDropSectionList.module.css";

const DragAndDropSectionList = (
  props: PropsWithChildren<{
    id: SectionTypes;
    sectionTitle: string;
    items: IDragAndDropCard[];
    currentProfileId: string;
    isDisabled: boolean;
    hasDraggableIcon?: boolean;
    hasBadge?: boolean;
    noContent?: React.ReactNode;
    containerClass?: string;
    image?: {
      profileId?: string;
      src?: string;
      fallbackFontAwesomeIconClass: string;
    };
  }>
) => {
  const {
    id,
    sectionTitle,
    items,
    currentProfileId,
    isDisabled,
    hasBadge,
    hasDraggableIcon,
    image,
    noContent,
    containerClass = "",
  } = props;

  return (
    <div className={cn(styles.dragAndDropSectionList, containerClass)}>
      <p className={cn(styles.sectionTitle, "text", "title", "bold")}>
        {!!hasBadge && (
          <span className={cn(styles.badge, "text", "tiny")}>
            {items.length}
          </span>
        )}
        <ContentfulRichField
          content={sectionTitle}
          className={styles.sectionTitleText}
        />
      </p>

      <DragAndDropList
        id={id}
        currentProfileId={currentProfileId}
        isDisabled={isDisabled}
        items={items}
        hasDraggableIcon={hasDraggableIcon}
        image={image}
        noContent={noContent}
      />
    </div>
  );
};

export default memo(DragAndDropSectionList);
