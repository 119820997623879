import { SessionStateValue } from "../apollo-graphql/types/session-state";
// import { ACTIVITY_TIMEOUT_VALUE } from "../constants/global";

export const calculateConsensusReached = (
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: any },
  isTransitioning: boolean
): number => {
  let consensus = 0;

  // TODO: update the calculations

  // allActivityResults.forEach((activityResult) => {
  //   const questionAnswers = allQuestionActivitiesAnswersMap[activityResult.key];
  //   if (!questionAnswers) return;
  //   const finalIndividualResults = activityResult.value.find(
  //     ({ key }) => key === ActivityPart.Review
  //   )?.value;

  //   if (
  //     !Array.isArray(finalIndividualResults) ||
  //     finalIndividualResults.length === 0
  //   )
  //     return;

  //   const hasTimeout = finalIndividualResults
  //     .filter(({ ready }) => ready)
  //     .every((v) => v.value === ACTIVITY_TIMEOUT_VALUE);

  //   if (hasTimeout) return;

  //   const answerId = finalIndividualResults.find(
  //     (r) => (r.ready || isTransitioning) && r.value !== ACTIVITY_TIMEOUT_VALUE
  //   )?.value;

  //   const individualAnswerIsAligned = finalIndividualResults
  //     .filter(({ ready }) => ready || isTransitioning)
  //     .every(({ value }) => value === answerId);

  //   if (!individualAnswerIsAligned || !answerId) return;

  //   consensus += 1;
  // });

  return consensus;
};

export const calculateAuthorMatches = (
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: any },
  isTransitioning: boolean
): number => {
  let authorMatches = 0;

  // TODO: update the calculations

  // allActivityResults.forEach((activityResult) => {
  //   const questionAnswers = allQuestionActivitiesAnswersMap[activityResult.key];
  //   if (!questionAnswers) return;

  //   const finalIndividualResults = activityResult.value.find(
  //     (a) => a.key === ActivityPart.Review
  //   )?.value;

  //   if (
  //     !Array.isArray(finalIndividualResults) ||
  //     finalIndividualResults.length === 0
  //   )
  //     return;

  //   finalIndividualResults
  //     .filter(({ ready }) => ready || isTransitioning)
  //     .forEach(({ value }) => {
  //       const hasTimeout = value === ACTIVITY_TIMEOUT_VALUE;
  //       if (hasTimeout) return;

  //       const hasAuthorsChoiceMatch = questionAnswers.find(
  //         (a) => a.authors_choice && a.id === value
  //       );
  //       if (!hasAuthorsChoiceMatch) return;

  //       authorMatches += 1;
  //     });
  // });

  return authorMatches;
};

export const calculateChangedOpinions = (
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: any },
  isTransitioning: boolean
): number => {
  let changedOpinions = 0;

  // TODO: update the calculations

  // allActivityResults.forEach((activityResult) => {
  //   const questionAnswers = allQuestionActivitiesAnswersMap[activityResult.key];
  //   if (!questionAnswers) return;

  //   const individualResults = activityResult.value.find(
  //     ({ key }) => key === ActivityPart.Individual
  //   )?.value;
  //   if (!Array.isArray(individualResults) || individualResults.length === 0)
  //     return;

  //   const hasIndividualTimeout = individualResults
  //     .filter(({ ready }) => ready || isTransitioning)
  //     .every((v) => v.value === ACTIVITY_TIMEOUT_VALUE);

  //   if (hasIndividualTimeout) return;

  //   const finalIndividualResults = activityResult.value.find(
  //     ({ key }) => key === ActivityPart.Review
  //   )?.value;

  //   if (
  //     !Array.isArray(finalIndividualResults) ||
  //     finalIndividualResults.length === 0
  //   )
  //     return;

  //   const hasFinalIndividualTimeout = finalIndividualResults
  //     .filter(({ ready }) => ready || isTransitioning)
  //     .every((v) => v.value === ACTIVITY_TIMEOUT_VALUE);

  //   if (hasFinalIndividualTimeout) return;

  //   individualResults
  //     .filter(({ ready }) => ready || isTransitioning)
  //     .forEach(({ value, profileId }) => {
  //       if (value === ACTIVITY_TIMEOUT_VALUE) return;

  //       const finalIndividualAnswer = finalIndividualResults
  //         .filter(({ ready }) => ready || isTransitioning)
  //         .find((res) => res.profileId === profileId);

  //       if (
  //         !finalIndividualAnswer ||
  //         finalIndividualAnswer.value === ACTIVITY_TIMEOUT_VALUE ||
  //         finalIndividualAnswer.value === value
  //       )
  //         return;

  //       changedOpinions += 1;
  //     });
  // });

  return changedOpinions;
};

export const calculateLeaderBoardStats = (
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: any },
  isTransitioning: boolean
) => {
  const consensusReached = calculateConsensusReached(
    allActivityResults,
    allQuestionActivitiesAnswersMap,
    isTransitioning
  );
  const authorMatches = calculateAuthorMatches(
    allActivityResults,
    allQuestionActivitiesAnswersMap,
    isTransitioning
  );
  const changedOpinions = calculateChangedOpinions(
    allActivityResults,
    allQuestionActivitiesAnswersMap,
    isTransitioning
  );

  return {
    consensusReached,
    authorMatches,
    changedOpinions,
  };
};
