import cn from "classnames";
import { memo } from "react";

import styles from "./CircledIcon.module.css";

const CircledIcon = (
  props: {
    iconClass: string;
    isSelected: boolean;
    className?: string;
  }
) => {
  const { iconClass, isSelected, className } = props;

  return (
    <div className={cn(styles.container, className, { [styles.selected]: isSelected })}>
      <i className={cn("icon fa", iconClass)}></i>
    </div>
  );
};

export default memo(CircledIcon);
