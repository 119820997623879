import { gql } from "@apollo/client";
import { SessionStateResult } from "../types/results/session-state-result";
import { AppApolloClient } from "../../contexts/Apollo";
import { SESSION_CONTEXT_RESULT_QUERY } from "../constants";

const query = gql`
  subscription Subscription($sessionId: String!, $connectionUUID: String!) {
    sessionState(sessionId: $sessionId, connectionUUID: $connectionUUID) {
      context ${SESSION_CONTEXT_RESULT_QUERY}
    }
  }
`;

export function openSessionStateSubscription(
  client: AppApolloClient,
  variables: { sessionId: string; connectionUUID: string }
) {
  return client.subscribe<SessionStateResult>({
    query,
    variables,
    fetchPolicy: "no-cache",
  });
}
