import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { JitsiContext } from "../contexts/Jitsi";
import {
  deserializeUint8Array,
  serializeUint8Array,
} from "../utils/uint8-array-serializers";
import { JitsiEvents } from "../jitsi";
import { GlobalContext } from "../contexts/Global";

export function useAwareness(names: Readonly<string[]>) {
  const globalContext = useContext(GlobalContext);
  const jitsiContext = useContext(JitsiContext);
  const [state, setState] = useState<
    { name: string; awareness: Uint8Array | null }[]
  >(names.map((name) => ({ name, awareness: null })));

  const { handleEditorAwarenessChange, jitsiInstance } = useMemo(() => {
    const {
      handleEditorAwarenessChange,
      state: {
        context: { jitsiInstance },
      },
    } = jitsiContext;
    return { handleEditorAwarenessChange, jitsiInstance };
  }, [jitsiContext]);

  const onAwarenessUpdate = useCallback(
    (data: { event: { type: "awareness"; awareness: any }; name: string }) => {
      handleEditorAwarenessChange({
        awareness: serializeUint8Array(data.event.awareness),
        name: data.name,
        instanceUUID: globalContext.instanceUUID,
      });
    },
    [globalContext.instanceUUID, handleEditorAwarenessChange]
  );

  useEffect(() => {
    function editorAwarenessChange(event: Event) {
      if (!("detail" in event)) return;
      const detail = event.detail;
      if (
        typeof detail !== "object" ||
        detail === null ||
        !("value" in detail) ||
        typeof detail.value !== "string" ||
        detail.value === "null"
      )
        return;

      const parsedValue = JSON.parse(detail.value) as {
        name: string;
        awareness: string;
        instanceUUID: string;
      };
      // Only apply the update on remote users
      if (parsedValue.instanceUUID === globalContext.instanceUUID) return;

      const name = parsedValue.name;
      const awareness = deserializeUint8Array(parsedValue.awareness);
      setState((state) =>
        state.map((entry) => {
          if (entry.name !== name) return entry;
          return { ...entry, awareness };
        })
      );
    }

    jitsiInstance?.addEventListener(
      JitsiEvents.EDITOR_AWARENESS_CHANGE,
      editorAwarenessChange
    );

    return () => {
      jitsiInstance?.removeEventListener(
        JitsiEvents.EDITOR_AWARENESS_CHANGE,
        editorAwarenessChange
      );
    };
  }, [globalContext.instanceUUID, jitsiInstance]);

  return { awarenessState: state, onAwarenessUpdate };
}
