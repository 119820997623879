import { openDB } from "idb";
const databaseVersion = 1;

const channel = new BroadcastChannel("indexedDB-updates");
const eventTarget = new EventTarget();

const dbPromise = openDB("ahaplay-db", databaseVersion, {
  upgrade(db) {
    if (db.objectStoreNames.contains("store")) return;
    db.createObjectStore("store");
  },
});

const notifyChange = <T>(value: T, key?: IDBValidKey | IDBKeyRange) => {
  const data = { key, value };
  channel.postMessage(data);
  eventTarget.dispatchEvent(new CustomEvent("change", { detail: data }));
};

export const putItem = async (
  item: any,
  id?: IDBValidKey | IDBKeyRange,
  skipNotification = false
) => {
  const db = await dbPromise;
  return db.put("store", item, id).then((value) => {
    if (!skipNotification) notifyChange(value, id);
    return value;
  });
};

export const getItems = async () => {
  const db = await dbPromise;
  return db.getAll("store");
};

export const getItem = async <T>(id: IDBValidKey | IDBKeyRange) => {
  const db = await dbPromise;
  return db.get("store", id) as Promise<T>;
};

export const deleteItem = async (
  id: IDBValidKey | IDBKeyRange,
  skipNotification = false
) => {
  const db = await dbPromise;
  return db.delete("store", id).then((value) => {
    if (!skipNotification) notifyChange(value, id);
    return value;
  });
};

export const listenToChanges = <T>(
  callback: (key: IDBValidKey | IDBKeyRange, value: T) => void
) => {
  channel.onmessage = (event) => {
    const { key, value } = event.data;
    callback(key, value);
  };
  const handler = function (event: any) {
    const { key, value } = event.detail;
    callback(key, value);
  };
  eventTarget.addEventListener("change", handler);
  return () => {
    channel.onmessage = null;
    eventTarget.removeEventListener("change", handler);
  };
};
