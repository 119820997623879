import { PropsWithChildren, memo } from "react";

import { WorkshopState } from "../../../../+xstate/machines/session/workshop";
import { Profile } from "../../../../apollo-graphql/types/profile";

import {
  WarmUpActivity,
  WarmUpActivityType,
} from "../../../../types/contentful/workshop/activities/warm-up";

import TeamName from "./components/TeamName/TeamName";

export default memo(function WarmUp(
  props: PropsWithChildren<{
    workshopState: WorkshopState;
    isConnectionWeak: boolean;
    teamNameValue: string;
    isReady: boolean;
    transition: number;
    isParticipating: boolean;
    notReadyProfilesCount: number;
    currentActiveParticipantCount: number;
    changeTeamNameHandler: (inputValue: string) => void;
    setActivityReadyHandler: () => void;
    profile: Profile;
    teamName: string;
    warmUpActivity: WarmUpActivity;
  }>
) {
  const {
    fields: {
      activity: {
        fields: { title, instructions },
      },
    },
    sys: {
      contentType: {
        sys: { id: warmUpActivityType },
      },
    },
  } = props.warmUpActivity;

  if (warmUpActivityType === WarmUpActivityType.TeamNameActivity) {
    return <TeamName {...props} title={title} instructions={instructions} />;
  }

  return null;
});
