import { PropsWithChildren } from 'react';

import styles from './TextEllipsis.module.css';

type TextEllipsisProps = PropsWithChildren<{
  text: string;
}>

const TextEllipsis = ({ text }: TextEllipsisProps) => (
  <div className={styles.textEllipsis}>
    {text}
  </div>
);

export default TextEllipsis;
