import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";

const query = gql`
  query TechnicalSetupHelp {
    technicalSetupHelpRequest {
      success
    }
  }
`;

export function technicalSetupHelp(client: AppApolloClient) {
  return client
    .query<{ technicalSetupHelpRequest: { success: boolean } }>({
      query,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.technicalSetupHelpRequest;
    });
}
