import {
  ChangeEvent,
  Fragment,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { startOfDay, endOfDay, formatDate } from "date-fns";
import { Slot } from "../../apollo-graphql/types/slot";

import cn from "classnames";
import styles from "./Reschedule.module.css";

import { formatSlotScheduleDate } from "../../utils/format-schedule-date";
import { InvitationStatus } from "../../types/enums/invitation-status";

const Reschedule = (
  props: PropsWithChildren<{
    invitationId: string;
    workshopTitle: string;
    profileId: string;
    scheduledSlots: Slot[] | null;
    rescheduleHandler: (invitationId: string, slot: Slot) => void;
    navigateToWorkshop: () => void;
  }>
) => {
  const {
    invitationId,
    workshopTitle,
    scheduledSlots,
    profileId,
    rescheduleHandler,
    navigateToWorkshop,
  } = props;
  const todayTimestamp = useMemo(() => new Date().getTime(), []);

  const [filterDate, setFilterDate] = useState<string>(
    formatDate(todayTimestamp, "yyyy-MM-dd")
  );

  const onFilterDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterDate(event.target.value);
    },
    []
  );

  const slots = useMemo(() => {
    const startTimeStamp = startOfDay(filterDate).getTime() / 1000;
    const endTimeStamp = endOfDay(filterDate).getTime() / 1000;

    return scheduledSlots?.filter((s) => {
      const slotStartTimestamp = new Date(s.schedule_date).getTime();

      return (
        startTimeStamp <= slotStartTimestamp &&
        endTimeStamp >= slotStartTimestamp
      );
    });
  }, [filterDate, scheduledSlots]);

  const slotsContent = useMemo(
    () =>
      slots?.length ? (
        slots.map((slot) => {
          const isEnrolled = slot.invitations.find(
            (i) =>
              i.profile.id === profileId &&
              i.status !== InvitationStatus.AUTO_GENERATED
          );
          const workshopInfoText =
            new Date(slot.schedule_date).getTime() <= todayTimestamp / 1000
              ? "Starts on"
              : "The conversation will start soon";

          return (
            <Fragment key={slot.id}>
              <div className="line"></div>
              <div className="workshop-content">
                <div className="workshop-info">
                  <p>{workshopInfoText}</p>
                  <h3 className="bold">{formatSlotScheduleDate(slot.schedule_date)}</h3>
                </div>
                <button
                  className={cn(
                    "workshop-button",
                    isEnrolled ? "enrolled" : "not-enrolled"
                  )}
                  disabled={!!isEnrolled}
                  onClick={
                    isEnrolled
                      ? () => {}
                      : () => rescheduleHandler(invitationId, slot)
                  }
                >
                  <span className="icon"></span>
                  {isEnrolled ? "Enrolled" : "Select"}
                </button>
              </div>
            </Fragment>
          );
        })
      ) : (
        <div>No Slots found.</div>
      ),
    [invitationId, profileId, rescheduleHandler, slots, todayTimestamp]
  );

  const noRescheduleWorkshopContent = useMemo(
    () => (
      <>
        <div className={styles.noRescheduleImageContainer}>
          <img
            src="/images/no-upcoming-workshops.svg"
            alt="no-upcoming-workshops"
          />
        </div>
        <h1>You don’t have any upcoming conversations!</h1>
        <p className={cn(styles.noRescheduleInfoText, "text")}>
          Please contact your company trainers at{" "}
          <span className="text bold">stan@stan.vision</span>,
          <span className="text bold">stavros.stavru@gmail.com</span> or{" "}
          <span className="text bold">maria@stan.vision</span> to schedule a
          conversation.
        </p>
        <button className={styles.workshopBtn} onClick={navigateToWorkshop}>
          Go Back
        </button>
      </>
    ),
    [navigateToWorkshop]
  );

  const rescheduleWorkshopContent = useMemo(
    () => (
      <>
        <div>
          <h6 className="text">
            Choose an available time slots for team conversation
          </h6>
          <h1>{workshopTitle}</h1>
        </div>
        <div className={styles.rescheduleContent}>
          <div>
            <div className={styles.inputContainer}>
              <div className="input-text">Filter by date</div>
              <input
                type="date"
                defaultValue={filterDate}
                onChange={onFilterDateChange}
              />
              {/* <i className="fa fa-calendar-alt" > </i> */}
            </div>
          </div>
          <div className="workshops-container">{slotsContent}</div>
        </div>
        <button className={styles.workshopBtn} onClick={navigateToWorkshop}>
          Go Back
        </button>
      </>
    ),
    [
      filterDate,
      navigateToWorkshop,
      onFilterDateChange,
      slotsContent,
      workshopTitle,
    ]
  );

  const content = useMemo(() => {
    return scheduledSlots?.length
      ? rescheduleWorkshopContent
      : noRescheduleWorkshopContent;
  }, [
    noRescheduleWorkshopContent,
    rescheduleWorkshopContent,
    scheduledSlots?.length,
  ]);

  return content;
};

export default memo(Reschedule);
