import { createMachine, assign, sendTo, spawnChild, raise } from "xstate";
import { AppApolloClient } from "../../contexts/Apollo";
import * as actions from "../actions/team-members";
import { getProfile, getProfiles } from "../../apollo-graphql/queries/profile";
import { Pagination } from "../../types/pagination";
import { fetchMachineFactory } from "./fetch-factory";
import { inviteProfile } from "../../apollo-graphql/mutations/invite-profile";
import { Profile } from "../../apollo-graphql/types/profile";
import { updateProfile } from "../../apollo-graphql/mutations/update-profile";
import { deleteProfile } from "../../apollo-graphql/mutations/delete-profile";
import {
  deleteImageFromS3,
  getPreSignedUrl,
  uploadImageToS3,
} from "../../fetch/image";
import { getUnixTime } from "date-fns/getUnixTime";

export enum TeamMembersState {
  List = "list",
  InviteDialog = "inviteDialog",
  EditDialog = "editDialog",
  DeleteDialog = "deleteDialog"
}

interface TeamMembersMachineContext {
  client: AppApolloClient;
  workspaceId: string;
  error: string | null;
  selectedTeamMember: Profile | null;
  deletedTeamMemberId: string | null;
  selectedTeamMemberImageData: {
    presignedUrl: string | null;
    lastUpdatedTimeStamp: number | null;
  };
}

type TeamMemberActionCreators = typeof actions;
type TeamMemberActionCreatorKeys = keyof TeamMemberActionCreators;
type TeamMemberActions = ReturnType<
  TeamMemberActionCreators[TeamMemberActionCreatorKeys]
>;

type TeamMembersMachineTypes = {
  context: TeamMembersMachineContext;
  events:
  | TeamMemberActions
  | ReturnType<typeof getTeamMembersSuccess>
  | ReturnType<typeof getTeamMembersFailure>
  | ReturnType<typeof getTeamMembersDone>
  | ReturnType<typeof inviteTeamMemberSuccess>
  | ReturnType<typeof inviteTeamMemberFailure>
  | ReturnType<typeof getTeamMemberSuccess>
  | ReturnType<typeof getTeamMemberFailure>
  | ReturnType<typeof editTeamMemberSuccess>
  | ReturnType<typeof editTeamMemberFailure>
  | ReturnType<typeof deleteTeamMemberSuccess>
  | ReturnType<typeof deleteTeamMemberFailure>
  | ReturnType<typeof getPreSignedTeamMemberUrlSuccess>
  | ReturnType<typeof getPreSignedTeamMemberUrlFailure>
  | ReturnType<typeof uploadTeamMemberImageSuccess>
  | ReturnType<typeof uploadTeamMemberImageFailure>
  | ReturnType<typeof deleteTeamMemberImageSuccess>
  | ReturnType<typeof deleteTeamMemberImageFailure>;
};

export const {
  machine: getTeamMembersMachine,
  success: getTeamMembersSuccess,
  failure: getTeamMembersFailure,
  done: getTeamMembersDone,
  trigger: getTeamMembersTrigger,
  reTrigger: getTeamMembersReTrigger,
} = fetchMachineFactory({
  id: "getTeamMembers",
  invokeFn: ({
    workspaceId,
    pagination,
    query,
    client,
  }: {
    workspaceId: string;
    pagination: Pagination;
    query?: string;
    client: AppApolloClient;
  }) => {
    return getProfiles(client, {
      workspaceId,
      pagination,
      query,
    }).then((data) => ({
      profiles: data,
      workspaceId,
    }));
  },
  triggerOnCreation: false,
});

const {
  machine: getTeamMemberMachine,
  success: getTeamMemberSuccess,
  failure: getTeamMemberFailure,
  trigger: getTeamMemberTrigger,
} = fetchMachineFactory({
  id: "getTeamMember",
  invokeFn: ({ client, id }: { client: AppApolloClient; id: string }) => {
    return getProfile(client, { id }).then((data) => ({
      profile: data,
    }));
  },
});

export const {
  machine: inviteTeamMemberMachine,
  success: inviteTeamMemberSuccess,
  failure: inviteTeamMemberFailure,
  trigger: inviteTeamMemberTrigger,
} = fetchMachineFactory({
  id: "inviteTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["inviteSend"]>["payload"];
  }) => {
    return inviteProfile(client, payload.variables);
  },
});

export const {
  machine: editTeamMemberMachine,
  success: editTeamMemberSuccess,
  failure: editTeamMemberFailure,
  trigger: editTeamMemberTrigger,
} = fetchMachineFactory({
  id: "editTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["editSubmit"]>["payload"];
  }) => {
    return updateProfile(client, payload.variables);
  },
});

export const {
  machine: deleteTeamMemberMachine,
  success: deleteTeamMemberSuccess,
  failure: deleteTeamMemberFailure,
  trigger: deleteTeamMemberTrigger,
} = fetchMachineFactory({
  id: "deleteTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["deleteSubmit"]>["payload"];
  }) => {
    return deleteProfile(client, payload.variables);
  },
});

export const {
  machine: getPreSignedTeamMemberUrlMachine,
  success: getPreSignedTeamMemberUrlSuccess,
  failure: getPreSignedTeamMemberUrlFailure,
  trigger: getPreSignedTeamMemberUrlTrigger,
} = fetchMachineFactory({
  id: "getPreSignedTeamMemberUrl",
  invokeFn: ({ token, key }: { token: string; key: string }) => {
    return getPreSignedUrl(token, key);
  },
  triggerOnCreation: false,
});

export const {
  machine: uploadTeamMemberImageMachine,
  success: uploadTeamMemberImageSuccess,
  failure: uploadTeamMemberImageFailure,
  trigger: uploadTeamMemberImageTrigger,
} = fetchMachineFactory({
  id: "uploadTeamMemberImage",
  invokeFn: ({ url, body }: { url: string; body: File | ReadableStream }) => {
    return uploadImageToS3(url, body);
  },
  triggerOnCreation: false,
});

export const {
  machine: deleteTeamMemberImageMachine,
  success: deleteTeamMemberImageSuccess,
  failure: deleteTeamMemberImageFailure,
  trigger: deleteTeamMemberImageTrigger,
} = fetchMachineFactory({
  id: "deleteTeamMemberImage",
  invokeFn: ({ key, token }: { key: string; token: string }) => {
    return deleteImageFromS3(key, token);
  },
  triggerOnCreation: false,
});

export const teamMembersMachine = createMachine({
  types: {} as TeamMembersMachineTypes,
  id: "team-members",
  initial: TeamMembersState.List,
  context: ({ input }): TeamMembersMachineContext => {
    const machineInput = input as
      | {
        client?: AppApolloClient;
        workspaceId: string;
      }
      | undefined;

    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");

    return {
      client: machineInput.client,
      workspaceId: machineInput.workspaceId,
      error: null,
      selectedTeamMember: null,
      deletedTeamMemberId: null,
      selectedTeamMemberImageData: {
        presignedUrl: null,
        lastUpdatedTimeStamp: null,
      },
    };
  },
  entry: [
    spawnChild(getTeamMembersMachine, {
      id: getTeamMembersMachine.id,
      input: ({ context }: { context: TeamMembersMachineContext }) => context,
      syncSnapshot: true,
    }),
    spawnChild(getTeamMemberMachine, {
      id: getTeamMemberMachine.id,
      input: ({ context }: { context: TeamMembersMachineContext }) => context,
      syncSnapshot: true,
    }),
    spawnChild(editTeamMemberMachine, {
      id: editTeamMemberMachine.id,
      input: ({ context }: { context: TeamMembersMachineContext }) => context,
      syncSnapshot: true,
    }),
    spawnChild(deleteTeamMemberMachine, {
      id: deleteTeamMemberMachine.id,
      input: ({ context }: { context: TeamMembersMachineContext }) => context,
      syncSnapshot: true,
    }),
    spawnChild(inviteTeamMemberMachine, {
      id: inviteTeamMemberMachine.id,
      input: ({ context }: { context: TeamMembersMachineContext }) => context,
      syncSnapshot: true,
    }),
    spawnChild(getPreSignedTeamMemberUrlMachine, {
      id: getPreSignedTeamMemberUrlMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(uploadTeamMemberImageMachine, {
      id: uploadTeamMemberImageMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(deleteTeamMemberImageMachine, {
      id: deleteTeamMemberImageMachine.id,
      syncSnapshot: true,
    }),
  ],
  states: {
    [TeamMembersState.List]: {
      on: {
        [actions.fetchTeamMembers.type]: {
          actions: [
            sendTo(getTeamMembersMachine.id, ({ event, context }) => {
              const { currentPage, pageSize, query } = event.payload.filters;
              if (!currentPage || !pageSize) return;

              const pagination = {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
              };
              return getTeamMembersTrigger({
                workspaceId: event.payload.workspaceId,
                client: context.client,
                pagination,
                query,
              });
            }),
          ],
        },
        [actions.editOpen.type]: {
          target: TeamMembersState.EditDialog,
        },
        [actions.deleteOpen.type]: {
          target: TeamMembersState.DeleteDialog,
          actions: [
            assign({
              deletedTeamMemberId: ({ event }) => event.payload.id
            })
          ]
        },
        [actions.inviteOpen.type]: {
          target: TeamMembersState.InviteDialog,
        },
      },
    },
    [TeamMembersState.InviteDialog]: {
      on: {
        [actions.inviteSend.type]: {
          actions: [
            sendTo(inviteTeamMemberMachine.id, ({ event, context }) => {
              event = event as ReturnType<
                TeamMemberActionCreators["inviteSend"]
              >;

              return inviteTeamMemberTrigger({
                client: context.client,
                payload: event.payload,
              });
            }),
          ],
        },
        [inviteTeamMemberSuccess.type]: {
          actions: [
            assign({ error: null }),
            raise(() => actions.inviteClose()),
          ],
        },
        [inviteTeamMemberFailure.type]: {
          actions: [
            assign({
              error: ({ event }) => `${event.payload.error.message}`,
            }),
          ],
        },
        [actions.inviteClose.type]: {
          target: TeamMembersState.List,
          actions: [
            sendTo(getTeamMembersMachine.id, () => getTeamMembersReTrigger()),
          ],
        },
      },
    },
    [TeamMembersState.EditDialog]: {
      entry: [
        sendTo(getTeamMemberMachine.id, ({ context, event }) => {
          event = event as ReturnType<TeamMemberActionCreators["editOpen"]>;
          return getTeamMemberTrigger({
            client: context.client,
            id: event.payload.id,
          });
        }),
      ],
      on: {
        [getTeamMemberSuccess.type]: {
          actions: [
            assign({
              selectedTeamMember: ({ event }) => {
                return event.payload.output.profile;
              },
            }),
          ],
        },
        [getTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.editSubmit.type]: {
          actions: [
            sendTo(editTeamMemberMachine.id, ({ context, event }) => {
              return editTeamMemberTrigger({
                client: context.client,
                payload: { variables: event.payload.variables },
              });
            }),
          ],
        },
        [editTeamMemberSuccess.type]: {
          actions: [
            assign({ error: null }),
            sendTo(getTeamMembersMachine.id, () => {
              return getTeamMembersReTrigger();
            }),
            raise(() => actions.editClose()),
          ],
        },
        [editTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.editClose.type]: {
          target: TeamMembersState.List,
          actions: [assign({ selectedTeamMember: null })],
        },
        [actions.getPresignedTeamMemberUrl.type]: {
          actions: [
            sendTo(getPreSignedTeamMemberUrlMachine.id, ({ event }: any) => {
              event = event as ReturnType<
                TeamMemberActionCreators["getPresignedTeamMemberUrl"]
              >;

              return getPreSignedTeamMemberUrlTrigger(event.payload);
            }),
          ],
        },
        [actions.uploadTeamMemberImage.type]: {
          actions: [
            sendTo(uploadTeamMemberImageMachine.id, ({ event }: any) => {
              event = event as ReturnType<
                TeamMemberActionCreators["uploadTeamMemberImage"]
              >;

              return uploadTeamMemberImageTrigger(event.payload);
            }),
          ],
        },
        [actions.deleteTeamMemberImage.type]: {
          actions: [
            sendTo(deleteTeamMemberImageMachine.id, ({ event }: any) => {
              event = event as ReturnType<
                TeamMemberActionCreators["deleteTeamMemberImage"]
              >;

              return deleteTeamMemberImageTrigger(event.payload);
            }),
          ],
        },
        [getPreSignedTeamMemberUrlSuccess.type]: {
          actions: [
            assign(({ event }) => ({
              selectedTeamMemberImageData: {
                presignedUrl: event.payload.output,
                lastUpdatedTimeStamp: getUnixTime(new Date()),
              },
            })),
          ],
        },
        [getPreSignedTeamMemberUrlFailure.type]: {
          actions: [
            assign(() => ({
              selectedTeamMemberImageData: {
                presignedUrl: null,
                lastUpdatedTimeStamp: null,
              },
            })),
          ],
        },
        [uploadTeamMemberImageSuccess.type]: {
          actions: [
            assign(({ context }) => ({
              selectedTeamMemberImageData: {
                presignedUrl: context.selectedTeamMemberImageData.presignedUrl,
                lastUpdatedTimeStamp: getUnixTime(new Date()),
              },
              error: null,
            })),
          ],
        },
        [uploadTeamMemberImageFailure.type]: {
          actions: [
            assign(() => ({
              selectedTeamMemberImageData: {
                presignedUrl: null,
                lastUpdatedTimeStamp: null,
              },
            })),
          ],
        },
        [deleteTeamMemberImageSuccess.type]: {
          actions: [
            assign(({ context }) => ({
              selectedTeamMemberImageData: {
                presignedUrl: context.selectedTeamMemberImageData.presignedUrl,
                lastUpdatedTimeStamp: getUnixTime(new Date()),
              },
              error: null,
            })),
          ],
        },
        [deleteTeamMemberImageFailure.type]: {
          actions: [
            assign(() => ({
              selectedTeamMemberImageData: {
                presignedUrl: null,
                lastUpdatedTimeStamp: null,
              },
            })),
          ],
        },
      },
    },
    [TeamMembersState.DeleteDialog]: {

      on: {

        [actions.deleteSubmit.type]: {
          actions: [
            sendTo(deleteTeamMemberMachine.id, ({ context, event }) => {
              return deleteTeamMemberTrigger({
                client: context.client,
                payload: { variables: event.payload.variables },
              });
            }),
          ],
        },
        [deleteTeamMemberSuccess.type]: {
          actions: [
            assign({ error: null }),
            sendTo(getTeamMembersMachine.id, () => {
              return getTeamMembersReTrigger();
            }),
            raise(() => actions.deleteClose()),
          ],
        },
        [deleteTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.deleteClose.type]: {
          target: TeamMembersState.List,
          actions: [assign({ deletedTeamMemberId: null })],
        }
      }
    }
  },
  exit: sendTo(getTeamMembersMachine.id, () => {
    return getTeamMembersDone();
  }),
});
