import { useContext, useMemo } from "react";
import { SessionContext } from "../contexts/Session";

const useActivityTimeout = () => {
  const { workshopClock } = useContext(SessionContext);
  const clockInstance = workshopClock.state.context.clockInstance;

  const activityParsedTimeRemaining = useMemo(
    () => clockInstance?.activityParsedTimeRemaining,
    [clockInstance?.activityParsedTimeRemaining]
  );

  
  const isActivityTimeout = useMemo(
    () => activityParsedTimeRemaining === "",
    [activityParsedTimeRemaining]
  );

  return { isActivityTimeout, clockInstance } as const;
};

export default useActivityTimeout;
