import cn from "classnames";

import { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { adminDashboardScheduleDialogMachine } from "../../../../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import { ApolloContext } from "../../../../../../contexts/Apollo";
import { openScheduleDialog } from "../../../../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";
import { GlobalContext } from "../../../../../../contexts/Global";

import styles from "./JourneyDetailsTitle.module.css";

interface JourneyDetailsTitleProps {
  title: string | undefined;
  journeyId: string;
  conversationId: string | null;
  authorName: string;
}

const JourneyDetailsTitle = ({
  title,
  journeyId,
  conversationId,
  authorName,
}: JourneyDetailsTitleProps) => {
  const { workshopId } = useParams();
  const { client } = useContext(ApolloContext);
  const [, send] = useMachine(adminDashboardScheduleDialogMachine, {
    input: { client },
  });
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (workshopId) {
      return void send(
        openScheduleDialog({
          workshopId,
          workspaceId: profile!.workspace.workspace_id,
        })
      );
    }
  }, [workshopId, profile, send]);

  return (
    <div className={styles.titleWrapper}>
      <div className={styles.title}>
        {title && <div className={styles.titleMain}>{title}</div>}
        <div className={styles.titleSecondary}>
          <span className={styles.titleSecondaryAccent}>
            {`by ${authorName}`},{" "}
          </span>
          Conversations worth having!
        </div>
      </div>
      {conversationId && (
        <Link
          className={cn("btn", styles.startJourneyButton)}
          to={`/journeys/${journeyId}/schedule/${conversationId}`}
        >
          Start your journey
          <i
            className={cn("fa-regular", "fa-arrow-right", styles.buttonIcon)}
          />
        </Link>
      )}
    </div>
  );
};

export default JourneyDetailsTitle;
