import { memo, useCallback, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import withRouteConfig from "../../../hocs/withRouteConfig";
import useCopyFromClipboard from "../../../hooks/useCopyFromClipboard";
import Button3D from "../../Shared/Buttons/Button3D/Button3D";

import cn from "classnames";
import styles from "./ThankYou.module.css";

function ThankYou() {
  const params = useParams<{ slotId: string; group: string }>();
  const navigate = useNavigate();

  const { slotId, group } = useMemo(
    () => params || { slotId: null, group: null },
    [params]
  );

  const workshopLink = useMemo(
    () => `workshop-redirect/${slotId}/${group}`,
    [group, slotId]
  );

  const { copyHandler, isCopied } = useCopyFromClipboard({
    copyText: workshopLink,
    durationInMilliseconds: 1000,
  });

  const handleRejoinWorkshop = useCallback(() => {
    navigate(`/${workshopLink}`);
  }, [navigate, workshopLink]);

  if (!slotId || !group) return <Navigate to="/" />;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.partyPooper}>🎉</p>
        <h1>Thank you!</h1>
        <p className={cn("text", styles.descriptionText)}>
          You can reopen the conversation any time from the link below
        </p>

        <div className={styles.actions}>
          <Button3D variant="secondary" onClick={copyHandler}>
            {isCopied ? "Conversation link coppied!" : "Copy conversation link"}
          </Button3D>
          <Button3D variant="info" onClick={handleRejoinWorkshop}>
            Rejoin conversation
          </Button3D>
        </div>
      </div>
    </div>
  );
}

export default memo(withRouteConfig(ThankYou));
