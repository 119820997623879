import { PropsWithChildren, memo, useMemo } from "react";
import withLoadingDelay, { LoadingDelay } from "../../../../hocs/withLoadingDelay";
import { ButtonSize, ButtonVariant } from "../../../../types/buttons";

import cn from "classnames";

type LoadingButtonProps = PropsWithChildren<{
  size?: ButtonSize;
  variant?: ButtonVariant;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "reset" | "submit";
  className?: string;
  iconClass?: string;
  iconPosition?: "start" | "end";
}> &
  LoadingDelay;

const LoadingButton = (props: LoadingButtonProps) => {
  const {
    size,
    variant,
    className,
    children,
    type = "button",
    iconPosition = "start",
    onClick,
    disabled,
    iconClass,
    isLoading,
  } = props;

  const buttonContent = useMemo(() => {
    return (
      <>
        {isLoading && <i className="fa fa-circle-o-notch fa-spin" />}
        {!isLoading && iconClass && iconPosition === "start" && (
          <i className={iconClass} />
        )}
        <p style={{ margin: iconClass ? "0" : "0 auto" }}>{children}</p>
        {!isLoading && iconClass && iconPosition === "end" && (
          <i className={iconClass} />
        )}
      </>
    );
  }, [children, iconClass, iconPosition, isLoading]);

  return (
    <button
      className={cn("btn", className, size, variant || "primary")}
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      style={{ display: "flex", gap: "5px" }}
    >
      {buttonContent}
    </button>
  );
};

export default memo(withLoadingDelay(LoadingButton));
