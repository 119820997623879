import { memo, useCallback, useState } from 'react';
import { RemoteParticipantData } from '../../../../../types/jitsi';
import SelfDirectedDiscussionListItem, { Item } from './SelfDirectedDiscussionListItem/SelfDirectedDiscussionListItem';

import styles from './SelfDirectedDiscussionList.module.css';

interface RadioImageOptionsProps {
  activityId: string;
  items: Item[];
  remoteParticipantsData: RemoteParticipantData[];
  workspaceId: string;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const SelfDirectedDiscussionList = ({ activityId, items, remoteParticipantsData, workspaceId, setActivityValueHandler }: RadioImageOptionsProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const handleSelection = useCallback((item: string) => {
    const newSelectedItems = selectedItems.includes(item)
      ? selectedItems.filter((selectedItem) => selectedItem !== item)
      : [...selectedItems, item];
    
    setSelectedItems(newSelectedItems);
    setActivityValueHandler({ activityId, value: JSON.stringify(newSelectedItems) });
  }, [selectedItems, setSelectedItems, setActivityValueHandler, activityId]);

  return (
    <div className={styles.radioImageOptions}>
      {items.map((item) => {
        const { value: { id }, profileId } = item;
        const checked = selectedItems.includes(id);
        const name = remoteParticipantsData.find((participant) => participant.profileId === profileId)?.name;

        return (
          name && <SelfDirectedDiscussionListItem
            checked={checked}
            item={item}
            name={name}
            workspaceId={workspaceId}
            handleSelection={handleSelection}
            key={id}
          />
        )
      })}
    </div>
  );
};

export default memo(SelfDirectedDiscussionList);
