import { PropsWithChildren, memo } from "react";
import cn from 'classnames';

import styles from './Numbers.module.css';

type NumbersProps = PropsWithChildren<{
  selected: boolean;
  onClick: () => void;
}>;

const Numbers = (props: NumbersProps) => {
  const { children, selected, onClick } = props;

  return (
    <button
      className={cn(styles.button,
        {
          [styles.selected]: selected
        }
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default memo(Numbers);
