import cn from "classnames";

import styles from './AssignmentTitle.module.css';

const AssignmentTitle = () => (
  <div className={styles.assignmentTitle}>
    <i className={cn("fa-solid fa-comment-pen", styles.assignmentIcon)}/> Assignment:
  </div>
);

export default AssignmentTitle;