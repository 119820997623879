export function generateWorkspaceProfileImageKey(
  profileId: string,
  workspace_id: string
) {
  return `workspace_${workspace_id}:profile_${profileId}:profile_image`;
}

export function generateProfileImageKey(profileId: string) {
  return `public:profile_${profileId}:profile_image`;
}

export function generateThemeImageKey(theme_id: string) {
  return `public:${theme_id}:theme_image`;
}

export function generateJourneyImageKey(journey_id: string) {
  return `public:${journey_id}:journey_image`;
}

export function generateJourneyWorkshopImageKey(journey_id: string, workshop_id: string, locked: boolean = false) {
  return `public:${journey_id}:workshop_${workshop_id}:workshop_image${locked ? ":locked": ""}`;
}

export function generateBrainstormingItemImageKey(brainstorming_id: string, brainstorming_item_id: string) {
  return `public:${brainstorming_id}:brainstorming_item_${brainstorming_item_id}:brainstorming_item_image`;
}