import { memo } from "react";
import { JourneyAuthor as JourneyAuthorType } from "../../../../../../types/contentful/workshop/journey";
import ContentfulRichField from "../../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from './JourneyAuthor.module.css';

interface JourneyAuthorInterface {
  author: JourneyAuthorType;
}

const JourneyAuthor = ({ author }: JourneyAuthorInterface) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {`About ${author.fields.name}:`}
      </h2>
      <div className={styles.authorInfo}>
        <img src={author.fields.image?.fields?.file?.url} alt="Author" />
        <div>
          <p>{author.fields.headline}</p>
          <ContentfulRichField
            content={author.fields.bio}
            className={styles.bio}
          />
        </div>
      </div>
    </div>
  )
};

export default memo(JourneyAuthor);