import { PropsWithChildren, memo } from "react";

import cn from "classnames";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import styles from "./TranscriptList.module.css";

export interface Transcript {
  index: number;
  transcript: string;
  heading: string;
}

export default memo(function TranscriptList(
  props: PropsWithChildren<{
    transcripts: Transcript[];
    showLeaderBoard: boolean;
    inOverview: boolean;
  }>
) {
  const { transcripts, showLeaderBoard, inOverview } = props;

  return (
    <div
      className={cn(styles.transcriptsContainer, {
        leaderBoardVisible: showLeaderBoard,
        inOverview: inOverview,
      })}
    >
      {transcripts.length !== 0 ? (
        transcripts.map(({ index, transcript, heading }) => (
          <div className={styles.transcript} key={index}>
            <h3 className={cn(styles.heading, "text", "bold")}>
              {heading}
            </h3>
            <ContentfulRichField
              content={transcript}
              className={cn(styles.content, "text")}
            />
          </div>
        ))
      ) : (
        <h3 className={styles.noTranscripts}>
          There are no transcripts found!
        </h3>
      )}
    </div>
  );
});
