import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import cn from "classnames";

import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import { ProfileUpdate } from "../../../apollo-graphql/types/profile";
import Password from "../../Shared/Password/Password";

import styles from "./ChangePasswordModal.module.css";
const ChangePasswordModal = (
  props: PropsWithChildren<{
    closeDialogHandler: () => void;
    updateProfile: (payload: { variables: ProfileUpdate }) => void;
    profileId: string;
    errorMessage: string | null;
  }>
) => {
  const { closeDialogHandler, profileId, updateProfile, errorMessage } = props;

  const [error, setError] = useState<string>("");

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setError("");
      event.preventDefault();
      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        currentPassword: string;
        newPassword: string;
        repeatPassword: string;
      };

      // Validations
      if (
        payload.currentPassword.length === 0 ||
        payload.newPassword.length === 0 ||
        payload.repeatPassword.length === 0 ||
        !profileId
      ) {
        setError("All fields are required!");
        return;
      }

      if (payload.newPassword !== payload.repeatPassword) {
        setError("Passwords do not match!");
        return;
      }

      updateProfile({
        variables: {
          id: profileId,
          currentPassword: payload.currentPassword,
          newPassword: payload.newPassword,
        },
      });
    },
    [profileId, updateProfile]
  );

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <Form.Root className="form-container" onSubmit={onSubmitHandler}>
          <Password
            name="currentPassword"
            label="Current password"
            labelClassName="label"
          />
          <Password
            name="newPassword"
            label="New Password"
            labelClassName="label"
          />
          <Password
            name="repeatPassword"
            label="Repeat password"
            labelClassName="label"
          />
          <Form.Field name="actions" className="form-row actions">
            <Form.Submit className="btn save">Change</Form.Submit>
          </Form.Field>
          {(error || errorMessage) && <div>{error || errorMessage}</div>}
        </Form.Root>
      </div>
    );
  }, [onSubmitHandler, error, errorMessage]);

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className={cn(styles.ChangePasswordModal, "DialogContent")}
        >
          <Dialog.Title className="DialogTitle">Change Password</Dialog.Title>
          {dialogContent}
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(ChangePasswordModal);
