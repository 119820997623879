import { Suspense } from "react";
import withRouteConfig from "../hocs/withRouteConfig";
import Dashboard from "../components/Dashboard/Dashboard";

function DashboardLayout() {
  return (
    <Suspense>
      <Dashboard />
    </Suspense>
  );
}

export default withRouteConfig(DashboardLayout);
