export enum WorkshopActivityType {
  BrainstormingActivity = "brainstormingActivity",
  ClosedQuestionActivity = "closedQuestionActivity",
  ComparisonActivity = "comparisonActivity",
  ConceptualisationActivity = "conceptualisationActivity",
  FilteringActivity = "filteringActivity",
  OpenQuestion = "openQuestion",
  TranslationActivity = "translationActivity",
  PresentationActivity = "videoActivity",
  VotingActivity = "votingActivity",
  GroupingActivity = "groupingActivity",
  RatingActivity = "ratingActivity",
  SelfDirectedDiscussionActivity = "selfDirectedDiscussionActivity",
}
