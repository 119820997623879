export async function checkAudioAndVideoPermissions(): Promise<void> {
  const checkMediaPermission = async (
    type: "camera" | "microphone"
  ): Promise<boolean> => {
    try {
      const permission = await navigator.permissions.query({
        name: type as PermissionName,
      });
      return permission.state === "granted";
    } catch (err) {
      // In case permissions API is not supported, we'll fall back to requesting permissions
      return false;
    }
  };

  try {
    // Check for camera and microphone permissions
    const audioPermissionGranted = await checkMediaPermission("microphone");
    const videoPermissionGranted = await checkMediaPermission("camera");

    if (audioPermissionGranted && videoPermissionGranted) {
      // Both permissions are already granted
      return Promise.resolve();
    }

    // If permissions are not granted, request them using getUserMedia
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    // Once permissions are granted, stop the media tracks to turn off the camera/microphone
    mediaStream.getTracks().forEach((track) => track.stop());

    return Promise.resolve();
  } catch (err) {
    return Promise.reject("Permissions for audio and/or video were denied.");
  }
}
