import { PropsWithChildren, ReactNode, memo, useMemo, useState } from "react";

import styles from "./InfoBox.module.css";
import cn from "classnames";

const InfoBox = (
  props: PropsWithChildren<{
    title?: string | ReactNode;
    description?: string;
    node?: ReactNode;
    isDismissible?: boolean;
    transparent?: boolean;
  }>
) => {
  const { title, description, node, isDismissible, transparent } = props;

  const [isDismissed, setIsDismissed] = useState(false);

  const titleContent = useMemo(() => {
    if (!title) return null;
    return typeof title === "string" ? (
      <p className={cn(styles.title, "text small bold")}>{title}</p>
    ) : (
      title
    );
  }, [title]);

  return !isDismissed ? (
    <div className={cn(styles.container, transparent && styles.transparent)}>
      <div className={cn(styles.iconContainer)}>
        <i className="fa-regular fa-circle-info" />
      </div>
      <div
        className={styles.box}
        style={{ width: isDismissible ? "calc(100% - 64px)" : "auto" }}
      >
        {titleContent}
        {description && <p className="text small">{description}</p>}
        {!!node && node}
      </div>
      {isDismissible && (
        <div className={styles.dismissButton}>
          <i
            className="fa fa-xmark icon-dismiss"
            onClick={() => setIsDismissed(true)}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default memo(InfoBox);
