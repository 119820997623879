import { PropsWithChildren, memo, useContext, useMemo } from "react";

import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";

import { formatSlotScheduleDate } from "../../../utils/format-schedule-date";
import { getTitle } from "../../../utils";

import { GlobalContext } from "../../../contexts/Global";
import { InvitationStatus } from "../../../types/enums/invitation-status";
import { Slot } from "../../../apollo-graphql/types/slot";

import styles from "./RescheduleDialog.module.css";

export default memo(function RescheduleDialog(
  props: PropsWithChildren<{
    scheduledSlots: Slot[] | null;
    isRescheduleDialogLoadingSlots: boolean;
    isRescheduleDialogRescheduling: boolean;
    rescheduleHandler: (invitationId: string, slot: Slot) => void;
    closeDialogHandler: () => void;
  }>
) {
  const globalContext = useContext(GlobalContext);
  const {
    scheduledSlots,
    isRescheduleDialogLoadingSlots,
    isRescheduleDialogRescheduling,
    rescheduleHandler,
    closeDialogHandler,
  } = props;

  const profileId = globalContext.auth.context.profile?.id;

  const dialogContent = useMemo(() => {
    if (isRescheduleDialogLoadingSlots) {
      return <div>Loading...</div>;
    }
    if (isRescheduleDialogRescheduling) {
      return <div>Rescheduling...</div>;
    }
    if (!scheduledSlots || scheduledSlots.length === 0) {
      return <div>No other slots available</div>;
    }

    return (
      <ul className={styles.content}>
        {scheduledSlots.map((s) => {
          const invitation = s.invitations.find(
            (i) =>
              i.profile.id === profileId &&
              i.status !== InvitationStatus.AUTO_GENERATED
          )!;

          return (
            <li key={s.id} className={styles.slotItem}>
              <p>Conversation: {getTitle(s.workshop)}</p>
              <div className={styles.actions}>
                <p>Date: {formatSlotScheduleDate(s.schedule_date)}</p>
                <button
                  className="btn small secondary"
                  disabled={!!invitation}
                  onClick={() => rescheduleHandler(invitation.id, s)}
                >
                  Select
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }, [
    isRescheduleDialogLoadingSlots,
    isRescheduleDialogRescheduling,
    scheduledSlots,
    profileId,
    rescheduleHandler,
  ]);

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Reschedule</Dialog.Title>
          {dialogContent}
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
