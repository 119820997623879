import { memo, useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NoSlotsFound.module.css";

const NoSlotsFound = ({
  type,
  menu,
  trainers,
}: {
  type: "upcoming" | "completed";
  menu: "personal" | "team";
  trainers: string[];
}) => {
  const imageSrc = useMemo(
    () =>
      type === "upcoming"
        ? "/images/no-upcoming-slots-available.svg"
        : "/images/no-completed-slots-available.svg",
    [type]
  );

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={imageSrc} alt="No slots found" />
      </div>
      <div className={styles.textContainer}>
        <h2>You don’t have any {type} conversations!</h2>
        <p>
          Looks like you haven't attended any workshops yet. Reach out to your
          company trainers{" "}
          {!!trainers.length && (
            <>
              - at <span className="bold">{trainers.join(", ")}</span>
            </>
          )}{" "}
          or <span className="bold">admin@ahaplay.com</span> to schedule a
          workshop.
        </p>
        {type === "upcoming" && (
          <Link
            to={`/schedule/${menu === "personal" ? "my" : "team"}/history`}
            className={cn(styles.redirectBtn, "btn small")}
          >
            Go to history
          </Link>
        )}
      </div>
    </div>
  );
};

export default memo(NoSlotsFound);
