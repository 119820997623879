import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { SESSION_CONTEXT_RESULT_QUERY } from "../constants";

const mutation = gql`
  mutation Mutation($sessionId: String!, $connectionUUID: String!, $intended: Boolean, $observe: Boolean) {
    disconnect(sessionId: $sessionId, connectionUUID: $connectionUUID, intended: $intended, observe: $observe) {
      context ${SESSION_CONTEXT_RESULT_QUERY}
    }
  }
`;

export function disconnect(
  client: AppApolloClient,
  variables: {
    sessionId: string;
    intended?: boolean;
    connectionUUID: string;
    observe?: boolean;
  }
) {
  return client
    .mutate<any>({ mutation, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.disconnect;
    });
}
