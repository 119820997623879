import { PropsWithChildren, memo, useMemo } from "react";

import cn from "classnames";

import { getEntryId, getType } from "../../../../../../utils";

import {
  Objective,
  Workshop,
} from "../../../../../../types/contentful/workshop/workshop";
import {
  OpeningActivityFieldsLayout,
  OpeningActivityLayoutItemTypes,
} from "../../../../../../types/contentful/workshop/activities/opening";
import { SlotType } from "../../../../../../apollo-graphql/types/enums/slot-type";
import { Journey } from "../../../../../../types/contentful/workshop/journey";

import JourneyInformation from "../../../../JourneyInformation/JourneyInformation";
import { FetchImage } from "../../../../../Shared/FetchImage/FetchImage";
import ContentfulRichField from "../../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./WorkshopDetails.module.css";

export interface WorkshopDetailProps {
  author: { id: string; name: string; headline: string };
  layouts: OpeningActivityFieldsLayout[];
  workshop: {
    id: string;
    title: string;
    headline: string;
    objectives: Objective[];
    duration: number;
    author: {
      id: string;
      name: string;
      headline: string;
      bio: string;
      image: {
        url: string;
        fileName: string;
      };
    };
    journey: Journey;
  };
  journeyItems?: Workshop[] | null;
  invitedParticipantCount: number;
  slotType?: SlotType;
  profileId?: string;
}

function WorkshopDetails(
  props: PropsWithChildren<WorkshopDetailProps & { noInteraction: boolean }>
) {
  const {
    workshop,
    layouts,
    journeyItems,
    profileId,
    invitedParticipantCount,
    noInteraction,
  } = props;

  const authorContent = useMemo(() => {
    if (!workshop?.author) return null;

    return (
      <>
        <div className="text small secondary">Conversation by</div>

        <div className="author-container">
          {workshop.author.image?.url && (
            <div className={styles.imgContainer}>
              <FetchImage
                src={`https:${workshop.author.image.url}`}
                alt={workshop.author.image.fileName}
              />
            </div>
          )}

          <div className={styles.authorInfoContainer}>
            <h3 className="bold">{workshop.author.name}</h3>
            <div className={styles.authorInfo}>
              <ContentfulRichField
                content={workshop.author.bio}
                className="text"
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [workshop.author]);

  const journeyContent = useMemo(() => {
    return !(journeyItems && journeyItems.length > 1 && profileId) ? null : (
      <JourneyInformation
        currentWorkshopId={workshop?.id}
        journeyTitle={workshop?.journey?.fields?.title}
        image={
          workshop?.journey?.fields?.smallBannerImage?.fields?.file?.url || null
        }
        journeyItems={journeyItems}
        profileId={profileId}
        currentWorkshopTitle={workshop.title}
      />
    );
  }, [
    journeyItems,
    profileId,
    workshop?.id,
    workshop?.journey?.fields?.smallBannerImage?.fields?.file?.url,
    workshop?.journey?.fields?.title,
    workshop.title,
  ]);

  const headerContent = useMemo(() => {
    return (
      <div className={styles.topSection}>
        <h1>{workshop.title}</h1>
        <ContentfulRichField content={workshop.headline} className="text" />
      </div>
    );
  }, [workshop.headline, workshop.title]);

  const layoutContent = useMemo(() => {
    if (!layouts || layouts.length === 0)
      return <>MISSING DATA FOR THE LAYOUT</>;

    return layouts.map((layout) => {
      if (!layout || !layout.fields) return null;

      const id = getEntryId(layout);
      const { numberOfColumns = 1, items = [] } = layout.fields;
      if (items.length === 0) return null;

      let gridColumns = numberOfColumns;

      const shouldSubtract = gridColumns >= items?.length;
      const hasObjectives = workshop?.objectives?.length;
      const hasPeopleInvited = !!invitedParticipantCount;
      const hasAuthor = !!authorContent;
      const hasJourney = !!journeyContent;

      const itemsContent = items.map((item) => {
        if (!item || !item.fields) return null;

        const itemId = getEntryId(item);
        const itemType = getType<OpeningActivityLayoutItemTypes>(item);
        const { title, icon } = item.fields;

        const iconSrc = icon?.fields?.file?.url;
        const iconAlt = icon?.fields?.title;
        const hasIcon = !!(iconSrc && iconAlt);

        if (itemType === OpeningActivityLayoutItemTypes.Objectives) {
          if (!hasObjectives) {
            if (shouldSubtract) gridColumns--;
            return null;
          }

          return (
            <div key={itemId} className={styles.dataSection}>
              <h3 className={cn(styles.objectivesHeading, "bold")}>{title}</h3>
              <div className={styles.objectivesContainer}>
                {workshop.objectives.map((o) => {
                  return (
                    <div key={getEntryId(o)} className={styles.objective}>
                      {hasIcon && (
                        <img
                          className={styles.icon}
                          src={iconSrc}
                          alt={iconAlt}
                        />
                      )}
                      <ContentfulRichField
                        content={o.fields.text}
                        className="text"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }

        // TODO: update this check when the new type can be defined into contentful
        // if (itemType === OpeningActivityLayoutItemTypes.PeopleInvited)
        if (
          itemType === OpeningActivityLayoutItemTypes.Timing &&
          title === "People invited"
        ) {
          if (!hasPeopleInvited) {
            if (shouldSubtract) gridColumns--;
            return null;
          }

          return (
            <div
              key={itemId}
              className={cn(styles.dataSection, styles.metricsSection)}
            >
              <div className={cn(styles.dataSectionTitle, "text")}>
                {hasIcon && (
                  <img className={styles.icon} src={iconSrc} alt={iconAlt} />
                )}
                {title}
              </div>
              <div className={styles.statisticContainer}>
                <div className={cn(styles.participantsCount, "primary")}>
                  {invitedParticipantCount}
                </div>
                <span className="text small secondary">Users</span>
              </div>
            </div>
          );
        }

        if (itemType === OpeningActivityLayoutItemTypes.Timing) {
          return (
            <div
              key={itemId}
              className={cn(styles.dataSection, styles.metricsSection)}
            >
              <div className={cn(styles.dataSectionTitle, "text", "secondary")}>
                {hasIcon && (
                  <img className={styles.icon} src={iconSrc} alt={iconAlt} />
                )}
                {title}
              </div>
              <div className={styles.statisticContainer}>
                <div className={cn(styles.duration, "primary")}>
                  {workshop.duration}
                </div>
                <span className="text small secondary">Minutes</span>
              </div>
            </div>
          );
        }

        if (itemType === OpeningActivityLayoutItemTypes.Author) {
          if (!hasAuthor) {
            if (shouldSubtract) gridColumns--;
            return null;
          }
          return (
            <div key={itemId} className={styles.dataSection}>
              <div className={styles.authorContainer}>{authorContent}</div>
            </div>
          );
        }

        if (itemType === OpeningActivityLayoutItemTypes.Journey) {
          if (!hasJourney) {
            if (shouldSubtract) gridColumns--;
            return null;
          }
          return (
            <div key={itemId} className={styles.dataSection}>
              <div className={styles.authorContainer}>{journeyContent}</div>
            </div>
          );
        }

        return null;
      });

      return (
        <div
          key={id}
          style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
          className={styles.dataSections}
        >
          {itemsContent}
        </div>
      );
    });
  }, [
    authorContent,
    invitedParticipantCount,
    journeyContent,
    layouts,
    workshop.duration,
    workshop.objectives,
  ]);

  return (
    <div
      className={cn(
        styles.container,
        noInteraction && "no-interaction-section"
      )}
    >
      {headerContent}
      <div className={styles.dataSectionsContainer}>{layoutContent}</div>
    </div>
  );
}

export default memo(WorkshopDetails);
