import { ActivityComplexValueType } from "../../../../types/enums/activity-complex-value-type";
import { ActivityComplexValue } from "../../../../types/activity-complex-value";
import { parseToJson } from "../../../../utils";

export interface IMultiValue {
  [key: string]: string;
}

export const generateDefaultMultiValue = (
  keys: string[],
  isString?: boolean
): IMultiValue => {
  const defaultValue = isString ? "" : new Uint8Array([]);

  return keys.reduce((acc, curr) => {
    return Object.assign(acc, { [curr]: defaultValue });
  }, {} as IMultiValue);
};

export const generateDefaultComplexValue = (keys: string[]) => {
  return {
    value: JSON.stringify({ value: generateDefaultMultiValue(keys) }),
    type: ActivityComplexValueType.yjs,
  };
};

export const extractMultiValueComplexValue = (
  value: string,
  keys: string[],
  useDefaultValue?: boolean
) => {
  const complexValue = parseToJson<ActivityComplexValue | null>(
    value,
    !!useDefaultValue ? generateDefaultComplexValue(keys) : null
  );

  if (!complexValue || !complexValue.value.length) return null;

  return complexValue.value;
};

export const extractMultiValueResult = (
  value: string,
  keys: string[],
  isString?: boolean,
  useDefaultValue?: boolean
) => {
  return parseToJson<IMultiValue | null>(
    value,
    !!useDefaultValue ? generateDefaultMultiValue(keys, isString) : null
  );
};
