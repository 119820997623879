import * as RadixSelect from "@radix-ui/react-select";
import {
  CheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@radix-ui/react-icons";

import styles from "./Select.module.css";
import cn from "classnames";
import { ReactNode, useMemo } from "react";


export enum SelectType {
  DEFAULT = "default",
  GROUPING = "grouping",
}

interface SelectProps {
  options: { label: string; value: string; key: string; disabled?: boolean }[];
  value: string | null;
  onChange?: (value: string) => void;
  label: string;
  labelNode?: ReactNode;
  className?: string;
  type?: SelectType;
  disabled?: boolean;

  extraStyles?: {
    trigger?: React.CSSProperties;
    // content?: React.CSSProperties;
    // item?: React.CSSProperties;
    // indicator?: React.CSSProperties;
    // scrollButton?: React.CSSProperties;
    // viewPort?: React.CSSProperties;
  };
}
export const Select = (props: SelectProps) => {
  const {
    label,
    labelNode,
    options,
    value,
    className,
    disabled,
    type = SelectType.DEFAULT,
    extraStyles = {},
    onChange,

  } = props;

  const typeClassName = useMemo(() => `type-${type}`, [type]);
  const showSelectedItemIndicator = useMemo(() => type === SelectType.DEFAULT, [type]);

  return (
    <RadixSelect.Root
      value={value || undefined}
      onValueChange={onChange}
      disabled={disabled}
    >
      <RadixSelect.Trigger
        className={cn(styles.trigger, typeClassName, className)}
        style={extraStyles.trigger}
      >
        <RadixSelect.Value placeholder={label} />
        {labelNode}
        <RadixSelect.Icon className={styles.selectIcon}>
          <ChevronDownIcon />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>

      <RadixSelect.Portal>
        <RadixSelect.Content className={styles.content}>
          <RadixSelect.ScrollUpButton className={styles.scrollButton}>
            <ChevronUpIcon />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport className={styles.viewPort}>
            {options.map((option) => (
              <RadixSelect.Item
                className={cn(styles.item, typeClassName, option.value === value && "selected")}
                key={option.key}
                value={option.value}
                disabled={option.disabled}
              >
                <RadixSelect.ItemText>{option.label}</RadixSelect.ItemText>
                {showSelectedItemIndicator && <RadixSelect.ItemIndicator className={styles.indicator}>
                  <CheckIcon />
                </RadixSelect.ItemIndicator>}
              </RadixSelect.Item>
            ))}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className={styles.scrollButton}>
            <ChevronDownIcon />
          </RadixSelect.ScrollDownButton>
          <RadixSelect.Arrow />
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
};
