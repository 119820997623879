import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { GET_WORKSHOP_QUERY, WORKSHOP_JOURNEY_QUERY, WORKSHOP_JOURNEY_WITH_WORKSHOPS_QUERY } from "../constants";
import { Workshop } from "../../types/contentful/workshop/workshop";
import { Journey } from "../../types/contentful/workshop/journey";

const getJourneyWorkshopsQuery = gql`
  query GetJourneyWorkshops($journeyId: String!) {
    getContentfulJourneyWorkshops(journeyId: $journeyId) {
      ${GET_WORKSHOP_QUERY}
    }
  }
`;

const getJourneysQuery = gql`
  query GetJourneys {
    getContentfulJourneys ${WORKSHOP_JOURNEY_QUERY}
  }
`;

const getJourneysWithWorkshopsQuery = gql`
  query GetJourneys {
    getContentfulJourneys ${WORKSHOP_JOURNEY_WITH_WORKSHOPS_QUERY}
  }
`;

const getJourneyQuery = gql`
  query GetJourney($journeyId: String!) {
    getContentfulJourney(journeyId: $journeyId) ${WORKSHOP_JOURNEY_QUERY}
  }
`;

const getJourneyWithWorkshopsQuery = gql`
  query GetJourney($journeyId: String!) {
    getContentfulJourney(journeyId: $journeyId) ${WORKSHOP_JOURNEY_WITH_WORKSHOPS_QUERY}
  }
`;

// Get all workshops for a specific journey
export function getJourneyWorkshops(
  client: AppApolloClient,
  variables: { journeyId: string }
) {
  return client
    .query<{ getContentfulJourneyWorkshops: Workshop[] }>({
      query: getJourneyWorkshopsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getContentfulJourneyWorkshops;
    });
}

// Get all journeys
export function getJourneys(
  client: AppApolloClient,
  variables: { includeWorkshops: boolean }
) {
  return client
    .query<{ getContentfulJourneys: Journey[] }>({
      query: variables.includeWorkshops ? getJourneysWithWorkshopsQuery : getJourneysQuery,
      variables, // Ensure variables are passed correctly
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getContentfulJourneys;
    });
}

// Get journey item
export function getJourney(
  client: AppApolloClient,
  variables: { journeyId: string, includeWorkshops: boolean }
) {
  return client
    .query<{ getContentfulJourney: Journey }>({
      query: variables.includeWorkshops ? getJourneyWithWorkshopsQuery : getJourneyQuery,
      variables, // Ensure variables are passed correctly
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);

      return result.data.getContentfulJourney;
    });
}