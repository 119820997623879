const BASE_OFFSET = 24;

export const calculateOffset = (index: number, count: number) => {
  const half = count / 2;
  if (index < half) {
    return index * BASE_OFFSET;
  }
  return (count - index - 1) * BASE_OFFSET;
};

export const calculateOffsetForOffsetPattern = (index: number, pattern: number[]) => {
  const offsetIndex = index % pattern.length;

  return pattern[offsetIndex];
};

const computeArrowStyles = (
  goingRight: boolean,
  transformAngle: number,
  nextIsLocked: boolean
) => {
  let transform = `rotate(${goingRight ? '-' : ''}${transformAngle}deg)`;
  let left = goingRight ? 45 : 20;
  let height = 24;
  let bottom = -24;

  if (nextIsLocked) {
    height += 3;
    bottom -= 2;
  }

  return { transform, left, height, bottom };
};

export const calculateArrowStyles = (
  index: number,
  count: number,
  nextIsLocked: boolean
) => {
  const half = count / 2;
  const goingRight = index < half - 1;
  const goingDown = count % 2 === 0 && index === half - 1;
  const transformAngle = 18;

  if (goingDown) {
    return {
      transform: "rotate(0deg)",
      left: 30,
      height: 24,
      bottom: -24,
    };
  }

  return computeArrowStyles(
    goingRight,
    transformAngle,
    nextIsLocked
  );
};

export const calculateArrowStylesSpiralPattern = (
  index: number,
  nextIsLocked: boolean
) => {
  const goingRight = Math.floor(index / 3) % 2 === 0;
  const transformAngle = 18;

  return computeArrowStyles(
    goingRight,
    transformAngle,
    nextIsLocked,
  );
};

