import { PropsWithChildren, memo } from "react";
import Loader from "../../Loader/Loader";
import { ButtonVariant } from "../../../../types/buttons";
import withLoadingDelay, { LoadingDelay } from "../../../../hocs/withLoadingDelay";

import cn from "classnames";

type Button3DProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariant;
  hasLoader?: boolean;
  type?: "button" | "reset" | "submit";
}> &
  LoadingDelay;

const Button3D = (props: Button3DProps) => {
  const {
    className,
    disabled,
    variant,
    onClick,
    children,
    type = "button",
    isLoading = false,
    hasLoader = true
  } = props;

  return (
    <button
      className={cn("btn", "dbl", className, variant || "primary")}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
    >
      <div className={cn("front", isLoading && "loading")}>
        {children}
        {hasLoader && isLoading && <Loader className="loading-container" />}
      </div>
    </button>
  );
};

export default memo(withLoadingDelay(Button3D));
