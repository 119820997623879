import { Profile } from "../../../apollo-graphql/types";
import { createAction, props } from "../../utils";

export const init = createAction(
  "[Admin Dashboard Set Leaders Dialog] Init",
  props<{ workspaceId: string }>()
);

export const setLeaders = createAction(
  "[Admin Dashboard Set Leaders Dialog] Set Leaders",
  props<{ journeyLeaders: Profile[]; journeyId: string; workspaceId: string }>()
);
