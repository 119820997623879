import {
  PropsWithChildren,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { TechnicalSetupHelpOutcome } from "../../+xstate/machines/auth";
import { SourceData } from "../../types/source-data";

import { VideoPreview } from "../VideoPreview/VideoPreview";
import Button3D from "../Shared/Buttons/Button3D/Button3D";
import SkeletonLoader from "../Shared/SkeletonLoader/SkeletonLoader";
import InfoBox from "../InfoBox/InfoBox";

import styles from "./JitsiSetup.module.css";
import cn from "classnames";

export const JitsiSetup = memo(
  forwardRef(
    (
      props: PropsWithChildren<{
        profileId: string;
        isLoading: boolean;
        setupComplete: boolean;
        selectedAudioSourceData: SourceData | null;
        selectedVideoSourceData: SourceData | null;
        availableAudioSources: SourceData[] | null;
        availableVideoSources: SourceData[] | null;
        audioAndVideoConfigureDone: boolean;
        technicalSetupHelpOutcome: TechnicalSetupHelpOutcome | null;
        toggleAudioHandler: () => void;
        toggleVideoHandler: () => void;
        setSetupCompleteHandler: () => void;
        technicalSetupHelpHandler: () => void;
        audioDeviceChangeHandler: (newSourceId: string) => void;
        videoDeviceChangeHandler: (newSourceId: string) => void;
      }>,
      ref: React.ForwardedRef<HTMLVideoElement | null>
    ) => {
      const {
        profileId,
        isLoading,
        selectedAudioSourceData,
        selectedVideoSourceData,
        availableAudioSources,
        availableVideoSources,
        audioAndVideoConfigureDone,
        setupComplete,
        toggleAudioHandler,
        toggleVideoHandler,
        setSetupCompleteHandler,
        audioDeviceChangeHandler,
        videoDeviceChangeHandler,
        technicalSetupHelpHandler,
      } = props;

      const setupCompleteTakesTooLongTimerId = useRef<null | number>(null);
      const [
        showCompleteTakesTooLongMessage,
        setShowCompleteTakesTooLongMessage,
      ] = useState(false);

      useEffect(() => {
        if (!setupComplete) return;

        setupCompleteTakesTooLongTimerId.current = setTimeout(() => {
          setShowCompleteTakesTooLongMessage(true);
        }, 5000) as unknown as number;

        return () => {
          if (setupCompleteTakesTooLongTimerId.current === null) return;
          clearTimeout(setupCompleteTakesTooLongTimerId.current);
        };
      }, [setupComplete]);

      const issuesContent = useMemo(() => {
        return (
          <div className={cn(styles.issues, "text")}>
            <p>Experiencing issues with the setup?</p>
            <p
              className={styles.issuesSkipAction}
              onClick={technicalSetupHelpHandler}
            >
              Skip and ask for help!
            </p>
          </div>
        );
      }, [technicalSetupHelpHandler]);

      const content = useMemo(() => {
        return (
          <>
            <div
              className={cn(
                styles.loaderContent,
                (!isLoading || setupComplete) && "hidden"
              )}
            >
              <SkeletonLoader
                className={styles.loaderVideo}
                borderRadius={16}
              />
              <SkeletonLoader
                className={styles.loaderActions}
                width={120}
                height={32}
                baseColor="#f9f9f9"
              />
              <SkeletonLoader width={390} height={115} borderRadius={16} />
            </div>
            <VideoPreview
              className={cn(
                styles.videoContent,
                isLoading && !setupComplete && "hidden"
              )}
              ref={ref}
              width={390}
              height={390}
              profileId={profileId}
              isLoading={isLoading && !setupComplete}
              selectedAudioDevice={selectedAudioSourceData}
              availableAudioSources={availableAudioSources}
              selectedVideoDevice={selectedVideoSourceData}
              availableVideoSources={availableVideoSources}
              hideConfigurations={false}
              audioAndVideoConfigureDone={audioAndVideoConfigureDone}
              audioDeviceChangeHandler={audioDeviceChangeHandler}
              videoDeviceChangeHandler={videoDeviceChangeHandler}
              toggleAudioHandler={toggleAudioHandler}
              toggleVideoHandler={toggleVideoHandler}
            />
          </>
        );
      }, [
        isLoading,
        setupComplete,
        ref,
        profileId,
        selectedAudioSourceData,
        availableAudioSources,
        selectedVideoSourceData,
        availableVideoSources,
        audioAndVideoConfigureDone,
        audioDeviceChangeHandler,
        videoDeviceChangeHandler,
        toggleAudioHandler,
        toggleVideoHandler,
      ]);

      const actionsContent = useMemo(() => {
        return isLoading ? (
          <SkeletonLoader width={390} height={55} borderRadius={16} />
        ) : (
          <div className={styles.actionContainer}>
            <InfoBox
              title="Intellectual Property Alert"
              description="By joining this conversation you acknowledge and agree that recording, replication, or distribution of any part of the platform, it’s content, materials, and user experience is strictly forbidden and may result in legal action."
            />
            <Button3D
              disabled={setupComplete || !selectedAudioSourceData}
              variant="success"
              onClick={setSetupCompleteHandler}
              isLoading={setupComplete}
            >
              {setupComplete ? `Processing` : `Let's start`}
            </Button3D>
            {issuesContent}
          </div>
        );
      }, [
        isLoading,
        setupComplete,
        selectedAudioSourceData,
        setSetupCompleteHandler,
        issuesContent,
      ]);

      return (
        <div className={styles.container}>
          <div className={styles.inner}>
            <h1>Device Setup</h1>
            <div className={styles.content}>{content}</div>
            {showCompleteTakesTooLongMessage && (
              <h3>
                We're just making sure everything is perfect for your
                experience. Please bear with us for a few seconds.
              </h3>
            )}
            {actionsContent}
          </div>
        </div>
      );
    }
  )
);
