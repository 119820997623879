import cn from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import { v4 } from "uuid";

import {
  activityTypeFooterTextFactoryMap,
  deserializeUint8Array,
  getEntryId,
  getTransitionData,
  parseToJson,
} from "../../../../utils";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { ActivityCommon } from "../../../../types/activity-common";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { OpenQuestionActivity } from "../../../../types/contentful/workshop/activities/open-question";
import { ActionFooterType } from "../../../../types/action-footer";
import { observerFooterData } from "../constants";
import { OutcomeMode, WorkMode } from "../../../../types/contentful/enums";
import { FooterType } from "../../../../types/enums/activity-footer";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";

import CaseStudy from "../../../Shared/CaseStudy/CaseStudy";
import Textarea from "../../../Shared/Textarea/Textarea";
import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";

import { ActivityComplexValue } from "../../../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../../../types/enums/activity-complex-value-type";
import { useDocMap } from "../../../../hooks/useDocMap";
import { useAwareness } from "../../../../hooks/useAwareness";
import { Profile } from "../../../../apollo-graphql/types";
import Editor from "../../../Shared/Editor/Editor";
import { useEditorClass } from "../../../../hooks/useEditorClass";
import { getOpenQuestionAnswers } from "../utils/activity-helper";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import AssignmentTitle from "../../../Shared/AssignmentTitle/AssignmentTitle";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";

import styles from "./OpenQuestion.module.css";

const editorKey = "openQuestion";
const editorDeps = [editorKey] as const;

interface OpenQuestionProps extends ActivityCommon {
  profile: Profile;
  currentActiveParticipants: string[];
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
    markAsReady?: boolean;
  }) => void;
  isViewResults: boolean;
  allActivitiesResult: IActivityResult[];
}

const OpenQuestion = (props: OpenQuestionProps) => {
  const {
    activity: rawActivity,
    activityResults,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    activityResultForCurrentProfile,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    nextActivity,
    hasAhaMoments,
    profile,
    allActivitiesResult,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;
  const activity = rawActivity as OpenQuestionActivity;

  const { awarenessState, onAwarenessUpdate } = useAwareness(editorDeps);
  const { docs, generateComplexResultValueAsString } = useDocMap(editorDeps);

  const answerId = useMemo(() => {
    const newId = v4();
    if (!activityResultForCurrentProfile) return newId;
    try {
      return JSON.parse(activityResultForCurrentProfile.value).id;
    } catch (e) {
      return newId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStateValue = useMemo(() => {
    if (!activityResultForCurrentProfile) return "";

    try {
      return JSON.parse(activityResultForCurrentProfile.value).value;
    } catch (e) {
      return "";
    }
  }, [activityResultForCurrentProfile]);

  const [currentValue, setCurrentValue] = useState(currentStateValue);
  const {
    instructions,
    textareaPlaceholder,
    questionInstructions,
    questionTitle,
    questionDescription,
    referenceTitle,
  } = useMemo(() => {
    const { fields } = activity;

    return {
      instructions: fields.activity.fields.instructions,
      textareaPlaceholder: fields.placeholderText,
      questionInstructions: fields.questionInstructions,
      questionTitle: fields.questionTitle,
      questionDescription: fields.questionDescription,
      referenceTitle: fields.referenceTitle,
    };
  }, [activity]);
  const actionFooterDataRef = useRef<ActionFooterType>();
  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const isConsensusMode = useMemo(
    () => activity.fields.activity.fields.outcomeMode === OutcomeMode.Consensus,
    [activity]
  );
  const isSynchronised = useMemo(
    () => activity.fields.activity.fields.workMode === WorkMode.Synchronised,
    [activity]
  );

  const activityData = useMemo(
    () => ({
      id: getEntryId(activity),
      referenceActivity: activity.fields.referenceActivity,
      referenceValueLabel: activity.fields.referenceValueLabel,
    }),
    [activity]
  );
  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isLoading && actionFooterDataRef.current)
      return actionFooterDataRef.current;
    if (isTransitioning) {
      actionFooterDataRef.current = getTransitionActionFooterData({
        text: "Everyone is ready. Continuing forward...",
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
      return actionFooterDataRef.current;
    }

    if (!isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      const playerHasAnswered =
        !!currentValue &&
        activityResultForCurrentProfile?.value !== ACTIVITY_TIMEOUT_VALUE;

      actionFooterDataRef.current = {
        text: activityTypeFooterTextFactoryMap[
          WorkshopActivityType.OpenQuestion
        ](playersClicked),
        buttonText: "Continue",
        disabledButton: false,
        type: FooterType.Notice,
      };

      if (!isConsensusMode) {
        actionFooterDataRef.current.disabledButton = !playerHasAnswered;
        actionFooterDataRef.current.text = playerHasAnswered ? (
          <>
            Click on “<span className="accent green">Continue</span>” to submit
            your individual opinion.
            {playersClicked > 0 && (
              <>
                {" "}
                <span className="accent">
                  {playersClicked} player{playersClicked > 1 && "s"} clicked.
                </span>
              </>
            )}
          </>
        ) : (
          activityTypeFooterTextFactoryMap[WorkshopActivityType.OpenQuestion](
            playersClicked
          )
        );

        actionFooterDataRef.current.buttonText = "Continue";
      }

      return actionFooterDataRef.current;
    }

    actionFooterDataRef.current = {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: !isConsensusMode ? "Go to discussion" : "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
    return actionFooterDataRef.current;
  }, [
    isParticipating,
    isLoading,
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    isConsensusMode,
    isActivityTimeout,
    currentActiveParticipantCount,
    currentValue,
    activityResultForCurrentProfile?.value,
  ]);

  const debouncedSetActivityValue = useMemo(
    () =>
      debounce((value: string, isSynchronised = false) => {
        setActivityValueHandler({
          activityId: activityData.id,
          value: isSynchronised
            ? value
            : JSON.stringify({
                id: answerId,
                value,
              }),
        });
      }, 200),
    [activityData.id, answerId, setActivityValueHandler]
  );

  const referenceValue = useMemo(() => {
    const referenceActivityContentTypeId =
      activityData.referenceActivity?.sys?.contentType?.sys?.id;

    if (
      referenceActivityContentTypeId ===
      WorkshopActivityType.ClosedQuestionActivity
    ) {
      const closedQuestionAnswer = allActivitiesResult?.find(
        (a) => a.key === activity.fields.referenceActivity.sys.id
      )?.value;
      const openQuestionAnswers = getOpenQuestionAnswers(
        activity,
        allActivitiesResult
      );
      const closedQuestionAnswerResult =
        closedQuestionAnswer?.[0]?.value || null;
      const parsedClosedQuestionAnswer = closedQuestionAnswerResult
        ? parseToJson(closedQuestionAnswerResult, {
            data: { playerSelectedValues: {} as { [key: string]: string } },
            value: "",
          })
        : { data: { playerSelectedValues: {} }, value: "" };

      if (
        parsedClosedQuestionAnswer.value ||
        parsedClosedQuestionAnswer.data.playerSelectedValues
      ) {
        const playerSelectedValues =
          parsedClosedQuestionAnswer.data.playerSelectedValues;
        const groupSelectedValue =
          playerSelectedValues[Object.keys(playerSelectedValues)[0]];
        const selectedOpenAnswer = openQuestionAnswers?.find((answer) =>
          answer.value.includes(groupSelectedValue)
        );
        if (selectedOpenAnswer?.value) {
          return parseToJson(selectedOpenAnswer.value, { id: "", value: "" })
            ?.value;
        }
        if (!parsedClosedQuestionAnswer?.value) return "";

        return parseToJson(parsedClosedQuestionAnswer.value, {
          groupDefinition: "",
        }).groupDefinition;
      }

      return null;
    }

    if (referenceActivityContentTypeId !== WorkshopActivityType.OpenQuestion)
      return null;

    const referenceActivityResults = allActivitiesResult?.find(
      (result) => result.key === activityData.referenceActivity!.sys.id
    );
    const currentUserResults = referenceActivityResults?.value.find(
      (result) => result.profileId === profile.id
    );

    if (!currentUserResults) return null;

    try {
      const parsedUserResults = JSON.parse(currentUserResults!.value);
      return parsedUserResults.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [activityData, allActivitiesResult, profile.id, activity]);

  const editorClass = useEditorClass({
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
  });

  const synchronizedValue = useMemo(
    () => activityResults?.[0]?.value,
    [activityResults]
  );
  const questionData = useMemo(() => {
    if (!synchronizedValue?.includes(`"value":"${editorKey}:[`)) return null;
    try {
      return JSON.parse(synchronizedValue) as ActivityComplexValue;
    } catch {
      console.error("Cannot parse synchronizedValue to extract data");
      return null;
    }
  }, [synchronizedValue]);

  const questionUIntValue = useMemo(() => {
    if (questionData === null) return new Uint8Array([]);
    const value = questionData.value.split(":")[1];
    return deserializeUint8Array(value) || new Uint8Array([]);
  }, [questionData]);

  const onSynchronizedValueUpdate = useCallback(() => {
    const activityComplexValue: ActivityComplexValue = {
      value: generateComplexResultValueAsString(),
      type: ActivityComplexValueType.yjs,
    };

    debouncedSetActivityValue(JSON.stringify(activityComplexValue), true);
  }, [debouncedSetActivityValue, generateComplexResultValueAsString]);

  return (
    <>
      <ScrollIndicator key={activityData.id} className="activity-container">
        <div className={cn(styles.container, "main-container")}>
          <div className={styles.leftWrapper}>
            <AssignmentTitle />
            {referenceTitle ? (
              <>
                <ContentfulRichField
                  content={questionInstructions}
                  className={cn(styles.referenceInstructions)}
                />
                <div className={styles.referenceTitle}>{referenceTitle}</div>
                <div className={styles.referenceAnswer}>
                  <div className={styles.referenceAnswerTitle}>
                    Aligned strategy:
                  </div>
                  <div className={styles.groupAlignmentValue}>
                    {referenceValue}
                  </div>
                </div>
              </>
            ) : (
              <CaseStudy
                questionInstructions={questionInstructions}
                questionTitle={questionTitle}
                questionDescription={questionDescription}
              />
            )}
          </div>
          <div className={styles.inputContainer}>
            <ContentfulRichField
              className={cn(styles.instructions, "text")}
              content={instructions}
            />
            {(!isSynchronised || isViewResults) && (
              <Textarea
                className={styles.textArea}
                disabled={!!isViewResults}
                onInput={({ target: { value } }) => {
                  if (!!isViewResults) return;
                  setCurrentValue(value);
                  debouncedSetActivityValue(value);
                }}
                isLoading={false}
                value={currentValue}
                placeholder={textareaPlaceholder}
              />
            )}
            {isSynchronised && !isViewResults && (
              <div className={styles.editorWrapper}>
                <Editor
                  doc={docs[editorKey]}
                  name={editorKey}
                  key={editorKey}
                  onValueUpdate={onSynchronizedValueUpdate}
                  onAwarenessUpdate={onAwarenessUpdate}
                  value={questionUIntValue as Uint8Array}
                  awareness={awarenessState[0]?.awareness}
                  profileName={profile.name}
                  placeholder={textareaPlaceholder}
                  isInput={false}
                  className={editorClass}
                />
              </div>
            )}
            {referenceValue && !referenceTitle && (
              <div className={styles.referenceValueContainer}>
                {activityData.referenceValueLabel && (
                  <p className={cn(styles.referenceValueLabel, "text")}>
                    {activityData.referenceValueLabel}{" "}
                    <span className="faded">(visible only to you)</span>
                  </p>
                )}
                <Textarea
                  className={styles.textArea}
                  onInput={() => {}}
                  isLoading={false}
                  value={referenceValue}
                  disabled
                  placeholder={textareaPlaceholder}
                />
              </div>
            )}
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default OpenQuestion;
