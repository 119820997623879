import { useMemo, useState } from "react";

import * as Collapsible from "@radix-ui/react-collapsible";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import ToggleChevron from "../ToggleChevron/ToggleChevron";

import cn from "classnames";
import ContentfulRichField from "../ContentfulRichField/ContentfulRichField";

import styles from "./CaseStudy.module.css";

interface CaseStudyProps {
  questionInstructions?: string;
  questionTitle: string;
  questionDescription: string;
  videoUrl?: string;
}

const CaseStudy = ({
  questionInstructions,
  questionTitle,
  questionDescription,
  videoUrl,
}: CaseStudyProps) => {
  const isQuestionDescriptionCollapsible = useMemo(
    () => videoUrl && questionDescription,
    [questionDescription, videoUrl]
  );
  const [expanded, setExpanded] = useState(
    isQuestionDescriptionCollapsible ? false : true
  );

  const hasQuestionDetails = useMemo(
    () => !!questionTitle && (questionDescription || videoUrl),
    [questionDescription, questionTitle, videoUrl]
  );

  return (
    <div className={styles.container}>
      {questionInstructions && (
        <ContentfulRichField
          content={questionInstructions}
          className={styles.questionInstructions}
        />
      )}
      {hasQuestionDetails && (
        <div className={styles.questionDetails}>
          <h3 className={styles.questionTitle}>
            Case Study - "{questionTitle}":
          </h3>
          {videoUrl && (
            <VideoPlayer src={videoUrl} description="Case study video" />
          )}
          <Collapsible.Root
            open={expanded}
            onOpenChange={() => setExpanded(!expanded)}
            className={styles.collapsibleContainer}
          >
            {isQuestionDescriptionCollapsible && (
              <Collapsible.Trigger className={styles.collapsibleTrigger}>
                <div className={styles.questionDescriptionTitle}>
                  <span className="text">
                    Open this scenario <b>in textual format</b>
                  </span>
                  <ToggleChevron
                    expanded={expanded}
                    // onClick={() => setExpanded(!expanded)}
                    containerClass={styles.questionDescriptionTitleChevron}
                  />
                </div>
              </Collapsible.Trigger>
            )}
            {!!questionDescription && (
              <Collapsible.Content
                className={styles.collapsibleContentContainer}
              >
                <ContentfulRichField
                  content={questionDescription}
                  className={cn(
                    styles.questionDescription,
                    isQuestionDescriptionCollapsible && "collapsible"
                  )}
                />
              </Collapsible.Content>
            )}
          </Collapsible.Root>
        </div>
      )}
    </div>
  );
};

export default CaseStudy;
