import { memo, useContext, useEffect, useMemo } from "react";
import cn from "classnames";
import { useMachine } from "@xstate/react";
import withRouteConfig from "../../../../hocs/withRouteConfig";
import { AdminDashboardContext } from "../../../../contexts/AdminDashboard";
import { useChildMachine } from "../../../../hooks/useChildMachine";
import { getJourneysMachine } from "../../../../+xstate/machines/dashboard/admin-dashboard";
import { FetchState } from "../../../../+xstate/machines/fetch-factory";
import { enterAdminJourneys } from "../../../../+xstate/actions/dashboard/admin-dashboard";
import { GlobalContext } from "../../../../contexts/Global";
import JourneyListItem from "./components/JourneyListItem/JourneyListItem";
import styles from "./JourneyList.module.css";
import itemStyles from "./components/JourneyListItem/JourneyListItem.module.css";
import SkeletonLoader from "../../../Shared/SkeletonLoader/SkeletonLoader";
import { JOURNEYS_DISABLED } from "../../../../constants/global";
import { Navigate } from "react-router-dom";

const JourneyListLoading = () => (
  <div className={itemStyles.journeySection}>
    <SkeletonLoader count={1} height={400} width={620} borderRadius={16} />
    <div className={itemStyles.journeyCard}>
      <div className={styles.skeletons}>
        <SkeletonLoader
          containerClassName={styles.upper}
          count={2}
          height={40}
          borderRadius={16}
        />
        <SkeletonLoader height={280} borderRadius={16} />
      </div>
    </div>
  </div>
);

const JourneyList = () => {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);
  const { state: adminDashboardState, send: adminDashboardSend } = useContext(
    AdminDashboardContext
  );

  const [getJourneysState] = useChildMachine(
    adminDashboardState,
    getJourneysMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof getJourneysMachine>>;

  useEffect(() => {
    adminDashboardSend(enterAdminJourneys());
  }, [adminDashboardSend]);

  const journeys = useMemo(
    () => adminDashboardState.context.journeys || [],
    [adminDashboardState.context.journeys]
  );

  const journeysLoading = useMemo(
    () =>
      getJourneysState ? getJourneysState.matches(FetchState.Fetching) : true,
    [getJourneysState]
  );

  if (JOURNEYS_DISABLED) {
    return <Navigate to="/workshops" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.headingText}>
        <h3>Welcome, {profile?.name ? `${profile.name}` : ""}</h3>
        <h3>Here are some recommended journeys for your teams:</h3>
      </div>

      <div className={styles.journeysListBody}>
        {journeysLoading
          ? [...Array(3).keys()].map((i) => (
              <JourneyListLoading key={`skeleton-${i}`} />
            ))
          : journeys.map((journey) => (
              <JourneyListItem
                journey={journey}
                profile={profile}
                key={journey.sys.id}
              />
            ))}
      </div>

      <div className={styles.trainingMethodologies}>
        <h2>
          On the way to bring your teams some of the most requested corporate
          training methodologies:
        </h2>
        <div className={styles.trainings}>
          {[...Array(12).keys()].map((i) => (
            <div
              className={styles.trainingPlaceholder}
              key={`placeholder-${i}`}
            ></div>
          ))}
        </div>
        <button className={cn("btn small", styles.requestBtn)}>
          Request another training methodology{" "}
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default memo(withRouteConfig(JourneyList));
