import {
  SessionStateValue,
  CompletedSessionStateValue,
} from "../apollo-graphql/types/session-state";
import { parseToJson } from "./parse-to-json";

export const parseSession = (state: string) => {
  const { context, value } = parseToJson<
    CompletedSessionStateValue | { context: undefined; value: undefined }
  >(state, { context: undefined, value: undefined }, "Error parsing for parseSession function");

  return {
    value,
    context: {
      ...(context || {}),
      activityResult: Object.entries(context?.activityResult || {}).map(
        ([key, value]) => ({ key, value })
      ),
    },
  } as SessionStateValue;
};
