import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./VideoPlayer.module.css";
import cn from "classnames";

export default memo(function VideoPlayer(
  props: PropsWithChildren<{
    src: string;
    description: string;
    showPreview?: boolean;
  }>
) {
  const { src, description, showPreview } = props;

  const supportedVideoExt = useMemo(() => ["mp4", "m4v"], []);
  const videoSizeThreshold: number = 640; // below this size the play button becomes smaller

  const videoRef = useRef<HTMLVideoElement>(null);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [buttonSizeClass, setButtonSizeClass] = useState("large");

  const handlePlay = useCallback(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          setControlsVisible(true);
          setShowPlayButton(false);
        })
        .catch((error) => {
          console.error("Error attempting to play the video:", error);
        });
    }
  }, []);

  const handleVideoPlay = useCallback(() => {
    setControlsVisible(true);
    setShowPlayButton(false);
  }, []);

  const handleVideoPause = useCallback(() => {
    setShowPlayButton(true);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateButtonSize = () =>
      setButtonSizeClass(
        video.offsetWidth <= videoSizeThreshold ? styles.small : styles.large
      );
    // update play button size initially
    updateButtonSize();
    // add event listeners
    window.addEventListener("resize", updateButtonSize);
    video.addEventListener("play", handleVideoPlay);
    video.addEventListener("pause", handleVideoPause);
    // clean up event listeners
    return () => {
      window.removeEventListener("resize", updateButtonSize);
      video.removeEventListener("play", handleVideoPlay);
      video.removeEventListener("pause", handleVideoPause);
    };
  }, [handleVideoPause, handleVideoPlay]);

  const isMp4Url = useMemo(() => {
    try {
      const urlObject = new URL(src);
      const pathname = urlObject.pathname;
      const extension = pathname.split(".").pop()?.toLowerCase() || "";
      return supportedVideoExt.includes(extension);
    } catch (e) {
      console.error("Invalid URL:", e);
      return false;
    }
  }, [src, supportedVideoExt]);

  return isMp4Url ? (
    <div
      className={cn(
        styles.videoContainer,
        "video-player-container",
        !controlsVisible ? "videoControlsHidden" : ""
      )}
      onClick={handlePlay}
    >
      {showPlayButton && (
        <button
          className={cn(
            styles.customPlayButton,
            "fa-solid fa-circle-play",
            buttonSizeClass
          )}
          onClick={(e) => {
            e.stopPropagation();
            handlePlay();
          }}
        />
      )}
      <video
        ref={videoRef}
        title={description}
        poster={showPreview ? `${src}.preview` : undefined}
        controls={controlsVisible}
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  ) : (
    <div className={styles.iframeContainer}>
      <iframe title={description} src={src} allowFullScreen></iframe>
    </div>
  );
});
