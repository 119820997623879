import { Workspace } from "../../../../apollo-graphql/types/workspace";
import { Certificate } from "../../../../apollo-graphql/types/certificate";
import { JourneyParticipationData } from "../../../../apollo-graphql/types/journey-participation-data";
import { JourneyWorkspace } from "../../../../apollo-graphql/types/journeyWorkspace";
import { Journey } from "../../../../types/contentful/workshop/journey";
import { JourneyStatus } from "../../../../types/enums/journey-status";
import { Workshop } from "../../../../types/contentful/workshop/workshop";

export interface Aggregate {
  quantity: number;
  description?: string;
}

export const calculateJourneyDuration = (journey: Journey) => {
  const durationInMinutes =
    journey?.fields?.workshops?.reduce(
      (duration, workshop) => duration + (workshop.fields.duration || 0),
      0
    ) || 0;

  return parseFloat((durationInMinutes / 60).toFixed(2));
};

export const calculateAhaMomentsCount = (workshops?: Workshop[]) =>
  workshops?.reduce(
    (sum, workshop) => sum + (workshop.fields?.ahaMoments?.length || 0),
    0
  ) || 0;

export const calculateBehavioursCount = (workshops?: Workshop[]) =>
  workshops?.reduce(
    (sum, workshop) =>
      sum +
      workshop.fields?.ahaMoments?.reduce(
        (behavioursSum, ahaMoment) =>
          behavioursSum + ahaMoment.fields?.behaviours?.length,
        0
      ),
    0
  ) || 0;

export const getJourneyType = (authorName: string, workspaceName: string) => {
  if (authorName === "AhaPlay") {
    return "by AhaPlay";
  } else {
    return authorName === workspaceName ? `by ${workspaceName}` : "Masterclass";
  }
};

const findJourneyFromWorkspace = (workspace: Workspace, journeyId: string) => {
  const journeyResults = workspace.journeyParticipationData?.journeyResults;

  const journey = journeyResults?.find(
    ({ journey }) => journey.sys?.id === journeyId
  );

  return journey;
};

export const calculateJourneyParticipants = (
  workspace: Workspace,
  journeyId: string
): number => {
  const journey = findJourneyFromWorkspace(workspace, journeyId);
  if (!journey) {
    return 0;
  }

  return (
    journey.workshopParticipation?.reduce(
      (total, { profiles }) => total + (profiles?.length ?? 0),
      0
    ) || 0
  );
};

export const calculateCompletedConversations = (
  workspace: Workspace,
  journeyId: string
): number => {
  const journey = findJourneyFromWorkspace(workspace, journeyId);
  if (!journey) {
    return 0;
  }

  const totalWorkspaceProfiles = workspace.profiles?.length || 0;
  if (totalWorkspaceProfiles === 0) {
    return 0;
  }

  const workshopParticipationMap = new Map(
    journey.workshopParticipation?.map((participation) => [
      participation.workshop_id,
      participation.profiles?.length || 0,
    ])
  );

  const completedConversationsCount = journey.workshops?.reduce(
    (count, workshop) => {
      const participatedProfiles = workshop.sys?.id
        ? workshopParticipationMap.get(workshop.sys.id)
        : 0;

      if (!participatedProfiles) {
        return count;
      }

      const participationPercentage =
        (participatedProfiles / totalWorkspaceProfiles) * 100;

      return participationPercentage >= 70 ? count + 1 : count;
    },
    0
  );

  return completedConversationsCount || 0;
};

export const calculateJourneyStatus = (data: {
  journey: Journey | null;
  availableJourneys: JourneyWorkspace[];
  certificates: Certificate[];
  journeyParticipationData: JourneyParticipationData | null;
}): JourneyStatus => {
  const { journey, availableJourneys, certificates, journeyParticipationData } =
    data;
  let status = JourneyStatus.Locked;
  if (!journey) return status;

  const journeyCertificates = certificates.find(
    (c) => c.journey_id === journey?.sys?.id
  );
  const journeyResults = journeyParticipationData?.journeyResults.find(
    (jr) => jr?.journey?.sys?.id === journey?.sys?.id
  );
  const allWorkspaceProfilesHaveCompletedJourney =
    (journeyResults?.workshopParticipation?.length || 0) > 0 &&
    !journeyResults?.workshopParticipation
      .map(
        (jp) =>
          ((journeyParticipationData?.workspaceProfilesCount || 0) /
            jp.profiles.length) *
            100 >=
          70
      )
      .includes(false);

  if (availableJourneys.find((aj) => aj.journey_id === journey?.sys?.id))
    status = JourneyStatus.Unlocked;

  if (!journeyParticipationData) return status;

  if ((journeyResults?.workshopParticipation.length || 0) > 0)
    status = JourneyStatus.InProgress;

  if (allWorkspaceProfilesHaveCompletedJourney)
    status = JourneyStatus.Completed;

  if (
    journeyCertificates?.is_valid === false &&
    !!journeyCertificates?.last_invalidation_date
  )
    status = JourneyStatus.NeedsUpdate;

  return status;
};
