import cn from "classnames";
import VoiceIndicator from "../../VoiceIndicator/VoiceIndicator";

import styles from './Indicators.module.css';

interface IndicatorsProps {
  hasMicrophone: boolean;
  hasVoice: boolean;
  isObserver: boolean;
}

const Indicators = ({ hasMicrophone, hasVoice, isObserver }: IndicatorsProps) => (
  <>
    {isObserver && (
      <div className={cn(styles.indicator, styles.indicatorLeft)}>
        <i className="icon fa fa-eye" />
      </div>
    )}
    {hasMicrophone && (
      <div className={cn(styles.indicator, styles.indicatorRight)}>
        <i className="icon fa fa-microphone-slash red" />
      </div>
    )}
    {hasVoice && (
      <div className={cn(styles.indicator, styles.indicatorRight)}>
        <VoiceIndicator />
      </div>
    )}
  </>
);

export default Indicators