import {
  ChangeEvent,
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";
import { Cross2Icon } from "@radix-ui/react-icons";

import { startCase, toLower } from "lodash";

import { AdminDashboardContext } from "../../../../../../contexts/AdminDashboard";
import { FetchState } from "../../../../../../+xstate/machines/fetch-factory";
import {
  deleteTeamMemberImage,
  getPresignedTeamMemberUrl,
  uploadTeamMemberImage,
} from "../../../../../../+xstate/actions/team-members";

import { Select } from "../../../../../Shared/Select/Select";
import LoadingButton from "../../../../../Shared/Buttons/LoadingButton/LoadingButton";
import UserImage from "../../../../../Shared/UserImage/UserImage";

import {
  Profile,
  ProfileUpdate,
} from "../../../../../../apollo-graphql/types/profile";
import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../../../apollo-graphql/types/enums";
import { mappedErrorMessages } from "../../../../../../constants/mapped-error-messages";
import { UpdateProfileErrors } from "../../../../../../types/enums/errors";

import styles from "./EditTeamMemberModal.module.css";
import cn from "classnames";

// TODO: Fetch from backend once we have Tags
// const tags = ["designer", "developer"];

const EditTeamMemberModal = (
  props: PropsWithChildren<{
    teamMember: Profile;
    errorMessage: string | null;
    isLoading: boolean;
    imageData: { token: null | string; key: null | string };
    updateTeamMember: (params: ProfileUpdate) => void;
    onClose: () => void;
  }>
) => {
  const {
    teamMember,
    imageData,
    errorMessage,
    updateTeamMember,
    isLoading,
    onClose,
  } = props;

  const {
    teamMembers: {
      adminTeamMembersState: state,
      adminTeamMembersSend: send,
      uploadTeamMemberImageMachineState,
      deleteTeamMemberImageMachineState,
    },
  } = useContext(AdminDashboardContext);

  const [dialogState, setDialogState] = useState<{
    permissions: ProfileWorkspaceAccess;
    status: ProfileWorkspaceStatus;
    tags: string[];
  }>({
    permissions: teamMember.workspace.access,
    status: teamMember.workspace.status,
    tags: [],
  });
  const fileUploadRef = useRef<HTMLInputElement | null>(null);

  const [error, setError] = useState<string>("");

  const isFileUploading = useMemo(
    () => uploadTeamMemberImageMachineState.value === FetchState.Fetching,
    [uploadTeamMemberImageMachineState?.value]
  );
  const isFileDeleting = useMemo(
    () => deleteTeamMemberImageMachineState.value === FetchState.Fetching,
    [deleteTeamMemberImageMachineState?.value]
  );

  const selectedTeamMemberImageData = useMemo(
    () => state.context.selectedTeamMemberImageData,
    [state.context.selectedTeamMemberImageData]
  );

  const submitHandler = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      setError("");
      e.preventDefault();

      const payload = Object.fromEntries(new FormData(e.currentTarget)) as {
        name: string;
        email: string;
        jobTitle: string;
        tags?: string;
      };

      updateTeamMember({
        id: teamMember.id,
        ...payload,
        ...dialogState,
      });
    },
    [updateTeamMember, teamMember, dialogState]
  );

  const uploadImageHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const file = files && files[0];
      if (
        !file ||
        !selectedTeamMemberImageData.presignedUrl ||
        !fileUploadRef.current
      )
        return;

      send(
        uploadTeamMemberImage({
          url: selectedTeamMemberImageData.presignedUrl,
          body: file,
        })
      );

      fileUploadRef.current.value = "";
    },
    [send, selectedTeamMemberImageData.presignedUrl]
  );

  const deleteImageHandler = useCallback(() => {
    const { key, token } = imageData;
    if (!key || !token) return;
    send(deleteTeamMemberImage({ key, token }));
  }, [imageData, send]);

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className="user-container">
          <UserImage
            isPublic={false}
            profileId={teamMember.id}
            profileWorkspaceId={teamMember.workspace.workspace_id}
            showLoader={isFileUploading || isFileDeleting}
            containerClass={styles.imageContainer}
            fallbackFontAwesomeIconClass="fa fa-user"
            alt="user-profile"
            lastUpdatedImageTimestamp={
              selectedTeamMemberImageData.lastUpdatedTimeStamp
            }
          />
          <label className="upload-container">
            {(isFileUploading || isFileDeleting) && (
              <i className="fa fa-circle-o-notch fa-spin" />
            )}
            Upload picture
            <input
              type="file"
              disabled={isFileUploading}
              ref={fileUploadRef}
              accept="image/*"
              onChange={uploadImageHandler}
            />
          </label>
          <button onClick={deleteImageHandler}>Delete</button>
        </div>
        <div className="user-info-container">
          <Form.Root className="form-container" onSubmit={submitHandler}>
            <Form.Field name="name" className="form-row">
              <Form.Label className="label">Name</Form.Label>
              <Form.Control
                className="FormControl"
                type="text"
                defaultValue={teamMember?.name}
              />
            </Form.Field>
            <Form.Field name="email" className="form-row">
              <Form.Label className="label">Email</Form.Label>
              <Form.Control
                className="FormControl"
                type="email"
                value={teamMember?.email}
                disabled
              />
            </Form.Field>
            <Form.Field name="jobTitle" className="form-row">
              <Form.Label className="label">Job Title</Form.Label>
              <Form.Control
                className="FormControl"
                type="text"
                value={teamMember?.headline}
              />
            </Form.Field>
            <Form.Field name="permissions" className="form-row">
              <Form.Label className="label">Access Permissions</Form.Label>

              <Select
                label="Select permissions"
                value={dialogState.permissions}
                options={[...(Object.values(ProfileWorkspaceAccess) || [])].map(
                  (access) => ({
                    label: startCase(toLower(access)),
                    value: access,
                    key: `${access}-access`,
                  })
                )}
                className={styles.selectInput}
                onChange={(permissions) => {
                  setDialogState((prev) => ({
                    ...prev,
                    permissions: permissions as ProfileWorkspaceAccess,
                  }));
                }}
              />
            </Form.Field>

            <Form.Field name="status" className="form-row">
              <Form.Label className="label">Profile Status</Form.Label>
              <Select
                label="Select status"
                value={dialogState.status}
                options={[...(Object.values(ProfileWorkspaceStatus) || [])].map(
                  (status) => ({
                    label: startCase(toLower(status)),
                    value: status,
                    key: `${status}-status`,
                  })
                )}
                className={styles.selectInput}
                onChange={(status) => {
                  setDialogState((prev) => ({
                    ...prev,
                    status: status as ProfileWorkspaceStatus,
                  }));
                }}
              />
            </Form.Field>
            {/* <Form.Field name="tags" className="form-row">
              <Form.Label className="label">Add tags (Unavailable)</Form.Label>

              {dialogState.tags.length > 0 && (
                <div className={styles.tagContainer}>
                  <div className={styles.selectedTagList}>
                    {dialogState.tags.map((tag) => (
                      <div key={`tag-${tag}`} className={styles.selectedTag}>
                        <span>{tag}</span>
                        <button
                          onClick={() => {
                            setDialogState((prev) => ({
                              ...prev,
                              tags: prev.tags?.filter((t) => tag !== t),
                            }));
                          }}
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <Select
                className={styles.selectInput}
                label="Select tags"
                value="-"
                disabled
                options={(tags || [])
                  .filter(
                    (tag) =>
                      !dialogState.tags?.some(
                        (alreadyChosen) => tag === alreadyChosen
                      )
                  )
                  .map((tag) => ({
                    label: tag,
                    value: tag,
                    key: `tag-${tag}`,
                    disabled: dialogState.tags?.some(
                      (selected) => selected === tag
                    ),
                  }))}
                onChange={(tag: string) => {
                  setDialogState((prev) => ({
                    ...prev,
                    tags: [...prev?.tags, tag],
                  }));
                }}
              />
            </Form.Field> */}

            <div className={styles.footer}>
              {(error || errorMessage) && (
                <div className={styles.errorContainer}>
                  {error ||
                    mappedErrorMessages[errorMessage as UpdateProfileErrors]}
                </div>
              )}
              <div className={styles.saveBtnContainer}>
                <LoadingButton
                  isLoading={isLoading}
                  className={cn("btn", styles.saveBtn)}
                  type="submit"
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </Form.Root>
        </div>
      </div>
    );
  }, [
    teamMember.id,
    teamMember.workspace.workspace_id,
    teamMember?.name,
    teamMember?.email,
    teamMember?.headline,
    isFileUploading,
    isFileDeleting,
    selectedTeamMemberImageData.lastUpdatedTimeStamp,
    uploadImageHandler,
    deleteImageHandler,
    submitHandler,
    dialogState.permissions,
    dialogState.status,
    error,
    errorMessage,
    isLoading,
  ]);

  useEffect(() => {
    const { key, token } = imageData;
    if (!key || !token) return;

    send(getPresignedTeamMemberUrl({ key, token }));
  }, [imageData, send]);

  return (
    <Dialog.Root open onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn(styles.editModal, "DialogContent")}>
          <Dialog.Title className="DialogTitle">Edit team member</Dialog.Title>
          {dialogContent}
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(EditTeamMemberModal);
