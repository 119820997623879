import { memo, useMemo } from "react";

import { getEntryId } from "../../../../../../utils";
import { calculateAhaMomentsCount, calculateBehavioursCount } from "../../utils";

import { Journey } from "../../../../../../types/contentful/workshop/journey";
import JourneyConversations, {
  JourneyConversation,
} from "../../../../../Workshop/JourneyInformation/JourneyConversations/JourneyConversations";
import { SectionItemType } from "../../../../../../types/contentful/workshop/summary";
import { JourneyLeader } from "../../../../../../apollo-graphql/types";

import styles from "./JourneyConversationsSteps.module.css";

interface JourneyConversationsStepsProps {
  journey: Journey;
  journeyLeaders: JourneyLeader[] | null;
  isJourneyAvailable: boolean;
}

const JourneyConversationsSteps = (props: JourneyConversationsStepsProps) => {
  const { journey } = props;
  const journeyId = useMemo(() => getEntryId(journey), [journey]);

  const journeyConversations: JourneyConversation[] = useMemo(() => {
    if (!journey.fields.workshops) return [];

    return journey.fields.workshops.map((workshop, index) => {
      const { title, headline, welcomeText, icon, color, summary, completedDate, scheduleDate, link } =
        workshop.fields;
      const ahaMomentsCount = calculateAhaMomentsCount([workshop]);
      const behavioursCount = calculateBehavioursCount([workshop]);
      const sectionItemTypes = (summary?.fields?.sections || [])
        ?.flatMap((s) => s?.fields.items?.map((i) => i?.fields?.type))
        .filter((type) => !!type && Object.keys(SectionItemType).includes(type)).filter(x => !!x) as SectionItemType[];

      const isLocked = true; // to be changed when logic is defined

      return {
        id: workshop.sys.id,
        title,
        headline: headline || welcomeText || "",
        isCurrent: false, // to be changed when logic is defined
        isCompleted: false, // to be changed when logic is defined
        image: icon?.fields?.file?.url,
        color: color,
        isLocked,
        ahaMomentsCount,
        behavioursCount,
        sectionItemTypes,
        completedDate,
        scheduleDate,
        link,
      };
    });
  }, [journey.fields.workshops]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {`${journey.fields.title} Conversations:`}
      </div>

      <JourneyConversations
        journeyConversations={journeyConversations}
        journeyTitle={journey?.fields.title}
        journeyId={journeyId}
      />
    </div>
  );
};

export default memo(JourneyConversationsSteps);
