import {
  useState,
  useEffect,
  PropsWithChildren,
  memo,
  useMemo,
  useRef,
} from "react";
import { serverUrl } from "../../../constants/endpoints";

export const FetchImage = memo(
  ({
    src,
    alt,
    token,
    hideOnFailure,
    handleImageLoad,
    handleImageError,
  }: PropsWithChildren<{
    src: string;
    token?: string;
    alt?: string;
    hideOnFailure?: boolean;
    handleImageLoad?: (width: number, height: number) => void;
    handleImageError?: () => void;
  }>) => {
    const headers = useMemo(() => {
      if(!token) return {};
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      return headers;
    }, [token]);

    const [showImage, setShowImage] = useState(true);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [initialized, setInitialized] = useState(false);
    const currentImageSrc = useRef<null | string>(null);
    const isSvg = src.split('.').pop()?.toLowerCase() === 'svg';

    useEffect(() => {
      if (error || loading) return;
      const fetchImage = async () => {
        setLoading(true);
        if (initialized === false) setInitialized(true);
        
        try {
          const response = await fetch(
            src.startsWith("http")
              ? src
              : `${serverUrl}${src.startsWith("/") ? src : "/" + src}`,
            { headers }
          );
          if (!response.ok) {
            if (handleImageError) {
              handleImageError();
            } else {
              throw new Error(`Error: ${response.statusText}`);
            }
          }
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          currentImageSrc.current = src;
          setImageSrc(imageUrl);
        } catch (err: any) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      if (showImage && (!imageSrc || currentImageSrc.current !== src))
        fetchImage();

      return () => {
        if (!imageSrc) return;
        URL.revokeObjectURL(imageSrc);
      };
    }, [
      error,
      handleImageError,
      headers,
      imageSrc,
      initialized,
      loading,
      showImage,
      src,
    ]);

    if ((loading || !src || !initialized) && showImage) return null; // We can present a loader here maybe?
    if (error) return null;

    const handleError = async () => {
      if (handleImageError) {
        handleImageError();
      }
      if (hideOnFailure) setShowImage(false);
    };

    const handleLoad = (
      event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
      const img = event.currentTarget;
      if (handleImageLoad) {
        handleImageLoad(img.naturalWidth, img.naturalHeight);
      }
    };

    return (
      imageSrc &&
      showImage && (
        isSvg
          ? <object 
              type="image/svg+xml"
              data={imageSrc}
              aria-label={alt}
              className="fetch-image"
            />
          : <img
              src={imageSrc}
              alt={alt}
              onError={handleError}
              onLoad={handleLoad}
              className="fetch-image"
            />
      )
    );
  }
);
