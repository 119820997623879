import { SlotType } from "../../../apollo-graphql/types/enums/slot-type";
import { createAction, props } from "../../utils";

export const openScheduleDialog = createAction(
  "[Admin Dashboard Schedule] Open schedule dialog",
  props<{ workshopId: string; workspaceId: string }>()
);

export const closeScheduleDialog = createAction(
  "[Admin Dashboard Schedule] Close schedule dialog"
);

export const selectWorkshopStart = createAction(
  "[Admin Dashboard Schedule] Select workshop start",
  props<{ type: SlotType }>()
);

export const setScheduleDateTime = createAction(
  "[Admin Dashboard Schedule] Set schedule date time",
  props<{ dateTime: Date }>()
);

export const setScheduleParticipants = createAction(
  "[Admin Dashboard Schedule] Set schedule participants",
  props<{ participantEmails: string[] }>()
);

export const createSlot = createAction(
  "[Admin Dashboard Schedule] Create slot",
  props<
    | { type: SlotType.ALL; dateTime: null; participantEmails: null }
    | { type: SlotType.SPLIT; dateTime: Date; participantEmails: string[] }
  >()
);
