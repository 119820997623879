export type DevicePermissions = {
  microphone: PermissionState;
  camera: PermissionState;
};

export const checkCameraAndMicPermissions = (): Promise<DevicePermissions> =>
  Promise.all([
    navigator.permissions
      .query({
        name: "microphone" as PermissionName,
      })
      .catch((error) => {
        console.error(error);
        return { state: "denied" } as { state: PermissionState };
      }),
    navigator.permissions
      .query({
        name: "camera" as PermissionName,
      })
      .catch((error) => {
        console.error(error);
        return { state: "denied" } as { state: PermissionState };
      }),
  ]).then(([micPermission, cameraPermission]) => {
    return {
      microphone: micPermission.state,
      camera: cameraPermission.state,
    };
  });
