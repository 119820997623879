import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Navigate,
  useMatches,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { debounce, startCase, toLower } from "lodash";

import InviteTeamMemberModal from "./components/InviteTeamMemberModal/InviteTeamMemberModal";
import EditTeamMemberModal from "./components/EditTeamMemberModal/EditTeamMemberModal";
import DeleteTeamMemberModal from "./components/DeleteTeamMemberModal/DeleteTeamMemberModal";
import Loader from "../../../Shared/Loader/Loader";

import { generateWorkspaceProfileImageKey } from "../../../../utils";
import { defaultPage, defaultPageSize } from "../../../../utils/filters";
import {
  deserializePageParams,
  serializePageParams,
} from "../../../../utils/filters";

import withRouteConfig from "../../../../hocs/withRouteConfig";
import { FetchState } from "../../../../+xstate/machines/fetch-factory";
import { TeamMembersState } from "../../../../+xstate/machines/team-members";
import * as teamMemberActions from "../../../../+xstate/actions/team-members";

import {
  Profile,
  ProfileInvite,
} from "../../../../apollo-graphql/types/profile";
import Pagination from "../../../Pagination/Pagination";
import UserImage from "../../../Shared/UserImage/UserImage";
import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../apollo-graphql/types/enums";
import {
  ProfileUpdate,
  ProfileDelete,
} from "../../../../apollo-graphql/types/profile";
import { AdminDashboardContext } from "../../../../contexts/AdminDashboard";
import { GlobalContext } from "../../../../contexts/Global";

import styles from "./TeamMembers.module.css";
import cn from "classnames";
import { enterAdminTeamMembers } from "../../../../+xstate/actions/dashboard/admin-dashboard";
import { TEAM_MEMBERS_DISABLED } from "../../../../constants/global";

const mapFieldToStyle: {
  readonly type: {
    readonly [key in ProfileWorkspaceAccess]: string;
  };
  readonly status: {
    readonly [key in ProfileWorkspaceStatus]?: string;
  };
} = {
  type: {
    [ProfileWorkspaceAccess.ADMIN]: styles.adminType,
    [ProfileWorkspaceAccess.OWNER]: styles.ownerType,
    [ProfileWorkspaceAccess.SUPER_ADMIN]: styles.adminType,
    [ProfileWorkspaceAccess.TEAM_MEMBER]: styles.teamMemberType,
    [ProfileWorkspaceAccess.NONE]: styles.teamMemberType,
  },
  status: {
    [ProfileWorkspaceStatus.ACTIVE]: styles.activeStatus,
    [ProfileWorkspaceStatus.ABSENCE]: styles.absenceStatus,
    [ProfileWorkspaceStatus.LEFT]: styles.absenceStatus,
    [ProfileWorkspaceStatus.NONE]: styles.absenceStatus,
    [ProfileWorkspaceStatus.PROHIBITED]: styles.absenceStatus,
    [ProfileWorkspaceStatus.UNSUBSCRIBED]: styles.absenceStatus,
  },
};

export const tmDefaultFilteringState = {
  currentPage: defaultPage,
  pageSize: defaultPageSize,
};

export interface TeamMembersFilteringState {
  currentPage: number;
  pageSize: number;
  query?: string;
}

type TeamMembersProps = PropsWithChildren<{}>;

const TeamMembers = (props: TeamMembersProps) => {
  const {
    teamMembers: {
      adminTeamMembersState: state,
      adminTeamMembersSend: send,
      getTeamMembersState,
      inviteTeamMemberState,
      editTeamMemberState,
      deleteTeamMemberState,
    },
    send: adminDashboardSend,
  } = useContext(AdminDashboardContext);
  const {
    auth: {
      context: { token, profile },
    },
  } = useContext(GlobalContext);

  const workspaceId = useMemo(() => profile!.workspace.workspace_id, [profile]);

  useEffect(() => {
    adminDashboardSend(
      enterAdminTeamMembers({ workspaceId, ...tmDefaultFilteringState })
    );
  }, [adminDashboardSend, workspaceId]);

  const matches = useMatches();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    currentPage: `${defaultPage}`,
  });

  const queryRef = useRef<HTMLInputElement>(null);

  const teamMembersLoading = useMemo(
    () => getTeamMembersState.matches(FetchState.Fetching),
    [getTeamMembersState]
  );

  const isEditLoading = useMemo(
    () => editTeamMemberState.matches(FetchState.Fetching),
    [editTeamMemberState]
  );

  const isDeleteLoading = useMemo(
    () => deleteTeamMemberState.matches(FetchState.Fetching),
    [deleteTeamMemberState]
  );

  const { selectedTeamMember, deletedTeamMemberId, error } = useMemo(
    () => state.context,
    [state.context]
  );

  const teamMembers = useMemo(
    () =>
      getTeamMembersState.context.data?.profiles || {
        nodes: [],
        pageInfo: {
          total: 0,
        },
      },
    [getTeamMembersState.context.data?.profiles]
  );
  const { pageInfo } = useMemo(
    () => teamMembers || { total: 0 },
    [teamMembers]
  );
  const pageCount = useMemo(
    () => Math.round(pageInfo.total / defaultPageSize),
    [pageInfo]
  );

  const updateUrlFilters = useCallback(
    (filters: Partial<TeamMembersFilteringState>) => {
      const params = deserializePageParams(searchParams);
      if (filters.query) {
        params.currentPage = "1";
      }
      const newParams = { ...params, ...filters } as TeamMembersFilteringState;
      setSearchParams(serializePageParams(newParams));
    },
    [setSearchParams, searchParams]
  );

  useEffect(() => {
    const { currentPage, query } = deserializePageParams(searchParams);
    send(
      teamMemberActions.fetchTeamMembers({
        workspaceId,
        filters: {
          currentPage: Number(currentPage),
          pageSize: defaultPageSize,
          query,
        },
      })
    );
  }, [searchParams, workspaceId, send]);

  useEffect(() => {
    const matchesEditUrl = matches.find(
      ({ id }) => id === "dashboard-team-members-edit"
    );
    const matchesDeletetUrl = matches.find(
      ({ id }) => id === "dashboard-team-members-delete"
    );

    if (matchesEditUrl?.params.id) {
      send(teamMemberActions.editOpen({ id: matchesEditUrl.params.id }));
    }
    if (matchesDeletetUrl?.params.id) {
      send(teamMemberActions.deleteOpen({ id: matchesDeletetUrl.params.id }));
    }
  }, [searchParams, workspaceId, matches, send]);

  const showInviteTeamMember = useMemo(
    () => state.matches(TeamMembersState.InviteDialog),
    [state]
  );

  useEffect(() => {
    if (
      editTeamMemberState.matches(FetchState.Success) ||
      deleteTeamMemberState.matches(FetchState.Success)
    ) {
      navigate("/team-members");
    }
  }, [deleteTeamMemberState, editTeamMemberState, navigate]);

  const imageData = useMemo(() => {
    if (!selectedTeamMember || !token) return { token: null, key: null };
    return {
      token,
      key: generateWorkspaceProfileImageKey(
        selectedTeamMember.id,
        selectedTeamMember.workspace.workspace_id
      ),
    };
  }, [selectedTeamMember, token]);

  const toggleInviteModal = useCallback(() => {
    if (showInviteTeamMember) {
      send(teamMemberActions.inviteClose());
    } else {
      send(teamMemberActions.inviteOpen());
    }
  }, [send, showInviteTeamMember]);

  const handleInviteTeamMember = useCallback(
    (params: ProfileInvite) => {
      send(
        teamMemberActions.inviteSend({
          variables: { ...params, workspaceId },
        })
      );
    },
    [send, workspaceId]
  );

  const isInviteLoading = useMemo(
    () => inviteTeamMemberState.matches(FetchState.Fetching),
    [inviteTeamMemberState]
  );

  const handleDeleteOpen = useCallback(
    (id: string) => {
      if (queryRef.current) {
        queryRef.current.value = "";
      }
      navigate(`/team-members/delete/${id}`);
    },
    [navigate]
  );

  const handleDeleteTeamMember = useCallback(
    (params: ProfileDelete) => {
      send(
        teamMemberActions.deleteSubmit({
          variables: { ...params },
        })
      );
    },
    [send]
  );

  const handleEditOpen = useCallback(
    (id: string) => {
      if (queryRef.current) {
        queryRef.current.value = "";
      }
      navigate(`/team-members/edit/${id}`);
    },
    [navigate]
  );

  const handleEditTeamMember = useCallback(
    (params: ProfileUpdate) => {
      send(
        teamMemberActions.editSubmit({
          variables: { ...params },
        })
      );
    },
    [send]
  );

  const handleDeleteClose = useCallback(() => {
    navigate("/team-members");
    send(teamMemberActions.deleteClose());
  }, [navigate, send]);

  const handleEditClose = useCallback(() => {
    navigate("/team-members");
    send(teamMemberActions.editClose());
  }, [navigate, send]);

  const handlePageChange = useCallback(
    ({ selected }: { selected: number }) => {
      updateUrlFilters({ currentPage: selected + 1 });
    },
    [updateUrlFilters]
  );

  const debouncedQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        updateUrlFilters({ query });
      }, 300),
    [updateUrlFilters]
  );

  const handleQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const query = e.currentTarget.value;
      debouncedQueryChange(query);
    },
    [debouncedQueryChange]
  );

  // Mock Tag computed from the domain of the email
  // TODO: Replace with real ones on profile level once implemented
  const mockTags = useMemo(() => {
    const d = teamMembers.nodes[0]?.email.split("@")[1].split(".")[0];
    return (
      <p key={`tag-${d}`} className={cn("text", "tiny", "bold", styles.status)}>
        {d}
      </p>
    );
  }, [teamMembers]);

  const teamMembersContent = useMemo(
    () =>
      teamMembers.nodes.map((tm) => (
        <div className={styles.teamMember} key={`team-member-${tm.id}`}>
          <div className={styles.teamMemberContent}>
            <div className={styles.teamMemberLine}>
              <div className={cn(styles.lineSection, styles.nameColumn)}>
                <div className={styles.userImageContainer}>
                  <UserImage
                    isPublic={false}
                    profileId={tm.id}
                    containerClass={styles.imageContainer}
                    profileWorkspaceId={workspaceId}
                    fallbackFontAwesomeIconClass="fa fa-user"
                    alt="user-profile"
                  />
                </div>

                <p className="text">{tm.name}</p>
              </div>
              <div className={cn(styles.lineSection, styles.emailColumn)}>
                <p className="text">{tm.email}</p>
              </div>
              <div className={cn(styles.lineSection, styles.accountTypeColumn)}>
                <p
                  className={cn(
                    "text",
                    "tiny",
                    "bold",
                    styles.status,
                    mapFieldToStyle.type[tm.workspace?.access]
                  )}
                >
                  {startCase(toLower(tm.workspace?.access))}
                </p>
              </div>
              <div className={cn(styles.lineSection, styles.statusColumn)}>
                <p
                  className={cn(
                    "text",
                    "tiny",
                    "bold",
                    styles.status,
                    mapFieldToStyle.status[tm.workspace?.status]
                  )}
                >
                  {startCase(toLower(tm.workspace?.status))}
                </p>
              </div>
              <div className={cn(styles.lineSection, styles.tagsColumn)}>
                {mockTags}
              </div>
              <div className={cn(styles.lineSection, styles.actionColumn)}>
                <button
                  id="edit"
                  onClick={() => handleEditOpen(tm.id)}
                  type="submit"
                  className="btn secondary small"
                >
                  <i className="fa-regular fa-pen" /> Edit
                </button>
                <button
                  onClick={() => handleDeleteOpen(tm.id)}
                  type="submit"
                  className="btn destructive small"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )),
    [handleDeleteOpen, handleEditOpen, mockTags, teamMembers.nodes, workspaceId]
  );

  if (TEAM_MEMBERS_DISABLED) {
    return <Navigate to="/workshops" />;
  }

  return (
    <div className={styles.container}>
      <h3 className="thin">Members</h3>

      <div className={styles.filtersAndPagination}>
        <div className={styles.inputStyle}>
          <input
            type="text"
            placeholder="&#xF002; Filter by name or email..."
            onChange={handleQueryChange}
            ref={queryRef}
          />
        </div>

        <div className={styles.paginationAndInvite}>
          <Pagination
            selectedPage={Number(searchParams.get("currentPage")) - 1}
            onPageChange={handlePageChange}
            pageCount={pageCount}
          />
          <button
            className={cn(styles.inviteBtn, "btn small")}
            onClick={toggleInviteModal}
          >
            <i className="fa-regular fa-plus" /> Invite people
          </button>
        </div>
      </div>

      <div>
        <div className={styles.teamMemberListContainer}>
          <div className={styles.teamMemberListHeader}>
            <div className={cn(styles.lineSection, styles.nameColumn)}>
              <span className="text-subtitle palest">Name</span>
            </div>
            <div className={cn(styles.lineSection, styles.emailColumn)}>
              <span className="text-subtitle palest">Email</span>
            </div>
            <div className={cn(styles.lineSection, styles.accountTypeColumn)}>
              <span className="text-subtitle palest">Account Type</span>
            </div>
            <div className={cn(styles.lineSection, styles.statusColumn)}>
              <span className="text-subtitle palest">Status</span>
            </div>
            <div className={cn(styles.lineSection, styles.tagsColumn)}>
              <span className="text-subtitle palest">Tags</span>
            </div>
            <div className={cn(styles.lineSection, styles.actionColumn)} />
          </div>

          <div className={styles.teamMemberListBody}>
            {teamMembersLoading ? (
              <Loader className={styles.loaderContainer} />
            ) : teamMembers?.nodes.length ? (
              teamMembersContent
            ) : (
              <p className="text">Team Members not found.</p>
            )}
          </div>
        </div>
      </div>

      {showInviteTeamMember && (
        <InviteTeamMemberModal
          inviteTeamMember={handleInviteTeamMember}
          onClose={toggleInviteModal}
          isLoading={isInviteLoading}
          errorMessage={error}
        />
      )}

      {selectedTeamMember && token && (
        <EditTeamMemberModal
          teamMember={selectedTeamMember}
          updateTeamMember={handleEditTeamMember}
          imageData={imageData}
          onClose={handleEditClose}
          isLoading={isEditLoading}
          errorMessage={error}
        />
      )}
      {deletedTeamMemberId && token && (
        <DeleteTeamMemberModal
          memberProfile={
            teamMembers.nodes.find(
              (profile) => profile.id === deletedTeamMemberId
            ) as Profile
          }
          deleteTeamMember={handleDeleteTeamMember}
          onClose={handleDeleteClose}
          isLoading={isDeleteLoading}
          errorMessage={error}
        />
      )}
    </div>
  );
};

export default memo(withRouteConfig(TeamMembers));
