import * as Collapsible from "@radix-ui/react-collapsible";
import * as Checkbox from '@radix-ui/react-checkbox';
import { memo, useEffect, useState } from "react";
import cn from "classnames";
import ToggleChevron from "../../../../../Shared/ToggleChevron/ToggleChevron";
import UserImage from "../../../../../Shared/UserImage/UserImage";

import styles from './SelfDirectedDiscussionListItem.module.css';

export interface Item {
  profileId: string;
  value: {
    id: string;
    value: string;
    image?: string;
  }
}

interface SelfDirectedDiscussionListItemProps {
  checked: boolean;
  item: Item;
  name: string;
  workspaceId: string;
  handleSelection: (id: string) => void;
}

const SelfDirectedDiscussionListItem = ({ checked, item, name, workspaceId, handleSelection }: SelfDirectedDiscussionListItemProps) => {
  const [expanded, setExpanded] = useState(checked);
  const { value: { value, id }, profileId} = item;

  useEffect(() => {
    setExpanded(checked)
  }, [checked])

  return (
    <Collapsible.Root
      open={expanded}
      onOpenChange={() => setExpanded(!expanded)}
      className={cn(styles.collapsibleContainer, {
        [styles.collapsibleContainerChecked]: checked 
      })}
    >
        <Collapsible.Trigger className={styles.collapsibleTrigger}>
          <div className={styles.questionDescriptionTitle}>
            <label className={styles.radioItem}>
              <Checkbox.Root
                checked={checked}
                onCheckedChange={() => handleSelection(id)}
                className={styles.checkboxRoot}
              >
                <Checkbox.Indicator className={styles.checkboxIndicator}>
                  <i className={cn("fa-solid", "fa-check", styles.checkIcon)}></i>
                </Checkbox.Indicator>
              </Checkbox.Root>

              <div className={styles.itemContent}>
                <span>{name} Situation</span>
                <UserImage
                  isPublic={false}
                  profileId={profileId}
                  profileWorkspaceId={workspaceId}
                  alt="profile-image"
                  fallbackFontAwesomeIconClass="icon fa fa-user"
                  containerClass={styles.profileImage}
                />
              </div>
            </label>
            {checked && (
              <ToggleChevron
                expanded={expanded}
                onClick={() => setExpanded(!expanded)}
                containerClass={styles.questionDescriptionTitleChevron}
              />
            )}
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content
          className={styles.collapsibleContentContainer}
        >
          Situation - {value}
        </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default memo(SelfDirectedDiscussionListItem);