import { PropsWithChildren, useRef, memo } from "react";
import Video from "../Shared/Video/Video";
import UserImage from "../Shared/UserImage/UserImage";

import cn from "classnames";
import styles from "./VideoWithUserImage.module.css";

const VideoWithUserImage = (
  props: PropsWithChildren<{
    width: number | string;
    height: number | string;
    participantId: string;
    profileId: string;
    workspaceId: string;
    noBorderRadius?: boolean;
    disableAudioAndVideo: boolean;
    isConnecting?: boolean;
    isVideoMuted?: boolean;
    attachHtmlVideoElementToTracks(
      participantId: string,
      videoElement: HTMLVideoElement,
      audioElement: HTMLAudioElement
    ): void;
  }>
) => {
  const {
    width,
    height,
    participantId,
    profileId,
    workspaceId,
    noBorderRadius,
    isConnecting,
    isVideoMuted,
    attachHtmlVideoElementToTracks,
  } = props;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  return (
    <>
      <div className={cn(styles.videoContainer, isVideoMuted && "hidden")}>
        <Video
          key={
            "video-" +
            participantId +
            `-${isConnecting ? "connecting" : "connected"}`
          }
          noBorderRadius={noBorderRadius}
          participantId={participantId}
          width={width}
          isConnecting={isConnecting}
          height={height}
          ref={(ref) => {
            if (!ref) return;
            videoRef.current = ref;

            if (audioRef.current) {
              setTimeout(() => {
                attachHtmlVideoElementToTracks(
                  participantId,
                  videoRef.current!,
                  audioRef.current!
                );
              }, 500);
            }
          }}
          audioRef={(ref) => {
            if (!ref) return;
            audioRef.current = ref;

            if (videoRef.current) {
              setTimeout(() => {
                attachHtmlVideoElementToTracks(
                  participantId,
                  videoRef.current!,
                  audioRef.current!
                );
              }, 500);
            }
          }}
        />
      </div>

      <div className={cn(styles.imageContainer, !isVideoMuted && "hidden")}>
        <div className={styles.mutedVideoContainer}>
          <UserImage
            isPublic={false}
            profileId={profileId}
            profileWorkspaceId={workspaceId}
            alt="profile-image"
            fallbackFontAwesomeIconClass="icon fa fa-user"
            showLoader={!isVideoMuted}
          />
        </div>
      </div>
    </>
  );
};

export default memo(VideoWithUserImage);
