import { PropsWithChildren, createContext, useEffect, useState } from "react";

export const InternetConnectionContext = createContext<boolean>({} as any);

export const InternetConnectionContextProvider = (props: PropsWithChildren) => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const onlineHandler = () => {
      setIsOnline(true);
    };
    const offlineHandler = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <InternetConnectionContext.Provider value={isOnline}>
      {props.children}
    </InternetConnectionContext.Provider>
  );
};
