import { Metadata, Sys } from "../../common";
import { WorkshopActivity } from "../activity";

export enum WarmUpActivityType {
  TeamNameActivity = "teamNameActivity",
}

interface WarmUpActivityFields {
  title: string;
  activity: WorkshopActivity;
}

export interface WarmUpActivity {
  metadata: Metadata;
  sys: Sys;
  fields: WarmUpActivityFields;
}
