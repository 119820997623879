import { PropsWithChildren, memo } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import ReactRouterPrompt from "react-router-prompt";

import cn from "classnames";
import styles from "./WorkshopExitModal.module.css";

const WorkshopExitModalContent = memo(
  (
    props: PropsWithChildren<{ onConfirm: () => void; onCancel: () => void }>
  ) => {
    const { onConfirm, onCancel } = props;
    return (
      <Dialog.Root open={true}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className={cn(styles.dialogTitle, "DialogTitle")}>
              Exit conversation
            </Dialog.Title>
            <Dialog.Description className={cn(styles.dialogContent, "text")}>
              Are you sure you want to exit the conversation?
            </Dialog.Description>

            <div className={styles.dialogFooter}>
              <button className="btn ghost" onClick={onCancel}>
                Cancel
              </button>
              <button className="btn destructive" onClick={onConfirm}>
                Exit
              </button>
            </div>

            <Dialog.Close asChild>
              <button
                onClick={onCancel}
                className={cn(styles.dialogCloseButton, "IconButton")}
                aria-label="Close"
              >
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }
);

const WorkshopExitModal = (
  props: PropsWithChildren<{
    onExitCallback?: () => void;
    shouldSkipModal?: boolean;
  }>
) => {
  const { shouldSkipModal, onExitCallback } = props;
  return (
    <ReactRouterPrompt
      when={(data) => {
        if (!data) return false;
        const { nextLocation } = data;
        return (
          !shouldSkipModal &&
          !nextLocation?.pathname.startsWith("/session/instance") &&
          !nextLocation?.pathname.includes("error")
        );
      }}
    >
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <WorkshopExitModalContent
            onConfirm={() => {
              onExitCallback?.();
              onConfirm();
            }}
            onCancel={onCancel}
          />
        )
      }
    </ReactRouterPrompt>
  );
};

export default WorkshopExitModal;
