import { memo, useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./Navigation.module.css";
import {
  DASHBOARD_DISABLED_ADMIN,
  DASHBOARD_DISABLED_MEMBER,
  JOURNEYS_DISABLED,
  MILESTONES_DISABLED,
  SCHEDULE_DISABLED,
  TEAM_MEMBERS_DISABLED,
} from "../../constants/global";
import { hasAdminAccess } from "../../utils/profile";
import { GlobalContext } from "../../contexts/Global";

const groupTitleClassnames = cn(
  styles.groupTitle,
  "text-subtitle",
  "secondary"
);
const MemberDashboardNavigation = () => {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);

  const isAdmin = useMemo(() => hasAdminAccess(profile), [profile]);

  const memberNavContent = useMemo(
    () => (
      <>
        {!DASHBOARD_DISABLED_MEMBER && (
          <>
            <NavLink className={cn(styles.link, "text")} to="/">
              <i className={cn("icon", styles.icon, "fa", "fa-tachometer")} />
              <span className="text small bold">Dashboard</span>
            </NavLink>
          </>
        )}

        <NavLink className={cn(styles.link, "text")} to="/schedule/my">
          <i
            className={cn(
              "icon",
              styles.icon,
              "fa",
              "fa-regular",
              "fa-calendar-range"
            )}
          />
          <span className="text small bold">Schedule</span>
        </NavLink>
        <div className={styles.divider} />
        <div className={styles.group}>
          <span className={groupTitleClassnames}>Grow</span>
          <NavLink className={cn(styles.link, "text")} to="/workshops">
            <i
              className={cn(
                "icon",
                styles.icon,
                "fa",
                "fa-regular",
                "fa-graduation-cap"
              )}
            />
            <span className="text small bold">Conversations</span>
          </NavLink>
        </div>
      </>
    ),
    []
  );

  const adminNavContent = useMemo(
    () => (
      <>
        {!DASHBOARD_DISABLED_ADMIN && (
          <>
            <NavLink className={cn(styles.link, "text")} to="/">
              <i className={cn("icon", styles.icon, "fa", "fa-tachometer")} />
              <span className="text small bold">Dashboard</span>
            </NavLink>

            <div className={styles.divider} />
          </>
        )}

        <div className={styles.group}>
          <span className={groupTitleClassnames}>Grow</span>
          <NavLink className={cn(styles.link, "text")} to="/workshops">
            <i
              className={cn(
                "icon",
                styles.icon,
                "fa",
                "fa-regular",
                "fa-graduation-cap"
              )}
            />
            <span className="text small bold">Conversations</span>
          </NavLink>
          {!JOURNEYS_DISABLED && (
            <NavLink className={cn(styles.link, "text")} to="/journeys">
              <i
                className={cn("icon", styles.icon, "fa", "fa-graduation-cap")}
              />
              <span className="text small bold">Journeys</span>
            </NavLink>
          )}

          {!MILESTONES_DISABLED && (
            <NavLink
              className={cn(styles.link, "text", styles.disabled)}
              to="/workshops"
              onClick={(e) => e.preventDefault()}
            >
              <i className={cn("icon", styles.icon, "fa", "fa-trophy")} />
              <span className="text small bold">Milestones</span>
              <span className={cn(styles.soon, "badge", "secondary")}>
                soon
              </span>
            </NavLink>
          )}
        </div>
        <div className={styles.divider} />

        {(!SCHEDULE_DISABLED || !TEAM_MEMBERS_DISABLED) && (
          <>
            <div className={styles.group}>
              <span className={groupTitleClassnames}>Manage</span>
              {!SCHEDULE_DISABLED && (
                <NavLink
                  className={cn(styles.link, "text")}
                  to="/schedule/team"
                >
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-calendar-range"
                    )}
                  />
                  <span className="text small bold">Team's Schedule</span>
                </NavLink>
              )}
              {!SCHEDULE_DISABLED && (
                <NavLink className={cn(styles.link, "text")} to="/schedule/my">
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-calendar-range"
                    )}
                  />
                  <span className="text small bold">My Schedule</span>
                </NavLink>
              )}
              {!SCHEDULE_DISABLED && (
                <NavLink
                  className={cn(styles.link, "text", styles.disabled)}
                  to="/schedule"
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-calendar-clock"
                    )}
                  />
                  <span className="text small bold">Auto-scheduler</span>
                  <span className={cn(styles.soon, "badge", "secondary")}>
                    soon
                  </span>
                </NavLink>
              )}
              {!TEAM_MEMBERS_DISABLED && (
                <NavLink className={cn(styles.link, "text")} to="/team-members">
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-user-group-simple"
                    )}
                  />
                  <span className="text small bold">Team members</span>
                </NavLink>
              )}
            </div>

            <div className={styles.divider} />
          </>
        )}

        <div className={styles.group}>
          <span className={groupTitleClassnames}>Create</span>
          <NavLink
            className={cn(styles.link, "text", styles.disabled)}
            to="/"
            onClick={(e) => e.preventDefault()}
          >
            <i
              className={cn(
                "icon",
                styles.icon,
                "fa",
                "fa-regular",
                "fa-shapes"
              )}
            />
            <span className="text small bold">Conversation builder</span>
            <span className={cn(styles.soon, "badge", "secondary")}>soon</span>
          </NavLink>
        </div>
      </>
    ),
    []
  );

  return (
    <div className={styles.container}>
      {isAdmin ? adminNavContent : memberNavContent}
    </div>
  );
};
export default memo(MemberDashboardNavigation);
