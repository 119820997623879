import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Cross2Icon } from "@radix-ui/react-icons";

import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import cn from "classnames";
import styles from "./TextInputDialog.module.css";
import Alert, { AlertType } from "../Alert/Alert";
import LoadingButton from "../Buttons/LoadingButton/LoadingButton";

type TextInputDialogProps = PropsWithChildren<{
  dialogTitle: string;
  label: string;
  successAlertHeading: string;
  successAlertDescription?: string;
  errorAlertHeading: string;
  errorAlertDescription?: string;
  textAreaPlaceholder: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  closeDialogHandler: () => void;
  submitDialogHandler: (comment: string) => Promise<any>;
}>;

const TextInputDialog = (props: TextInputDialogProps) => {
  const {
    label,
    dialogTitle,
    textAreaPlaceholder,
    successAlertHeading,
    successAlertDescription,
    errorAlertHeading,
    errorAlertDescription,
    submitButtonText,
    cancelButtonText,
    closeDialogHandler,
    submitDialogHandler,
  } = props;
  const timeoutIdRef = useRef<number | null>();
  const [comment, setComment] = useState<string>("");
  const [status, setStatus] = useState<string>("idle");

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const onCloseHandler = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    closeDialogHandler();
  }, [closeDialogHandler]);

  const onSubmitHandler = useCallback(() => {
    if (!comment || comment.length === 0 || status === "loading") {
      return;
    }
    setStatus("loading");
    submitDialogHandler(comment)
      .then((res) => {
        setStatus("success");
        timeoutIdRef.current = setTimeout(() => {
          closeDialogHandler();
          setStatus("idle");
        }, 3000) as unknown as number;
      })
      .catch((err) => {
        setStatus("error");
        timeoutIdRef.current = setTimeout(() => {
          closeDialogHandler();
          setStatus("idle");
        }, 3000) as unknown as number;
      });
  }, [comment, submitDialogHandler, closeDialogHandler, status]);

  const content = useMemo(
    () => (
      <>
        <Form.Root>
          <Form.Field name="comment" className={styles.textAreaContainer}>
            <Form.Label className="text">{label}</Form.Label>
            <Form.Control asChild>
              <textarea
                className={cn("Textarea", "text")}
                required
                placeholder={textAreaPlaceholder}
                onInput={onChangeHandler}
              />
            </Form.Control>
          </Form.Field>
        </Form.Root>
        {status === "error" && <p>Error message! Please try again later.</p>}
      </>
    ),
    [label, onChangeHandler, status, textAreaPlaceholder]
  );

  if (status === "success") {
    return (
      <Alert
        type={AlertType.Success}
        heading={successAlertHeading}
        description={successAlertDescription as string}
        onClose={onCloseHandler}
      />
    );
  }

  if (status === "error") {
    return (
      <Alert
        type={AlertType.Error}
        heading={errorAlertHeading}
        description={errorAlertDescription as string}
        onClose={onCloseHandler}
      />
    );
  }

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn(styles.dialogContent, "DialogContent")}>
          <Dialog.Title className={cn(styles.title, "DialogTitle")}>
            {dialogTitle}
          </Dialog.Title>
          <div className={styles.content}>{content}</div>
          <div className={styles.dialogFooter}>
            <button
              className={cn(
                "btn medium cancel outline",
                comment.length === 0 || status === "loading" ? "disabled" : ""
              )}
              onClick={closeDialogHandler}
              disabled={status === "loading"}
            >
              {cancelButtonText || "Cancel"}
            </button>
            <LoadingButton
              className="submit"
              size="medium"
              variant="primary"
              onClick={onSubmitHandler}
              isLoading={status === "loading"}
              disabled={comment.length === 0 || status === "loading"}
              delayInMilliseconds={250}
            >
              {submitButtonText || "Submit"}
            </LoadingButton>
          </div>
          <Dialog.Close asChild>
            <button
              className={cn(styles.dialogCloseButton, "IconButton")}
              aria-label="Close"
              disabled={status === "loading"}
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(TextInputDialog);
