export const ACTIVITY_TIMEOUT_VALUE = "<TIMEOUT_NO_VALUE>";

export const SORT_DIRECTION_QUERY_KEY = "sortDirection";

export const SPLIT_KEY_REG_EX =
  /^(\d+):([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i;

export const DASHBOARD_DISABLED_ADMIN = true;
export const DASHBOARD_DISABLED_MEMBER = true;
export const JOURNEYS_DISABLED = false;
export const SCHEDULE_DISABLED = false;
export const TEAM_MEMBERS_DISABLED = false;
export const MILESTONES_DISABLED = true;
