import {
  standardSessionActivityList,
  StandardSessionActivity,
} from "../apollo-graphql/types/enums/standard-session-activity";
import { SessionStateValue } from "../apollo-graphql/types/session-state";
import { Slot } from "../apollo-graphql/types/slot";

export const getCurrentLevel = ({ value, context }: SessionStateValue) => {
  const currentActivityId = standardSessionActivityList.includes(
    value as StandardSessionActivity
  )
    ? null
    : value;
  const filteredActivities = context.activityResult.filter(
    (res) =>
      !standardSessionActivityList.includes(res.key as StandardSessionActivity)
  );
  const currentLevel =
    filteredActivities.length +
    (!!filteredActivities.find((x) => x.key === currentActivityId) ? 0 : 1);

  return currentLevel;
};

export const getTotalLevels = (slot?: Slot) => {
  return slot?.workshop?.fields?.activities.length ?? null;
};

export const getCurrentActivity = (activityId: string, slot?: Slot) => {
  const activitiesOfSlotWorkshop = slot?.workshop?.fields?.activities;
  const activityName = activitiesOfSlotWorkshop?.find(
    (a) => a.sys.id === activityId
  )?.fields?.title;

  return activityName ?? null;
};
