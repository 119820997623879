import { memo } from "react";
import { PropsWithChildren, useMemo, useState } from "react";

import { getEntryId, getType } from "../../../../../../utils";

import {
  IActivityResult,
  SessionStateValue,
} from "../../../../../../apollo-graphql/types/session-state";
import { Profile } from "../../../../../../apollo-graphql/types/profile";
import { Workshop } from "../../../../../../types/contentful/workshop/workshop";
import { WorkshopActivityType } from "../../../../../../types/enums/activity-type";
import { WarmUpActivityType } from "../../../../../../types/contentful/workshop/activities/warm-up";
import { ActivityType } from "../../../../../../types/contentful/workshop/activity-type";
import { Transcript } from "../../../../../SidePanel/components/TranscriptList/TranscriptList";

import SidePanel from "../../../../../SidePanel/SidePanel";
import SectionCard from "../SectionCard/SectionCard";
import Numbers from "../../../../../Shared/Numbers/Numbers";
import WorkshopActivity from "../../../../Activity/Activity";

import styles from "./Overview.module.css";

const Overview = ({
  workshop,
  title,
  activityResult,
  activities,
  profile,
  sessionState,
  isConnectionWeak,
  isParticipating,
}: PropsWithChildren<{
  workshop: Workshop;
  title: string;
  isConnectionWeak: boolean;
  activityResult: IActivityResult[];
  activities: ActivityType[];
  profile: Profile;
  sessionState: SessionStateValue;
  isParticipating: boolean;
}>) => {
  const [selectedActivityId, setActivityId] = useState(
    activities.find(
      (a) => getType(a) === WorkshopActivityType.PresentationActivity
    )?.sys.id || ""
  );

  const activitiesContent = useMemo(() => {
    return activities
      .map((activity, idx) => {
        return {
          value: getEntryId(activity),
          label:
            getType(activity) === WorkshopActivityType.PresentationActivity
              ? "Assignment"
              : idx + 1,
        };
      })
      .map(({ value, label }) => (
        <Numbers
          key={value}
          selected={value === selectedActivityId}
          onClick={() => setActivityId(value)}
        >
          {label}
        </Numbers>
      ));
  }, [activities, selectedActivityId]);

  const currentActivity = useMemo(
    () => activities.find((a) => getEntryId(a) === selectedActivityId),
    [activities, selectedActivityId]
  );

  const activityType = useMemo(
    () => getType(currentActivity),
    [currentActivity]
  );

  const currentActivityResults = useMemo(
    () => activityResult.find((a) => a.key === selectedActivityId)?.value || [],
    [activityResult, selectedActivityId]
  );

  const activityResultForCurrentProfile = useMemo(
    () =>
      activityResult
        .find((a) => a.key === selectedActivityId)
        ?.value.find((a) => a.profileId === profile.id) || null,
    [activityResult, selectedActivityId, profile]
  );

  const currentActiveParticipants = useMemo(
    () =>
      sessionState.context.currentActiveProfiles.map(
        ({ profileId }) => profileId
      ),
    [sessionState.context.currentActiveProfiles]
  );

  const currentActiveParticipantCount = useMemo(
    () => currentActiveParticipants.length,
    [currentActiveParticipants.length]
  );

  const transcripts: Transcript[] = useMemo(() => {
    const currentActivityIndex = (activities || []).findIndex(
      (a) => getEntryId(a) === selectedActivityId
    );

    return (activities || [])
      ?.map((a, index) => {
        return {
          index,
          heading: a.fields.title,
          transcript: a?.fields?.activity?.fields?.transcript,
        };
      })
      .filter(({ index, transcript }) => {
        return transcript && index <= currentActivityIndex;
      })
      .reverse();
  }, [selectedActivityId, activities]);

  const hasTeamName =
    workshop?.fields.warmUpActivity?.sys.contentType.sys.id ===
    WarmUpActivityType.TeamNameActivity;

  const overviewContent = useMemo(() => {
    if (currentActivity) {
      return (
        <>
          <WorkshopActivity
            profile={profile}
            isReady={false}
            hasAhaMoments={false}
            isConnectionWeak={isConnectionWeak}
            transition={0}
            activity={currentActivity}
            currentActivityId={selectedActivityId}
            activityResult={activityResult}
            currentActivityResults={currentActivityResults}
            activityResultForCurrentProfile={activityResultForCurrentProfile}
            notReadyProfilesCount={0}
            isParticipating={isParticipating}
            currentActiveParticipants={currentActiveParticipants}
            currentActiveParticipantCount={currentActiveParticipantCount}
            workshopActivities={activities}
            setActivityValueHandler={() => {}}
            setActivityReadyHandler={() => {}}
            isViewResults
          />
          {activityType === WorkshopActivityType.PresentationActivity && (
            <SidePanel
              transcripts={transcripts}
              containerClass="side-panel-final-screen"
              hasTeamName={hasTeamName}
              hasLeaderBoard={!!workshop?.fields.showScoreboard}
              hideTabSwitched
              hideControlPanel
            />
          )}
        </>
      );
    }

    return null;
  }, [
    currentActivity,
    profile,
    isConnectionWeak,
    selectedActivityId,
    activityResult,
    currentActivityResults,
    activityResultForCurrentProfile,
    isParticipating,
    currentActiveParticipants,
    currentActiveParticipantCount,
    activities,
    activityType,
    transcripts,
    hasTeamName,
    workshop?.fields.showScoreboard,
  ]);

  return (
    <div className={styles.workshopReviewContainer}>
      <div className={styles.scrollableContainer}>
        <SectionCard
          key="workshop-preview"
          className={styles.sectionCard}
          title={title}
          imagePath="/images/read-icon.svg"
          imageAlt="read-icon"
        >
          <div className={styles.scrollableInnerContainer}>
            <div className={styles.activitiesContainer}>
              {activitiesContent}
            </div>
            <div className={styles.overviewContent}>{overviewContent}</div>
          </div>
        </SectionCard>
      </div>
    </div>
  );
};

export default memo(Overview);
