import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  MouseSensor,
  rectIntersection,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";

import DragAndDropCard from "./Card/DragAndDropCard";
import DragAndDropSectionList from "./DragAndDropSectionList/DragAndDropSectionList";
import UserImage from "../UserImage/UserImage";

import { IDragAndDropCard, ISection, SectionTypes } from "./types";

import styles from "./DragAndDrop.module.css";
import dragAndDropCardImageStyles from "./styles/drag-and-drop-image.module.css";
import cn from "classnames";
interface DragAndDropProps extends ISection {
  currentProfileId: string;
  isTransitioning: boolean;
  isLoading: boolean;
  sectionATitle: string;
  sectionBTitle: string;
  disableDragAndDrop?: boolean;
  hasDraggableIcon?: boolean;
  hasBadge?: boolean;
  dropElementAtFirstPosition?: boolean;
  sectionANoContent?: React.ReactNode;
  sectionBNoContent?: React.ReactNode;
  image?: {
    profileId: string;
    src: string;
    fallbackFontAwesomeIconClass: string;
  };
  setValueHandler: (updatedSection: ISection) => void;
}

const findSectionContainer = (section: ISection, id: string) => {
  if (id in section) {
    return id as SectionTypes;
  }

  const container = Object.keys(section).find((key) =>
    section[key as keyof ISection].find((item) => item.id === id)
  );
  return container as SectionTypes;
};

const getItem = (items: IDragAndDropCard[], id: string) => {
  return items.find((item) => item.id === id) || null;
};

const DragAndDrop = (props: PropsWithChildren<DragAndDropProps>) => {
  const {
    sectionA,
    sectionATitle,
    sectionANoContent,
    sectionB,
    sectionBTitle,
    sectionBNoContent,
    currentProfileId,
    isTransitioning,
    isLoading,
    dropElementAtFirstPosition = false,
    hasBadge = false,
    disableDragAndDrop = false,
    hasDraggableIcon = false,
    image,
    setValueHandler,
  } = props;

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor),
    useSensor(MouseSensor)
  );

  const lastSetTime = useRef<number | null>(null);
  const [activeItemId, setActiveItemId] = useState<null | string>(null);
  const [sections, setSections] = useState<ISection>({
    sectionA,
    sectionB,
  });

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      if (disableDragAndDrop) return;
      setActiveItemId(active.id as string);
    },
    [disableDragAndDrop]
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (disableDragAndDrop) return;
      setSections((sections) => {
        const activeContainer = findSectionContainer(
          sections,
          active.id as string
        );
        const overContainer = findSectionContainer(
          sections,
          over?.id as string
        );

        if (
          !activeContainer ||
          !overContainer ||
          activeContainer === overContainer
        )
          return sections;

        const activeItems = sections[activeContainer];
        const overItems = sections[overContainer];

        const activeIndex = activeItems.findIndex(
          (item) => item.id === active.id
        );
        const overIndex = overItems.findIndex((item) => item.id !== over?.id);

        const updatedSection = {
          ...sections,
          [activeContainer]: [
            ...sections[activeContainer].filter(
              (item) => item.id !== active.id
            ),
          ],
          [overContainer]: [
            ...sections[overContainer].slice(0, overIndex),
            sections[activeContainer][activeIndex],
            ...sections[overContainer].slice(
              overIndex,
              sections[overContainer].length
            ),
          ],
        };

        return updatedSection;
      });
    },
    [disableDragAndDrop]
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (disableDragAndDrop) return;

      setSections((sections) => {
        const now = new Date().valueOf();
        if (lastSetTime.current) {
          const diff = now - lastSetTime.current;
          if (diff < 500) return sections;
        }
        lastSetTime.current = now;

        const activeContainer = findSectionContainer(
          sections,
          active.id as string
        );
        const overContainer = findSectionContainer(
          sections,
          over?.id as string
        );

        if (
          !activeContainer ||
          !overContainer ||
          activeContainer !== overContainer
        )
          return sections;

        const activeIndex = sections[activeContainer].findIndex(
          (item) => item.id === active.id
        );
        const overIndex = dropElementAtFirstPosition
          ? 0
          : sections[overContainer].findIndex((item) => item.id === over?.id);
        const newSectionValue = arrayMove(
          sections[overContainer],
          activeIndex,
          overIndex
        );

        const updatedSection = {
          ...sections,
          [overContainer]: newSectionValue,
        };

        setValueHandler(updatedSection);
        return updatedSection;
      });

      setActiveItemId(null);
    },
    [disableDragAndDrop, dropElementAtFirstPosition, setValueHandler]
  );

  const item = activeItemId
    ? getItem([...sectionA, ...sectionB], activeItemId)
    : null;

  const cachedImage = useMemo(() => {
    if (!image) return null;
    return (
      <UserImage
        src={image.src}
        profileId={image.profileId}
        alt="author-profile"
        fallbackFontAwesomeIconClass={cn(
          dragAndDropCardImageStyles.fallbackIconClass,
          image.fallbackFontAwesomeIconClass
        )}
        containerClass={dragAndDropCardImageStyles.imgContainer}
        isImageLoaded
      />
    );
  }, [image]);

  useEffect(() => {
    setSections({ sectionA, sectionB });
  }, [sectionA, sectionB]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <div className={cn(styles.dragAndDropSection, 'dnd-section')}>
        <DragAndDropSectionList
          containerClass={cn(styles.leftList)}
          id={SectionTypes.SECTION_A}
          sectionTitle={sectionATitle}
          currentProfileId={currentProfileId}
          isDisabled={isTransitioning || isLoading || disableDragAndDrop}
          items={sections[SectionTypes.SECTION_A]}
          hasDraggableIcon={hasDraggableIcon}
          hasBadge={hasBadge}
          image={image}
          noContent={sectionANoContent}
        />
        <div className={cn(styles.delimiter, "delimiter")}>
          <div className={styles.iconContainer}>
            <img src="/icons/drag-and-drop.svg" alt="drag-and-drop" />
          </div>
          {/* <div className={styles.line}></div> */}
        </div>
        <DragAndDropSectionList
          containerClass={cn(styles.rightList)}
          id={SectionTypes.SECTION_B}
          sectionTitle={sectionBTitle}
          currentProfileId={currentProfileId}
          isDisabled={isTransitioning || isLoading || disableDragAndDrop}
          items={sections[SectionTypes.SECTION_B]}
          hasDraggableIcon={hasDraggableIcon}
          hasBadge={hasBadge}
          image={image}
          noContent={sectionBNoContent}
        />
      </div>

      <DragOverlay dropAnimation={defaultDropAnimation}>
        {item ? (
          <DragAndDropCard
            id={item.id}
            item={item}
            currentProfileId={currentProfileId}
            isDraggable
            hasDraggableIcon
            className={styles.draggingCard}
            cachedImage={cachedImage}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default DragAndDrop;
