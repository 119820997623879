import { Doc, applyUpdate } from "yjs";
import { StandardSessionActivity } from "../apollo-graphql/types/enums/standard-session-activity";
import { IActivityResult } from "../apollo-graphql/types/session-state";
import { parseToJson } from "./parse-to-json";
import { deserializeUint8Array } from "./uint8-array-serializers";

export const extractComplexNameValue = (
  activity: IActivityResult | string | undefined
) => {
  if (!activity) return null;

  const valueAsString =
    typeof activity === "string"
      ? activity
      : activity?.value?.[0]?.value || null;
  const { value, type } = parseToJson<{
    value: string;
    type: string;
  }>(valueAsString, { value: "", type: "yjs" });

  if (value) {
    if (type === "yjs") {
      const yDoc = new Doc();
      const uInt8ArrayString = value.split(":")[1];
      const update = deserializeUint8Array(uInt8ArrayString);
      applyUpdate(yDoc, update!);
      return yDoc?.getText("codemirror")?.toString() || null;
    }

    try {
      return JSON.parse(value)?.teamName;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return null;
};

export const getTeamName = (
  activityResult: IActivityResult[],
  defaultValue: string
) => {
  const teamActivity = ((activityResult as IActivityResult[]) || []).find(
    ({ key }) => key === StandardSessionActivity.WarmUp
  );

  return extractComplexNameValue(teamActivity) || defaultValue;
};
