import cn from "classnames";
import { Aggregate } from "../../../utils";
import styles from "./JourneyProgress.module.css";
import JourneyAggregateItem from "../JourneyAggregateItem/JourneyAggregateItem";
import { memo } from "react";

interface JourneyProgressProps {
  teamMembers: Aggregate;
  coverage: Aggregate;
  leaders: Aggregate;
  completedConversations: Aggregate;
  className?: string;
}

const JourneyProgress = ({
  teamMembers,
  coverage,
  leaders,
  completedConversations,
  className,
}: JourneyProgressProps) => {
  return (
    <>
      <p className={styles.description}>Journey team progress:</p>
      <div className={cn(styles.details, className)}>
        <JourneyAggregateItem
          logo={
            <i
              className={cn(
                "fa-light",
                "fa-users",
                "details-logo",
                styles.detailsLogo
              )}
            />
          }
          quantity={teamMembers.quantity}
          title={`${
            teamMembers.quantity === 1 ? "Team Member" : "Team Members"
          }`}
          description={teamMembers.description}
        />
        <JourneyAggregateItem
          logo={
            <i
              className={cn(
                "fa-light",
                "fa-circle-check",
                "details-logo",
                styles.detailsLogo
              )}
            />
          }
          isPercentage
          quantity={coverage.quantity}
          title="Coverage"
          description={coverage.description}
        />
        <JourneyAggregateItem
          logo={
            <i
              className={cn(
                "fa-light",
                "fa-user",
                "details-logo",
                styles.detailsLogo
              )}
            />
          }
          quantity={leaders.quantity}
          title={`${leaders.quantity === 1 ? "Leader" : "Leaders"}`}
          description={leaders.description}
        />
        <JourneyAggregateItem
          logo={
            <i
              className={cn(
                "fa-light",
                "fa-messages",
                "details-logo",
                styles.detailsLogo
              )}
            />
          }
          quantity={completedConversations.quantity}
          title={`${
            completedConversations.quantity === 1
              ? "Conversation"
              : "Conversations"
          }`}
          description={completedConversations.description}
        />
      </div>
    </>
  );
};

export default memo(JourneyProgress);
