import { PropsWithChildren, SyntheticEvent, memo } from "react";

import styles from "./ToggleChevron.module.css";
import cn from "classnames";

const ToggleChevron = (
  props: PropsWithChildren<{
    expanded: boolean;
    onClick?: (e: SyntheticEvent) => void;
    containerClass?: string;
  }>
) => {
  const { expanded, onClick, containerClass = "" } = props;

  return (
    <div
      className={cn(styles.toggle, containerClass, "toggle-chevron-container", {
        [styles.expanded]: expanded,
      })}
      onClick={onClick}
    >
      <i className={cn("icon fa fa-chevron-down", styles.toggleIcon)} />
    </div>
  );
};

export default memo(ToggleChevron);
