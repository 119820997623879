import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { JourneyTheme } from "../types/contentful/workshop/journey";
import { Theme } from "../apollo-graphql/types/theme";

interface IHeaderContext {
  theme: Theme | JourneyTheme | null | undefined;
  useDefaultTheme: boolean;
  title: string;
  setTheme: (theme: Theme | JourneyTheme |  null, resetTheme?: boolean) => void;
  setUseDefaultTheme: (val: boolean) => void;
  setTitle: (title: string) => void;
  resetTheme: () => void;
}

export const HeaderContext = createContext<IHeaderContext>(
  {} as IHeaderContext
);

export const HeaderContextProvider = (props: PropsWithChildren) => {
  const [title, setTitle] = useState<string>("");
  const [theme, setTheme] = useState<Theme | JourneyTheme | null>(null);
  const [themes, setThemes] = useState<(Theme | JourneyTheme)[]>([]);
  const [useDefaultTheme, setUseDefaultTheme] = useState<boolean>(false);

  const setThemeHandler = useCallback(
    (value: Theme | JourneyTheme | null) => {
      if (value) setThemes((curr) => curr.concat(value!));
      if (!value) {
        value = themes[themes.length - 2] || null;
        if (themes[themes.length - 1]) setThemes((curr) => curr.slice(0, -1));
      }
      if (value) setTheme(value);
    },
    [themes]
  );

  const setUseDefaultThemeHandler = useCallback((val: boolean) => {
    setUseDefaultTheme(val);
  }, []);

  const setTitleHandler = useCallback((title: string) => {
    setTitle(title);
  }, []);

  const resetThemeHandler = useCallback(() => {
    setTitle("");
    setTheme(null);
    setUseDefaultTheme(false);
  }, []);

  const value = useMemo(
    () => ({
      theme,
      setTheme: setThemeHandler,
      title,
      setTitle: setTitleHandler,
      useDefaultTheme,
      setUseDefaultTheme: setUseDefaultThemeHandler,
      resetTheme: resetThemeHandler,
    }),
    [
      resetThemeHandler,
      setThemeHandler,
      setTitleHandler,
      setUseDefaultThemeHandler,
      theme,
      title,
      useDefaultTheme,
    ]
  );

  return (
    <HeaderContext.Provider value={value}>
      {props.children}
    </HeaderContext.Provider>
  );
};
