import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export function useReadyRemovedDueToChange(
  isReady: boolean,
) {
  const isReadyRef = useRef(isReady);
  const [readyRemovedDueToChange, setReadyRemovedDueToChange] = useState(false);

  useEffect(() => {
    if (readyRemovedDueToChange && isReady) {
      setReadyRemovedDueToChange(false);
    }
    if (isReadyRef.current && !isReady) {
      setReadyRemovedDueToChange(true);
    }
    isReadyRef.current = isReady;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const userValueChangeHandler = useCallback(() => {
    if (isReadyRef.current) {
      isReadyRef.current = false;
    }
  }, []);

  const changeStateText = useMemo(() => {
    return readyRemovedDueToChange ? "Someone made a change. " : "";
  }, [readyRemovedDueToChange]);

  return {
    userValueChangeHandler,
    readyRemovedDueToChange,
    changeStateText
  }
}