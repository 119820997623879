import { PropsWithChildren, memo, useContext, useMemo } from "react";
import { GlobalContext } from "../../../contexts/Global";
import { serverUrl } from "../../../constants/endpoints";
import {
  generateProfileImageKey,
  generateWorkspaceProfileImageKey,
} from "../../../utils";

import Image from "../Image/Image";

type UserImageProps =
  | {
      src?: string;
      isPublic: true;
      profileId: string;
      profileWorkspaceId?: undefined;
      alt: string;
      profileAbbreviation?: string;
      fallbackFontAwesomeIconClass?: string;
      showLoader?: boolean;
      lastUpdatedImageTimestamp?: number | null;
      loadOnDemand?: boolean;
      containerClass?: string;
      isImageLoaded?: boolean;
    }
  | {
      src?: string;
      isPublic: false;
      profileId: string;
      profileWorkspaceId: string;
      alt: string;
      profileAbbreviation?: string;
      fallbackFontAwesomeIconClass?: string;
      showLoader?: boolean;
      lastUpdatedImageTimestamp?: number | null;
      loadOnDemand?: boolean;
      containerClass?: string;
      isImageLoaded?: boolean;
    }
  | {
      src: string;
      alt: string;
      profileId: string;
      isPublic?: false;
      profileWorkspaceId?: string;
      profileAbbreviation?: string;
      fallbackFontAwesomeIconClass?: string;
      showLoader?: boolean;
      lastUpdatedImageTimestamp?: number | null;
      loadOnDemand?: boolean;
      containerClass?: string;
      isImageLoaded?: boolean;
    };

const UserImage = (props: PropsWithChildren<UserImageProps>) => {
  const {
    isPublic,
    profileId,
    src,
    profileWorkspaceId,
    alt,
    fallbackFontAwesomeIconClass,
    profileAbbreviation,
    showLoader,
    loadOnDemand,
    lastUpdatedImageTimestamp = null,
    containerClass = "",
    isImageLoaded = false,
  } = props;

  const {
    auth: {
      context: { token, imageLastUpdatedTimeStamp, profile },
    },
  } = useContext(GlobalContext);

  const s3Key = useMemo(
    () =>
      src
        ? null
        : isPublic
        ? generateProfileImageKey(profileId)
        : profileId && profileWorkspaceId
        ? generateWorkspaceProfileImageKey(profileId, profileWorkspaceId)
        : null,
    [isPublic, profileId, profileWorkspaceId, src]
  );

  const imageTimeStamp = useMemo(
    () =>
      src
        ? null
        : lastUpdatedImageTimestamp ||
          (profileId === profile?.id ? imageLastUpdatedTimeStamp : null),
    [
      src,
      lastUpdatedImageTimestamp,
      profileId,
      profile?.id,
      imageLastUpdatedTimeStamp,
    ]
  );

  const imageSrc = useMemo(() => {
    if (!!src?.length) return src;
    if (!s3Key || !token) return null;
    return `${serverUrl}/asset/${s3Key}?auth=${token}${
      imageTimeStamp ? `&timestamp=${imageTimeStamp}` : ""
    }`;
  }, [imageTimeStamp, s3Key, src, token]);

  return (
    <Image
      imageSrc={imageSrc}
      alt={alt}
      fallbackFontAwesomeIconClass={fallbackFontAwesomeIconClass}
      profileAbbreviation={profileAbbreviation}
      containerClass={containerClass}
      loadOnDemand={loadOnDemand}
      showLoader={showLoader}
      isImageLoaded={isImageLoaded}
    />
  );
};

export default memo(UserImage);
