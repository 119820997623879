import { ReactNode } from "react";
import cn from "classnames";

import styles from './JourneyGoal.module.css'

interface JourneyGoalProps {
  title: string;
  descriptionAccent: string;
  description: string;
  imageBefore: ReactNode;
  imageAfter: ReactNode;
}

const JourneyGoal = ({ title, descriptionAccent, description, imageBefore, imageAfter }: JourneyGoalProps) => (
  <div className={cn(styles.journeyGoal, "journey-goal")}>
    <div className={styles.topSection}>
      <div className={styles.title}>
        100% {title}
      </div>
      <div className={styles.description}>
        <span className={styles.descriptionAccent}>
          {descriptionAccent}
        </span>
        {description}
      </div>
    </div>
    <div className={styles.imagesContainer}>
      {imageBefore}
      <i className={cn(styles.arrowIcon, "fa-light fa-arrow-right-long")}></i>
      {imageAfter}
    </div>
  </div>
);

export default JourneyGoal;