import { FormEvent, useCallback, useContext, useMemo, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";

import cn from "classnames";
import * as Form from "@radix-ui/react-form";

import withRouteConfig from "../../hocs/withRouteConfig";

import { GlobalContext } from "../../contexts/Global";
import { AuthState } from "../../+xstate/machines/auth";
import { LoginErrors, RefreshTokenErrors } from "../../types/enums/errors";
import { ILogin } from "../../apollo-graphql/types/login";
import { mappedErrorMessages } from "../../constants/mapped-error-messages";

import Copyright from "../../components/Copyright/Copyright";
import Password from "../../components/Shared/Password/Password";

import styles from "./Login.module.css";

const {
  [RefreshTokenErrors.TOKEN_NOT_FOUND]: _,
  ...mappedErrorMessagesForLogin
} = mappedErrorMessages;

function Login() {
  const {
    auth: { login, context, matches },
  } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  const [errorMessage, setErrorMessage] = useState("");

  const isAuthenticating = matches(AuthState.Authenticating);
  const isAuthenticated = matches(AuthState.Authenticated);

  const email = useMemo(
    () => context.unauthenticatedUserEmail || "",
    [context.unauthenticatedUserEmail]
  );
  const redirectUrl = useMemo(
    () => searchParams.get("redirectUrl") || "/",
    [searchParams]
  );

  const loginHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setErrorMessage("");

      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as ILogin;
      if (!payload.email || !payload.password) {
        setErrorMessage(mappedErrorMessages[LoginErrors.MISSING_DATA]);
        return;
      }

      login({
        variables: {
          email: payload.email.toLowerCase(),
          password: payload.password,
        },
      });
    },
    [login]
  );

  return isAuthenticated ? (
    <Navigate to={redirectUrl} />
  ) : (
    <div className={styles.loginContainer}>
      <div className={cn("FormContainer", styles.formContainer)}>
        <h2 className="thin">Login to AhaPlay</h2>
        <Form.Root className={cn("Form", styles.form)} onSubmit={loginHandler}>
          <div className="FormFieldsContainer">
            <Form.Field className="FormField" name="email">
              <Form.Label className="caption medium">Email</Form.Label>
              <Form.Control
                defaultValue={email}
                className="FormControl"
                placeholder="Work email address"
              />
            </Form.Field>
            <Password name="password" label="Password" placeholder="Password" />
          </div>
          <Form.Submit className="btn" disabled={isAuthenticating}>
            Continue with email
          </Form.Submit>
          <Link
            className={cn("text", "secondary", styles.forgotPassword)}
            to={"/reset-password"}
          >
            Forgot your password?
          </Link>
          {(errorMessage || context?.error) && !isAuthenticating && (
            <div className="error">
              {errorMessage ||
                mappedErrorMessagesForLogin[
                  context?.error as keyof typeof mappedErrorMessagesForLogin
                ]}
            </div>
          )}
          {isAuthenticating && <div className="loading">Loading...</div>}
        </Form.Root>
      </div>
      <div className={cn("text", "small", styles.footerMessage)}>
        By pressing on “Continue”, you agree to accept our{" "}
        <span className="underline">Terms of Service</span> &{" "}
        <span className="underline">Privacy Policy</span>.
      </div>

      <Copyright />
    </div>
  );
}

export default withRouteConfig(Login);
