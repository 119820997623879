import JourneyGoal from './JourneyGoal';
import styles from './JourneyGoals.module.css';

interface JourneyGoalsProps {
  topic: string;
}

const JourneyGoals = ({ topic }: JourneyGoalsProps) => (
  <div className={styles.container}>
    <div className={styles.title}>
      With AhaPlay your organisation can reach what was unthinkable until now:
    </div>
    <div className={styles.goals}>
      <JourneyGoal
        title="Awareness"
        descriptionAccent="Full coverage"
        description={` on the ${topic} topic among all of your team members.`}
        imageBefore={<img src='/images/journeyGoal1.svg' alt="100% Awareness before" />}
        imageAfter={<img src='/images/journeyGoal2.svg' alt="100% Awareness after" />}
      />
      <JourneyGoal
        title="Alignment"
        descriptionAccent="Complete alignment"
        description={` on the ${topic} topic among all of your team members.`}
        imageBefore={<img src='/images/journeyGoal2.svg' alt="100% Alignment before" />}
        imageAfter={<img src='/images/journeyGoal3.svg' alt="100% Alignment after" />}
      />
      <JourneyGoal
        title="Attachment"
        descriptionAccent="Strong attachment"
        description={` on the ${topic} topic among all of your team members.`}
        imageBefore={<img src='/images/journeyGoal3.svg' alt="100% Attachment before" />}
        imageAfter={<img src='/images/journeyGoal4.svg' alt="100% Attachment after" />}
      />
    </div>
  </div>
);

export default JourneyGoals;