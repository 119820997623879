import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import { SlotStatus } from "../../../../../../apollo-graphql/types/enums";
import { ISlotInvitation } from "../../../../../../apollo-graphql/types/slot";
import UserAvatar from "../../../../../Shared/UserAvatar/UserAvatar";

import styles from "./WorkshopResultCard.module.css";
import cn from "classnames";
import SkeletonLoader from "../../../../../Shared/SkeletonLoader/SkeletonLoader";
import Tooltip from "../../../../../Shared/Tooltip/Tooltip";

const WorkshopResultCard = (
  props: PropsWithChildren<{
    teamName: string;
    currentLevel: number;
    currentActivity: string | null;
    totalLevels: number | null;
    activityPlayersIds: string[];
    status: SlotStatus.ONGOING | SlotStatus.COMPLETED;
    invitations: ISlotInvitation[];
    sessionKey: string;
    group: string;
    levelProgress: number;
    timeValue?: string;
  }>
) => {
  const {
    teamName,
    currentLevel,
    currentActivity,
    totalLevels,
    activityPlayersIds,
    status,
    invitations,
    sessionKey,
    group,
    levelProgress,
    timeValue,
  } = props;

  const joinLink = useMemo(
    () => `/session/instance/${sessionKey}/${group}`,
    [sessionKey, group]
  );

  const activePlayersContent = useMemo(() => {
    return invitations
      .map((i) => ({
        ...i,
        inActive: activityPlayersIds.includes(i.profile.id) ? 0 : 1,
      }))
      .sort((a, b) => a.inActive - b.inActive)
      .map(({ status, profile, slot_id, inActive }) => {
        return (
          <UserAvatar
            key={`${status}-${slot_id}-${profile.id}`}
            profile={profile}
            status={status}
            inActive={!!inActive}
          />
        );
      });
  }, [activityPlayersIds, invitations]);

  const progress = useMemo(
    () => (levelProgress > 100 ? 100 : levelProgress) + "%",
    [levelProgress]
  );

  const onClickHandler = useCallback(() => {
    if (status !== SlotStatus.COMPLETED) {
      window.open(joinLink, "_blank");
    }
    // TODO: Add logic here for reschedule button type
  }, [joinLink, status]);

  const tooltipActivityMessage = useMemo(
    () => `Activity: ${currentActivity}`,
    [currentActivity]
  );

  return (
    <div
      className={cn(
        styles.workshopResult,
        status === SlotStatus.COMPLETED ? "completed" : "ongoing"
      )}
    >
      <div className="header">
        <div>
          <div className="team-name">{teamName}</div>
          <button onClick={onClickHandler} className="btn small">
            {status === SlotStatus.COMPLETED ? "Go to Summary" : "Join"}
          </button>
        </div>
        {currentActivity ? (
          <Tooltip
            message={tooltipActivityMessage}
            delayDuration={0}
            containerClass={styles.progressTooltipContainer}
            containerStyle={{
              left: `calc(${progress} - 3rem)`,
            }}
          >
            <div
              className={cn(
                styles.progressBar,
                status === SlotStatus.COMPLETED && styles.completedProgress
              )}
              style={{ width: progress }}
            ></div>
          </Tooltip>
        ) : (
          <div
            className={cn(
              styles.progressBar,
              status === SlotStatus.COMPLETED && styles.completedProgress
            )}
            style={{ width: progress }}
          ></div>
        )}
        <div className={styles.progressData}>
          {!timeValue && status !== SlotStatus.COMPLETED ? (
            <SkeletonLoader width={270} height={25} baseColor="#d9d9d5" />
          ) : (
            <>
              <div className="level">
                Step {currentLevel}
                {totalLevels &&
                  `/${currentLevel > totalLevels ? currentLevel : totalLevels}`}
                {currentActivity && (
                  <Tooltip
                    message={tooltipActivityMessage}
                    delayDuration={0}
                    containerClass={styles.tooltipContainer}
                  >
                    <i
                      className="fa-regular fa-circle-info"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </div>
              <div
                className={cn(
                  "time",
                  "text",
                  "tiny",
                  "bold",
                  status === SlotStatus.COMPLETED && styles.completedTime
                )}
              >
                {timeValue}
              </div>
              <div
                className={cn(
                  "status",
                  status === SlotStatus.COMPLETED ? "completed" : "ongoing"
                )}
              >
                {status === SlotStatus.COMPLETED ? "Completed" : "Ongoing"}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="content">
        <div className="active-players-text">
          {!!activityPlayersIds.length
            ? `${activityPlayersIds.length}${
                invitations.length ? `/${invitations.length}` : ""
              } active
          ${activityPlayersIds.length > 1 ? "players" : "player"}`
            : "No active players"}
        </div>
        <div className="active-players-content">{activePlayersContent}</div>
        <div className="spacer"></div>
        <div className="actions">
          <div className="copy">
            <button
              className="btn small ghost"
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.origin}${joinLink}`
                )
              }
            >
              <i className="icon fa fa-clone" style={{ marginRight: "4px" }} />
              {sessionKey}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(WorkshopResultCard);
