import { IInviteProfile } from "../../apollo-graphql/types/invite-profile";
import { ProfileUpdate, ProfileDelete } from "../../apollo-graphql/types/profile";
import { createAction, props } from "../utils";

export const fetchTeamMembers = createAction(
  "[Team Members] Fetching Team Members",
  props<{
    workspaceId: string;
    filters: {
      currentPage?: number;
      pageSize?: number;
      query?: string;
    };
  }>()
);

export const inviteOpen = createAction("[Team Members] Invite Open");

export const inviteSend = createAction(
  "[Team Members] Send",
  props<{ variables: IInviteProfile }>()
);

export const inviteClose = createAction("[Team Members] Invite Close");

export const editOpen = createAction(
  "[Team Members] Edit Open",
  props<{ id: string }>()
);

export const editSubmit = createAction(
  "[Team Members] Edit Team Member",
  props<{ variables: ProfileUpdate }>()
);

export const editClose = createAction("[Team Members] Edit Close");

export const deleteOpen = createAction(
  "[Team Members] Delete Open",
  props<{ id: string }>()
);

export const deleteSubmit = createAction(
  "[Team Members] Delete Team Member",
  props<{ variables: ProfileDelete }>()
);

export const deleteClose = createAction("[Team Members] Delete Close");

export const getPresignedTeamMemberUrl = createAction(
  "[Team Members] Get Presigned Team Image Url",
  props<{ token: string; key: string }>()
);

export const uploadTeamMemberImage = createAction(
  "[Team Members] Upload Team Member Image",
  props<{ url: string; body: File | ReadableStream }>()
);

export const deleteTeamMemberImage = createAction(
  "[Team Members] Delete Team Member Image",
  props<{ key: string; token: string }>()
);
