import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { ICreateSlot, IEditSlot } from "../types/slot";
import { CreateSlotResult } from "../types/results/create-slot.results";
import { EditSlotResult } from "../types/results/edit-slot.results";
import { GET_WORKSHOP_QUERY } from "../constants";

const createSlotMutation = gql`
  mutation Mutation(
    $type: SlotType!
    $scheduleDate: Date
    $workshopId: String!
    $workspaceId: String!
    $participantEmails: [String]
  ) {
    createSlot(
      type: $type
      schedule_date: $scheduleDate
      workshop_id: $workshopId
      workspace_id: $workspaceId
      participant_emails: $participantEmails
    ) {
      id
      creator_id
      ics
      ics_uid
      key
      reminder_status
      schedule_date
      status
      type
      workshop_id
      workspace_id
    }
  }
`;

export function createSlot(client: AppApolloClient, variables: ICreateSlot) {
  return client
    .mutate<CreateSlotResult>({
      mutation: createSlotMutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.createSlot;
    });
}

const editSlotMutation = gql`
  mutation Mutation(
    $id: String!
    $type: SlotType
    $key: String
    $schedule_date: Date
    $workshop_id: String
    $workspace_id: String
    $ics: String
    $ics_uid: String
    $status: SlotStatus
    $reminder_status: SlotReminderStatus
  ) {
    editSlot(
      id: $id
      type: $type
      key: $key
      schedule_date: $schedule_date
      workshop_id: $workshop_id
      workspace_id: $workspace_id
      ics: $ics
      ics_uid: $ics_uid
      status: $status
      reminder_status: $reminder_status
    ) {
      id
      creator_id
      ics
      ics_uid
      invitations {
        email
        emails_count
        profile {
          id
          name
          image
          headline
          email
        }
        slot_id
        status
      }
      key
      profile {
        create_date
        email
        headline
        id
        image
        login_date
        name
        update_date
      }
      reminder_status
      schedule_date
      status
      type
      sessions {
        id
        create_date
        update_date
        complete_date
        session_key
        state
        status
        slot_id
        creator_id
        workshop_id
        workspace_id
      }
      workshop_id
      workspace_id
      workshop {
        ${GET_WORKSHOP_QUERY}
      }
    }
  }
`;

export function editSlot(client: AppApolloClient, variables: IEditSlot) {
  return client
    .mutate<EditSlotResult>({
      mutation: editSlotMutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.editSlot;
    });
}
