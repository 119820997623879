import { SlotStatus } from "../../../apollo-graphql/types/enums";
import { IEditSlot } from "../../../apollo-graphql/types/slot";
import { SortDirection } from "../../../types/enums/sort-direction";
import { createAction, props } from "../../utils";

export const enterAdminDashboard = createAction(
  "[Admin Dashboard] Enter Admin Dashboard"
);
export const enterAdminWorkshops = createAction(
  "[Admin Dashboard] Enter Admin Workshops",
  props<{ searchText: string }>()
);
export const enterAdminJourneys = createAction(
  "[Admin Dashboard] Enter Admin Journeys"
);
export const enterAdminSchedule = createAction(
  "[Admin Dashboard] Enter Admin Schedule",
  props<{
    workspaceId: string | null;
    sortDirection: SortDirection;
    slotStatuses?: SlotStatus[];
    emails?: string[];
  }>()
);

export const editSlot = createAction(
  "[Admin Dashboard Schedule] Edit slot",
  props<IEditSlot>()
);

export const enterAdminTeamMembers = createAction(
  "[Admin Dashboard] Enter Admin Team Members",
  props<{
    workspaceId: string;
    currentPage: number;
    pageSize: number;
    query?: string;
  }>()
);

export const enterAdminJourneyDetails = createAction(
  "[Admin Dashboard] Enter Journey Details",
  props<{
    journeyId: string;
  }>()
);

export const requestJourney = createAction(
  "[Admin Dashboard] Request Journey",
  props<{
    journeyId: string;
    message: string;
  }>()
);

export const requestWorkshop = createAction(
  "[Admin Dashboard] Request Workshop",
  props<{
    id: string;
    message: string;
  }>()
);
