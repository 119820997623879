import { PropsWithChildren, memo } from "react";
import ReactPaginate from "react-paginate";
import styles from "./Pagination.module.css";

const Pagination = (
  props: PropsWithChildren<{
    selectedPage: number;
    onPageChange: (selectedItem: { selected: number }) => void;
    pageRange?: number;
    pageCount: number;
  }>
) => {
  const { selectedPage, onPageChange, pageRange, pageCount } = props;

  if (pageCount <= 1) {
    return null;
  }

  return (
    <ReactPaginate
      forcePage={selectedPage}
      onPageChange={onPageChange}
      pageRangeDisplayed={pageRange ?? 3}
      pageCount={pageCount}
      breakLabel="..."
      nextLabel=">"
      previousLabel="<"
      renderOnZeroPageCount={null}
      containerClassName={styles.containerClassName}
      breakClassName={styles.breakClassName}
      previousClassName={styles.previousClassName}
      nextClassName={styles.nextClassName}
      activeLinkClassName={styles.activeLinkClassName}
      pageLinkClassName={styles.pageLinkBase}
      disabledClassName={styles.disabledClassName}
    />
  );
};

export default memo(Pagination);
