import cn from "classnames";

import styles from './ContentfulRichField.module.css';

interface ContentfulRichFieldProps {
  content: string;
  className?: string;
}

const ContentfulRichField = ({ content, className }: ContentfulRichFieldProps) => (
  <span
    className={cn(styles.container, className)}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

export default ContentfulRichField;