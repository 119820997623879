import { useCallback, useMemo } from "react";
import { Journey } from "../../../../../../types/contentful/workshop/journey";
import Image from "../../../../../Shared/Image/Image";
import JourneyAggregates from "../JourneyDescription/JourneyAggregates/JourneyAggregates";
import cn from "classnames";
import { Link } from "react-router-dom";
import {
  calculateAhaMomentsCount,
  calculateBehavioursCount,
  calculateJourneyDuration,
  getJourneyType,
} from "../../utils";
import { Profile } from "../../../../../../apollo-graphql/types";
import { useJourneyStatus } from "../../../../../../hooks/useJourneyStatus";
import JourneyTags from "../../JourneyDetails/JourneyTags/JourneyTags";
import ContentfulRichField from "../../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./JourneyListItem.module.css";

interface JourneyListItemProps {
  journey: Journey;
  profile: Profile | null;
}

const JourneyListItem = ({ journey, profile }: JourneyListItemProps) => {
  const aggregates = useMemo(
    () => ({
      conversations: {
        quantity: journey?.fields?.workshops?.length || 0,
      },
      hours: {
        quantity: calculateJourneyDuration(journey),
      },
      moments: {
        quantity: calculateAhaMomentsCount(journey?.fields?.workshops),
      },
      behaviours: {
        quantity: calculateBehavioursCount(journey?.fields?.workshops),
      },
    }),
    [journey]
  );

  const journeyStatus = useJourneyStatus(journey, profile);

  const generateJourneyImage = useCallback((journey: Journey) => {
    const { bigBannerImage, author, title } = journey.fields;
    const bannerUrl = bigBannerImage?.fields?.file?.url;
    const authorImageUrl = author.fields.image.fields.file.url;
    const authorName = author.fields.name;
    const authorHeadline = author.fields.headline;

    return (
      <div className={styles.journeyImage}>
        {bannerUrl && (
          <Image
            imageSrc={bannerUrl}
            alt={title}
            className={styles.journeyBannerImage}
            skeletonLoader={{ width: 620, height: 400 }}
          />
        )}

        <div
          className={cn(styles.content, {
            [styles.contentBackground]: bannerUrl,
          })}
        >
          <div className={styles.authorImage}>
            <Image
              imageSrc={authorImageUrl}
              alt={authorName}
              skeletonLoader={{ width: 96, height: 96 }}
            />
          </div>

          <div className={styles.authorInfo}>
            <h2>by {authorName}</h2>
            <ContentfulRichField
              content={authorHeadline}
              className={styles.authorHeadline}
            />
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={styles.journeySection} key={journey.sys.id}>
      {generateJourneyImage(journey)}
      <div className={styles.journeyCard}>
        <div className={styles.tags}>
          <div className={styles.activeTag}>
            <p>
              {getJourneyType(
                journey?.fields.author.fields.name || "",
                profile?.workspace.workspace.name || ""
              )}
            </p>
          </div>
          <div className={styles.status}>
            <p>{journeyStatus}</p>
          </div>
          <JourneyTags
            tags={journey.fields.tags}
          />
        </div>
        <JourneyAggregates
          {...aggregates}
          className={styles.journeyListAggregates}
        />
        <div className={styles.info}>
          <h2>{journey.fields.title}</h2>
          <ContentfulRichField
            content={journey.fields.longDescription}
            className={styles.longDescription}
          />
        </div>
        <Link
          to={`/journeys/${journey.sys.id}`}
          className={cn("btn small", styles.journeyBtn)}
        >
          Go to the journey and learn more{" "}
          <i className="fa-solid fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default JourneyListItem;
