import { checkAudioAndVideoPermissions } from "./utils/check-audio-video-permissions";

const domain = "vc.ahaplay.com";

function generateServiceUrl(room: string): string {
  return `https://${domain}/http-bind?room=${room}`;
}

const options = {
  hosts: {
    domain: domain,
    muc: `conference.${domain}`,
    focus: `focus.${domain}`,
  },
  bridgeChannel: {},
  testing: {},
  enableNoisyMicDetection: true,
  enableNoAudioDetection: true,
  channelLastN: -1,
  bosh: `https://${domain}/http-bind`,
  serviceUrl: `WE WILL SET THIS WHEN INITIALIZING USING generateServiceUrl(roomName: string)`,
  // clientNode: "http://jitsi.org/jitsimeet",
  p2p: {
    enabled: false,
    useStunTurn: true,
    stunServers: [
      {
        urls: `stun:turn-${domain}:443`,
      },
    ],
  },
  analytics: {},
  mouseMoveCallbackInterval: 1000,
  useStunTurn: true,
  flags: {
    sourceNameSignaling: true,
    sendMultipleVideoStreams: true,
    receiveMultipleVideoStreams: true,
  },
  prejoinConfig: {},
  toolbarConfig: {},
  disabledSounds: [],
  e2ee: {},
  transcription: {},
  recordingService: {},
  liveStreaming: {},
  speakerStats: {},
};

const forceReloadedWindowSessionClearTimeout = 1000 * 60 * 5; // 5 min
const forceReloadedWindowSessionStorageKey = "forceReloadedWindow";

export enum JitsiEvents {
  CONNECTION_ESTABLISHED = "CONNECTION_ESTABLISHED",
  CONNECTION_FAILURE = "CONNECTION_FAILURE",
  CONNECTION_DISCONNECTED = "CONNECTION_DISCONNECTED",
  LOCAL_VIDEO_TRACK_READY = "LOCAL_VIDEO_TRACK_READY",
  LOCAL_AUDIO_TRACK_READY = "LOCAL_AUDIO_TRACK_READY",
  LOCAL_AUDIO_AND_VIDEO_TRACK_READY = "LOCAL_AUDIO_AND_VIDEO_TRACK_READY",
  LOCAL_AUDIO_AND_VIDEO_CONFIG_READY = "LOCAL_AUDIO_AND_VIDEO_CONFIG_READY",
  CONFERENCE_JOINED = "CONFERENCE_JOINED",
  REMOTE_TRACK_ADDED = "REMOTE_TRACK_ADDED",
  TRACK_AUDIO_LEVEL_CHANGED = "TRACK_AUDIO_LEVEL_CHANGED",
  LOCAL_TRACK_AUDIO_LEVEL_CHANGED = "LOCAL_TRACK_AUDIO_LEVEL_CHANGED",
  REMOTE_TRACK_REMOVED = "REMOTE_TRACK_REMOVED",
  REMOTE_TRACK_MUTED = "REMOTE_TRACK_MUTED",
  VIDEO_PLAYING = "VIDEO_PLAYING",
  VIDEO_PAUSED = "VIDEO_PAUSED",
  VIDEO_TRACK_FAILURE = "VIDEO_TRACK_FAILURE",
  USER_LEFT = "USER_LEFT",
  USER_JOINED = "USER_JOINED",
  USER_NAME_CHANGED = "USER_NAME_CHANGED",
  EDITOR_AWARENESS_CHANGE = "EDITOR_AWARENESS_CHANGE",
  VIDEO_PERMISSION_DENIED = "VIDEO_PERMISSION_DENIED",
  AUDIO_PERMISSION_DENIED = "AUDIO_PERMISSION_DENIED",
}

export enum JitsiCustomCommands {
  USER_NAME_CHANGED = "USER_NAME_CHANGED",
  EDITOR_AWARENESS_CHANGE = "EDITOR_AWARENESS_CHANGE",
}

export type AvailableSources = {
  audio: MediaDeviceInfo[];
  video: MediaDeviceInfo[];
};

export class Jitsi extends EventTarget {
  localTrackVideoElement: HTMLVideoElement | null = null;
  connection: any = null;
  roomName: string | null = null;
  profileId = "";
  localTracks: any[] = [];
  remoteTracks: Record<string, any[]> = {};
  conference: any;
  intervalId: number | null = null;
  options: any | null = null;

  constructor() {
    super();
    window.__jitsi = this;
    if (this.getHasForceReloadedWindow()) {
      setTimeout(() => {
        this.setHasForceReloadedWindow(false);
      }, forceReloadedWindowSessionClearTimeout);
    }
  }

  emit = (event: JitsiEvents) => {
    this.dispatchEvent(new Event(event));
  };

  initializeReady!: Promise<void>;
  init = (profileId: string, localTrackVideoElement: HTMLVideoElement) => {
    if (this.connection) return console.log("Jitsi already initialized!");
    this.profileId = profileId;
    this.localTrackVideoElement = localTrackVideoElement;
    this.initializeReady = checkAudioAndVideoPermissions()
      .then(() => this.createAudioTrack())
      .then(() => this.createVideoTrack())
      .then(() => {
        if (!this.localTrackVideoElement) return;
        this.attachLocalTracksVideoContainer(this.localTrackVideoElement);
        this.emit(JitsiEvents.LOCAL_AUDIO_AND_VIDEO_CONFIG_READY);
      });

    JitsiMeetJS.init();
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
  };

  setupConnection = (roomName: string) => {
    if (this.roomName === roomName)
      return console.log(
        "A conference with the same name has already been created!"
      );

    this.roomName = roomName;
    this.options = { ...options };
    this.options.serviceUrl = generateServiceUrl(roomName);
    this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.options);

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionEstablished
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.onConnectionDisconnected
    );
    this.connection.connect();
  };

  reconnect = () => {
    if (this.connection) {
      this.connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.onConnectionEstablished
      );

      this.connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        this.onConnectionFailed
      );

      this.connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        this.onConnectionDisconnected
      );
    } else {
      this.connection = new JitsiMeetJS.JitsiConnection(null, null, options);

      this.connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.onConnectionEstablished
      );

      this.connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        this.onConnectionFailed
      );

      this.connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        this.onConnectionDisconnected
      );
    }

    this.connection.connect();
  };

  onConferenceJoined = () => {
    const participants = this.conference.getParticipants();
    for (const participant of participants) {
      const participantId = participant.id;
      const remoteTracks = participant.getTracks();
      this.remoteTracks[participantId] = remoteTracks;
    }
    this.emit(JitsiEvents.CONFERENCE_JOINED);
  };

  onConnectionEstablished = () => {
    if (this.intervalId) {
      clearTimeout(this.intervalId);
      this.intervalId = null;
    }
    this.emit(JitsiEvents.CONNECTION_ESTABLISHED);
    this.conference = this.connection.initJitsiConference(
      this.roomName,
      this.options
    );

    this.conference.setDisplayName(this.profileId);

    this.conference.addCommandListener(
      JitsiCustomCommands.USER_NAME_CHANGED,
      this.onUserNameChanged
    );

    this.conference.addCommandListener(
      JitsiCustomCommands.EDITOR_AWARENESS_CHANGE,
      this.onEditorAwarenessChanged
    );

    this.conference.on(
      JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      this.onConferenceJoined
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_ADDED,
      this.onTrackAdded
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_REMOVED,
      this.onTrackRemoved
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
      this.onTrackMuted
    );
    this.conference.on(
      JitsiMeetJS.events.conference.USER_LEFT,
      this.onUserLeft
    );
    this.conference.on(
      JitsiMeetJS.events.conference.USER_JOINED,
      this.onUserJoined
    );

    this.setupLocalTracks();
  };

  setupLocalTracks = async () => {
    await this.initializeReady;

    for (const track of this.localTracks) {
      this.conference.addTrack(track);
      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        (audioLevel: any) => {
          this.dispatchEvent(
            new CustomEvent(JitsiEvents.LOCAL_TRACK_AUDIO_LEVEL_CHANGED, {
              detail: { audioLevel },
            })
          );
        }
      );
    }

    for (const localTrack of this.localTracks) {
      localTrack.attach(this.localTrackVideoElement);
    }

    this.emit(JitsiEvents.LOCAL_AUDIO_AND_VIDEO_TRACK_READY);
  };

  watchPermissionsChanged = async () => {
    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.PERMISSIONS_CHANGED,
      async (event: { video: boolean; audio: boolean }) => {
        const { audio, video } = event;

        if (
          audio &&
          (!this.localTracks.length ||
            !this.localTracks.some((track) => track.getType() === "audio"))
        ) {
          await this.createAudioTrack();
        }

        if (this.localTracks && !audio) {
          for (const track of this.localTracks) {
            if (track.getType() === "audio") {
              await track.dispose();

              const videoTrack = this.localTracks.find(
                (track) => track.getType() === "video"
              );
              if (videoTrack) {
                videoTrack.detach(this.localTrackVideoElement);
                videoTrack.attach(this.localTrackVideoElement);
              }

              this.emit(JitsiEvents.AUDIO_PERMISSION_DENIED);
            }
          }
        }

        if (
          video &&
          (!this.localTracks.length ||
            !this.localTracks.some((track) => track.getType() === "video"))
        ) {
          await this.createVideoTrack();
        }

        if (this.localTracks && !video) {
          for (const track of this.localTracks) {
            if (track.getType() === "video") {
              await track.dispose();
              this.emit(JitsiEvents.VIDEO_PERMISSION_DENIED);
            }
          }
        }
      }
    );
  };

  createAudioTrack = () => {
    return JitsiMeetJS.createLocalTracks({ devices: ["audio"] })
      .then((tracks: any) => {
        for (const track of tracks) {
          this.conference?.addTrack(track);
          track.addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
            (audioLevel: any) => {
              this.dispatchEvent(
                new CustomEvent(JitsiEvents.LOCAL_TRACK_AUDIO_LEVEL_CHANGED, {
                  detail: { audioLevel },
                })
              );
            }
          );
        }

        this.localTracks = [...this.localTracks, ...tracks];
        this.emit(JitsiEvents.LOCAL_AUDIO_TRACK_READY);
      })
      .catch((err: any) => {
        console.error("createLocalTracks error", err);
      });
  };

  createVideoTrack = () => {
    return JitsiMeetJS.createLocalTracks({ devices: ["video"] })
      .then((tracks: any) => {
        const videoTrack = tracks.find((t: any) => t.getType() === "video");
        if (!videoTrack) {
          this.emit(JitsiEvents.VIDEO_TRACK_FAILURE);
        }

        this.localTracks = [...this.localTracks, ...tracks];
        for (const localTrack of tracks) {
          localTrack.attach(this.localTrackVideoElement);
        }
        this.emit(JitsiEvents.LOCAL_VIDEO_TRACK_READY);
      })
      .catch((err: any) => {
        console.error("createLocalTracks error", err);
      });
  };

  toggleAudioMute = async (deviceId: string, desiredMuteState: boolean) => {
    const audioTrack = this.localTracks.find((t) => t.getType() === "audio");
    if (!audioTrack) {
      await this.changeAudioSource(deviceId);
      return;
    }

    try {
      const currentMuteState = audioTrack.isMuted();
      if (currentMuteState === desiredMuteState)
        return Promise.resolve(currentMuteState);

      const operation = currentMuteState ? "unmute" : "mute";
      return audioTrack[operation]().then(() => audioTrack.isMuted());
    } catch (error) {
      this.handleJitsiError(error);
    }
  };

  toggleVideoMute = async (deviceId: string, desiredMuteState: boolean) => {
    const videoTrack = this.localTracks.find((t) => t.getType() === "video");
    if (!videoTrack) {
      await this.changeVideoSource(deviceId);
      return;
    }

    try {
      const currentMuteState = videoTrack.isMuted();
      if (currentMuteState === desiredMuteState)
        return Promise.resolve(currentMuteState);

      const operation = currentMuteState ? "unmute" : "mute";
      return videoTrack[operation]().then(() => videoTrack.isMuted());
    } catch (error) {
      this.handleJitsiError(error);
    }
  };

  changeSource = (type: "audio" | "video", deviceId: string): Promise<any> => {
    return type === "audio"
      ? this.changeAudioSource(deviceId)
      : this.changeVideoSource(deviceId);
  };

  changeVideoSource = (deviceId: string): Promise<any> => {
    if (!deviceId) {
      return Promise.resolve();
    }

    return JitsiMeetJS.createLocalTracks({
      devices: ["video"],
      firePermissionPromptIsShownEvent: true,
      cameraDeviceId: deviceId,
    })
      .then((tracks: any) => {
        if (!this.conference) {
          tracks.forEach((track: any) => track.dispose());
          return;
        }

        const newVideoTrack = tracks.find(
          (track: any) => track.getType() === "video"
        );
        const currentLocalVideoTrack = this.getLocalVideoTrack();

        const removeVideoPromise = currentLocalVideoTrack
          ? (this.conference?.removeTrack(
              currentLocalVideoTrack
            ) as Promise<any>)
          : Promise.resolve();

        return removeVideoPromise
          ?.then(() => {
            if (currentLocalVideoTrack) {
              return currentLocalVideoTrack.dispose();
            }
          })
          .then(() => {
            if (newVideoTrack) {
              this.conference.addTrack(newVideoTrack);
              this.localTracks = this.localTracks
                .filter((track) => track.getType() !== "video")
                .concat(newVideoTrack);
              newVideoTrack.attach(this.localTrackVideoElement);
            }

            return newVideoTrack;
          });
      })
      .catch((e: any) => {
        console.error("change video source error: ", e);
      });
  };

  handleJitsiError(error: any) {
    const hasForceReloadedWindow = this.getHasForceReloadedWindow();
    if (!hasForceReloadedWindow) {
      this.setHasForceReloadedWindow(true);
      window.location.reload();
      return;
    }
    throw error;
  }

  changeAudioSource = (deviceId: string): Promise<any> => {
    if (!deviceId) {
      return Promise.resolve();
    }

    return JitsiMeetJS.createLocalTracks({
      devices: ["audio"],
      micDeviceId: deviceId,
    }).then((tracks: any) => {
      const newAudioTrack = tracks.find(
        (track: any) => track.getType() === "audio"
      );
      const currentLocalAudioTrack = this.getLocalAudioTrack();
      const videoTrack = this.localTracks.find(
        (track) => track.getType() === "video"
      );

      const removeAudioPromise = currentLocalAudioTrack
        ? (this.conference?.removeTrack(currentLocalAudioTrack) as Promise<any>)
        : Promise.resolve();

      return removeAudioPromise
        ?.then(() => {
          if (currentLocalAudioTrack) {
            return currentLocalAudioTrack.dispose();
          }
        })
        .then(() => {
          if (videoTrack) {
            videoTrack.detach(this.localTrackVideoElement);
            videoTrack.attach(this.localTrackVideoElement);
          }

          if (newAudioTrack) {
            this.conference.addTrack(newAudioTrack);
            this.localTracks = this.localTracks
              .filter((track) => track.getType() !== "audio")
              .concat(newAudioTrack);
            newAudioTrack.attach(currentLocalAudioTrack);

            newAudioTrack.addEventListener(
              JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
              (audioLevel: any) => {
                this.dispatchEvent(
                  new CustomEvent(JitsiEvents.LOCAL_TRACK_AUDIO_LEVEL_CHANGED, {
                    detail: { audioLevel },
                  })
                );
              }
            );
          }

          if (videoTrack) {
            videoTrack.attach(this.localTrackVideoElement);
          }

          return newAudioTrack;
        });
    });
  };

  onConnectionFailed = () => {
    this.emit(JitsiEvents.CONNECTION_FAILURE);
    this.intervalId = setTimeout(
      () => this.reconnect(),
      3000
    ) as unknown as number;
  };

  onConnectionDisconnected = () => {
    this.emit(JitsiEvents.CONNECTION_DISCONNECTED);
  };

  onVideoPlaying = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PLAYING, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  onVideoPaused = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PAUSED, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  onAudioPlaying = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PLAYING, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  onAudioPaused = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PAUSED, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  attachVideoElementToRemoteTracks = (
    participantId: any,
    remoteTrackVideoElement: HTMLVideoElement,
    remoteTrackAudioElement: HTMLAudioElement
  ) => {
    const participantTracks = this.remoteTracks[participantId];
    if (!participantTracks || participantTracks.length === 0) return;
    for (const track of participantTracks) {
      const previousContainer = track.containers[0];
      if (track.getType() === "video") {
        if (previousContainer === remoteTrackVideoElement) continue;
        if (previousContainer) {
          track.detach(previousContainer);
          previousContainer.removeEventListener("playing", this.onVideoPlaying);
          previousContainer.removeEventListener("pause", this.onVideoPaused);
        }
        track.attach(remoteTrackVideoElement);
      } else {
        if (previousContainer === remoteTrackAudioElement) continue;
        if (previousContainer) {
          track.detach(previousContainer);
          previousContainer.removeEventListener("playing", this.onAudioPlaying);
          previousContainer.removeEventListener("pause", this.onAudioPaused);
        }
        track.attach(remoteTrackAudioElement);
      }
    }

    const isSetup = remoteTrackVideoElement.hasAttribute("data-participant-id");
    if (!isSetup) {
      remoteTrackVideoElement.setAttribute(
        "data-participant-id",
        participantId
      );
      remoteTrackAudioElement.setAttribute(
        "data-participant-id",
        participantId
      );

      remoteTrackVideoElement.addEventListener("playing", this.onVideoPlaying);
      remoteTrackVideoElement.addEventListener("pause", this.onVideoPaused);

      remoteTrackAudioElement.addEventListener("playing", this.onAudioPlaying);
      remoteTrackAudioElement.addEventListener("pause", this.onAudioPaused);
    }
  };

  detachVideoElementToRemoteTracks = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const participantTracks = this.remoteTracks[participantId];
    if (!participantTracks || participantTracks.length === 0) return;
    for (const track of participantTracks) {
      track.containers.forEach((container: HTMLVideoElement) => {
        const isSetup = container.hasAttribute("data-participant-id");
        if (isSetup) {
          container.addEventListener("playing", this.onVideoPlaying);
          container.addEventListener("pause", this.onVideoPaused);
          container.removeAttribute("data-participant-id");
        }
        track.detach(container);
      });
    }
  };

  detachLocalTracksVideoContainer = (
    localTrackVideoElement?: HTMLVideoElement
  ) => {
    for (const localTrack of this.localTracks) {
      const container = localTrackVideoElement || localTrack.containers[0];
      if (container) localTrack.detach(container);
    }
  };

  attachLocalTracksVideoContainer = (
    localTrackVideoElement: HTMLVideoElement
  ) => {
    for (const localTrack of this.localTracks) {
      const previousContainer = localTrack.containers[0];
      if (previousContainer) localTrack.detach(previousContainer);
      localTrack.attach(localTrackVideoElement);
    }
  };

  onTrackAdded = (track: any) => {
    if (track.isLocal() && !this.localTracks.find((t) => t === track)) {
      this.localTracks = this.localTracks.concat(track);
      track.attach(this.localTrackVideoElement);
    } else if (!track.isLocal()) {
      const participant = track.getParticipantId();
      this.remoteTracks[participant] = (
        this.remoteTracks[participant] || []
      ).concat(track);
      this.dispatchEvent(
        new CustomEvent(JitsiEvents.REMOTE_TRACK_ADDED, { detail: track })
      );

      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        (audioLevel: any) => {
          this.dispatchEvent(
            new CustomEvent(JitsiEvents.TRACK_AUDIO_LEVEL_CHANGED, {
              detail: { track, audioLevel },
            })
          );
        }
      );
    }
  };

  onUserLeft = (participantId: string) => {
    const participantTracks = this.remoteTracks[participantId];

    const disposePromises = participantTracks.map((track) => track.dispose());
    Promise.all(disposePromises).then(() => {
      this.remoteTracks[participantId] = [];
      this.dispatchEvent(
        new CustomEvent(JitsiEvents.USER_LEFT, { detail: participantId })
      );
    });
  };
  onUserJoined = (participantId: string) => {
    this.remoteTracks[participantId] = [];
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.USER_JOINED, { detail: participantId })
    );
  };

  onUserNameChanged = (data: { participantId: string; name: string }) => {
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.USER_NAME_CHANGED, { detail: data })
    );
  };

  onEditorAwarenessChanged = (data: {
    participantId: string;
    name: string;
  }) => {
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.EDITOR_AWARENESS_CHANGE, { detail: data })
    );
  };

  onTrackRemoved = (track: any) => {
    if (track.isLocal()) {
      this.localTracks = this.localTracks.filter((t) => t !== track);
      track.detach(this.localTrackVideoElement);
    } else {
      const participant = track.getParticipantId();
      this.remoteTracks[participant] = (
        this.remoteTracks[participant] || []
      ).filter((t) => t !== track);
      this.dispatchEvent(
        new CustomEvent(JitsiEvents.REMOTE_TRACK_REMOVED, { detail: track })
      );
    }
  };

  onTrackMuted = (track: any) => {
    if (track.isLocal()) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.REMOTE_TRACK_MUTED, { detail: track })
    );
  };

  detachLocalVideoElement = () => {
    for (const track of this.localTracks) {
      track.detach(this.localTrackVideoElement);
    }
    this.localTrackVideoElement = null;
  };

  attachLocalVideoElement = (newLocalVideoElement: HTMLVideoElement) => {
    if (this.localTrackVideoElement === newLocalVideoElement) return;
    // Detach the video track from the old video element
    if (this.localTrackVideoElement) {
      for (const track of this.localTracks) {
        if (track.getType() === "video") {
          track.detach(this.localTrackVideoElement);
        }
      }
    }

    // Attach the video track to the new video element
    for (const track of this.localTracks) {
      if (track.getType() === "video") {
        track.attach(newLocalVideoElement);
      }
    }

    // Update the local video element reference
    this.localTrackVideoElement = newLocalVideoElement;
  };

  availableAudioDevices: AvailableSources["audio"] = [];
  availableVideoDevices: AvailableSources["video"] = [];
  getAvailableSources = (): Promise<AvailableSources> => {
    this.availableAudioDevices = [];
    this.availableVideoDevices = [];
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return devices.reduce(
          (acc, device) => {
            if (device.kind === "audioinput") {
              acc.audio.push(device);
              this.availableAudioDevices.push(device);
            } else if (device.kind === "videoinput") {
              acc.video.push(device);
              this.availableVideoDevices.push(device);
            }
            return acc;
          },
          { audio: [], video: [] } as AvailableSources
        );
      })
      .catch(() => ({ audio: [], video: [] } as AvailableSources));
  };

  getLocalAudioTrack = () => {
    return this.localTracks.find((t) => t.getType() === "audio");
  };
  getLocalVideoTrack = () => {
    return this.localTracks.find((t) => t.getType() === "video");
  };
  getParticipantData = (participantId: string) => {
    return this.conference.getParticipantById(participantId);
  };

  sendCommand = <T>(
    command: JitsiCustomCommands,
    {
      attributes,
      children,
    }: {
      attributes: T;
      children?: [];
    }
  ) => {
    if (!this.conference) return;
    const commandData = {
      attributes: attributes || {},
      children: children || [],
    };
    this.conference.sendCommandOnce(command, commandData);
  };

  getHasForceReloadedWindow = (): boolean => {
    const val = sessionStorage.getItem(forceReloadedWindowSessionStorageKey);
    if (!val) return false;
    return val === "true";
  };
  setHasForceReloadedWindow = (val = true) => {
    sessionStorage.setItem(
      forceReloadedWindowSessionStorageKey,
      val.toString()
    );
  };

  cleanup = () => {
    window.__jitsi = null;

    const trackCleanUp = (t: any) => t.dispose();
    const disposeLocalTracks = Promise.all([
      ...this.localTracks.map(trackCleanUp),
    ]);

    disposeLocalTracks.then(() => {
      this.conference?.leave();
      this.connection?.disconnect();
      this.remoteTracks = {};
      this.localTracks = [];
      this.localTrackVideoElement = null;
      this.connection = null;
      this.conference = null;
      this.roomName = "";
      this.profileId = "";
    });
  };
}
