import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { WorkshopSessions } from "../types/results/workshops-sessions-result";
import { Slot } from "../types/slot";

const query = gql`
  query GetSessionsByWorkshopIdsForCurrentProfile($workshopIds: [String]!) {
    getSessionsByWorkshopIdsForCurrentProfile(workshopIds: $workshopIds) {
      id
      workshop_id
      session_key
      complete_date
    }
  }
`;

const getNextWorkshopQuery = gql`
  query GetSlots($workspaceId: String, $emails: [String]) {
    getSlots(workspace_id: $workspaceId, emails: $emails) {
      id
      schedule_date
      status
      workshop_id
    }
  }
`;

export function getNextWorkshop(
  client: AppApolloClient,
  variables: {
    workshop_id: string;
    emails: string[];
  }
) {
  return client
    .query<{ getSlots: Slot[] }>({
      query: getNextWorkshopQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

export function getWorkshopsSessions(
  client: AppApolloClient,
  variables: { workshopIds: string[] }
) {
  return client
    .query<WorkshopSessions>({
      query,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);

      return result.data;
    });
}
