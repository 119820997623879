import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { JourneyLeader } from "../types";

const query = gql`
  query GetJourneyLeaders($journeyId: String!, $workspaceId: String) {
    getJourneyLeaders(journeyId: $journeyId, workspaceId: $workspaceId) {
      id
      create_date
      update_date
      journey_id
      workspace_id
      profile_id
    }
  }
`;

export function getJourneyLeaders(
  client: AppApolloClient,
  variables: { journeyId: string; workspaceId?: string }
) {
  return client
    .query<{ getJourneyLeaders: JourneyLeader[] }>({
      query,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getJourneyLeaders;
    });
}
