import { Asset, Metadata, Sys } from "../../common";

export enum OpeningActivityLayoutItemTypes {
  Objectives = "openningLayoutItemObjectives",
  Journey = "openningLayoutItemJourney",
  Timing = "openningLayoutItemTiming",
  Author = "openningLayoutItemAuthor",
  PeopleInvited = "openningLayoutItemPeopleInvited",
}

interface OpeningActivityFieldsLayoutItemFields {
  title: string;
  icon?: Asset;
}

interface OpeningActivityFieldsLayoutItem {
  metadata: Metadata;
  sys: Sys;
  fields: OpeningActivityFieldsLayoutItemFields;
}

interface OpeningActivityFieldsLayoutFields {
  title: string;
  numberOfColumns: number;
  items: OpeningActivityFieldsLayoutItem[];
}

export interface OpeningActivityFieldsLayout {
  metadata: Metadata;
  sys: Sys;
  fields: OpeningActivityFieldsLayoutFields;
}

interface OpeningActivityFields {
  title: string;
  layouts: OpeningActivityFieldsLayout[];
}

export interface OpeningActivity {
  metadata: Metadata;
  sys: Sys;
  fields: OpeningActivityFields;
}
