import {
  PropsWithChildren,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import cn from "classnames";

import {
  WorkshopClock,
  WorkshopClockEvent,
} from "../../../../helpers/workshop-clock";
import { getHours, getMinutes, getUnixTime } from "date-fns";
import { ApolloContext, SERVER_TIME_UPDATE } from "../../../../contexts/Apollo";
import { WorkshopState } from "../../../../+xstate/machines/session/workshop";
import { Slot } from "../../../../apollo-graphql/types/slot";
import { FooterType } from "../../../../types/enums/activity-footer";
import { Workshop } from "../../../../types/contentful/workshop/workshop";
import { OpeningActivity } from "../../../../types/contentful/workshop/activities/opening";

import { stepsSelectors } from "../../../Guide/steps";
import { observerFooterData } from "../constants";

import WorkshopDetails, {
  WorkshopDetailProps,
} from "./components/WorkshopDetails/WorkshopDetails";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import ActionFooter from "../../ActionFooter/ActionFooter";

import styles from "./Opening.module.css";

const Opening = (
  props: PropsWithChildren<
    {
      activity: OpeningActivity;
      slot: Slot;
      isReady: boolean;
      transition: number;
      workshopState: WorkshopState;
      isParticipating: boolean;
      notReadyProfilesCount: number;
      invitedParticipantCount: number;
      currentActiveParticipantCount: number;
      minimumWorkshopParticipants: number;
      millisecondsToStart: number | null;
      isConnectionWeak: boolean;
      journeyItems: Workshop[] | null;
      setActivityReadyToStartHandler: () => void;
    } & Omit<WorkshopDetailProps, "layouts">
  >
) => {
  const {
    slot,
    author,
    isReady,
    activity,
    workshop,
    profileId,
    transition,
    workshopState,
    isParticipating,
    notReadyProfilesCount,
    invitedParticipantCount,
    millisecondsToStart,
    minimumWorkshopParticipants,
    isConnectionWeak,
    currentActiveParticipantCount,
    journeyItems,
    setActivityReadyToStartHandler,
  } = props;

  const { serverTimeEventTarget } = useContext(ApolloContext);

  const [isTimeToStart, setIsTimeToStart] = useState<boolean>(
    !millisecondsToStart || millisecondsToStart <= 0
  );
  const [startTimeString, setStartTimeString] = useState<string>("--:--");
  const [buttonText, setButtonText] = useState<string>("");

  const clockRef = useRef<WorkshopClock | null>(null);

  const defaultButtonText = useMemo(
    () => (!isParticipating ? observerFooterData.buttonText : "Let's start"),
    [isParticipating]
  );
  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const isLoading = useMemo(
    () => workshopState === WorkshopState.SettingReadyToStart,
    [workshopState]
  );

  const layouts = useMemo(
    () => activity?.fields?.layouts || [],
    [activity?.fields?.layouts]
  );

  const footerText = useMemo(() => {
    if (!isParticipating) return observerFooterData.text;
    if (isTimeToStart) {
      if (currentActiveParticipantCount < minimumWorkshopParticipants)
        // TODO @ak Take reconnect timeouts into account here when counting
        return <>Waiting for other players to join</>;
      if (notReadyProfilesCount > 0 && transition === 0) {
        if (notReadyProfilesCount === currentActiveParticipantCount) {
          return (
            <>
              Once all players join, everyone has to click{" "}
              <span className="accent success">“Let's start”</span> to begin.
            </>
          );
        }

        const playersClicked =
          currentActiveParticipantCount - notReadyProfilesCount;

        if (!isReady && playersClicked) {
          return (
            <>
              Once all players join, everyone has to click{" "}
              <span className="accent success">“Let's start”</span> to begin.{" "}
              {playersClicked > 0 && (
                <span className="accent">
                  {playersClicked} player{playersClicked > 1 && "s"} clicked.
                </span>
              )}
            </>
          );
        }

        return (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        );
      }
      return <>Everyone is ready. Continuing forward...</>;
    }
    return (
      <>
        You will be able to start the conversation{" "}
        <span className="time">at {startTimeString}</span>
      </>
    );
  }, [
    currentActiveParticipantCount,
    isParticipating,
    isReady,
    isTimeToStart,
    minimumWorkshopParticipants,
    notReadyProfilesCount,
    startTimeString,
    transition,
  ]);

  const footerType: FooterType = useMemo(
    () =>
      !isParticipating
        ? observerFooterData.type
        : transition > 0
        ? FooterType.Ready
        : isReady && !isLoading
        ? FooterType.Waiting
        : FooterType.Notice,
    [isParticipating, transition, isReady, isLoading]
  );

  const footerButtonDisabled = useMemo(
    () =>
      isReady ||
      (footerType === FooterType.Observer
        ? false
        : currentActiveParticipantCount < minimumWorkshopParticipants) ||
      (footerType !== FooterType.Notice &&
        footerType !== FooterType.Observer) ||
      buttonText !== defaultButtonText ||
      isTransitioning,
    [
      isReady,
      currentActiveParticipantCount,
      minimumWorkshopParticipants,
      footerType,
      buttonText,
      defaultButtonText,
      isTransitioning,
    ]
  );

  const showInvitedPeople = useMemo(() => {
    return slot.schedule_date !== slot.create_date;
  }, [slot.create_date, slot.schedule_date]);

  useEffect(() => {
    if (isTimeToStart) {
      setButtonText(defaultButtonText);
      return;
    }

    const serverTimeEventHandler = (event: Event) => {
      const currentServerTime = (event as any).detail;
      const secondsToStart = slot.schedule_date.valueOf() - currentServerTime;
      const new_isTimeToStart = secondsToStart <= 0;

      setIsTimeToStart(new_isTimeToStart);
      if (new_isTimeToStart) {
        serverTimeEventTarget.removeEventListener(
          SERVER_TIME_UPDATE,
          serverTimeEventHandler
        );
        clockRef.current?.dispose();
        setButtonText(defaultButtonText);
        return;
      }

      const now = getUnixTime(new Date());
      const localStartUnixTime = now + secondsToStart;

      const hours = getHours(localStartUnixTime * 1000);
      const minutes = getMinutes(localStartUnixTime * 1000);
      const hoursText = hours < 10 ? `0${hours}` : `${hours}`;
      const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
      setStartTimeString(`${hoursText}:${minutesText}`);

      if (!clockRef.current) {
        clockRef.current = new WorkshopClock({
          durationInSeconds: secondsToStart,
        });
        clockRef.current.addEventListener(WorkshopClockEvent.TICK, () => {
          setButtonText(clockRef.current!.workshopParsedTimeRemaining);
        });
        clockRef.current.addEventListener(WorkshopClockEvent.TIMEOUT, () => {
          setButtonText(defaultButtonText);
          clockRef.current?.dispose();
        });
        clockRef.current.start(currentServerTime);
        return;
      }
      clockRef.current.adjustTime(currentServerTime);
    };
    serverTimeEventTarget.addEventListener(
      SERVER_TIME_UPDATE,
      serverTimeEventHandler
    );

    return () => {
      serverTimeEventTarget.removeEventListener(
        SERVER_TIME_UPDATE,
        serverTimeEventHandler
      );
      clockRef.current?.dispose();
    };
  }, [
    serverTimeEventTarget,
    slot.schedule_date,
    isTimeToStart,
    defaultButtonText,
  ]);

  return (
    <>
      <div className={styles.container}>
        <ScrollIndicator
          className={cn(
            "main-container",
            styles.detailsContainer,
            stepsSelectors.waitingContainer,
            !isParticipating && "cursor-not-allowed"
          )}
        >
          <WorkshopDetails
            workshop={workshop}
            layouts={layouts}
            author={author}
            invitedParticipantCount={
              showInvitedPeople ? invitedParticipantCount : 0
            }
            journeyItems={journeyItems}
            profileId={profileId}
            noInteraction={!isParticipating}
          />
        </ScrollIndicator>
      </div>
      <ActionFooter
        buttonText={buttonText}
        type={footerType}
        disabledButton={footerButtonDisabled}
        buttonClickHandler={setActivityReadyToStartHandler}
        isLoading={isLoading}
        isConnectionWeak={isConnectionWeak}
      >
        {footerText}
      </ActionFooter>
    </>
  );
};

export default memo(Opening);
