import { memo } from "react";
import { Certificate } from "../../../../../../types/contentful/workshop/journey";
import { CertificationType } from "../../../../../../types/enums/certification-type";
import { JourneyCertificationWithCertificate } from "../../../../../../types/journey-certificates";
import JourneyAchievement from "./JourneyCertificate";

import styles from "./JourneyCertificates.module.css";

interface JourneyCertificatesProps {
  companyName: string;
  certificates: Certificate[];
  currentJourneyCertificationWithCertificate?: JourneyCertificationWithCertificate | null;
  locked?: boolean;
  title: string;
}

const certificationMap = [
  CertificationType.Personal,
  CertificationType.Leader,
  CertificationType.Workspace,
];

const JourneyCertificates = ({
  companyName,
  certificates,
  currentJourneyCertificationWithCertificate: journeyCertification,
  locked = false,
  title,
}: JourneyCertificatesProps) => (
  <div className={styles.container}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.achievementsContainer}>
      {certificates.map((certificate, index) => (
        <JourneyAchievement
          key={index}
          certificate={certificate}
          currentJourneyCertificationWithCertificate={
            journeyCertification || null
          }
          certificationType={certificationMap[index]}
          companyName={companyName}
          locked={locked}
        />
      ))}
    </div>
  </div>
);

export default memo(JourneyCertificates);
