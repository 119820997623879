export const cursorPalette = [
  { color: "#30bced", light: "#30bced33" },
  { color: "#6eeb83", light: "#6eeb8333" },
  { color: "#ffbc42", light: "#ffbc4233" },
  { color: "#ecd444", light: "#ecd44433" },
  { color: "#ee6352", light: "#ee635233" },
  { color: "#9ac2c9", light: "#9ac2c933" },
  { color: "#8acb88", light: "#8acb8833" },
  { color: "#1be7ff", light: "#1be7ff33" },
  { color: "#ff5733", light: "#ff573333" },
  { color: "#33ff57", light: "#33ff5733" },
  { color: "#5733ff", light: "#5733ff33" },
  { color: "#ff33a1", light: "#ff33a133" },
  { color: "#a1ff33", light: "#a1ff3333" },
  { color: "#33a1ff", light: "#33a1ff33" },
  { color: "#ff8c00", light: "#ff8c0033" },
  { color: "#8c00ff", light: "#8c00ff33" },
  { color: "#00ff8c", light: "#00ff8c33" },
  { color: "#ff0033", light: "#ff003333" },
  { color: "#0033ff", light: "#0033ff33" },
  { color: "#33ff00", light: "#33ff0033" },
  { color: "#ff6600", light: "#ff660033" },
  { color: "#6600ff", light: "#6600ff33" },
  { color: "#00ff66", light: "#00ff6633" },
];
