import { memo, PropsWithChildren } from 'react';

import styles from './JourneyBottomNavigation.module.css';

type JourneyBottomNavigationProps = PropsWithChildren<{}>

const JourneyBottomNavigation = ({ children }: JourneyBottomNavigationProps) => {
  return (
    <div className={styles.container}>
      <img src="/images/journeyDetailsBottomBannerImage.svg" alt="Bottom banner" height={295}/>
      <div className={styles.buttonsContainer}>
        {children}
      </div>
    </div>
  );
};

export default memo(JourneyBottomNavigation);