import { useContext, useRef, useEffect } from "react";
import { ApolloContext, SERVER_TIME_UPDATE } from "../contexts/Apollo";
import { getUnixTime } from "date-fns";

const useServerTime = () => {
  const { serverTimeEventTarget } = useContext(ApolloContext);
  const currentServerTimeRef = useRef<number>(getUnixTime(new Date()));

  useEffect(() => {
    const serverTimeEventHandler = (event: Event) => {
      currentServerTimeRef.current = (event as any).detail as number;
    };

    serverTimeEventTarget.addEventListener(
      SERVER_TIME_UPDATE,
      serverTimeEventHandler
    );

    return () => {
      serverTimeEventTarget.removeEventListener(
        SERVER_TIME_UPDATE,
        serverTimeEventHandler
      );
    };
  }, [serverTimeEventTarget]);

  return currentServerTimeRef
}

export default useServerTime;