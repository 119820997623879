import { PropsWithChildren, memo, useMemo } from "react";

import { InvitationStatus } from "../../../types/enums/invitation-status";

import styles from "./InvitationStatusIcon.module.css";
import cn from "classnames";

const iconTypeMap = {
  [InvitationStatus.ACCEPTED]: "fa-check",
  [InvitationStatus.DECLINED]: "fa-x",
  [InvitationStatus.PENDING]: "fa-question",
  [InvitationStatus.TENTATIVE]: "fa-question",
  [InvitationStatus.AUTO_GENERATED]: "fa-check",
};

const InvitationStatusIcon = (
  props: PropsWithChildren<{ status: InvitationStatus; className?: string }>
) => {
  const { status, className } = props;

  const icon = useMemo(
    () => <i className={cn("fa", iconTypeMap[status])} />,
    [status]
  );
  const formattedStatus = useMemo(() => status.toLocaleLowerCase(), [status]);

  const title = useMemo(
    () => formattedStatus[0].toUpperCase() + formattedStatus.slice(1),
    [formattedStatus]
  );

  return (
    <div
      className={cn(styles.iconContainer, formattedStatus, className)}
      title={title}
    >
      {icon}
    </div>
  );
};

export default memo(InvitationStatusIcon);
