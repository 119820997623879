import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { RequestWorkshopResult } from "../types/results";

const requestWorkshopMutation = gql`
  mutation requestWorkshop($id: String!, $message: String) {
    requestWorkshop(id: $id, message: $message)
  }
`;

export function requestWorkshop(
  client: AppApolloClient,
  variables: {
    id: string;
    message?: string;
  }
) {
  return client
    .mutate<RequestWorkshopResult>({
      mutation: requestWorkshopMutation,
      variables,
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return !!result.data?.requestWorkshop;
    });
}
