import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ClosedQuestionActivity, ClosedQuestionAnswer } from "../../../../types/contentful/workshop/activities/closed-question";
import { VotingActivity } from "../../../../types/contentful/workshop/activities/voting";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";

export const getFirstLevelReferenceActivityId = (activity: VotingActivity) => (
  activity["fields"]["referenceActivity"]?.["sys"]["contentType"]?.["sys"]["id"]
);

export const getIsReferenceActivityOpenQuestion = (referenceActivityId: string | undefined) => (
  referenceActivityId === WorkshopActivityType.OpenQuestion
);

export const getIsReferenceActivityVoting = (referenceActivityId: string | undefined) => (
  referenceActivityId === WorkshopActivityType.VotingActivity
);

export const formatAnswers = (
  answers: IActivityResult["value"], 
  votes: { [id: string]: number } | null, 
  profileId: string
): ClosedQuestionAnswer[] => {
  
  const parsedAnswers: ClosedQuestionAnswer[] = answers.map((answer, index) => {
    try {
      const parsedAnswer = JSON.parse(answer.value);

      const title = `${index + 1}. ` + (answer.profileId === profileId ? "Your Strategy" : "Team Member's Strategy");
      const votesContent = votes ? `(✋ ${votes[parsedAnswer.id] || 0} Votes)` : '';

      return {
        fields: {
          title: `${title} ${votesContent}`,
          text: `${parsedAnswer.value}`,
        },
        sys: {
          id: parsedAnswer.id,
        },
        metadata: {
          tags: []
        }
      };
    } catch (e) {
      console.error("Error parsing answer", e);
      return null;
    }
  }).filter(Boolean) as unknown as ClosedQuestionAnswer[];

  return parsedAnswers;
};

export const getOpenQuestionAnswersAndVotes = (
  activity: VotingActivity | ClosedQuestionActivity,
  allActivitiesResult: IActivityResult[] | undefined,
  profileId: string,
) => {
  const openQuestionAnswers = getOpenQuestionAnswers(activity, allActivitiesResult);

  return openQuestionAnswers ? formatAnswers(openQuestionAnswers, getVotes(activity, allActivitiesResult), profileId) : [];
}

export const getOpenQuestionAnswers = (
  activity: VotingActivity | ClosedQuestionActivity,
  allActivitiesResult: IActivityResult[] | undefined,
) => {
  // Helper function to recursively find the reference activity ID
  const getDeepReferenceActivityId = (activity: any): string | undefined => {
    if (activity?.["fields"]?.["referenceActivity"]) {
      const firstLevelReferenceId = activity["fields"]["referenceActivity"]?.["sys"]["id"];
      const isFirstLevelOpenQuestion = getIsReferenceActivityOpenQuestion(getFirstLevelReferenceActivityId(activity));

      if (isFirstLevelOpenQuestion) {
        return firstLevelReferenceId;
      }

      // Check the next level of referenceActivity
      if (activity["fields"]["referenceActivity"]?.["fields"]["referenceActivity"]) {
        return getDeepReferenceActivityId(activity["fields"]["referenceActivity"]);
      }
    }
    return undefined;
  };

  // Get the deepest open question reference activity ID
  const deepReferenceActivityId = getDeepReferenceActivityId(activity);

  const openQuestionAnswers = deepReferenceActivityId
    ? allActivitiesResult?.find((a) => a.key === deepReferenceActivityId)?.value
    : [];

  return openQuestionAnswers;
}

export const getVotes = (
  activity: VotingActivity | ClosedQuestionActivity,
  allActivitiesResult: IActivityResult[] | undefined,
) => {
  if (!activity?.["fields"]?.["referenceActivity"]) return null;
  const referenceId = activity["fields"]["referenceActivity"]["sys"]["id"];
  const isReferenceActivityVoting = getIsReferenceActivityVoting(getFirstLevelReferenceActivityId(activity));

  const activityResults = allActivitiesResult?.find((a) => a.key === referenceId);
  if (!isReferenceActivityVoting || !referenceId || !activityResults) {
    return null;
  }
  try {
    const res: { [id: string]: number } = {};
    activityResults?.value.forEach(({ value }) => {
      res[value] = res[value] ? res[value] + 1 : 1;
    });

    return res;
  } catch (e) {
    console.error("Error getting votes", e);
    return {};
  }
}