import { memo, PropsWithChildren } from "react";

import VideoPlayer from "../../../../../../../Shared/VideoPlayer/VideoPlayer";
import SectionCard from "../../../SectionCard/SectionCard";
import ContentfulRichField from "../../../../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./VideoItem.module.css";

const VideoItem = ({
  title,
  link,
  text,
}: PropsWithChildren<{
  title: string;
  link: string;
  text: string;
}>) => {
  return (
    <SectionCard
      title={title}
      imagePath="/images/unlock-video-icon.svg"
      imageAlt="unlock-link"
    >
      <hr className={styles.contentSep}></hr>
      <div className={styles.content}>
        <div className={styles.contentVideo}>
          <VideoPlayer description={"text"} src={link} showPreview={true} />
        </div>
        <ContentfulRichField
          className={styles.contentText}
          content={text}
        />
      </div>
    </SectionCard>
  );
};

export default memo(VideoItem);
