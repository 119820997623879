import { memo, PropsWithChildren, useMemo } from "react";

import { getEntryId } from "../../../../../../utils";

import {
  SectionItemType,
  SummaryFieldsSectionFieldsItem,
} from "../../../../../../types/contentful/workshop/summary";

import NextStepsHeader from "./components/NextStepsHeader/NextStepsHeader";
import VideoItem from "./components/VideoItem/VideoItem";
import DocumentItem, {
  DocumentItemType,
} from "./components/DocumentItem/DocumentItem";

import styles from "./NextStep.module.css";

interface INextStepProps {
  items: SummaryFieldsSectionFieldsItem[];
  sectionId: string;
  title: string;
  workshopAuthor: {
    name: string;
    imageUrl: string;
    headline: string;
  };
}

const NextStep = (props: PropsWithChildren<INextStepProps>) => {
  const { items, sectionId, title, workshopAuthor } = props;

  const content = useMemo(() => {
    return items.map((item) => {
      if (!item || !item.fields) return null;

      const id = getEntryId(item);
      const { link, text, title, type } = item.fields;

      let content: JSX.Element | null = null;

      if (type === SectionItemType.Video) {
        content = (
          <VideoItem
            key={`video-item-${id}`}
            link={link}
            text={text}
            title={title}
          />
        );
      }

      if (
        [
          SectionItemType.Link,
          SectionItemType.FreeContent,
          SectionItemType.Downloadable,
          SectionItemType.Conversation,
        ].includes(type)
      ) {
        content = (
          <DocumentItem
            type={type as DocumentItemType}
            key={`document-item-${id}`}
            link={link}
            text={text}
            title={title}
            workshopAuthor={workshopAuthor}
          />
        );
      }

      return (
        <div key={id} className={styles.item}>
          {content}
        </div>
      );
    });
  }, [items, workshopAuthor]);

  if (!items || items.length === 0) return null;

  return (
    <div className={styles.container}>
      <NextStepsHeader
        key={sectionId}
        title={title}
        imagePath="/images/party.png"
        imageAlt="party"
      />
      {content}
    </div>
  );
};

export default memo(NextStep);
