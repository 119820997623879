import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import * as adminDashboardScheduleDialogActions from "../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";
import { AdminDashboardScheduleState } from "../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import {
  selectWorkshopStart,
  setScheduleDateTime,
  setScheduleParticipants,
  createSlot,
} from "../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";

import { Profile } from "../../../apollo-graphql/types/profile";
import { Workshop } from "../../../types/contentful/workshop/workshop";

import WorkshopList from "./components/WorkshopList/WorkshopList";
import ScheduleDialog from "./components/ScheduleDialog/ScheduleDialog";

function AdminWorkshopList(
  props: PropsWithChildren<{
    isDialogOpen: boolean;
    workshopsLoading: boolean;
    workshops: Workshop[];
    sheduleDialogMachine: any;
  }>
) {
  const { isDialogOpen, workshopsLoading, workshops, sheduleDialogMachine } =
    props;
  const navigate = useNavigate();

  const [state, send] = sheduleDialogMachine;
  const { workshopId: contextWorkshopId, profiles, slot } = state.context;

  const selectedWorkshop = useMemo(
    () => workshops.find((w) => w.sys.id === contextWorkshopId),
    [contextWorkshopId, workshops]
  );

  const currentState: AdminDashboardScheduleState = useMemo(() => {
    if (state.matches(AdminDashboardScheduleState.Start)) {
      return AdminDashboardScheduleState.Start;
    }
    if (state.matches(AdminDashboardScheduleState.LoadScheduleData)) {
      return AdminDashboardScheduleState.LoadScheduleData;
    }
    if (state.matches(AdminDashboardScheduleState.ScheduleReady)) {
      return AdminDashboardScheduleState.ScheduleReady;
    }
    if (state.matches(AdminDashboardScheduleState.Done)) {
      return AdminDashboardScheduleState.Done;
    }
    return AdminDashboardScheduleState.Idle;
  }, [state]);

  const closeDialogHandler = useCallback(() => {
    navigate("/workshops");
    send(adminDashboardScheduleDialogActions.closeScheduleDialog());
  }, [navigate, send]);

  const dialogContinueHandler = useCallback(
    (data?: any) => {
      if (currentState === AdminDashboardScheduleState.Start) {
        send(selectWorkshopStart({ type: data.type }));
      }

      if (currentState === AdminDashboardScheduleState.ScheduleReady) {
        const dateTime = new Date(`${data.date}T${data.time}`);
        const participantEmails: string[] = (
          (data?.profiles || []) as Profile[]
        ).map((p: any) => p.email);

        send(setScheduleDateTime({ dateTime }));
        send(setScheduleParticipants({ participantEmails }));
        send(createSlot({ type: data.type, dateTime, participantEmails }));
      }

      if (currentState === AdminDashboardScheduleState.Done) {
        closeDialogHandler();
      }
    },
    [currentState, send, closeDialogHandler]
  );

  return (
    <WorkshopList
      workshops={workshops}
      workshopsLoading={workshopsLoading}
      buttonText="Start conversation"
    >
      {isDialogOpen && (
        <ScheduleDialog
          workshop={selectedWorkshop!}
          state={currentState}
          profiles={profiles}
          slot={slot}
          continueButtonClickHandler={dialogContinueHandler}
          closeDialogHandler={closeDialogHandler}
        />
      )}
    </WorkshopList>
  );
}

export default memo(AdminWorkshopList);
