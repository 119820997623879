import styles from "./VideoPreview.module.css";
import cn from "classnames";

interface DisabledTooltipProps {
  title: string;
  description: string;
  className?: string;
}

export function DisabledTooltip({ title, description, className}: DisabledTooltipProps) {
  return (
    <div className={cn(styles.tooltip, className)}>
      <p className="text medium bold">
        <i
          className={cn(
            "fa-regular fa-triangle-exclamation",
            styles.tooltipWarning
          )}
        />
        {title}
      </p>
      <span className={cn("text", "small", styles.description)}>
        {description}
      </span>
    </div>
  );
}