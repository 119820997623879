import { PropsWithChildren, memo, useMemo } from "react";

import { startCase } from "lodash";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

import { FooterType } from "../../../types/enums/activity-footer";
import { ConferenceMode } from "../../../types/contentful/enums";
import { nextActivityTypeMapper } from "../Activities/constants";

import styles from "./NextStepTransition.module.css";
import cn from "classnames";

interface NextStepTransitionProps {
  nextStep: string;
  sessionType: ConferenceMode;
  transition: number;
  isActivityTimeout?: boolean;
}

export default memo(function NextStepTransition(
  props: PropsWithChildren<NextStepTransitionProps>
) {
  const {
    nextStep,
    sessionType,
    transition,
    isActivityTimeout = false,
  } = props;

  const transitionType = useMemo(
    () => (!!isActivityTimeout ? FooterType.Error : FooterType.Ready),
    [isActivityTimeout]
  );

  const content = useMemo(
    () => (
      <div className={styles.content}>
        <div className={styles.transitionContainer}>
          <div className={cn("big-icon", transitionType)}>{transition}</div>
        </div>
        <div className={styles.textContainer}>
          <div className={cn("badge blue", styles.badge)}>
            {startCase(nextActivityTypeMapper[sessionType])} session
          </div>
          <h3>Next step: {nextStep}</h3>
        </div>
      </div>
    ),
    [nextStep, sessionType, transition, transitionType]
  );

  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          aria-describedby={transitionType}
          className={cn(styles.dialogContent, transitionType, "DialogContent")}
        >
          <VisuallyHidden.Root>
            <Dialog.Title>{transitionType}</Dialog.Title>
            <Dialog.Description>Next step</Dialog.Description>
          </VisuallyHidden.Root>
          {content}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
