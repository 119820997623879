import { memo, useMemo } from "react";

import cn from "classnames";

import {
  activityTypeFooterTextFactoryMap,
  getDuration,
  getEntryId,
  getTransitionData,
  getType,
  parseToJson,
} from "../../../../utils";
import { Profile } from "../../../../apollo-graphql/types";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { observerFooterData } from "../constants";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { FooterType } from "../../../../types/enums/activity-footer";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { SelfDirectedDiscussionActivity } from "../../../../types/contentful/workshop/activities/selfDirectedDiscussionActivity";
import { RemoteParticipantData } from "../../../../types/jitsi";

import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import SelfDirectedDiscussionList from "./SelfDirectedDiscussionList/SelfDirectedDiscussionList";
import UserImage from "../../../Shared/UserImage/UserImage";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import AssignmentTitle from "../../../Shared/AssignmentTitle/AssignmentTitle";

import styles from "./SelfDirectedDiscussion.module.css";

interface SelfDirectedDiscussionProps extends ActivityCommon {
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
  profile: Profile;
  allActivitiesResult?: IActivityResult[];
  remoteParticipantsData: RemoteParticipantData[];
}

const SelfDirectedDiscussion = ({
  activity: rawActivity,
  profile,
  isConnectionWeak,
  activityResultForCurrentProfile,
  allActivitiesResult,
  isParticipating,
  currentActiveParticipantCount,
  transition,
  nextActivity,
  hasAhaMoments,
  isActivityTimeout,
  isLoading,
  isReady,
  notReadyProfilesCount,
  remoteParticipantsData,
  setActivityReadyHandler,
  setActivityValueHandler,
}: SelfDirectedDiscussionProps) => {
  const activity = rawActivity as SelfDirectedDiscussionActivity;

  const activityData = useMemo(() => {
    return {
      id: getEntryId(activity),
      duration: getDuration(activity),
      type: getType(activity) as WorkshopActivityType,
      instructions: activity.fields.activity.fields.instructions,
      referenceInstructions: activity.fields.assignmentInstructions,
      referenceTitle: activity.fields.referenceTitle,
    };
  }, [activity]);

  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const defaultAnswerData = useMemo(() => ({ id: "", value: "" }), []);

  const activityResultValueForCurrentProfile = useMemo(
    () => activityResultForCurrentProfile?.value,
    [activityResultForCurrentProfile?.value]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready. Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }

    if (!isReady) {
      const selectedItems = activityResultValueForCurrentProfile
        ? parseToJson(activityResultValueForCurrentProfile, [])
        : [];

      return {
        text: activityTypeFooterTextFactoryMap[activityData.type](
          currentActiveParticipantCount - notReadyProfilesCount
        ),
        buttonText: "Continue",
        disabledButton:
          currentActiveParticipantCount !== selectedItems.length + 1,
        type: FooterType.Notice,
      };
    }

    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
  }, [
    isParticipating,
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    isActivityTimeout,
    activityResultValueForCurrentProfile,
    activityData.type,
    currentActiveParticipantCount,
  ]);

  const answers = useMemo(
    () =>
      allActivitiesResult?.find(
        (a) => a.key === activity.fields.referenceActivity.sys.id
      )?.value || [],
    [allActivitiesResult, activity.fields.referenceActivity.sys.id]
  );

  const formattedAnswers = useMemo(() => {
    return answers?.map((answer) => {
      const value = answer?.value
        ? parseToJson(answer.value, defaultAnswerData)
        : defaultAnswerData;

      return {
        profileId: answer.profileId,
        value,
      };
    });
  }, [answers, defaultAnswerData]);

  const currentProfileAnswerValue = useMemo(() => {
    const answer = answers.find((a) => a.profileId === profile.id);

    return answer?.value
      ? parseToJson(answer?.value, defaultAnswerData).value
      : defaultAnswerData.value;
  }, [answers, defaultAnswerData, profile.id]);

  return (
    <>
      <div className={cn(styles.container, "activity-container")}>
        <div className={cn("main-container", styles.container)}>
          <div className={styles.leftWrapper}>
            <AssignmentTitle />
            <ContentfulRichField
              content={activityData.referenceInstructions}
              className={styles.referenceInstructionsContent}
            />
            <div>{activityData.referenceTitle}</div>
            <ContentfulRichField content={activityData.referenceInstructions} />
            <AssignmentTitle />
            <div className={styles.referenceInstructionsContent} />
            <div>{activityData.referenceTitle}</div>
            <div className={styles.mySituationContainer}>
              <div className={styles.mySituationTitle}>
                My situation:
                <UserImage
                  isPublic={false}
                  profileId={profile.id}
                  profileWorkspaceId={profile.workspace.workspace_id}
                  alt="profile-image"
                  fallbackFontAwesomeIconClass="icon fa fa-user"
                  containerClass={styles.profileImage}
                />
              </div>
              <div className={styles.mySituationText}>
                {currentProfileAnswerValue}
              </div>
            </div>
          </div>
          <div className={styles.rightWrapper}>
            <ContentfulRichField
              content={activityData.instructions}
              className={styles.instructionsContent}
            />
            {formattedAnswers && remoteParticipantsData && (
              <SelfDirectedDiscussionList
                activityId={activityData.id}
                items={formattedAnswers}
                setActivityValueHandler={setActivityValueHandler}
                remoteParticipantsData={remoteParticipantsData}
                workspaceId={profile.workspace.workspace_id}
              />
            )}
          </div>
        </div>
        {isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        isLoading={isLoading}
        isConnectionWeak={isConnectionWeak}
        buttonClickHandler={() =>
          setActivityReadyHandler({ activityId: activityData.id })
        }
      >
        {actionFooterData.text}
      </ActionFooter>
    </>
  );
};

export default memo(SelfDirectedDiscussion);
