import { driver } from "driver.js";
import cn from "classnames";
import { steps } from "./steps";
import ReactDOM from "react-dom/client";
import GuideDotLottie, { DotLottieInstance } from "./GuideDotLottie";
import { useEffect } from "react";
import { StandardSessionActivity } from "../../apollo-graphql/types/enums/standard-session-activity";
import { addSeenStep, getSeenSteps, isGuideClosed, markGuideClosed } from "./guideUtils";

import styles from "./Guide.module.css";
import "driver.js/dist/driver.css";

interface GuideProps {
  activityId: StandardSessionActivity | string | null;
  isJitsiSetupDone: boolean;
}

let dotLottieInstance: DotLottieInstance | null = null;

const renderDotLottie = (elementId: string, animationSrc: string, setDotLottieInstance: (instance: DotLottieInstance) => void) => {
  const targetElement = document.getElementById(elementId);

  if (targetElement && animationSrc) {
    const root = ReactDOM.createRoot(targetElement);
    root.render(
      <GuideDotLottie animationSrc={animationSrc} setDotLottieInstance={setDotLottieInstance} />
    );
  }
};

const driverObj = driver({
  popoverClass: styles.popover,
  onDestroyStarted() {
    // need to pause the animation before driver to be destroyed, as the dotlottie library throws an error
    if (dotLottieInstance) {
      dotLottieInstance.pause();
    }

    driverObj.destroy();
  },
  onPopoverRender: (popover, { state }) => {
    popover.title.classList.add(styles.popoverTitle);
    popover.description.classList.add(styles.popoverDescription);
    popover.arrow.classList.add(styles.arrow);
    popover.footer.className = "guide-driver-footer";
    
    const currentIndex = driverObj.getActiveIndex() || 0;
    const currentStepId = state.activeStep?.element as string;

    // Mark step as seen
    addSeenStep(currentStepId);

    popover.footerButtons.innerHTML = "";
    popover.footerButtons.className = cn(
      styles.footerButtons,
      currentIndex === 0 ? styles.singleButton : styles.twoButtons
    );

    // Create left button
    if (currentIndex > 0) {
      const backButton = document.createElement("button");
      backButton.classList.add("btn", "secondary", "small");
      backButton.innerHTML = `<i class="fa fa-chevron-left" />`;
      backButton.onclick = () => {
        driverObj.movePrevious();
        if (dotLottieInstance) {
          dotLottieInstance.pause();
        }
      };
      popover.footerButtons.appendChild(backButton);
    }

    // Create right button
    if (!driverObj.isLastStep()) {
      const nextButton = document.createElement("button");
      nextButton.classList.add("btn", "secondary", "small");
      nextButton.innerHTML = `<i class="fa fa-chevron-right" />`;
      nextButton.onclick = () => {
        driverObj.moveNext();
        if (dotLottieInstance) {
          dotLottieInstance.pause();
        }
      };
      popover.footerButtons.appendChild(nextButton);
    } else {
      // Create "Got it" button for the last step
      const gotItButton = document.createElement("button");
      gotItButton.classList.add("btn", "small");
      gotItButton.innerHTML = "Got it";
      gotItButton.onclick = () => {
        driverObj.destroy();
      };
      popover.footerButtons.appendChild(gotItButton);
    }

    // Create "Close" button, becase allowClose:false removes it 
    const closeButton = document.createElement("button");
    closeButton.classList.add(styles.closeBtn);
    closeButton.innerHTML = `<i class='fa fa-xmark ${styles.closeBtnIcon}' />`;
    closeButton.onclick = () => {
      !driverObj.isLastStep() && markGuideClosed();
      driverObj.destroy();
    };
    popover.title.appendChild(closeButton)

    // render animation if configured
    const animationMatch = state.popover?.description.innerHTML.match(/data-anim-src=['"]([^'"]+)['"]/);
    if (animationMatch) {
      renderDotLottie('dotlottie-container', animationMatch[1], (instance) => dotLottieInstance = instance);
    }
  },
  onHighlightStarted(element) {
    if (!element) {
      setTimeout(() => driverObj.moveNext(), 20);
    }
  },
  stagePadding: 0,
  stageRadius: 12,
  allowClose: false,
});

const startGuide = (includeSeen: boolean) => {
  if (driverObj && driverObj.isActive()) {
    return;
  }

  const guideElements = document.querySelectorAll(
    '[class^="guide-"], [class*=" guide-"]'
  );
  const guideElementsArray = Array.from(guideElements);
  const stepClassNames = Object.keys(steps);

  const matchedSteps = stepClassNames
    .filter((className) =>
      guideElementsArray.some((element) =>
        element.classList.contains(className)
      )
    )
    .map((className: string) => steps[className as keyof typeof steps]);

  const seenSteps = getSeenSteps();

  const filteredSteps = includeSeen 
    ? matchedSteps
    : matchedSteps.filter(
      (step) => !seenSteps.includes(step.element)
    );

  const orderedSteps = filteredSteps
    .sort((a, b) => a.order - b.order)
    .map((step) => {
      const { order, ...pureStep } = step;
      return pureStep;
    });

  driverObj.setSteps(orderedSteps);
  driverObj.drive();
};

const Guide = ({ activityId, isJitsiSetupDone }: GuideProps) => {
  useEffect(() => {
    !isGuideClosed() && startGuide(false);
  }, [activityId, isJitsiSetupDone]);

  return (
    <>
      <button
        className="btn secondary small"
        onClick={() => startGuide(true)}
      >
        Guide
      </button>
    </>
  );
};

export default Guide;
