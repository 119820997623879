import { useMemo } from "react";

export const useEditorClass = (props: {
  isTransitioning: boolean;
  isReady: boolean;
  currentActiveParticipantCount: number;
  notReadyProfilesCount: number;
}) => {
  const {
    isReady,
    isTransitioning,
    currentActiveParticipantCount,
    notReadyProfilesCount,
  } = props;

  const editorClass = useMemo(() => {
    if (isTransitioning) return "is-transitioning";
    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    if (!isReady && playersClicked) return "not-ready-and-players-clicked";
    if (isReady && playersClicked) return "ready-and-players-clicked";

    return "";
  }, [
    currentActiveParticipantCount,
    isReady,
    isTransitioning,
    notReadyProfilesCount,
  ]);

  return editorClass;
};
