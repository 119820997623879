// Utility function to parse the data from string to json format
export function parseToJson<T>(
  data: string | null | undefined,
  defaultData: T,
  errorMessage?: string
): T {
  if (typeof data !== "string") {
    return defaultData;
  }

  try {
    return JSON.parse(data) as T;
  } catch (error) {
    const message = errorMessage || "Error parsing data to json";
    console.error(message, "/n", { data, error });
  }

  return defaultData;
}
