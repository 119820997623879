export enum StandardSessionActivity {
  Opening = "openning",
  StartEmotion = "startEmotion",
  WarmUp = "warmUp",
  EndEmotion = "endEmotion",
  ViewResults = "viewResults",
  MomentIndividual = "momentIndividual",
  MomentGroup = "momentGroup",
}

export const standardSessionActivityMapper: Record<
  StandardSessionActivity,
  string
> = {
  [StandardSessionActivity.WarmUp]: "Warm Up",
  [StandardSessionActivity.Opening]: "Waiting Room",
  [StandardSessionActivity.StartEmotion]: "Initial Emotion",
  [StandardSessionActivity.EndEmotion]: "Final Emotion",
  [StandardSessionActivity.ViewResults]: "Summary",
  [StandardSessionActivity.MomentIndividual]: "Moment",
  [StandardSessionActivity.MomentGroup]: "Moment",
};

export const standardSessionActivityList = Object.values(
  StandardSessionActivity
) as StandardSessionActivity[];
