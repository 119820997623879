import { memo } from "react";
import { JourneyTag } from "../../../../../../types/contentful/workshop/journey";

import styles from './JourneyTags.module.css';

interface JourneyTagsProps {
  tags: JourneyTag[];
}

const JourneyTags = ({ tags }: JourneyTagsProps) => (
  tags?.map((tag, index) => (
    <div
      key={`${tag.fields.name}-${index}`}
      className={styles.container}
    >
      {tag.fields.name}
    </div>
  ))
);

export default memo(JourneyTags);