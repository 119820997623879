import {
  ChangePasswordErrors,
  LoginErrors,
  ResetPasswordErrors,
  UpdateProfileErrors,
  InviteProfileErrors,
  RefreshTokenErrors,
} from "../types/enums/errors";

export const mappedErrorMessages = {
  [LoginErrors.MISSING_DATA]: "All fields are required!",
  [LoginErrors.DOMAIN_NOT_FOUND]: "Email domain not found",
  [LoginErrors.PROFILE_NOT_FOUND]: "Your email or password is incorrect",
  [LoginErrors.WRONG_DATA]: "Your email or password is incorrect",

  [UpdateProfileErrors.MISSING_DATA]: "Missing data",
  [UpdateProfileErrors.NOT_FOUND]: "User not found",
  [UpdateProfileErrors.WRONG_PASSWORD]: "Wrong password",

  [InviteProfileErrors.MISSING_DATA]: "All fields are required!",
  [InviteProfileErrors.DOMAIN_NOT_FOUND]: "Domain not found!",
  [InviteProfileErrors.PROFILE_ALREADY_EXISTS]:
    "Invited profile already exists!",
  [InviteProfileErrors.WORKSPACE_NOT_FOUND]: "Workspace not found!",
  [InviteProfileErrors.WORKSPACE_PROFILE_NOT_FOUND]:
    "Workspace profile not found!",

  [ChangePasswordErrors.MISSING_DATA]: "All fields are required!",
  [ChangePasswordErrors.PASSWORDS_MISMATCH]:
    "Password and Repeat Password do not match!",
  [ChangePasswordErrors.PROFILE_NOT_FOUND]: null,
  [ChangePasswordErrors.TOKEN_NOT_FOUND]: "Token not found or expired",
  [ChangePasswordErrors.WRONG_TOKEN]: "Token not found or expired",

  [ResetPasswordErrors.MISSING_DATA]: "Email address is required",
  [ResetPasswordErrors.DOMAIN_NOT_FOUND]: "Email domain not found",
  [ResetPasswordErrors.PROFILE_NOT_FOUND]: null,

  [RefreshTokenErrors.TOKEN_EXPIRED]:
    "Operation token has expired, please try repeating the whole process again.",
  [RefreshTokenErrors.TOKEN_NOT_FOUND]:
    "Something went wrong, please try repeating the whole process again.",
  [RefreshTokenErrors.CORRUPTED_TOKEN]:
    "Something went wrong, please try repeating the whole process again.",
  [RefreshTokenErrors.TOKEN_INVALID]:
    "Something went wrong, please try repeating the whole process again.",

  undefined: "Something went wrong!",
};
