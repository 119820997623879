import {
  PropsWithChildren,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { WorkshopClockEvent } from "../../../helpers/workshop-clock";
import { SessionContext } from "../../../contexts/Session";

import Countdown from "../Countdown/Countdown";
import ContentfulRichField from "../ContentfulRichField/ContentfulRichField";

import cn from "classnames";
import styles from "./Timer.module.css";

const Portal = ({ children }: PropsWithChildren) => {
  return createPortal(children, document.body);
};

export default memo(function Timer(
  props: PropsWithChildren<{
    title?: string;
    isHeaderTime?: boolean;
    isDone?: boolean;
    hasCurrentActivity?: boolean;
  }>
) {
  const { title, isHeaderTime, isDone, hasCurrentActivity } = props;
  const sessionContext = useContext(SessionContext);
  const [progress, setProgress] = useState<number>(0);
  const [value, setValue] = useState<string>("");
  const [isHurryUpPopupHidden, setIsHurryUpPopupHidden] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, right: 0 });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { clockInstance } = sessionContext.workshopClock.state.context;
  const isActivityTimer = !isHeaderTime;

  const timeIsUp = useMemo(() => progress >= 100, [progress]);
  const timeIsRunningUp = useMemo(() => {
    if (!value || !value.includes(":")) return false;
    const [, remainingTimeString] = /(\d+):/.exec(value || "00:00") || [
      "",
      "00:00",
    ];

    return parseInt(remainingTimeString) === 0;
  }, [value]);

  const timerContent = useMemo(() => {
    return isHeaderTime ? (
      <div className={cn(styles.container, "timer-container")}>
        {title && <h3 className="thin">{title}</h3>}
        {value && hasCurrentActivity && !isDone && (
          <Countdown
            timeValue={value}
            progress={progress}
            timeIsRunningUp={timeIsRunningUp}
            isPassedTime={true}
          />
        )}
      </div>
    ) : null;
  }, [
    isDone,
    isHeaderTime,
    hasCurrentActivity,
    timeIsRunningUp,
    progress,
    title,
    value,
  ]);

  const [minutes, seconds] = useMemo(() => {
    if (!value || !value.includes(":")) return [0, 0];
    return value.split(":");
  }, [value]);

  const timeLeft = useMemo(() => {
    return (
      <p className="time-left">
        <span>{minutes}</span>:<span>{seconds}</span>
      </p>
    );
  }, [minutes, seconds]);

  useEffect(() => {
    const tickEventListener = () => {
      const value = isHeaderTime
        ? clockInstance?.workshopParsedTimeElapsed
        : clockInstance?.activityParsedTimeRemaining;

      const progress = isHeaderTime
        ? clockInstance?.workshopProgress
        : clockInstance?.activityProgress;

      setProgress(progress || 0);
      setValue(value || "");
    };

    clockInstance?.addEventListener(WorkshopClockEvent.TICK, tickEventListener);

    return () => {
      clockInstance?.removeEventListener(
        WorkshopClockEvent.TICK,
        tickEventListener
      );
    };
  }, [clockInstance, isHeaderTime]);

  useEffect(() => {
    if (!timeIsRunningUp) setIsHurryUpPopupHidden(false);
  }, [timeIsRunningUp]);

  useEffect(() => {
    if (!containerRef?.current) return;

    if (timeIsRunningUp) {
      const { top, right } = containerRef.current.getBoundingClientRect();
      setTooltipPosition({ top, right });
    }
  }, [setTooltipPosition, timeIsRunningUp]);

  return (
    <>
      {clockInstance?.isTicking && (
        <>
          {timerContent}
          {isActivityTimer && (
            <div
              className={cn(styles.container, "activity-timer")}
              ref={containerRef}
            >
              <div className={styles.infoContainer}>
                {title ? (
                  <ContentfulRichField
                    content={title}
                    className={styles.timerTitle}
                  />
                ) : (
                  <div className="flex-spacer" />
                )}
                {!!value && !timeIsUp && (
                  <>
                    <div className={cn(styles.timerValue)}>
                      <div
                        className={cn(styles.info, "text", "tiny", "palest")}
                      >
                        time left
                      </div>
                      <h2
                        className={cn(
                          styles.time,
                          "bold",
                          timeIsRunningUp && "isRed"
                        )}
                      >
                        {timeLeft}
                      </h2>
                    </div>
                  </>
                )}
                {timeIsUp && <h3 className={styles.timeIsUp}>Time is up</h3>}
                {timeIsRunningUp && !isHurryUpPopupHidden && !timeIsUp && (
                  <Portal>
                    <div
                      className={cn(styles.hurryUpContainer)}
                      style={{
                        top: tooltipPosition.top + 3,
                        left: tooltipPosition.right - 6,
                        zIndex: 1000,
                      }}
                    >
                      <div className="line">
                        <div className="left">
                          <i className="icon fa fa-triangle-exclamation red" />
                          <span className="text bold">Hurry up</span>
                        </div>
                        <div className="right">
                          <i
                            className="icon fa fa-xmark"
                            onClick={() => setIsHurryUpPopupHidden(true)}
                          />
                        </div>
                      </div>
                      <p className="text small info-text">
                        Your time to finish this activity ends soon!
                      </p>
                      <div className={styles.tooltipArrow} />
                    </div>
                  </Portal>
                )}
              </div>
              <div className={styles.barContainer}>
                <div
                  className={cn(styles.bar, timeIsRunningUp && "isRed")}
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
});
