import { useState, useEffect, useCallback } from "react";
import { getItem, putItem, listenToChanges } from "../utils/db";

export const useIndexedDBState = <T = any>(
  key: IDBValidKey | IDBKeyRange,
  initialState: T | (() => T | undefined) | undefined,
  watch = false
) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    getItem<T>(key).then((storedValue) => {
      if (storedValue === undefined) return;
      setState(storedValue);
    });
  }, [key]);

  useEffect(() => {
    if (!watch) return;
    const unsubscribe = listenToChanges<T>((key, value) => {
      if (state === value) return;
      setState(value);
    });
    return () => {
      unsubscribe();
    };
  }, [state, watch]);

  const setIndexedDBState = useCallback(
    async (newValue: T) => {
      setState(newValue);
      await putItem(newValue, key);
    },
    [key]
  );

  return [state, setIndexedDBState];
};
