import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SessionContext } from "../contexts/Session";
import { useMatches, Navigate, useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/Global";
import { SessionState } from "../+xstate/machines/session/session";
import { HeaderContext } from "../contexts/Header";
import { JitsiSetup } from "../components/JitsiSetup/JitsiSetup";
import { JitsiContext } from "../contexts/Jitsi";
import {
  JitsiConnectionStates,
  JitsiSetupStates,
} from "../+xstate/machines/jitsi";

import WorkshopInstance from "../components/Workshop/Workshop/Workshop";
import WaitingRoom from "../components/WaitingRoom/WaitingRoom";
import SlotActiveSessionList from "../components/WaitingRoom/SlotActiveSessionList";
import SidePanel from "../components/SidePanel/SidePanel";
import Header from "../components/Workshop/Header/Header";
import WorkshopExitModal from "../components/Workshop/WorkshopExitModal/WorkshopExitModal";
import Loader from "../components/Shared/Loader/Loader";
import ThankYou from "../components/Workshop/ThankYou/ThankYou";
import ConnectionLost from "../components/Workshop/ConnectionLost/ConnectionLost";
import ErrorPage from "../components/Workshop/ErrorPage/ErrorPage";
import InternetConnectionStrength from "../components/InternetConnectionStrength/InternetConnectionStrength";

import {
  calculateWorkshopDuration,
  getActivities,
  getEntryId,
  getTitle,
} from "../utils";
import {
  GuidePortal,
  HeaderPortal,
  InternetConnectionPortal,
} from "../components/Header/Header";
import Guide from "../components/Guide/Guide";

import { Transcript } from "../components/SidePanel/components/TranscriptList/TranscriptList";
import { extractActivityDataFromSessionState } from "../utils/extract-activity-data-from-session-state";
import { SessionStatus } from "../apollo-graphql/types/enums/session-status";
import { WorkshopClockContextProvider } from "../contexts/WorkshopClock";
import { WorkshopState } from "../+xstate/machines/session/workshop";
import { StandardSessionActivity } from "../apollo-graphql/types/enums/standard-session-activity";
import { WorkshopDisconnectType } from "../types/enums/workshop-disconnect-type";
import { SessionStateValue } from "../apollo-graphql/types/session-state";
import { JitsiEvents } from "../jitsi";
import { SlotType } from "../apollo-graphql/types/enums/slot-type";
import { parseSession } from "../utils/parse-session";
import { getTeamName } from "../utils/get-team-name";
import { isObserver } from "../utils/observers";
import { Workspace } from "../apollo-graphql/types/workspace";
import { FetchState } from "../+xstate/machines/fetch-factory";
import { ApolloContext } from "../contexts/Apollo";
import { ConnectionStrength } from "../types/enums/connection-strength";
import { ConferenceMode } from "../types/contentful/enums";
import { refreshMyProfileChannel } from "../constants/channels";
import { WarmUpActivityType } from "../types/contentful/workshop/activities/warm-up";

import styles from "./Session.module.css";
import { TechnicalSetupHelpOutcome } from "../+xstate/machines/auth";
import ConfirmationDialog from "../components/ConfirmationDialog/ConfirmationDialog";

export default function Session() {
  const navigate = useNavigate();
  const matches = useMatches();
  const { auth } = useContext(GlobalContext);
  const sessionContext = useContext(SessionContext);
  const globalContext = useContext(GlobalContext);
  const jitsiContext = useContext(JitsiContext);
  const apolloContext = useContext(ApolloContext);
  const { theme, title, setTheme, setTitle, setUseDefaultTheme, resetTheme } =
    useContext(HeaderContext);

  const nameRef = useRef<string | null>(null);
  const setupTimerId1Ref = useRef<number | null>(null);
  const setupTimerId2Ref = useRef<number | null>(null);
  const [videoElementRef, setVideoElementRef] =
    useState<HTMLVideoElement | null>(null);

  const [setupComplete, setSetupComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSetupLoading, setIsSetupLoading] = useState(true);

  const slotMatch = useMemo(
    () => matches.find((item) => item.id === "session-slot"),
    [matches]
  );
  const instanceMatch = useMemo(
    () => matches.find((item) => item.id === "session-instance"),
    [matches]
  );
  const instanceGroupMatch = useMemo(
    () => matches.find((item) => item.id === "session-instance-group"),
    [matches]
  );
  const waitingRoomMatch = useMemo(
    () => matches.find((item) => item.id === "waiting-room"),
    [matches]
  );
  const ongoingSessionsMatch = useMemo(
    () => matches.find((item) => item.id === "ongoing-sessions"),
    [matches]
  );
  const waitingRoomRescheduleRedirectMatch = useMemo(
    () => matches.find((item) => item.id === "reschedule-redirect"),
    [matches]
  );
  const thankYouMatch = useMemo(
    () => matches.find((item) => item.id === "thank-you"),
    [matches]
  );
  const errorMatch = useMemo(
    () => matches.find((item) => item.id === "error"),
    [matches]
  );

  const connectionLostMatch = useMemo(
    () => matches.find((item) => item.id === "connection-lost"),
    [matches]
  );

  const jitsiInstance = useMemo(
    () => jitsiContext.state.context.jitsiInstance,
    [jitsiContext.state.context.jitsiInstance]
  );

  const jitsiIsConnected = useMemo(
    () =>
      jitsiContext.state.matches({
        connection: JitsiConnectionStates.Connected,
      }),
    [jitsiContext.state]
  );
  const hasLostJitsiConnection = useMemo(
    () =>
      jitsiContext.state.matches({
        connection: JitsiConnectionStates.Disconnected,
      }),
    [jitsiContext.state]
  );

  const connectionStrength = useMemo(
    () => apolloContext.socket.state.context.connectionStrength,
    [apolloContext.socket.state.context.connectionStrength]
  );

  const isUnknownConnectionStrength = useMemo(
    () => connectionStrength === ConnectionStrength.Unknown,
    [connectionStrength]
  );

  const isConnectionLost = useMemo(
    () => hasLostJitsiConnection || isUnknownConnectionStrength,
    [hasLostJitsiConnection, isUnknownConnectionStrength]
  );

  const instanceUUID = useMemo(
    () => globalContext.instanceUUID,
    [globalContext.instanceUUID]
  );
  const profile = useMemo(
    () => globalContext.auth.context.profile!,
    [globalContext.auth.context.profile]
  );

  const technicalSetupHelpOutcome =
    globalContext.auth.context.technicalSetupHelpOutcome;
  const technicalSetupClear = globalContext.auth.technicalSetupClear;

  const profileId = useMemo(() => profile!.id, [profile]);
  const currentProfileEmail = useMemo(() => profile!.email, [profile]);
  const token = useMemo(
    () => globalContext.auth.context.token,
    [globalContext.auth.context.token]
  );
  const slotInstance = useMemo(
    () => sessionContext.session.state.context.slot,
    [sessionContext.session.state.context.slot]
  );
  const technicalSetupHelp = useMemo(
    () => globalContext.auth.technicalSetupHelp,
    [globalContext.auth.technicalSetupHelp]
  );
  const participantProfiles = useMemo(
    () => sessionContext.session.state.context.profiles,
    [sessionContext.session.state.context.profiles]
  );
  const sessionInstance = useMemo(
    () => sessionContext.session.state.context.session,
    [sessionContext.session.state.context.session]
  );
  const sessionId = useMemo(() => sessionInstance?.id, [sessionInstance?.id]);
  const transition = useMemo(
    () => sessionContext.workshop.state.context.transition,
    [sessionContext.workshop.state.context.transition]
  );
  const workshopErrors = useMemo(
    () => sessionContext.workshop.state.context.errors,
    [sessionContext.workshop.state.context.errors]
  );
  const sessionError = useMemo(
    () => sessionContext.session.state.context.error,
    [sessionContext.session.state.context.error]
  );

  const workshopState = useMemo(
    () => sessionContext.workshop.state.value,
    [sessionContext.workshop.state.value]
  );

  const workshopParticipantChange = useMemo(
    () => sessionContext.workshop.workshopParticipantChange,
    [sessionContext.workshop.workshopParticipantChange]
  );
  const requestNextWorkshopMachine = useMemo(
    () => sessionContext.requestNextWorkshop,
    [sessionContext.requestNextWorkshop]
  );
  const sessionReset = useMemo(
    () => sessionContext.session.reset,
    [sessionContext.session.reset]
  );
  const workshopEnd = useMemo(() => {
    return sessionContext.workshop.workshopEnd;
  }, [sessionContext.workshop.workshopEnd]);

  const startWorkshop = useMemo(() => {
    return sessionContext.workshop.startWorkshop;
  }, [sessionContext.workshop.startWorkshop]);

  const requestNextWorkshop = useCallback(
    (id: string) => {
      requestNextWorkshopMachine.trigger(id);
    },
    [requestNextWorkshopMachine]
  );
  const nextWorkshopRequested = !requestNextWorkshopMachine.state.matches(
    FetchState.Idle
  );

  const workshop = useMemo(
    () => slotInstance?.workshop,
    [slotInstance?.workshop]
  );
  const workspace = useMemo(
    () => (profile?.workspace as any)?.workspace as Workspace | undefined,
    [profile?.workspace]
  );

  const journeyItems = useMemo(
    () => sessionContext?.workshop?.state?.context?.journeyWorkshops || [],
    [sessionContext?.workshop?.state?.context?.journeyWorkshops]
  );

  const {
    millisecondsToStart,
    splitMillisecondsWaitingTime,
    sessionOpeningTimeInMilliseconds,
    invitationStatus,
    invitationId,
    invitationResponseServerTimestamp,
    group,
  } = useMemo(
    () => sessionContext.session.state.context,
    [sessionContext.session.state.context]
  );

  const invitationNotFound = useMemo(
    () =>
      sessionContext.session.state.matches({
        session: SessionState.InvitationNotFound,
      }),
    [sessionContext.session.state]
  );

  const sessionNotFound = useMemo(
    () =>
      sessionContext.session.state.matches({
        session: SessionState.SessionNotFound,
      }) ||
      (!!sessionId && !group),
    [group, sessionContext.session.state, sessionId]
  );

  const isInviteState = useMemo(
    () =>
      sessionContext.session.state.matches({
        session: SessionState.Invite,
      }),
    [sessionContext.session.state]
  );

  useEffect(() => {
    if (!nameRef.current) {
      nameRef.current = profile?.name || null;
      return;
    }
    if (nameRef.current !== profile?.name) {
      jitsiContext.handleUserNameChange({
        participantId: profileId,
        name: profile?.name || "",
      });
      nameRef.current = profile?.name || null;
    }
  }, [jitsiContext, profile?.name, profileId]);

  useEffect(() => {
    if (sessionId) {
      setIsSetupLoading(false);
      setIsLoading(false);
    }
    if (!isSetupLoading || setupTimerId2Ref.current || isLoading) return;
    setupTimerId2Ref.current = setTimeout(() => {
      setIsSetupLoading(false);
    }, 8000) as unknown as number;
  }, [isLoading, isSetupLoading, sessionId]);

  useEffect(() => {
    if (setupTimerId1Ref.current) return;
    setupTimerId1Ref.current = setTimeout(() => {
      setIsLoading(false);
    }, 5000) as unknown as number;
  }, []);

  const workshopTheme = useMemo(() => {
    return workshop?.fields?.journey?.fields?.theme;
  }, [workshop?.fields?.journey?.fields?.theme]);

  useEffect(() => {
    if (!workshop) {
      setUseDefaultTheme(true);
      return;
    }

    if (theme !== workshopTheme) {
      if (workshopTheme) {
        setTheme(workshopTheme);
      }
      setUseDefaultTheme(!workshopTheme);
    }
    if (workshop?.fields.title && title !== workshop?.fields.title) {
      const journey = workshop.fields?.journey;
      const workshopTitle = workshop?.fields?.title;
      const journeyTitle = journey?.fields?.title;
      let title = `${
        journeyTitle ? `${journeyTitle}: ${workshopTitle}` : workshopTitle
      }`;
      if (!!journeyItems?.length) {
        const journeyIndex =
          journeyItems.findIndex(
            (i) => getEntryId(i) === getEntryId(workshop)
          ) + 1;
        const journeyCount = journeyItems.length;

        if (journeyIndex > 0) {
          title = `${journeyTitle} - Conversation ${journeyIndex} of ${journeyCount}: ${workshopTitle}`;
        }
      }
      setTitle(title);
    }
  }, [
    journeyItems,
    setTheme,
    setTitle,
    setUseDefaultTheme,
    theme,
    title,
    workshop,
    workshopTheme,
  ]);

  // When user leaves the workshop conference we need to reset the headerContext state
  useEffect(() => {
    return () => {
      resetTheme();
    };
  }, [resetTheme]);

  // TODO: Refactor when we connect all machines together. At the moment
  // there is no other way for jitsi to communicate to the other machines
  // because they are separate. The idea here is when someone changes their name
  // for us to update their profile information by fetching it from the backend
  useEffect(() => {
    function nameChangeHandler(event: Event) {
      const detail = (event as CustomEvent).detail as {
        attributes: {
          participantId: string;
          name: string;
        };
      };

      const {
        attributes: { participantId },
      } = detail;
      workshopParticipantChange({
        refetchParticipantIds: [participantId],
      });
    }

    jitsiInstance?.addEventListener(
      JitsiEvents.USER_NAME_CHANGED,
      nameChangeHandler
    );
    return () => {
      jitsiInstance?.removeEventListener(
        JitsiEvents.USER_NAME_CHANGED,
        nameChangeHandler
      );
    };
  }, [jitsiInstance, workshopParticipantChange]);

  const isSessionCompleted = useMemo(
    () =>
      sessionInstance?.status === SessionStatus.COMPLETED ||
      sessionContext.workshop.state.context.sessionState?.value ===
        StandardSessionActivity.ViewResults,
    [
      sessionContext.workshop.state.context.sessionState?.value,
      sessionInstance?.status,
    ]
  );

  const isSetupDone = useMemo(
    () =>
      jitsiContext.state.matches({
        setup: JitsiSetupStates.Done,
      }),
    [jitsiContext.state]
  );

  const isKicked = useMemo(
    () => sessionContext.workshop.state.matches(WorkshopState.Kicked),
    [sessionContext.workshop.state]
  );
  const hasErrored = useMemo(
    () =>
      !!sessionError ||
      sessionContext.workshop.state.matches(WorkshopState.Error),
    [sessionContext.workshop.state, sessionError]
  );

  const sessionState: SessionStateValue = useMemo(() => {
    if (sessionInstance?.status === SessionStatus.COMPLETED) {
      return parseSession(sessionInstance.state);
    }
    return {
      value: sessionContext.workshop.state.context.sessionState?.value,
      context: sessionContext.workshop.state.context.sessionState?.context,
    } as SessionStateValue;
  }, [
    sessionContext.workshop.state.context,
    sessionInstance?.state,
    sessionInstance?.status,
  ]);

  const isJitsiInInitialState = useMemo(
    () =>
      jitsiContext.state.matches({
        setup: JitsiSetupStates.Initial,
      }),
    [jitsiContext.state]
  );

  const isConnected = useMemo(
    () =>
      jitsiContext.state.matches({
        connection: JitsiConnectionStates.Connected,
      }),
    [jitsiContext.state]
  );

  const audioAndVideoConfigureDone = useMemo(
    () =>
      jitsiContext.state.matches({
        setup: JitsiSetupStates.Configure,
      }),
    [jitsiContext.state]
  );

  const selectedAudioSourceData = useMemo(
    () => jitsiContext.state.context.selectedAudioSourceData,
    [jitsiContext.state.context.selectedAudioSourceData]
  );
  const selectedVideoSourceData = useMemo(
    () => jitsiContext.state.context.selectedVideoSourceData,
    [jitsiContext.state.context.selectedVideoSourceData]
  );
  const availableAudioSources = useMemo(
    () => jitsiContext.state.context.availableAudioSources,
    [jitsiContext.state.context.availableAudioSources]
  );
  const availableVideoSources = useMemo(
    () => jitsiContext.state.context.availableVideoSources,
    [jitsiContext.state.context.availableVideoSources]
  );

  const observers = useMemo(
    () => sessionState?.context?.observers || [],
    [sessionState?.context?.observers]
  );

  const currentProfileIsObserver = useMemo(
    () => isObserver(profileId, observers),
    [observers, profileId]
  );

  const currentActiveProfiles = useMemo(
    () => sessionState?.context?.currentActiveProfiles || [],
    [sessionState?.context?.currentActiveProfiles]
  );

  const reconnectTimeouts = useMemo(
    () => sessionState?.context?.reconnectTimeouts || [],
    [sessionState?.context?.reconnectTimeouts]
  );

  const slotInvitations = useMemo(
    () => slotInstance?.invitations || [],
    [slotInstance?.invitations]
  );

  const isParticipating = useMemo(
    () =>
      isSessionCompleted
        ? true
        : !!currentActiveProfiles?.find((item) => item.profileId === profileId),
    [isSessionCompleted, profileId, currentActiveProfiles]
  );

  const isReadyToStart = useMemo(
    () =>
      isParticipating &&
      !!sessionState?.context?.readyActiveProfiles.includes(profileId),
    [isParticipating, profileId, sessionState?.context?.readyActiveProfiles]
  );

  const jitsiRemoteParticipantsData = useMemo(
    () => jitsiContext.state.context.remoteParticipantsData || [],
    [jitsiContext.state.context.remoteParticipantsData]
  );

  // Note: since currentActiveProfiles gets updated with new references when the state gets updated
  // I'm constructing a string from it so we can check if the remote user has configured everything properly
  // before showing him to the others. This filtering happens bellow in remoteSourceData which if not using a
  // string will get recalculated every time the context currentActiveProfiles changes even if we haven't got
  // any changes in the array itself so by using a string we pretty much resolve this recalculation.
  const currentActiveProfilesString = useMemo(() => {
    const remoteParticipantsIds = isSessionCompleted
      ? jitsiRemoteParticipantsData.map(({ name }) => name)
      : Array.from(
          new Set([
            ...(currentActiveProfiles.map(
              ({ profileId }) => profileId + "_cap"
            ) || []),
            ...observers.map((o) => o + "_cap"),
            ...(reconnectTimeouts.map(({ profileId }) => profileId + "_rt") ||
              []),
          ])
        );

    return remoteParticipantsIds.filter(Boolean).join(",");
  }, [
    currentActiveProfiles,
    isSessionCompleted,
    jitsiRemoteParticipantsData,
    observers,
    reconnectTimeouts,
  ]);

  const { id: currentActivityId } = useMemo(
    () => extractActivityDataFromSessionState(sessionState?.value || null),
    [sessionState?.value]
  );

  const isViewResultsStage = useMemo(
    () => currentActivityId === StandardSessionActivity.ViewResults,
    [currentActivityId]
  );

  const internetConnectionStrength = useMemo(() => {
    return isViewResultsStage
      ? jitsiIsConnected
        ? ConnectionStrength.High
        : ConnectionStrength.Unknown
      : connectionStrength;
  }, [connectionStrength, isViewResultsStage, jitsiIsConnected]);

  const remoteParticipantsData = useMemo(() => {
    if (!currentActiveProfilesString) return [];

    const uniqueRemoteParticipantIds = Array.from(
      new Set([
        ...currentActiveProfilesString
          .split(",")
          .map((entry) => entry.replace(/_(cap|rt)/g, "")),
      ])
    );

    return uniqueRemoteParticipantIds
      .filter((id) => {
        return id !== profileId;
      })
      .map((id) => {
        const remoteParticipantEntry = jitsiRemoteParticipantsData?.find(
          (p) => p.name === id
        )!;

        const profile =
          participantProfiles.find((p) => p.id === id) ||
          slotInvitations.find((invitation) => invitation.profile.id === id)
            ?.profile;

        if (!profile)
          return {
            ...remoteParticipantEntry,
            name: "Loading...",
            isConnecting: false,
          };

        const isConnecting = !!reconnectTimeouts.find(
          (r) => r.profileId === profile.id
        );

        return {
          ...remoteParticipantEntry,
          name: profile.name,
          isConnecting,
          profileId: profile.id,
          workspaceId: profile.workspace.workspace_id,
          isObserver: isObserver(profile.id, observers) && !isViewResultsStage,
        };
      })
      .sort((a, b) => {
        if (!a.profileId || !b.profileId) return 0;
        return a.profileId.localeCompare(b.profileId);
      });
    // I'm commenting out this because it's breaking the
    // logic for showing Connecting... when we have reconnect timeout
    // if this causes some issues please bring it back but if it doesn't
    // let's remove it after some time (12.05.2024)
    // .filter((x) => !!x?.participantId);
  }, [
    currentActiveProfilesString,
    profileId,
    jitsiRemoteParticipantsData,
    participantProfiles,
    slotInvitations,
    reconnectTimeouts,
    observers,
    isViewResultsStage,
  ]);

  const workshopActivities = useMemo(
    () => slotInstance?.workshop?.fields?.activities || [],
    [slotInstance?.workshop?.fields?.activities]
  );

  const currentActivity = useMemo(
    () => workshopActivities.find((a) => a.sys.id === currentActivityId),
    [currentActivityId, workshopActivities]
  );

  const nextActivity = useMemo(() => {
    const id = getEntryId(currentActivity) || currentActivityId;
    if (!id) return null;

    const currentActivityIndex = workshopActivities.findIndex(
      (x) => x.sys.id === id
    );

    const found = workshopActivities[currentActivityIndex + 1];
    return found || null;
  }, [currentActivity, currentActivityId, workshopActivities]);

  // TODO: Fix the logic for Question Activity
  const allQuestionActivitiesAnswersMap: {
    [key: string]: any;
  } = useMemo(() =>
    //   (
    //     slotInstance?.workshop.activities.filter(
    //       (activity: Activity) => activity.type === ActivityType.Question
    //     ) || []
    //   ).reduce(
    //     (acc, activity) => ({
    //       ...acc,
    //       [activity.id]: activity.answers || [],
    //     }),
    //     {}
    //   ),
    // [slotInstance?.workshop.activities]
    {
      return {};
    }, []);

  const autoDisableAudioAndVideoForIndividualScreen = useMemo(() => {
    const conferenceMode =
      currentActivity?.fields?.activity?.fields?.conferenceMode;

    return conferenceMode === ConferenceMode.Solo;
  }, [currentActivity]);

  const activityPartShouldBeMuted = useMemo(
    () => jitsiContext.state.context.activityPartShouldBeMuted,
    [jitsiContext.state.context.activityPartShouldBeMuted]
  );

  const isUserTalking = useMemo(
    () => !!jitsiContext.state.context.isUserTalking,
    [jitsiContext.state.context.isUserTalking]
  );

  const refreshJourneyData = useMemo(
    () => auth.refreshJourneyData,
    [auth.refreshJourneyData]
  );

  useEffect(() => {
    if (isSessionCompleted || !isViewResultsStage) return;
    refreshJourneyData();
    refreshMyProfileChannel.postMessage("refreshProfile");
  }, [isSessionCompleted, isViewResultsStage, refreshJourneyData]);

  useEffect(() => {
    if (
      activityPartShouldBeMuted !== autoDisableAudioAndVideoForIndividualScreen
    ) {
      jitsiContext.handleActivityPartChange({
        shouldBeMuted: autoDisableAudioAndVideoForIndividualScreen,
      });
    }
  }, [
    autoDisableAudioAndVideoForIndividualScreen,
    jitsiContext,
    activityPartShouldBeMuted,
  ]);

  const workshopDisconnect = useMemo(
    () => sessionContext.workshop.workshopDisconnect,
    [sessionContext.workshop.workshopDisconnect]
  );
  const workshopJoin = useMemo(
    () => sessionContext.workshop.workshopJoin,
    [sessionContext.workshop.workshopJoin]
  );

  const workshopDisconnectHandler = useCallback(() => {
    if (!sessionId) return;
    workshopDisconnect({
      sessionId,
      intended: true,
      type: WorkshopDisconnectType.Observe,
    });
  }, [sessionId, workshopDisconnect]);

  const workshopJoinHandler = useCallback(() => {
    if (!sessionId) return;
    workshopJoin({
      sessionId,
      uuid: instanceUUID,
    });
  }, [instanceUUID, sessionId, workshopJoin]);

  const toggleParticipationHandler = useCallback(() => {
    if (isParticipating) {
      workshopDisconnectHandler();
    } else {
      workshopJoinHandler();
    }
  }, [isParticipating, workshopDisconnectHandler, workshopJoinHandler]);

  const workshopSetActivityValue = useMemo(
    () => sessionContext.workshop.workshopSetActivityValue,
    [sessionContext.workshop.workshopSetActivityValue]
  );

  const workshopReadyToStart = useMemo(
    () => sessionContext.workshop.workshopReadyToStart,
    [sessionContext.workshop.workshopReadyToStart]
  );

  const workshopSetActivityReady = useMemo(
    () => sessionContext.workshop.workshopSetActivityReady,
    [sessionContext.workshop.workshopSetActivityReady]
  );

  const setReadyToStartHandler = useCallback(() => {
    if (!sessionId || isReadyToStart) return;
    if (!isParticipating) {
      workshopJoinHandler();
      return;
    }
    workshopReadyToStart({ sessionId });
  }, [
    sessionId,
    isReadyToStart,
    isParticipating,
    workshopReadyToStart,
    workshopJoinHandler,
  ]);

  const setActivityValueHandler = useCallback(
    ({
      activityId,
      value,
      markAsReady,
    }: {
      activityId: string;
      value: string;
      markAsReady?: boolean;
    }) => {
      if (!sessionId) return;
      if (!isParticipating) {
        workshopJoinHandler();
        return;
      }
      workshopSetActivityValue({
        sessionId,
        activityId,
        value,
        markAsReady: markAsReady || false,
      });
    },
    [sessionId, isParticipating, workshopSetActivityValue, workshopJoinHandler]
  );

  const setActivityReadyHandler = useCallback(
    ({ activityId }: { activityId: string }) => {
      if (!sessionId) return;
      if (!isParticipating) {
        workshopJoinHandler();
        return;
      }
      workshopSetActivityReady({
        sessionId,
        activityId,
      });
    },
    [sessionId, isParticipating, workshopSetActivityReady, workshopJoinHandler]
  );

  const joinConference = useMemo(
    () => jitsiContext.joinConference,
    [jitsiContext.joinConference]
  );

  const setupCompletedHandler = useCallback(() => {
    joinConference({});
    if (isSessionCompleted) {
      workshopEnd({
        journeyId: workshop!.fields.journey.sys.id,
        currentProfileEmail,
      });
    } else {
      startWorkshop({
        sessionId: sessionInstance!.id,
        currentProfileId: profileId,
        currentProfileEmail,
        uuid: instanceUUID,
        journeyId: workshop!.fields.journey.sys.id,
      });
    }
  }, [
    joinConference,
    isSessionCompleted,
    workshopEnd,
    workshop,
    currentProfileEmail,
    startWorkshop,
    sessionInstance,
    profileId,
    instanceUUID,
  ]);

  const configureAudio = useMemo(
    () => jitsiContext.configureAudio,
    [jitsiContext.configureAudio]
  );

  const configureVideo = useMemo(
    () => jitsiContext.configureVideo,
    [jitsiContext.configureVideo]
  );

  const audioDeviceChangeHandler = useCallback(
    (newSourceId: string) => {
      const newSelectedAudioSourceData = availableAudioSources!.find(
        (s) => s.sourceId === newSourceId
      )!;

      configureAudio({
        selectedAudioSourceData: {
          ...newSelectedAudioSourceData,
          isMuted: selectedAudioSourceData?.isMuted || false,
        },
      });
    },
    [availableAudioSources, configureAudio, selectedAudioSourceData?.isMuted]
  );

  const videoDeviceChangeHandler = useCallback(
    (newSourceId: string) => {
      const newSelectedVideoSourceData = availableVideoSources!.find(
        (s) => s.sourceId === newSourceId
      )!;

      configureVideo({
        selectedVideoSourceData: {
          ...newSelectedVideoSourceData,
          isMuted: selectedVideoSourceData?.isMuted || false,
        },
      });
    },
    [availableVideoSources, configureVideo, selectedVideoSourceData?.isMuted]
  );

  const toggleAudioHandler = useCallback(
    (muted?: boolean) => {
      if (!selectedAudioSourceData) return;
      const isMuted =
        typeof muted === "boolean" ? muted : !selectedAudioSourceData!.isMuted;
      if (isMuted === selectedAudioSourceData.isMuted) return;
      configureAudio({
        selectedAudioSourceData: {
          ...selectedAudioSourceData!,
          isMuted,
        },
      });
    },
    [configureAudio, selectedAudioSourceData]
  );

  const toggleVideoHandler = useCallback(
    (muted?: boolean) => {
      if (!selectedVideoSourceData) return;
      const isMuted =
        typeof muted === "boolean" ? muted : !selectedVideoSourceData!.isMuted;
      if (isMuted === selectedVideoSourceData.isMuted) return;
      configureVideo({
        selectedVideoSourceData: {
          ...selectedVideoSourceData!,
          isMuted,
        },
      });
    },
    [configureVideo, selectedVideoSourceData]
  );

  const waitingRoomTimeoutNavigation = useCallback(
    (slotId: string) => {
      sessionReset();
      navigate(`/session/slot/${slotId}`);
    },
    [navigate, sessionReset]
  );

  const jitsiReset = useMemo(() => jitsiContext.reset, [jitsiContext]);
  const handleExit = useCallback(() => {
    if (jitsiIsConnected) {
      sessionReset();
      jitsiReset();
    }
    if (sessionId) {
      workshopDisconnect({
        sessionId,
        intended: true,
        type: WorkshopDisconnectType.Default,
      });
    }
  }, [
    jitsiIsConnected,
    jitsiReset,
    sessionId,
    sessionReset,
    workshopDisconnect,
  ]);

  // Info: This load slot and session data
  useEffect(() => {
    if (hasErrored) return;

    if (
      !isInviteState &&
      (slotMatch || waitingRoomMatch || ongoingSessionsMatch) &&
      !slotInstance
    ) {
      const slotId = (slotMatch || waitingRoomMatch || ongoingSessionsMatch)!
        .params.slotId!;
      const email = profile!.email;
      return void sessionContext.session.getInvite({
        variables: { email, slotId },
      });
    }
    if ((instanceMatch || instanceGroupMatch) && !sessionInstance) {
      const sessionKey = (instanceMatch?.params.sessionKey ||
        instanceGroupMatch?.params.sessionKey)!;
      const includeSlotAndWorkshop = !slotInstance;
      const group = instanceGroupMatch?.params?.group
        ? +instanceGroupMatch.params.group
        : undefined;
      return void sessionContext.session.getSession({
        variables: {
          sessionKey,
          includeSlotAndWorkshop,
          group,
        },
      });
    }
  }, [
    profile,
    instanceMatch,
    isInviteState,
    matches,
    sessionContext,
    sessionInstance,
    slotInstance,
    slotMatch,
    waitingRoomMatch,
    ongoingSessionsMatch,
    instanceGroupMatch,
    hasErrored,
  ]);

  const attachHtmlVideoElementToLocalTracks = useMemo(
    () => jitsiContext.attachHtmlVideoElementToLocalTracks,
    [jitsiContext.attachHtmlVideoElementToLocalTracks]
  );

  const initialize = useMemo(
    () => jitsiContext.initialize,
    [jitsiContext.initialize]
  );

  const isInitialized = useRef(false);

  // Info: Initialize Jitsi when ready
  useLayoutEffect(() => {
    if (!isJitsiInInitialState || !videoElementRef || isInitialized.current) {
      if (!isJitsiInInitialState && videoElementRef) {
        attachHtmlVideoElementToLocalTracks(videoElementRef);
      }
      return;
    }
    initialize({
      profileId,
      htmlVideoElement: videoElementRef!,
    });
    isInitialized.current = true;
  }, [
    attachHtmlVideoElementToLocalTracks,
    isJitsiInInitialState,
    initialize,
    profileId,
    videoElementRef,
  ]);
  const setupConnection = useMemo(
    () => jitsiContext.setupConnection,
    [jitsiContext.setupConnection]
  );
  // Info: Setup Jitsi Connection when we have the roomName (sessionId)
  useEffect(() => {
    if (!sessionId || !jitsiInstance || !!jitsiInstance.connection) return;
    setupConnection({
      roomName: sessionId,
    });
  }, [setupConnection, jitsiInstance, sessionId]);
  // Info: Attach local tracks

  const transcripts: Transcript[] = useMemo(() => {
    const currentActivityIndex = (workshop?.fields?.activities || []).findIndex(
      (a) => getEntryId(a) === currentActivityId
    );

    return (workshop?.fields?.activities || [])
      ?.map((a, index) => {
        return {
          index,
          heading: a.fields.title,
          transcript: a?.fields?.activity?.fields?.transcript,
        };
      })
      .filter(({ index, transcript }) => {
        return transcript && index <= currentActivityIndex;
      })
      .reverse();
  }, [currentActivityId, workshop?.fields?.activities]);

  const teamName = useMemo(
    () => getTeamName(sessionState?.context?.activityResult, ""),
    [sessionState?.context?.activityResult]
  );

  // TODO: consider moving waiting room navigation outside session to remove the
  // need for session reset call because Session will be destroyed and once we navigate
  // back we will have a brand new machine
  useEffect(() => {
    if (!waitingRoomRescheduleRedirectMatch) return;
    sessionReset();
  }, [sessionReset, waitingRoomRescheduleRedirectMatch]);

  const isProcessing =
    sessionContext.session.state.matches({ session: SessionState.Initial }) ||
    (sessionContext.workshop.state.matches(WorkshopState.Joining) &&
      !currentProfileIsObserver) ||
    isInviteState;

  const currentActivityDescription = useMemo(
    () =>
      // currentActivity ? currentActivity.description.split(":")[0] : undefined,
      currentActivity ? currentActivity.fields.title : undefined,
    [currentActivity]
  );

  const shouldRejoinWorkshop = useMemo(() => {
    const isNotInstanceMatch = !(
      slotInstance &&
      (instanceMatch || instanceGroupMatch) &&
      typeof group === "number" &&
      +(instanceGroupMatch?.params?.group || "0") !== group
    );
    const isNotWaitingRoomRescheduleRedirect =
      !waitingRoomRescheduleRedirectMatch;
    const isNotOnOngoingSessions = !(
      (slotMatch || ongoingSessionsMatch) &&
      slotInstance &&
      slotInstance.sessions.length > 0 &&
      slotInstance.type === SlotType.SPLIT &&
      (invitationResponseServerTimestamp || 0) -
        Math.min(...slotInstance.sessions.map((s) => s.create_date)) >
        60
    );
    const isNotWaitingStage = !(
      !!millisecondsToStart &&
      slotInstance &&
      millisecondsToStart >
        (slotInstance.type === SlotType.ALL
          ? sessionContext.session.state.context
              .sessionOpeningTimeInMilliseconds!
          : 0)
    );
    const isNotProcessing = !(isProcessing && !invitationNotFound);

    const isNotRedirectingToSlot = !(
      !instanceMatch &&
      !instanceGroupMatch &&
      !!slotInstance?.key &&
      !ongoingSessionsMatch
    );

    const hasWorkshopData =
      isSetupDone &&
      workshop &&
      workspace &&
      slotInstance &&
      sessionState?.context;

    const isOnWorkshopScreenAndHasConnection =
      hasWorkshopData &&
      isNotInstanceMatch &&
      isNotWaitingRoomRescheduleRedirect &&
      isNotOnOngoingSessions &&
      isNotWaitingStage &&
      isNotProcessing &&
      isNotRedirectingToSlot;

    return isOnWorkshopScreenAndHasConnection && !isParticipating;
  }, [
    group,
    instanceGroupMatch,
    instanceMatch,
    invitationNotFound,
    invitationResponseServerTimestamp,
    isProcessing,
    isSetupDone,
    millisecondsToStart,
    ongoingSessionsMatch,
    isParticipating,
    sessionContext.session.state.context.sessionOpeningTimeInMilliseconds,
    sessionState?.context,
    slotInstance,
    slotMatch,
    waitingRoomRescheduleRedirectMatch,
    workshop,
    workspace,
  ]);
  useEffect(() => {
    if (shouldRejoinWorkshop && !currentProfileIsObserver) {
      toggleParticipationHandler();
    }
  }, [
    currentProfileIsObserver,
    shouldRejoinWorkshop,
    toggleParticipationHandler,
  ]);

  useEffect(() => {
    if (
      !sessionId ||
      !setupComplete ||
      isSetupDone ||
      !jitsiInstance?.conference ||
      !slotInstance?.status
    )
      return;
    setupCompletedHandler();
  }, [
    isSetupDone,
    jitsiInstance?.conference,
    sessionId,
    setupComplete,
    setupCompletedHandler,
    slotInstance,
  ]);

  const jitsiState = useMemo(() => jitsiContext.state, [jitsiContext.state]);

  const setSetupCompleteHandler = useCallback(() => {
    auth.saveDeviceSetup({
      selectedAudioDevice: selectedAudioSourceData,
      selectedVideoDevice: selectedVideoSourceData,
      connectionSuccessful: jitsiState.matches({
        connection: JitsiConnectionStates.Connected,
      }),
    });
    setSetupComplete(true);
  }, [auth, jitsiState, selectedAudioSourceData, selectedVideoSourceData]);

  const portalsContent = useMemo(() => {
    return (
      <>
        <HeaderPortal>
          <Header
            hasCurrentActivity={!!currentActivity}
            isDone={isViewResultsStage}
          />
        </HeaderPortal>
        {isSetupDone && internetConnectionStrength && (
          <InternetConnectionPortal>
            <InternetConnectionStrength strength={internetConnectionStrength} />
          </InternetConnectionPortal>
        )}
        <GuidePortal>
          <Guide
            activityId={currentActivityId}
            isJitsiSetupDone={isSetupDone}
          />
        </GuidePortal>
      </>
    );
  }, [
    currentActivity,
    currentActivityId,
    internetConnectionStrength,
    isSetupDone,
    isViewResultsStage,
  ]);

  const technicalSetupHelpRequestDescription = useMemo(
    () =>
      !technicalSetupHelpOutcome
        ? null
        : technicalSetupHelpOutcome === TechnicalSetupHelpOutcome.Success
        ? "Your workspace administrator has been notified for your request. Someone will contact you shortly."
        : "There was an issue sending your help request. Please try again or contact your workspace administrator.",

    [technicalSetupHelpOutcome]
  );

  const technicalSetupHelpRequestHandler = useCallback(() => {
    technicalSetupClear();
  }, [technicalSetupClear]);

  const technicalSetupHelpHandler = useCallback(() => {
    technicalSetupHelp();
  }, [technicalSetupHelp]);

  const technicalSetupDialog = useMemo(
    () =>
      technicalSetupHelpRequestDescription && (
        <ConfirmationDialog
          title="Technical setup help request"
          description={technicalSetupHelpRequestDescription}
          confirmationHandler={technicalSetupHelpRequestHandler}
        />
      ),
    [technicalSetupHelpRequestDescription, technicalSetupHelpRequestHandler]
  );

  if (
    technicalSetupHelpOutcome === TechnicalSetupHelpOutcome.Success &&
    !thankYouMatch
  )
    return <Navigate to={`/session/thank-you/${slotInstance!.key}/${group}`} />;
  if (isKicked) return <Navigate to="/disconnected" />;
  if ((hasLostJitsiConnection || hasErrored) && !errorMatch) {
    return <Navigate to={`/session/slot/error`} />;
  }
  if (matches.length < 3) return <Navigate to="/" />;

  if (thankYouMatch) {
    return (
      <>
        <ThankYou title="Thank You - AhaPlay" />;{technicalSetupDialog}
      </>
    );
  }
  if (errorMatch) {
    console.error("Session Error: ", sessionError);
    console.error("Workshop Error: ", workshopErrors);
    return <ErrorPage title="Error - AhaPlay" />;
  }

  if (connectionLostMatch) {
    return (
      <ConnectionLost
        title="Connection Lost - AhaPlay"
        jitsiIsConnected={jitsiIsConnected}
        isParticipating={isParticipating}
        handleExit={handleExit}
      />
    );
  }

  if (
    slotInstance &&
    (instanceMatch || instanceGroupMatch) &&
    typeof group === "number" &&
    +(instanceGroupMatch?.params?.group || "0") !== group
  ) {
    return <Navigate to={`/session/instance/${slotInstance.key}/${group}`} />;
  }

  if (waitingRoomRescheduleRedirectMatch)
    return (
      <Navigate
        to={`/session/slot/${waitingRoomRescheduleRedirectMatch.params.newSlotId}`}
      />
    );

  if (
    (slotMatch || ongoingSessionsMatch) &&
    slotInstance &&
    slotInstance.sessions.length > 0 &&
    slotInstance.type === SlotType.SPLIT &&
    (invitationResponseServerTimestamp || 0) -
      Math.min(...slotInstance.sessions.map((s) => s.create_date)) >
      60
  ) {
    if (ongoingSessionsMatch) {
      return (
        <SlotActiveSessionList
          slotId={ongoingSessionsMatch.params.slotId!}
          sessions={slotInstance.sessions}
          workshopDuration={calculateWorkshopDuration(workshop)}
          workshopActivities={getActivities(slotInstance.workshop)}
        />
      );
    }

    return <Navigate to={`/session/ongoing-sessions/${slotInstance.id}`} />;
  }

  if (
    !!millisecondsToStart &&
    slotInstance &&
    millisecondsToStart >
      (slotInstance.type === SlotType.ALL
        ? sessionContext.session.state.context.sessionOpeningTimeInMilliseconds!
        : 0)
  ) {
    if (waitingRoomMatch)
      return (
        <WaitingRoom
          slot={slotInstance}
          invitationStatus={invitationStatus!}
          invitationId={invitationId!}
          millisecondsToStart={millisecondsToStart!}
          splitMillisecondsWaitingTime={splitMillisecondsWaitingTime!}
          sessionOpeningTimeInMilliseconds={sessionOpeningTimeInMilliseconds!}
          navigateToSlotInstance={waitingRoomTimeoutNavigation}
        />
      );

    return <Navigate to={`/session/waiting-room/${slotInstance.id}`} />;
  }

  if (
    !instanceMatch &&
    !instanceGroupMatch &&
    !!slotInstance?.key &&
    !ongoingSessionsMatch
  )
    return <Navigate to={`/session/instance/${slotInstance.key}`} />;

  return (isProcessing && !invitationNotFound) || isLoading ? (
    <Loader className={styles.loaderContainer} />
  ) : (
    <WorkshopClockContextProvider>
      {technicalSetupDialog}
      <div>
        {portalsContent}
        <div className={styles.sessionContainer}>
          <WorkshopExitModal
            shouldSkipModal={isConnectionLost}
            onExitCallback={handleExit}
          />
          {isSetupDone &&
            workshop &&
            workspace &&
            slotInstance &&
            (sessionState?.context ? (
              <div className={styles.workshopInstanceContainer}>
                <WorkshopInstance
                  slot={slotInstance}
                  teamName={teamName}
                  workshop={workshop}
                  workshopState={workshopState}
                  connectionStrength={connectionStrength}
                  workspace={workspace}
                  transition={transition}
                  sessionState={sessionState as SessionStateValue}
                  currentActivity={currentActivity}
                  nextActivity={nextActivity}
                  token={token!}
                  currentActivityId={currentActivityId}
                  isSessionCompleted={isSessionCompleted}
                  profile={profile}
                  isParticipating={isParticipating}
                  isConnected={isConnected}
                  isReadyToStart={isReadyToStart}
                  reconnectTimeouts={reconnectTimeouts}
                  setActivityReadyHandler={setActivityReadyHandler}
                  toggleParticipationHandler={toggleParticipationHandler}
                  setReadyToStartHandler={setReadyToStartHandler}
                  setActivityValueHandler={setActivityValueHandler}
                  millisecondsToStart={millisecondsToStart}
                  title={`${getTitle(workshop)} - AhaPlay`}
                  description={currentActivityDescription}
                  requestNextWorkshop={requestNextWorkshop}
                  nextWorkshopRequested={nextWorkshopRequested}
                  journeyItems={journeyItems}
                  remoteParticipantsData={remoteParticipantsData || []}
                />
              </div>
            ) : (
              <div className={styles.workshopInstanceContainer}>
                <Loader className={styles.loaderContainer} />
              </div>
            ))}

          {!isSetupDone ? (
            <JitsiSetup
              ref={setVideoElementRef}
              profileId={profileId}
              isLoading={isSetupLoading && jitsiIsConnected}
              setupComplete={setupComplete}
              selectedAudioSourceData={selectedAudioSourceData}
              selectedVideoSourceData={selectedVideoSourceData}
              availableAudioSources={availableAudioSources}
              availableVideoSources={availableVideoSources}
              technicalSetupHelpOutcome={technicalSetupHelpOutcome}
              audioAndVideoConfigureDone={audioAndVideoConfigureDone}
              technicalSetupHelpHandler={technicalSetupHelpHandler}
              audioDeviceChangeHandler={audioDeviceChangeHandler}
              videoDeviceChangeHandler={videoDeviceChangeHandler}
              setSetupCompleteHandler={setSetupCompleteHandler}
              toggleAudioHandler={toggleAudioHandler}
              toggleVideoHandler={toggleVideoHandler}
            />
          ) : (
            sessionInstance &&
            sessionState.context && (
              <SidePanel
                transition={isViewResultsStage ? 1 : transition}
                currentActivity={currentActivity}
                currentActivityId={currentActivityId}
                allActivityResults={sessionState?.context?.activityResult || []}
                allQuestionActivitiesAnswersMap={
                  allQuestionActivitiesAnswersMap
                }
                transcripts={transcripts}
                profile={profile}
                isUserTalking={isUserTalking}
                isParticipating={isParticipating}
                selectedAudioDevice={selectedAudioSourceData}
                selectedVideoDevice={selectedVideoSourceData}
                availableAudioSources={availableAudioSources}
                availableVideoSources={availableVideoSources}
                audioDeviceChangeHandler={audioDeviceChangeHandler}
                videoDeviceChangeHandler={videoDeviceChangeHandler}
                disableAudioAndVideo={
                  autoDisableAudioAndVideoForIndividualScreen
                }
                remoteParticipantsData={remoteParticipantsData || []}
                toggleParticipationHandler={toggleParticipationHandler}
                attachHtmlVideoElementToLocalTracks={
                  jitsiContext.attachHtmlVideoElementToLocalTracks
                }
                attachHtmlVideoElementToRemoteTracks={
                  jitsiContext.attachHtmlVideoElementToRemoteTracks
                }
                toggleAudioHandler={toggleAudioHandler}
                toggleVideoHandler={toggleVideoHandler}
                teamName={teamName}
                slot={slotInstance}
                hasTeamName={
                  workshop?.fields.warmUpActivity?.sys.contentType.sys.id ===
                  WarmUpActivityType.TeamNameActivity
                }
                hasLeaderBoard={!!workshop?.fields.showScoreboard}
                currentProfileIsObserver={currentProfileIsObserver}
              />
            )
          )}
        </div>
        {invitationNotFound && <div>Invitation not found</div>}
        {sessionNotFound && <div>Session not found</div>}
      </div>
    </WorkshopClockContextProvider>
  );
}
