import { useCallback, useMemo } from "react";
import { applyUpdate, Doc, encodeStateAsUpdate } from "yjs";
import {
  deserializeUint8Array,
  serializeUint8Array,
} from "../utils/uint8-array-serializers";

export enum UseDocComplexValueType {
  Uint8Array = "UInt8Array",
  String = "string",
}

function parseComplexValueFactory<T extends ReadonlyArray<string>>() {
  return function parseComplexValue<
    TYPE extends UseDocComplexValueType = UseDocComplexValueType.Uint8Array,
    VAL = TYPE extends UseDocComplexValueType.Uint8Array ? Uint8Array : string,
    RESULT = Record<T[number], VAL>
  >(complexResultValue: string, type?: TYPE): RESULT {
    return complexResultValue.split(";").reduce((acc, curr) => {
      const [key, uInt8ArrayString] = curr.split(":");
      const update =
        deserializeUint8Array(uInt8ArrayString) || new Uint8Array();
      const resultValue: { [key: string]: Uint8Array | string } = {
        [key]: "",
      };
      if (type === UseDocComplexValueType.String) {
        const yDoc = new Doc();
        applyUpdate(yDoc, update!);
        resultValue[key] = yDoc?.getText("codemirror")?.toString() || "";
      } else {
        resultValue[key] = update;
      }
      return Object.assign(acc, resultValue);
    }, {} as any);
  };
}

export const useDocMap = <T extends ReadonlyArray<string>>(keys: T) => {
  const docs = useMemo(() => {
    return keys.reduce((acc, key) => {
      const itemDoc = new Doc({ guid: `${key}`, collectionid: `${key}` });
      acc[key] = itemDoc;
      return acc;
    }, {} as { [key: string]: Doc });
  }, [keys]);

  const generateComplexResultValueAsString = useCallback(() => {
    const valueAsString = Object.entries(docs)
      .map(([docKey, doc]) => {
        const update = encodeStateAsUpdate(doc);
        const uInt8ArrayString = serializeUint8Array(update);
        return `${docKey}:${uInt8ArrayString}`;
      })
      .join(";");

    return valueAsString;
  }, [docs]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parseComplexResultValue = useCallback(
    parseComplexValueFactory<T>(),
    []
  );

  return { docs, generateComplexResultValueAsString, parseComplexResultValue };
};
