import {
  PropsWithChildren,
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
  memo,
} from "react";
import Video from "../Shared/Video/Video";
import DeviceSelect from "../Shared/DeviceSelect/DeviceSelect";
import { OutputDevice } from "../../types/output-device";
import { DisabledTooltip } from "./DisabledTooltip";

import cn from "classnames";
import styles from "./VideoPreview.module.css";
import { SourceData } from "../../types/source-data";

export const VideoPreview = memo(
  forwardRef(
    (
      props: PropsWithChildren<{
        profileId: string;
        width: number;
        height: number;
        hideConfigurations?: boolean;
        selectedAudioDevice: SourceData | null;
        selectedVideoDevice: SourceData | null;
        availableAudioSources: SourceData[] | null;
        availableVideoSources: SourceData[] | null;
        audioAndVideoConfigureDone: boolean;
        isLoading: boolean;
        audioDeviceChangeHandler: (newSourceId: string) => void;
        videoDeviceChangeHandler: (newSourceId: string) => void;
        toggleAudioHandler: MouseEventHandler<HTMLButtonElement>;
        toggleVideoHandler: MouseEventHandler<HTMLButtonElement>;
        className?: string;
      }>,
      ref: React.ForwardedRef<HTMLVideoElement | null>
    ) => {
      const {
        width,
        height,
        profileId,
        isLoading,
        hideConfigurations,
        selectedAudioDevice,
        selectedVideoDevice,
        availableAudioSources,
        availableVideoSources,
        audioAndVideoConfigureDone,
        audioDeviceChangeHandler,
        videoDeviceChangeHandler,
        toggleAudioHandler,
        toggleVideoHandler,
        className,
      } = props;
      const [actualVideoToggleValue, setActualVideoToggleValue] = useState(
        selectedVideoDevice?.isMuted
      );
      const [actualAudioToggleValue, setActualAudioToggleValue] = useState(
        selectedAudioDevice?.isMuted
      );

      const [showAudioDisabledTooltip, setShowAudioDisabledTooltip] =
        useState(false);
      const [showVideoDisabledTooltip, setShowVideoDisabledTooltip] =
        useState(false);
      const [disableAudio, setDisableAudio] = useState(false);
      const [disableVideo, setDisableVideo] = useState(false);

      useEffect(() => {
        setActualVideoToggleValue(selectedVideoDevice?.isMuted);
      }, [selectedVideoDevice]);
      useEffect(() => {
        setActualAudioToggleValue(selectedAudioDevice?.isMuted);
      }, [selectedAudioDevice]);

      const videoToggleHandlerWithDisable = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setActualVideoToggleValue((prev) => !prev);
          toggleVideoHandler(event);
        },
        [toggleVideoHandler]
      );

      const audioToggleHandlerWithDisable = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setActualAudioToggleValue((prev) => !prev);
          toggleAudioHandler(event);
        },
        [toggleAudioHandler]
      );

      const showAudioDisabledTooltipHandler = useCallback(() => {
        if (!selectedAudioDevice) {
          setShowAudioDisabledTooltip(true);
        }
      }, [selectedAudioDevice]);

      const hideAudioDisabledTooltipHandler = useCallback(() => {
        setShowAudioDisabledTooltip(false);
      }, []);

      const showVideoDisabledTooltipHandler = useCallback(() => {
        if (!selectedVideoDevice) {
          setShowVideoDisabledTooltip(true);
        }
      }, [selectedVideoDevice]);

      const hideVideoDisabledTooltipHandler = useCallback(() => {
        setShowVideoDisabledTooltip(false);
      }, []);

      const parsedAvailableAudioSources = useMemo(
        () =>
          (availableAudioSources || [])
            .map((source) => ({
              label: source.label!,
              value: source.sourceId!,
              key: source.sourceId!,
            }))
            .filter((source) => !!source.value),
        [availableAudioSources]
      );

      const parsedAvailableVideoSources = useMemo(
        () =>
          (availableVideoSources || [])
            .map((source) => ({
              label: source.label!,
              value: source.sourceId!,
              key: source.sourceId!,
            }))
            .filter((source) => !!source.value),
        [availableVideoSources]
      );

      const hasVideo = useMemo(
        () => selectedVideoDevice && selectedVideoDevice.sourceId,
        [selectedVideoDevice]
      );
      const showVideo = useMemo(
        () => hasVideo && !selectedVideoDevice?.isMuted,
        [selectedVideoDevice, hasVideo]
      );

      useEffect(() => {
        if (audioAndVideoConfigureDone) {
          setDisableAudio(!selectedAudioDevice);
          setDisableVideo(!selectedVideoDevice);
        }
      }, [
        audioAndVideoConfigureDone,
        selectedAudioDevice,
        selectedVideoDevice,
      ]);

      return (
        <div className={cn(styles.container, className)}>
          <Video
            isConnecting={isLoading}
            ref={ref}
            width={width - 2}
            height={height - 2}
            ignoreAudio={true}
            participantId={profileId}
          />
          {!showVideo && (
            <div
              className={styles.mutedVideoContainer}
              style={{ width: `${width + 2}px`, height: `${height + 2}px` }}
            >
              <i className="icon fa fa-user" />
            </div>
          )}
          {!hideConfigurations && (
            <>
              <div className={styles.actions}>
                <div className={styles.action}>
                  <button
                    className={
                      actualAudioToggleValue || disableAudio
                        ? "deactivated"
                        : ""
                    }
                    onClick={audioToggleHandlerWithDisable}
                    onPointerEnter={showAudioDisabledTooltipHandler}
                    onPointerLeave={hideAudioDisabledTooltipHandler}
                  >
                    <i
                      className={cn(
                        "icon fa",
                        actualAudioToggleValue || disableAudio
                          ? "fa-microphone-slash"
                          : "fa-microphone neutral"
                      )}
                    ></i>
                  </button>
                  <DeviceSelect
                    deviceId={selectedAudioDevice?.sourceId}
                    deviceName={OutputDevice.AUDIO}
                    values={parsedAvailableAudioSources}
                    disabled={
                      !!selectedAudioDevice?.isMuted ||
                      actualAudioToggleValue ||
                      disableAudio
                    }
                    onClickHandler={audioDeviceChangeHandler}
                    initialArrowDirection="down"
                  />
                </div>
                {
                  <div className={styles.action}>
                    <button
                      className={cn(
                        "video-btn",
                        actualVideoToggleValue || disableVideo
                          ? "deactivated"
                          : ""
                      )}
                      onClick={videoToggleHandlerWithDisable}
                      onPointerEnter={showVideoDisabledTooltipHandler}
                      onPointerLeave={hideVideoDisabledTooltipHandler}
                      disabled={
                        actualVideoToggleValue !== selectedVideoDevice?.isMuted
                      }
                    >
                      <i
                        className={cn(
                          "icon fa",
                          actualVideoToggleValue || disableVideo
                            ? "fa-video-slash"
                            : "fa-video neutral"
                        )}
                      ></i>
                    </button>
                    <DeviceSelect
                      deviceId={selectedVideoDevice?.sourceId}
                      deviceName={OutputDevice.VIDEO}
                      values={parsedAvailableVideoSources}
                      disabled={
                        !!selectedVideoDevice?.isMuted ||
                        actualAudioToggleValue ||
                        disableVideo
                      }
                      onClickHandler={videoDeviceChangeHandler}
                      initialArrowDirection="down"
                    />
                  </div>
                }
                {(showAudioDisabledTooltip ||
                  disableAudio ||
                  showVideoDisabledTooltip) && (
                  <DisabledTooltip
                    title="Camera and/or microphone error"
                    description="AhaPlay requires access to your microphone and recommends enabling access to your camera."
                  />
                )}
              </div>
            </>
          )}
        </div>
      );
    }
  )
);
