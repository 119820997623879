import {
  PropsWithChildren,
  useState,
  memo,
  forwardRef,
  useMemo,
  useCallback,
} from "react";
import Loader from "../Loader/Loader";

import styles from "./Video.module.css";

const Video = memo(
  forwardRef(
    (
      props: PropsWithChildren<{
        participantId: string;
        width: string | number;
        height: string | number;
        noBorderRadius?: boolean;
        isConnecting?: boolean;
        style?: React.CSSProperties | undefined;
        ignoreAudio?: boolean;
        audioRef?:
          | React.MutableRefObject<HTMLAudioElement | null>
          | ((ref: HTMLAudioElement | null) => void);
        onDataLoadedHandler?: () => void;
      }>,
      ref: React.ForwardedRef<HTMLVideoElement | null>
    ) => {
      const {
        width,
        style,
        height,
        audioRef,
        participantId,
        noBorderRadius,
        isConnecting,
        onDataLoadedHandler,
        ignoreAudio = false,
      } = props;
      const [showVideo, setShowVideo] = useState(true);

      const [dimensions, setDimensions] = useState<{
        width: number | string;
        height: number | string;
      } | null>(null);

      const [localVideoRef, setLocalVideoRef] =
        useState<HTMLVideoElement | null>(null);
      const [localAudioRef, setLocalAudioRef] =
        useState<HTMLAudioElement | null>(null);

      const showContent = useMemo(
        () => !isConnecting && !!participantId,
        [isConnecting, participantId]
      );

      const videoKey = useMemo(
        () => participantId + "-video-element-",
        [participantId]
      );
      const audioKey = useMemo(
        () => participantId + "-audio-element",
        [participantId]
      );

      const playFallbackHandler = useCallback(() => {
        return setTimeout(() => {
          if (
            (localVideoRef?.readyState || 0) < 3 ||
            (!ignoreAudio && (localAudioRef?.readyState || 0) < 3)
          )
            return void setTimeout(() => playFallbackHandler(), 1000);

          if (
            localVideoRef?.paused ||
            (!ignoreAudio && localAudioRef?.paused)
          ) {
            setShowVideo(false);
            setTimeout(() => {
              setShowVideo(true);
              setTimeout(() => playFallbackHandler());
            }, 1_000) as unknown as number;
          }
        }, 2_000) as unknown as number;
      }, [
        ignoreAudio,
        localAudioRef?.paused,
        localAudioRef?.readyState,
        localVideoRef?.paused,
        localVideoRef?.readyState,
      ]);

      const onPausedHandler = useCallback(() => {
        setTimeout(() => {
          playFallbackHandler();
        }, 1000);
      }, [playFallbackHandler]);

      const videoStyle = useMemo(() => ({ maxHeight: height }), [height]);
      const loaderStyle = useMemo(
        () => ({
          zIndex: 1,
          width,
          height,
        }),
        [width, height]
      );

      return (
        <div
          className={styles.videoContainer}
          key={participantId + "-video-container"}
          style={{
            width,
            height,
            borderRadius: noBorderRadius ? undefined : "var(--br-16, 16px)",
            ...(style || {}),
          }}
        >
          <Loader className={styles.loaderContainer} style={loaderStyle} />
          {showContent && showVideo && (
            <>
              <video
                key={videoKey}
                className={styles.videoElement}
                style={videoStyle}
                muted={true}
                autoPlay={true}
                playsInline={true}
                disablePictureInPicture={true}
                width={dimensions?.width}
                height={dimensions?.height}
                onPause={onPausedHandler}
                ref={(_ref) => {
                  setLocalVideoRef(_ref);
                  const width = _ref?.videoWidth;
                  const height = _ref?.videoHeight;

                  if (
                    width !== dimensions?.width &&
                    height !== dimensions?.height
                  ) {
                    setDimensions(width && height ? { width, height } : null);
                  }
                  if (typeof ref === "function") return void ref(_ref);
                  if (ref) return void (ref.current = _ref);
                }}
                onLoadedData={onDataLoadedHandler}
              ></video>
              <audio
                playsInline={true}
                autoPlay={true}
                key={audioKey}
                ref={(_ref) => {
                  setLocalAudioRef(_ref);
                  if (typeof audioRef === "function")
                    return void audioRef(_ref);
                  if (audioRef) return void (audioRef.current = _ref);
                }}
              />
            </>
          )}
        </div>
      );
    }
  )
);

export default Video;
