import { PropsWithChildren, memo, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { GlobalContext } from "../../../../contexts/Global";
import { AdminDashboardContext } from "../../../../contexts/AdminDashboard";
import { enterAdminDashboard } from "../../../../+xstate/actions/dashboard/admin-dashboard";
import {
  DASHBOARD_DISABLED_ADMIN,
  DASHBOARD_DISABLED_MEMBER,
} from "../../../../constants/global";
import { hasAdminAccess } from "../../../../utils/profile";

import styles from "./Dashboard.module.css";

const Dashboard = (props: PropsWithChildren) => {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);
  const { send } = useContext(AdminDashboardContext);

  useEffect(() => send(enterAdminDashboard()), [send]);

  if (hasAdminAccess(profile) && DASHBOARD_DISABLED_ADMIN) {
    return <Navigate to="/workshops" />;
  }

  if (!hasAdminAccess(profile) && DASHBOARD_DISABLED_MEMBER) {
    return <Navigate to="/schedule/my/upcoming" />;
  }

  return (
    <div className={styles.container}>
      <h3 className="thin">Welcome, {profile?.name}</h3>
    </div>
  );
};
export default memo(Dashboard);
