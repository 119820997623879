import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import { startCase, toLower } from "lodash";

import { Select } from "../../../../../Shared/Select/Select";
import LoadingButton from "../../../../../Shared/Buttons/LoadingButton/LoadingButton";

import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../../../apollo-graphql/types/enums";
import { ProfileInvite } from "../../../../../../apollo-graphql/types/profile";
import { mappedErrorMessages } from "../../../../../../constants/mapped-error-messages";
import { InviteProfileErrors } from "../../../../../../types/enums/errors";

import styles from "./InviteTeamMemberModal.module.css";
import cn from "classnames";

// TODO: Fetch from backend once we have Tags
// const tags = ["designer", "developer", "product"];

const InviteTeamMemberModal = (
  props: PropsWithChildren<{
    inviteTeamMember: (variables: ProfileInvite) => void;
    errorMessage: string | null;
    isLoading: boolean;
    onClose: () => void;
  }>
) => {
  const { inviteTeamMember, errorMessage, isLoading, onClose } = props;

  const [dialogState, setDialogState] = useState<{
    permissions: ProfileWorkspaceAccess;
    status: ProfileWorkspaceStatus;
    tags: string[];
  }>({
    permissions: ProfileWorkspaceAccess.TEAM_MEMBER,
    status: ProfileWorkspaceStatus.ACTIVE,
    tags: [],
  });

  const [error, setError] = useState<string>("");

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      setError("");
      e.preventDefault();

      const payload = Object.fromEntries(new FormData(e.currentTarget)) as {
        email: string;
        name: string;
        jobTitle: string;
      };

      if (!payload.email || !payload.name || !payload.jobTitle) {
        setError("* Enter required fields.");
        return;
      }

      inviteTeamMember({
        ...payload,
        ...dialogState,
      });

      return () => {
        if (!errorMessage) {
          onClose();
        }
      };
    },
    [dialogState, inviteTeamMember, errorMessage, onClose]
  );

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className="user-info-container">
          <Form.Root className="form-container" onSubmit={handleSubmit}>
            <Form.Field name="email" className="form-row">
              <Form.Label className="label">
                Email <span className="requiredAsterisk">*</span>
              </Form.Label>
              <Form.Control
                className="FormControl"
                type="email"
                defaultValue=""
                placeholder="name@company.com"
              />
            </Form.Field>
            <Form.Field name="name" className="form-row">
              <Form.Label className="label">
                Name <span className="requiredAsterisk">*</span>
              </Form.Label>
              <Form.Control
                className="FormControl"
                type="text"
                defaultValue=""
                placeholder="ex. John Smith"
              />
            </Form.Field>
            <Form.Field name="jobTitle" className="form-row">
              <Form.Label className="label">
                Job Title <span className="requiredAsterisk">*</span>
              </Form.Label>
              <Form.Control
                className="FormControl"
                type="text"
                defaultValue=""
                placeholder="Engineer, Human Resources etc."
              />
            </Form.Field>
            <Form.Field name="permissions" className="form-row">
              <Form.Label className="label">
                Access Permissions <span className="requiredAsterisk">*</span>
              </Form.Label>

              <Select
                label="Select permissions"
                value={dialogState.permissions}
                options={[...(Object.values(ProfileWorkspaceAccess) || [])].map(
                  (access) => ({
                    label: startCase(toLower(access)),
                    value: access,
                    key: `${access}-access`,
                  })
                )}
                className={styles.selectInput}
                onChange={(permissions) => {
                  setDialogState((prev) => ({
                    ...prev,
                    permissions: permissions as ProfileWorkspaceAccess,
                  }));
                }}
              />
            </Form.Field>

            <Form.Field name="status" className="form-row">
              <Form.Label className="label">
                Profile Status <span className="requiredAsterisk">*</span>
              </Form.Label>
              <Select
                label="Select status"
                value={dialogState.status}
                options={[...(Object.values(ProfileWorkspaceStatus) || [])].map(
                  (status) => ({
                    label: startCase(toLower(status)),
                    value: status,
                    key: `${status}-status`,
                  })
                )}
                className={styles.selectInput}
                onChange={(status) => {
                  setDialogState((prev) => ({
                    ...prev,
                    status: status as ProfileWorkspaceStatus,
                  }));
                }}
              />
            </Form.Field>
            {/* <Form.Field name="tags" className="form-row">
              <Form.Label className="label">Add tags (Unavailable)</Form.Label>

              {dialogState.tags.length > 0 && (
                <div className={styles.tagContainer}>
                  <div className={styles.selectedTagList}>
                    {dialogState.tags.map((tag) => (
                      <div key={`tag-${tag}`} className={styles.selectedTag}>
                        <span>{tag}</span>
                        <button
                          onClick={() => {
                            setDialogState((prev) => ({
                              ...prev,
                              tags: prev.tags?.filter((t) => tag !== t),
                            }));
                          }}
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <Select
                className={styles.selectInput}
                label="Select tags"
                value="-"
                disabled
                options={(tags || [])
                  .filter(
                    (tag) =>
                      !dialogState.tags?.some(
                        (alreadyChosen) => tag === alreadyChosen
                      )
                  )
                  .map((tag) => ({
                    label: tag,
                    value: tag,
                    key: `tag-${tag}`,
                    disabled: dialogState.tags?.some(
                      (selected) => selected === tag
                    ),
                  }))}
                onChange={(tag: string) => {
                  setDialogState((prev) => ({
                    ...prev,
                    tags: [...prev?.tags, tag],
                  }));
                }}
              />
            </Form.Field> */}

            <div className={styles.footer}>
              {(error || errorMessage) && (
                <div className={styles.errorContainer}>
                  {error ||
                    mappedErrorMessages[errorMessage as InviteProfileErrors]}
                </div>
              )}
              <div className={styles.inviteBtnContainer}>
                <LoadingButton
                  isLoading={isLoading}
                  className={styles.inviteBtn}
                  type="submit"
                >
                  Send Invite
                </LoadingButton>
              </div>
            </div>
          </Form.Root>
        </div>
      </div>
    );
  }, [error, handleSubmit, dialogState.permissions, dialogState.status, isLoading, errorMessage]);

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn(styles.inviteModal, "DialogContent")}>
          <Dialog.Title className="DialogTitle">
            Invite people to AhaPlay
          </Dialog.Title>
          {dialogContent}
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(InviteTeamMemberModal);
