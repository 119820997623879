import { PropsWithChildren, memo } from "react";

import styles from "./SectionCard.module.css";
import cn from "classnames";

const SectionCard = ({
  title,
  className = "",
  imagePath,
  imageAlt,
  imageNode,
  children,
}: PropsWithChildren<{
  className?: string;
  title: string;
  imageNode?: JSX.Element;
  imagePath?: string;
  imageAlt?: string;
}>) => {
  return (
    <div className={cn(styles.card, className)}>
      <div className={cn(styles.header, "bold")}>
        {imagePath && imageAlt && <img src={imagePath} alt={imageAlt} />}
        {imageNode && imageNode}
        {title}
      </div>
      <div className={cn(styles.content, "section-card-content")}>{children}</div>
    </div>
  );
};

export default memo(SectionCard);
