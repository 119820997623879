import { PropsWithChildren, useMemo, memo } from "react";

import cn from "classnames";

import { getEntryId } from "../../../../utils";

import { EmotionActivity } from "../../../../types/contentful/workshop/activities/emotion";
import { FooterType } from "../../../../types/enums/activity-footer";
import { ActionFooterType } from "../../../../types/action-footer";
import { WorkshopState } from "../../../../+xstate/machines/session/workshop";
import { normalConnectionThreshold } from "../../../../contexts/Apollo";
import { observerFooterData } from "../../Activities/constants";

import ActionFooter from "../../ActionFooter/ActionFooter";
import Congratulations from "../../Congratulations/Congratulations";
import EmotionItem from "./components/EmotionItem";

import styles from "./Emotion.module.css";

export default memo(function Emotion(
  props: PropsWithChildren<{
    activity: EmotionActivity;
    workshopHeadline: string;
    workshopState: WorkshopState;
    isReady: boolean;
    isConnectionWeak: boolean;
    isParticipating: boolean;
    isEndEmotionStage: boolean;
    transition: number;
    currentEmotion: string | null;
    notReadyProfilesCount: number;
    currentActiveParticipantCount: number;
    setEmotionHandler: (emotionValue: string) => void;
    setActivityReadyHandler: () => void;
  }>
) {
  const {
    activity,
    isReady,
    transition,
    workshopState,
    isParticipating,
    isEndEmotionStage,
    isConnectionWeak,
    currentEmotion,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    setActivityReadyHandler,
    setEmotionHandler,
  } = props;

  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );
  const isLoading = useMemo(
    () => workshopState === WorkshopState.SettingValue,
    [workshopState]
  );

  const headerText = useMemo(
    () => activity?.fields?.title,
    [activity?.fields?.title]
  );
  const infoText = useMemo(
    () => activity?.fields?.activity?.fields?.title,
    [activity?.fields?.activity?.fields?.title]
  );

  const iconsContent = useMemo(() => {
    return (
      <div className={styles.emotionListContainer}>
        {activity?.fields?.icons.map((icon, idx) => {
          const id = getEntryId(icon);
          const emotionId = `${idx + 1}`;
          const iconSrc = icon?.fields?.file?.url;
          const iconTitle = icon?.fields?.title;

          return (
            <EmotionItem
              key={id}
              emotionId={emotionId}
              src={iconSrc}
              alt={iconTitle}
              isLoading={isLoading}
              selectedEmotion={currentEmotion}
              disabled={isTransitioning || !isParticipating}
              onClick={() =>
                isLoading || !isParticipating
                  ? null
                  : setEmotionHandler(emotionId)
              }
              delayInMilliseconds={delayInMilliseconds}
            />
          );
        })}
      </div>
    );
  }, [
    activity?.fields?.icons,
    currentEmotion,
    delayInMilliseconds,
    isLoading,
    isParticipating,
    isTransitioning,
    setEmotionHandler,
  ]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    const buttonText = isEndEmotionStage ? "Go to Summary" : "Continue";
    if (isTransitioning) {
      return {
        text: <>Everyone is ready. Continuing forward...</>,
        buttonText,
        disabledButton: true,
        type: FooterType.Ready,
      };
    }
    if (!currentEmotion && !isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      return {
        text: (
          <>
            Click the face that reflects your current emotion.{" "}
            {playersClicked > 0 && (
              <span className="accent">
                {playersClicked} player{playersClicked > 1 && "s"} clicked.
              </span>
            )}
          </>
        ),
        buttonText,
        disabledButton: true,
        type: FooterType.Notice,
      };
    }
    if (currentEmotion && !isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      return {
        text: (
          <>
            Click “Continue” to confirm your emotion.{" "}
            {playersClicked > 0 && (
              <span className="accent">
                {playersClicked} player{playersClicked > 1 && "s"} clicked.
              </span>
            )}
          </>
        ),
        buttonText,
        disabledButton: false,
        type: FooterType.Notice,
      };
    }
    if (currentEmotion && isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText,
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }
    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;
    return {
      text: (
        <>
          Click the face that reflects your current emotion.{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText,
      disabledButton: true,
      type: FooterType.Notice,
    };
  }, [
    isEndEmotionStage,
    isTransitioning,
    currentEmotion,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    isParticipating,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div
          className={cn(
            "main-container",
            [styles.emotionMainContainer],
            !isParticipating && "cursor-not-allowed"
          )}
        >
          <div
            className={cn(
              styles.emotionsContainer,
              isEndEmotionStage && "endEmotion"
            )}
          >
            {isEndEmotionStage && (
              <Congratulations
                showAnimation
                congratulationImageSrc="/images/congratulations_aha_moments.svg"
              />
            )}

            <div
              className={cn(
                styles.emotionContent,
                !isParticipating && "no-interaction-section"
              )}
            >
              <h1>{headerText}</h1>
              <p className={cn(styles.infoText, "text", "secondary")}>
                {infoText}
              </p>
              {iconsContent}
            </div>
          </div>
        </div>
      </div>
      <ActionFooter
        isLoading={isLoading}
        isConnectionWeak={isConnectionWeak}
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={setActivityReadyHandler}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
