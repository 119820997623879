import { createMachine, spawnChild, assign, stopChild } from "xstate";
import { AppApolloClient } from "../../../contexts/Apollo";
import * as adminDashboardScheduleActions from "../../actions/dashboard/admin-dashboard-schedule-dialog";
import { SlotType } from "../../../apollo-graphql/types/enums/slot-type";
import { fetchMachineFactory } from "../fetch-factory";
import { getProfiles } from "../../../apollo-graphql/queries/profile";
import { createSlot } from "../../../apollo-graphql/mutations/slot";
import { Profile } from "../../../apollo-graphql/types/profile";
import { ICreateSlot, Slot } from "../../../apollo-graphql/types/slot";
import { Pagination } from "../../../types/pagination";

export enum AdminDashboardScheduleState {
  Idle = "idle",
  Start = "start",
  LoadScheduleData = "loadScheduleData",
  ScheduleReady = "scheduleReady",
  Creating = "Creating",
  Done = "done",
}

export interface AdminDashboardScheduleMachineContext {
  client: AppApolloClient;
  profiles: Profile[] | null;
  workspaceId: string | null;
  workshopId: string | null;
  dateTime: Date | null;
  participantEmails: string[] | null;
  error: any | null;
  slot: Slot | null;
}

type AdminDashboardScheduleActionCreators =
  typeof adminDashboardScheduleActions;
type AdminDashboardScheduleActionCreatorKeys =
  keyof AdminDashboardScheduleActionCreators;
type AdminDashboardScheduleActions = ReturnType<
  AdminDashboardScheduleActionCreators[AdminDashboardScheduleActionCreatorKeys]
>;

const {
  machine: getProfilesMachine,
  success: getProfilesSuccess,
  failure: getProfilesFailure,
} = fetchMachineFactory({
  id: "getProfiles",
  invokeFn: ({
    client,
    workspaceId,
    pagination,
  }: {
    client: AppApolloClient;
    workspaceId: string;
    pagination: Pagination;
  }) => {
    return getProfiles(client, { workspaceId, pagination });
  },
  triggerOnCreation: true,
});

const {
  machine: createSlotMachine,
  success: createSlotSuccess,
  failure: createSlotFailure,
} = fetchMachineFactory({
  id: "createSlot",
  invokeFn: ({
    client,
    workspaceId,
    workshopId,
    scheduleDate,
    participantEmails,
    type,
  }: ICreateSlot & { client: AppApolloClient }) => {
    return createSlot(client, {
      workspaceId,
      workshopId,
      type,
      scheduleDate,
      participantEmails,
    });
  },
  triggerOnCreation: true,
});

type AdminDashboardScheduleMachineTypes = {
  context: AdminDashboardScheduleMachineContext;
  events:
    | AdminDashboardScheduleActions
    | ReturnType<typeof getProfilesSuccess>
    | ReturnType<typeof createSlotSuccess>
    | ReturnType<typeof getProfilesFailure>
    | ReturnType<typeof createSlotFailure>;
};

export const adminDashboardScheduleDialogMachine = createMachine({
  types: {} as AdminDashboardScheduleMachineTypes,
  id: "admin-dashboard-schedule-dialog",
  initial: AdminDashboardScheduleState.Idle,
  context: ({ input }): AdminDashboardScheduleMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
        }
      | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");
    return {
      client: machineInput.client,
      workshopId: null,
      workspaceId: null,
      dateTime: null,
      participantEmails: null,
      error: null,
      profiles: null,
      slot: null,
    };
  },
  states: {
    [AdminDashboardScheduleState.Idle]: {
      on: {
        [adminDashboardScheduleActions.openScheduleDialog.type]: {
          target: AdminDashboardScheduleState.Start,
          actions: assign({
            workspaceId: ({ event }) => event.payload.workspaceId,
            workshopId: ({ event }) => event.payload.workshopId,
          }),
        },
      },
    },
    [AdminDashboardScheduleState.Start]: {
      on: {
        [adminDashboardScheduleActions.selectWorkshopStart.type]: [
          {
            target: AdminDashboardScheduleState.LoadScheduleData,
            guard: ({ event, context }) =>
              event.payload.type === SlotType.SPLIT,
            actions: assign({
              error: null,
            }),
          },
          {
            target: AdminDashboardScheduleState.Creating,
            guard: ({ event, context }) => event.payload.type === SlotType.ALL,
            actions: assign({
              error: null,
            }),
          },
        ],
      },
    },
    [AdminDashboardScheduleState.LoadScheduleData]: {
      entry: spawnChild(getProfilesMachine, {
        id: getProfilesMachine.id,
        input: ({ event, context }: any) => {
          const scheduleEvent = event as ReturnType<
            typeof adminDashboardScheduleActions.selectWorkshopStart
          >;
          if (scheduleEvent.payload.type === SlotType.ALL) return;
          const workspaceId = context.workspaceId;
          const client = context.client;
          return { workspaceId, client };
        },
      }),
      exit: stopChild(getProfilesMachine.id),
      on: {
        [getProfilesSuccess.type]: {
          target: AdminDashboardScheduleState.ScheduleReady,
          actions: assign({
            profiles: ({ event }) => {
              const evt = event as ReturnType<typeof getProfilesSuccess>;
              return evt.payload.output.nodes;
            },
          }),
        },
        [getProfilesFailure.type]: {
          target: AdminDashboardScheduleState.Start,
          actions: assign({
            error: ({ event }) => {
              const evt = event as ReturnType<typeof getProfilesFailure>;
              return evt.payload.error;
            },
            dateTime: null,
            participantEmails: null,
            profiles: null,
            slot: null,
          }),
        },
      },
    },
    [AdminDashboardScheduleState.ScheduleReady]: {
      on: {
        [adminDashboardScheduleActions.setScheduleDateTime.type]: {
          actions: assign({
            dateTime: ({ event }) => {
              const evt = event as ReturnType<
                typeof adminDashboardScheduleActions.setScheduleDateTime
              >;
              return evt.payload.dateTime;
            },
          }),
        },
        [adminDashboardScheduleActions.setScheduleParticipants.type]: {
          actions: assign({
            participantEmails: ({ event }) => {
              const evt = event as ReturnType<
                typeof adminDashboardScheduleActions.setScheduleParticipants
              >;
              return evt.payload.participantEmails;
            },
          }),
        },
        [adminDashboardScheduleActions.createSlot.type]: {
          target: AdminDashboardScheduleState.Creating,
          guard: ({ event }) =>
            event.payload.type === SlotType.ALL ||
            !!(
              event.payload.type === SlotType.SPLIT &&
              event.payload.dateTime &&
              event.payload.participantEmails.length > 0
            ),
        },
      },
    },
    [AdminDashboardScheduleState.Creating]: {
      entry: spawnChild(createSlotMachine, {
        id: createSlotMachine.id,
        input: ({ event, context }: any) => {
          const createEvent = event as ReturnType<
            typeof adminDashboardScheduleActions.createSlot
          >;
          const { client, workshopId, workspaceId } = context;
          const {
            dateTime,
            type,
            participantEmails = [],
          } = createEvent.payload;
          return {
            client,
            workspaceId,
            workshopId,
            scheduleDate: dateTime,
            type,
            participantEmails,
          };
        },
      }),
      exit: stopChild(createSlotMachine.id),
      on: {
        [createSlotSuccess.type]: {
          target: AdminDashboardScheduleState.Done,
          actions: assign({
            slot: ({ event }) => {
              return event.payload.output;
            },
          }),
        },
        [createSlotFailure.type]: [
          {
            target: AdminDashboardScheduleState.ScheduleReady,
            actions: assign({
              error: ({ event }) => {
                const evt = event as ReturnType<typeof createSlotFailure>;
                return evt.payload.error;
              },
            }),
            guard: ({
              event: {
                payload: { input },
              },
            }: any) => {
              return input.type === SlotType.SPLIT;
            },
          },
          {
            target: AdminDashboardScheduleState.Start,
            actions: assign({
              error: ({ event }) => {
                const evt = event as ReturnType<typeof createSlotFailure>;
                return evt.payload.error;
              },
              dateTime: null,
              participantEmails: null,
              profiles: null,
              slot: null,
            }),
            guard: ({
              event: {
                payload: { input },
              },
            }: any) => {
              return input.type === SlotType.ALL;
            },
          },
        ],
      },
    },
    [AdminDashboardScheduleState.Done]: {},
  },
  on: {
    [adminDashboardScheduleActions.closeScheduleDialog.type]: {
      target: `.${AdminDashboardScheduleState.Idle}`,
      actions: assign({
        dateTime: null,
        participantEmails: null,
        error: null,
        profiles: null,
        slot: null,
      }),
    },
  },
});
