import { PropsWithChildren, memo } from "react";

import { Doc } from "yjs";

import { useEditorClass } from "../../../../../hooks/useEditorClass";
import { Profile } from "../../../../../apollo-graphql/types/profile";

import Editor from "../../../../Shared/Editor/Editor";

import cn from "classnames";
import styles from "./TranslationItem.module.css";

interface TranslationItemProps {
  translationItem: {
    id: string;
    targetPlaceholderText: string;
    // TODO: check why text is not used?
    text: string;
    title: string;
  };
  translationIndex: number;
  textAreaKey: string;
  textAreaValue: string | Uint8Array;
  isTransitioning: boolean;
  isLoading: boolean;
  delayInMilliseconds: number;
  showDefaultTextArea?: boolean;
  isReady: boolean;
  currentActiveParticipantCount: number;
  notReadyProfilesCount: number;
  awareness: Uint8Array | null;
  doc: Doc;
  profile: Profile;
  onValueUpdate: () => void;
  onAwarenessUpdate: (data: {
    event: {
      type: "awareness";
      // TODO: fix awareness type
      awareness: any;
    };
    name: string;
  }) => void;
}

export default memo(function TranslationItem(
  props: PropsWithChildren<TranslationItemProps>
) {
  const {
    translationItem,
    translationIndex,
    textAreaKey,
    textAreaValue,
    isTransitioning,
    showDefaultTextArea,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    awareness,
    doc,
    profile,
    onValueUpdate,
    onAwarenessUpdate,
  } = props;

  const editorClass = useEditorClass({
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
  });

  return (
    <div key={translationItem.id} className={styles.translationItemContainer}>
      <div className={styles.translationItemColLeft}>
        {translationIndex + 1}. {translationItem.title}
      </div>

      <div className={styles.translationItemColMid}>
        <i
          className={cn(
            "fa",
            "fa-solid",
            "fa-arrow-right-long",
            styles.translationItemColMidIcon
          )}
        />
      </div>

      <div className={styles.textAreaWrapper}>
        {!showDefaultTextArea ? (
          <Editor
            doc={doc}
            name={translationItem.id}
            key={translationItem.id}
            onValueUpdate={onValueUpdate}
            onAwarenessUpdate={onAwarenessUpdate}
            value={textAreaValue as Uint8Array}
            awareness={awareness}
            profileName={profile.name}
            placeholder={translationItem.targetPlaceholderText}
            isInput={false}
            className={editorClass}
          />
        ) : (
          <div
            className={cn("text", "textarea-sync-disabled")}
            key={textAreaKey}
          >
            {textAreaValue}
          </div>
        )}
      </div>
    </div>
  );
});
