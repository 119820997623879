import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useMachine, useSelector } from "@xstate/react";
import {
  requestNextWorkshopMachine,
  requestNextWorkshopTrigger,
  sessionMachine,
} from "../+xstate/machines/session/session";
import {
  getJourneyMachine,
  workshopMachine,
} from "../+xstate/machines/session/workshop";

import * as sessionActions from "../+xstate/actions/session/session";
import * as workshopActions from "../+xstate/actions/session/workshop";
import * as workshopClockActions from "../+xstate/actions/session/workshop-clock";
import { Entries, Writeable } from "../types/helpers";
import { ActorRef } from "xstate";
import { ApolloContext, SERVER_TIME_UPDATE } from "./Apollo";
import {
  WorkshopClockStates,
  workshopClockMachine,
} from "../+xstate/machines/session/workshop-clock";

type SessionActions = typeof sessionActions;
type SessionActionDispatchers = {
  [K in keyof SessionActions]: (
    payload: ReturnType<SessionActions[K]>["payload"]
  ) => void;
};

type WorkshopActions = typeof workshopActions;
type WorkshopActionDispatchers = {
  [K in keyof WorkshopActions]: (
    payload: ReturnType<WorkshopActions[K]>["payload"]
  ) => void;
};

type WorkshopClockActions = typeof workshopClockActions;
type WorkshopClockActionDispatchers = {
  [K in keyof WorkshopClockActions]: (
    payload: ReturnType<WorkshopClockActions[K]>["payload"]
  ) => void;
};

export const SessionContext = createContext<{
  session: {
    state: ReturnType<typeof useMachine<typeof sessionMachine>>["0"];
  } & SessionActionDispatchers;
  workshop: {
    state: ReturnType<typeof useMachine<typeof workshopMachine>>["0"];
  } & WorkshopActionDispatchers;
  workshopClock: {
    state: ReturnType<typeof useMachine<typeof workshopClockMachine>>["0"];
  } & WorkshopClockActionDispatchers;
  requestNextWorkshop: {
    state: ReturnType<
      typeof useMachine<typeof requestNextWorkshopMachine>
    >["0"];
    trigger: (id: string) => void;
  };
  getJourney: {
    state: ReturnType<typeof useMachine<typeof getJourneyMachine>>["0"];
  };
}>({} as any);

type WorkshopMachineSnapshot = ReturnType<
  typeof useMachine<typeof workshopMachine>
>["0"];
type WorkshopMachineSend = ReturnType<
  typeof useMachine<typeof workshopMachine>
>["1"];
type WorkshopMachineEvents = Parameters<WorkshopMachineSend>[0];

type WorkshopClockMachineSnapshot = ReturnType<
  typeof useMachine<typeof workshopClockMachine>
>["0"];
type WorkshopClockMachineSend = ReturnType<
  typeof useMachine<typeof workshopClockMachine>
>["1"];
type WorkshopClockMachineEvents = Parameters<WorkshopClockMachineSend>[0];

type RequestNextWorkshopMachineSnapshot = ReturnType<
  typeof useMachine<typeof requestNextWorkshopMachine>
>["0"];
type RequestNextWorkshopMachineSend = ReturnType<
  typeof useMachine<typeof requestNextWorkshopMachine>
>["1"];
type RequestNextWorkshopMachineEvents =
  Parameters<RequestNextWorkshopMachineSend>[0];

type GetJourneyMachineSnapshot = ReturnType<
  typeof useMachine<typeof getJourneyMachine>
>["0"];
type GetJourneyMachineSend = ReturnType<
  typeof useMachine<typeof getJourneyMachine>
>["1"];
type GetJourneyMachineEvents = Parameters<GetJourneyMachineSend>[0];

export type RequestNextWorkshopActor = ActorRef<
  RequestNextWorkshopMachineSnapshot,
  RequestNextWorkshopMachineEvents
>;

export type GetJourneyActor = ActorRef<
  GetJourneyMachineSnapshot,
  GetJourneyMachineEvents
>;

const sessionActionEntries = Object.entries(
  sessionActions
) as Entries<SessionActions>;
const workshopActionEntries = Object.entries(
  workshopActions
) as Entries<WorkshopActions>;
const workshopClockActionEntries = Object.entries(
  workshopClockActions
) as Entries<WorkshopClockActions>;

export const SessionContextProvider = (props: PropsWithChildren) => {
  const apolloContext = useContext(ApolloContext);

  const [state, send] = useMachine(sessionMachine, {
    input: {
      client: apolloContext.client,
      socketEventTarget: apolloContext.socketEventTarget,
    },
  });
  const workshopActor = useMemo(
    () => state.children["workshop"],
    [state.children]
  ) as ActorRef<WorkshopMachineSnapshot, WorkshopMachineEvents>;
  const workshopState = useSelector(workshopActor, (s) => s);

  const workshopClockActor = useMemo(
    () => state.children["workshopClock"],
    [state.children]
  ) as ActorRef<WorkshopClockMachineSnapshot, WorkshopClockMachineEvents>;
  const workshopClockState = useSelector(workshopClockActor, (s) => s);

  const getRequestNextWorkshopActor = useMemo(
    () => state.children[requestNextWorkshopMachine.id],
    [state.children]
  ) as RequestNextWorkshopActor;
  const getRequestNextWorkshopState = useSelector(
    getRequestNextWorkshopActor,
    (s) => s
  );

  const getJourneyActor = useMemo(
    () => workshopState.children[getJourneyMachine.id],
    [workshopState.children]
  ) as GetJourneyActor;
  const getJourneyState = useSelector(getJourneyActor, (s) => s);

  useEffect(() => {
    const serverTimeUpdate = function (event: Event) {
      const serverTimestamp = (event as any).detail;
      if (!workshopClockState.matches(WorkshopClockStates.Running)) return;
      workshopClockState.context.clockInstance?.adjustTime(serverTimestamp);
    };

    apolloContext.serverTimeEventTarget.addEventListener(
      SERVER_TIME_UPDATE,
      serverTimeUpdate
    );

    return () => {
      apolloContext.serverTimeEventTarget.removeEventListener(
        SERVER_TIME_UPDATE,
        serverTimeUpdate
      );
    };
  }, [
    apolloContext.serverTimeEventTarget,
    workshopClockActor,
    workshopClockState,
  ]);

  const sessionDispatchers = useMemo(
    () =>
      sessionActionEntries.reduce((acc, [key, creator]) => {
        acc[key] = (payload) => {
          const action = creator(payload as any);
          send(action);
        };
        return acc;
      }, {} as Writeable<SessionActionDispatchers>),
    [send]
  );

  const workshopDispatchers = useMemo(
    () =>
      workshopActionEntries.reduce((acc, [key, creator]) => {
        acc[key] = (payload) => {
          const action = creator(payload as any);
          send(action);
        };
        return acc;
      }, {} as Writeable<WorkshopActionDispatchers>),
    [send]
  );

  const workshopClockDispatchers = useMemo(
    () =>
      workshopClockActionEntries.reduce((acc, [key, creator]) => {
        acc[key] = (payload) => {
          const action = creator(payload as any);
          send(action);
        };
        return acc;
      }, {} as Writeable<WorkshopClockActionDispatchers>),
    [send]
  );

  const requestNextWorkshopTriggerCallback = useCallback(
    (id: string) => {
      getRequestNextWorkshopActor.send(
        requestNextWorkshopTrigger({ client: apolloContext.client, id })
      );
    },
    [apolloContext.client, getRequestNextWorkshopActor]
  );

  const value = useMemo(
    () => ({
      session: {
        state,
        ...sessionDispatchers,
      },
      workshop: {
        state: workshopState,
        ...workshopDispatchers,
      },
      workshopClock: {
        state: workshopClockState,
        ...workshopClockDispatchers,
      },
      requestNextWorkshop: {
        state: getRequestNextWorkshopState,
        trigger: requestNextWorkshopTriggerCallback,
      },
      getJourney: {
        state: getJourneyState,
      },
    }),
    [
      getRequestNextWorkshopState,
      requestNextWorkshopTriggerCallback,
      sessionDispatchers,
      state,
      workshopClockDispatchers,
      workshopClockState,
      workshopDispatchers,
      workshopState,
      getJourneyState,
    ]
  );

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  );
};
