import { useMemo } from "react";
import { calculateJourneyStatus } from "../components/Dashboard/Admin/Journey/utils";
import { Journey } from "../types/contentful/workshop/journey";
import { Profile } from "../apollo-graphql/types";

export function useJourneyStatus(
  journey: Journey | null,
  profile: Profile | null
) {
  const availableJourneys = useMemo(
    () => profile?.workspace.workspace.availableJourneys || [],
    [profile?.workspace.workspace.availableJourneys]
  );
  const certificates = useMemo(
    () => profile?.workspace.workspace.certificates || [],
    [profile?.workspace.workspace.certificates]
  );
  const journeyParticipationData =
    profile?.workspace.workspace.journeyParticipationData || null;

  const journeyStatus = useMemo(
    () =>
      calculateJourneyStatus({
        journey,
        availableJourneys,
        certificates,
        journeyParticipationData,
      }),
    [availableJourneys, certificates, journey, journeyParticipationData]
  );

  return journeyStatus;
}
