import { PropsWithChildren, memo, useCallback, useMemo, useState } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Profile } from "../../../../../../apollo-graphql/types/profile";
import { Select } from "../../../../../Shared/Select/Select";
import styles from "./JourneyLeadersDialog.module.css";

interface IScheduleDialogState {
  profiles: Profile[];
}

export default memo(function (
  props: PropsWithChildren<{
    profiles: Profile[] | null;
    isLoading: boolean;
    onOpenChangeHandler: (open: boolean) => void;
    setJourneyLeadersHandler: (selectedProfiles: Profile[]) => void;
  }>
) {
  const { profiles, setJourneyLeadersHandler, onOpenChangeHandler, isLoading } =
    props;

  const [dialogState, setDialogState] = useState<IScheduleDialogState>({
    profiles: [],
  });

  const buttonDisabled = useMemo(() => {
    return dialogState.profiles.length === 0;
  }, [dialogState]);

  const options = useMemo(() => {
    return isLoading
      ? [
          {
            label: "Loading...",
            value: "-",
            disabled: true,
            key: "loading",
          },
        ]
      : [{ email: "Select journey leaders", id: "-" }, ...(profiles || [])]
          .filter((p) => !dialogState.profiles?.some((pp) => pp.id === p.id))
          .map((source) => ({
            label: source.email,
            value: source.id,
            key: source.id,
            disabled: dialogState?.profiles?.some(
              (selected) => selected.id === source.id
            ),
          }));
  }, [dialogState.profiles, isLoading, profiles]);

  const content = useMemo(() => {
    return (
      <div>
        <div className={styles.line}>
          <div className={styles.profileContainer}>
            <span className="text bold">Journey Leaders</span>
            {!!dialogState.profiles?.length && (
              <div className={styles.selectedProfileList}>
                {dialogState.profiles?.map((profile) => (
                  <div key={profile.id} className={styles.selectedProfile}>
                    <span>{profile.email}</span>
                    <button
                      onClick={() => {
                        setDialogState((prev) => ({
                          ...prev,
                          profiles: [
                            ...prev.profiles!.filter(
                              (d) => d.id !== profile.id
                            ),
                          ],
                        }));
                      }}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className={styles.profileSelectorContainer}>
              <Select
                className={styles.selector}
                label="Select journey leaders"
                options={options}
                onChange={(profileId: string) => {
                  const profile = profiles?.find((d) => d.id === profileId)!;
                  setDialogState((prev) => ({
                    ...prev,
                    profiles: [
                      ...(prev.profiles || []).filter(
                        (d) => d.id !== profile.id
                      ),
                      profile,
                    ],
                  }));
                }}
                value={"-"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [dialogState.profiles, options, profiles]);

  const setLeadersHandler = useCallback(() => {
    if (dialogState.profiles.length === 0) return;
    setJourneyLeadersHandler(dialogState.profiles);
  }, [dialogState.profiles, setJourneyLeadersHandler]);

  return (
    <Dialog.Root open={true} onOpenChange={onOpenChangeHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">
            Select journey leaders
          </Dialog.Title>
          <div className={styles.content}>{content}</div>
          <div
            className={styles.footer}
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn ghost"
              type="button"
              onClick={setLeadersHandler}
              disabled={buttonDisabled}
            >
              Continue
            </button>
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
