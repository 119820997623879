import cn from "classnames";
import JourneyAggregateItem from "../JourneyAggregateItem/JourneyAggregateItem";
import AhaBubble from "../../../JourneyDetails/AhaBubble/AhaBubble";
import { Aggregate } from "../../../utils";

import styles from "./JourneyAggregates.module.css";
import { memo } from "react";

interface JourneyAggregatesProps {
  conversations: Aggregate;
  hours: Aggregate;
  moments: Aggregate;
  behaviours: Aggregate;
  className?: string;
}

const JourneyAggregates = ({
  conversations,
  hours,
  moments,
  behaviours,
  className,
}: JourneyAggregatesProps) => (
  <div className={cn(styles.detailsWrapper, className)}>
    <JourneyAggregateItem
      logo={
        <i
          className={cn(
            "fa-light",
            "fa-messages",
            "details-logo",
            styles.detailsLogo
          )}
        />
      }
      quantity={conversations.quantity}
      title={`${
        conversations.quantity === 1 ? "Conversation" : "Conversations"
      }`}
      description={conversations.description}
    />
    <JourneyAggregateItem
      logo={
        <i
          className={cn(
            "fa-light",
            "fa-clock",
            "details-logo",
            styles.detailsLogo
          )}
        />
      }
      quantity={hours.quantity}
      title="hours"
      description={hours.description}
    />
    <JourneyAggregateItem
      logo={<AhaBubble className="aha-logo" />}
      quantity={moments.quantity}
      title={`${moments.quantity === 1 ? "Aha Moment" : "Aha Moments"}`}
      description={moments.description}
    />
    <JourneyAggregateItem
      logo={
        <i
          className={cn(
            "fa-light",
            "fa-swap-arrows",
            "details-logo",
            styles.detailsLogo
          )}
        />
      }
      quantity={behaviours.quantity}
      title={`${behaviours.quantity === 1 ? "Behaviour" : "Behaviours"}`}
      description={behaviours.description}
    />
  </div>
);

export default memo(JourneyAggregates);
