import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";

const mutation = gql`
  mutation requestJourney($journeyId: String!, $message: String) {
    requestJourney(journeyId: $journeyId, message: $message)
  }
`;

export function requestJourney(
  client: AppApolloClient,
  variables: { journeyId: string; message: string }
) {
  return client
    .mutate<{ requestJourney: boolean }>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return !!result.data?.requestJourney;
    });
}
