import {
  assign,
  createActor,
  createMachine,
  fromCallback,
  fromPromise,
  raise,
} from "xstate";
import * as actions from "../actions/jitsi";
import { Jitsi, JitsiCustomCommands, JitsiEvents } from "../../jitsi";
import { RemoteParticipantData } from "../../types/jitsi";
import { diff } from "deep-diff";
import { SourceData } from "../../types/source-data";

const MIN_TALKING_AUDIO_LEVEL = 0.025;

const JITSI_MUTE_ERROR_TIMEOUT = 3_000;
const JITSI_MUTE_ERROR_TIMEOUT_MESSAGE =
  "Jitsi mute failed to respond within 1 second";
let jitsiAudioMuteTimeout: number | null = null;
let jitsiVideoMuteTimeout: number | null = null;

type JitsiActions = ReturnType<(typeof actions)[keyof typeof actions]>;
type JitsiContext = {
  connectionFailure: boolean | null;
  jitsiInstance: Jitsi | null;
  jitsiDisposeCallback: (() => void) | null;
  selectedAudioSourceData: SourceData | null;
  selectedVideoSourceData: SourceData | null;
  availableVideoSources: SourceData[] | null;
  availableAudioSources: SourceData[] | null;
  remoteParticipantsData: RemoteParticipantData[] | null;
  isUserTalking: boolean | null;
  activityPartShouldBeMuted: boolean | null;
  deviceMuteStateRef: { audio: boolean; video: boolean } | null;
};

type JitsiMachineTypes = {
  context: JitsiContext;
  events: JitsiActions;
};

export enum JitsiConnectionStates {
  Initial = "initial",
  Connected = "connected",
  Disconnected = "disconnected",
  Reconnecting = "reconnecting",
}

export enum JitsiSetupStates {
  Initial = "initial",
  Initializing = "initializing",
  Configure = "configure",
  ConfigureVideo = "configureVideo",
  ConfigureAudio = "configureAudio",
  VideoPermissionGranted = "videoPermissionGranted",
  VideoPermissionDenied = "videoPermissionDenied",
  AudioPermissionGranted = "audioPermissionGranted",
  AudioPermissionDenied = "audioPermissionDenied",
  JoinConference = "joinConference",
  NoPermissions = "noPermissions",
  Update = "update",
  Done = "done",
}

const context: JitsiContext = {
  jitsiInstance: null,
  jitsiDisposeCallback: null,
  selectedAudioSourceData: null,
  selectedVideoSourceData: null,
  availableVideoSources: null,
  availableAudioSources: null,
  remoteParticipantsData: null,
  isUserTalking: null,
  activityPartShouldBeMuted: null,
  deviceMuteStateRef: null,
  connectionFailure: null,
};

export const jitsiMachine = createMachine({
  id: "Jitsi",
  type: "parallel",
  types: {} as JitsiMachineTypes,
  context: ({ input }): JitsiContext => {
    const { selectedAudioDevice, selectedVideoDevice } = input as {
      parent: ReturnType<typeof createActor<typeof jitsiMachine>>;
      selectedAudioDevice: SourceData | null;
      selectedVideoDevice: SourceData | null;
    };
    return {
      ...context,
      selectedAudioSourceData: selectedAudioDevice,
      selectedVideoSourceData: selectedVideoDevice,
    };
  },
  invoke: {
    src: fromCallback(({ input }) => {
      const { parent } = input as {
        parent: ReturnType<typeof createActor<typeof jitsiMachine>>;
        selectedAudioDevice: SourceData | null;
        selectedVideoDevice: SourceData | null;
      };
      return () => {
        const context = parent.getSnapshot().context;
        if (!context.jitsiDisposeCallback) return;
        context.jitsiDisposeCallback();
      };
    }),
    input: ({ self }) => {
      return { parent: self };
    },
  },
  states: {
    connection: {
      initial: JitsiConnectionStates.Initial,
      states: {
        [JitsiConnectionStates.Initial]: {
          on: {
            [actions.connect.type]: {
              target: "connected",
            },
            [actions.disconnect.type]: {
              target: JitsiConnectionStates.Disconnected,
            },
          },
        },
        [JitsiConnectionStates.Connected]: {
          on: {
            [actions.disconnect.type]: {
              target: JitsiConnectionStates.Disconnected,
            },
          },
        },
        [JitsiConnectionStates.Disconnected]: {
          entry: [
            assign({
              connectionFailure: ({ event }) => {
                const disconnectedEvent = event as ReturnType<
                  typeof actions.disconnect
                >;
                return disconnectedEvent.payload.connectionFailure;
              },
            }),
          ],
          on: {
            [actions.reconnect.type]: {
              target: JitsiConnectionStates.Reconnecting,
            },
            [actions.connect.type]: {
              target: JitsiConnectionStates.Connected,
            },
          },
        },
        [JitsiConnectionStates.Reconnecting]: {
          on: {
            [actions.connect.type]: {
              target: JitsiConnectionStates.Connected,
            },
            [actions.disconnect.type]: {
              target: JitsiConnectionStates.Disconnected,
            },
          },
        },
      },
    },
    setup: {
      initial: JitsiSetupStates.Initial,
      states: {
        [JitsiSetupStates.Initial]: {
          description: "Initial state for the setup",
          on: {
            [actions.initialize.type]: {
              target: JitsiSetupStates.Initializing,
              actions: [
                assign({
                  jitsiInstance: () => new Jitsi(),
                }),
                assign({
                  jitsiDisposeCallback: ({
                    self,
                    context,
                    event: {
                      payload: { profileId, htmlVideoElement },
                    },
                  }) => {
                    const instance = context.jitsiInstance!;

                    let currIsUserTalkingValue = context.isUserTalking;
                    let currRemoteParticipantsIsTalkingData: {
                      [key: string]: boolean;
                    } = {};

                    const onLocalVideoTrackReady = () => {
                      instance
                        .getAvailableSources()
                        .then((availableSources) => {
                          const availableVideoSources: SourceData[] =
                            availableSources.video.map((s) => ({
                              label: s.label,
                              sourceId: s.deviceId,
                              isMuted: undefined,
                            }));
                          const currentVideo = instance.localTracks.find(
                            (t) => t.getType() === "video"
                          );
                          if (!currentVideo) return;
                          const videoMatch = availableVideoSources.find(
                            (t) => currentVideo?.track.label === t.label
                          );

                          const selectedVideoSourceData =
                            context.selectedVideoSourceData || {
                              label: videoMatch?.label || null,
                              isMuted: currentVideo?.isMuted() || null,
                              sourceId: videoMatch?.sourceId || null,
                            };

                          self.send(
                            actions.configureVideo({
                              availableVideoSources,
                              selectedVideoSourceData,
                            })
                          );
                        });
                    };
                    const onLocalAudioTrackReady = async () => {
                      const availableSources =
                        await instance.getAvailableSources();
                      const availableAudioSources: SourceData[] =
                        availableSources.audio.map((s) => ({
                          label: s.label,
                          sourceId: s.deviceId,
                          isMuted: undefined,
                        }));

                      const currentAudio = instance.localTracks.find(
                        (t) => t.getType() === "audio"
                      );
                      if (!currentAudio) return;
                      const audioMatch = availableAudioSources.find(
                        (t) => currentAudio.track.label === t.label
                      );

                      const selectedAudioSourceData: SourceData =
                        context.selectedAudioSourceData || {
                          label: audioMatch?.label || null,
                          isMuted: currentAudio?.isMuted() || null,
                          sourceId: audioMatch?.sourceId || null,
                        };

                      self.send(
                        actions.configureAudio({
                          availableAudioSources,
                          selectedAudioSourceData,
                        })
                      );
                    };

                    const onLocalAudioVideoTrackReady = async () => {
                      await onLocalAudioTrackReady();
                      onLocalVideoTrackReady();
                    };

                    const onConferenceJoined = () => {
                      self.send(actions.done({}));
                    };

                    const onConnected = () => {
                      self.send(actions.connect());
                    };
                    const onConnectionFailure = () => {
                      self.send(
                        actions.disconnect({ connectionFailure: true })
                      );
                    };
                    const onConnectionDisconnect = () => {
                      self.send(
                        actions.disconnect({ connectionFailure: false })
                      );
                    };

                    const onRemoteTrackAdded = (event: any) => {
                      const { detail } = event;
                      if (!detail) return;

                      const participantId = detail.getParticipantId();
                      if (!instance.remoteTracks[participantId]?.length) return;

                      const participantTracks =
                        instance.remoteTracks[participantId];
                      const participantAudio = participantTracks.find(
                        (t) => t.getType() === "audio"
                      );
                      const participantVideo = participantTracks.find(
                        (t) => t.getType() === "video"
                      );

                      if (!participantAudio || !participantVideo) return;
                      const data = instance.getParticipantData(participantId);

                      self.send(
                        actions.remoteParticipantJoined({
                          remoteParticipantData: {
                            participantId,
                            name: data?.getDisplayName() || null,
                            isAudioMuted: participantAudio?.isMuted() || null,
                            isVideoMuted: participantVideo?.isMuted() || null,
                            isVideoEnabled: !!participantVideo,
                            isTalking: false,
                            isPlaying: false,
                            isConnecting: false,
                          },
                        })
                      );
                    };

                    const onTrackAudioLevelChanged = (event: any) => {
                      const { detail } = event;
                      if (!detail) return;

                      const { track, audioLevel } = detail;

                      const isTalking = audioLevel >= MIN_TALKING_AUDIO_LEVEL;
                      const participantId = track.getParticipantId();

                      if (
                        currRemoteParticipantsIsTalkingData[participantId] ===
                        isTalking
                      )
                        return;
                      currRemoteParticipantsIsTalkingData[participantId] =
                        isTalking;

                      self.send(
                        actions.remoteParticipantIsTalking({
                          participantId,
                          isTalking,
                        })
                      );
                    };

                    const onLocalTrackAudioLevelChanged = (event: any) => {
                      const { detail } = event;
                      if (!detail) return;

                      const { audioLevel } = detail;

                      const isTalking = audioLevel >= MIN_TALKING_AUDIO_LEVEL;
                      if (currIsUserTalkingValue === isTalking) return;
                      currIsUserTalkingValue = isTalking;

                      self.send(actions.userIsTalking({ isTalking }));
                    };

                    const onRemoteTrackRemoved = (event: any) => {
                      const { detail } = event;
                      if (!detail) return;
                      const participantId = detail.getParticipantId();
                      self.send(
                        actions.remoteParticipantDisconnected({
                          participantId,
                        })
                      );
                    };

                    const onUserLeft = (event: any) => {
                      const { detail } = event;
                      self.send(
                        actions.remoteParticipantDisconnected({
                          participantId: detail,
                        })
                      );
                    };
                    const onUserJoined = (event: any) => {
                      const participantId = event.detail;
                      self.send(
                        actions.remoteParticipantJoined({
                          remoteParticipantData: {
                            participantId,
                            name: "",
                            isAudioMuted: null,
                            isVideoMuted: null,
                            isVideoEnabled: null,
                            isTalking: false,
                            isPlaying: false,
                            isConnecting: false,
                          },
                        })
                      );
                    };

                    const onRemoteTrackMuted = (event: any) => {
                      const { detail } = event;
                      if (!detail) return;
                      const participantId = detail.getParticipantId();
                      const type = detail.getType();
                      const isMuted = detail.isMuted();
                      self.send(
                        actions.remoteParticipantMuted({
                          participantId,
                          type,
                          isMuted,
                        })
                      );
                    };

                    function onVideoPlaying(event: any) {
                      const detail = event.detail;
                      if (!detail) return;
                      const { participantId } = detail || {};

                      const participantTracks =
                        instance.remoteTracks[participantId];
                      const participantAudio = participantTracks.find(
                        (t) => t.getType() === "audio"
                      );
                      const participantVideo = participantTracks.find(
                        (t) => t.getType() === "video"
                      );
                      const data = instance.getParticipantData(participantId);

                      self.send(
                        actions.remoteParticipantUpdate({
                          remoteParticipantData: {
                            participantId,
                            name: data?.getDisplayName() || null,
                            isAudioMuted: participantAudio?.isMuted() || null,
                            isVideoMuted: participantVideo?.isMuted() || null,
                            isVideoEnabled: !!participantVideo,
                            isPlaying: true,
                            isConnecting: false,
                            isTalking: false,
                          },
                        })
                      );
                    }

                    function onVideoPaused(event: any) {
                      const detail = event.detail;
                      if (!detail) return;
                      const { participantId } = detail || {};

                      const participantTracks =
                        instance.remoteTracks[participantId];
                      const participantAudio = participantTracks.find(
                        (t) => t.getType() === "audio"
                      );
                      const participantVideo = participantTracks.find(
                        (t) => t.getType() === "video"
                      );
                      const data = instance.getParticipantData(participantId);

                      self.send(
                        actions.remoteParticipantUpdate({
                          remoteParticipantData: {
                            participantId,
                            name: data?.getDisplayName() || null,
                            isAudioMuted: participantAudio?.isMuted() || null,
                            isVideoMuted: participantVideo?.isMuted() || null,
                            isVideoEnabled: !!participantVideo,
                            isPlaying: true,
                            isConnecting: false,
                            isTalking: false,
                          },
                        })
                      );
                    }

                    function onUserNameChange(event: Event) {
                      const detail = (event as CustomEvent).detail as {
                        attributes: {
                          participantId: string;
                          name: string;
                        };
                      };

                      const {
                        attributes: { participantId, name },
                      } = detail;

                      self.send(
                        actions.remoteParticipantUpdate({
                          updates: {
                            participantId,
                            name,
                          },
                        })
                      );
                    }

                    function disableAudio(event: Event) {
                      self.send(actions.disableAudio());
                    }

                    function disableVideo(event: Event) {
                      self.send(actions.disableVideo());
                    }

                    instance.addEventListener(
                      JitsiEvents.LOCAL_AUDIO_TRACK_READY,
                      onLocalAudioTrackReady
                    );
                    instance.addEventListener(
                      JitsiEvents.LOCAL_VIDEO_TRACK_READY,
                      onLocalVideoTrackReady
                    );
                    instance.addEventListener(
                      JitsiEvents.LOCAL_AUDIO_AND_VIDEO_TRACK_READY,
                      onLocalAudioVideoTrackReady
                    );
                    instance.addEventListener(
                      JitsiEvents.CONNECTION_ESTABLISHED,
                      onConnected
                    );
                    instance.addEventListener(
                      JitsiEvents.CONNECTION_FAILURE,
                      onConnectionFailure
                    );
                    instance.addEventListener(
                      JitsiEvents.CONNECTION_DISCONNECTED,
                      onConnectionDisconnect
                    );

                    instance.addEventListener(
                      JitsiEvents.CONFERENCE_JOINED,
                      onConferenceJoined
                    );
                    instance.addEventListener(
                      JitsiEvents.REMOTE_TRACK_ADDED,
                      onRemoteTrackAdded
                    );
                    instance.addEventListener(
                      JitsiEvents.TRACK_AUDIO_LEVEL_CHANGED,
                      onTrackAudioLevelChanged
                    );
                    instance.addEventListener(
                      JitsiEvents.LOCAL_TRACK_AUDIO_LEVEL_CHANGED,
                      onLocalTrackAudioLevelChanged
                    );
                    instance.addEventListener(
                      JitsiEvents.REMOTE_TRACK_REMOVED,
                      onRemoteTrackRemoved
                    );
                    instance.addEventListener(
                      JitsiEvents.USER_LEFT,
                      onUserLeft
                    );
                    instance.addEventListener(
                      JitsiEvents.USER_JOINED,
                      onUserJoined
                    );
                    instance.addEventListener(
                      JitsiEvents.REMOTE_TRACK_MUTED,
                      onRemoteTrackMuted
                    );
                    instance.addEventListener(
                      JitsiEvents.VIDEO_PLAYING,
                      onVideoPlaying
                    );
                    instance.addEventListener(
                      JitsiEvents.VIDEO_PAUSED,
                      onVideoPaused
                    );
                    instance.addEventListener(
                      JitsiEvents.USER_NAME_CHANGED,
                      onUserNameChange
                    );
                    instance.addEventListener(
                      JitsiEvents.VIDEO_PERMISSION_DENIED,
                      disableVideo
                    );
                    instance.addEventListener(
                      JitsiEvents.AUDIO_PERMISSION_DENIED,
                      disableAudio
                    );

                    instance.addEventListener(
                      JitsiEvents.LOCAL_AUDIO_AND_VIDEO_CONFIG_READY,
                      () => {
                        instance.watchPermissionsChanged();
                        self.send(actions.initialConfigureDone());
                      }
                    );

                    instance.init(profileId, htmlVideoElement);
                    return () => {
                      instance.cleanup();

                      instance.removeEventListener(
                        JitsiEvents.CONNECTION_ESTABLISHED,
                        onConnected
                      );
                      instance.removeEventListener(
                        JitsiEvents.CONNECTION_FAILURE,
                        onConnectionFailure
                      );
                      instance.removeEventListener(
                        JitsiEvents.CONNECTION_DISCONNECTED,
                        onConnectionDisconnect
                      );
                      instance.removeEventListener(
                        JitsiEvents.CONFERENCE_JOINED,
                        onConferenceJoined
                      );
                      instance.removeEventListener(
                        JitsiEvents.REMOTE_TRACK_ADDED,
                        onRemoteTrackAdded
                      );
                      instance.removeEventListener(
                        JitsiEvents.REMOTE_TRACK_REMOVED,
                        onRemoteTrackRemoved
                      );
                      instance.removeEventListener(
                        JitsiEvents.USER_LEFT,
                        onUserLeft
                      );
                      instance.removeEventListener(
                        JitsiEvents.USER_JOINED,
                        onUserJoined
                      );
                      instance.removeEventListener(
                        JitsiEvents.TRACK_AUDIO_LEVEL_CHANGED,
                        onTrackAudioLevelChanged
                      );
                      instance.removeEventListener(
                        JitsiEvents.LOCAL_TRACK_AUDIO_LEVEL_CHANGED,
                        onLocalTrackAudioLevelChanged
                      );
                      instance.removeEventListener(
                        JitsiEvents.VIDEO_PLAYING,
                        onVideoPlaying
                      );
                      instance.removeEventListener(
                        JitsiEvents.VIDEO_PLAYING,
                        onVideoPlaying
                      );
                      instance.removeEventListener(
                        JitsiEvents.VIDEO_PAUSED,
                        onVideoPaused
                      );
                      instance.removeEventListener(
                        JitsiEvents.USER_NAME_CHANGED,
                        onUserNameChange
                      );
                      instance.removeEventListener(
                        JitsiEvents.LOCAL_AUDIO_AND_VIDEO_CONFIG_READY,
                        instance.watchPermissionsChanged
                      );
                    };
                  },
                }),
              ],
            },
          },
        },
        [JitsiSetupStates.Initializing]: {
          description:
            "Jitsi instance is initializing for a given session id and html video element. User is configuring sources.",
          on: {
            [actions.configureVideo.type]: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                availableVideoSources: ({ event, context }) =>
                  event.payload.availableVideoSources ||
                  context.availableVideoSources ||
                  null,
                selectedVideoSourceData: ({ event }) =>
                  event.payload.selectedVideoSourceData,
              }),
            },
            [actions.configureAudio.type]: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                availableAudioSources: ({ event, context }) =>
                  event.payload.availableAudioSources ||
                  context.availableAudioSources ||
                  null,
                selectedAudioSourceData: ({ event }) =>
                  event.payload.selectedAudioSourceData,
              }),
            },
            [actions.noPermissions.type]: {
              target: JitsiSetupStates.NoPermissions,
            },
            [actions.initialConfigureDone.type]: {
              target: JitsiSetupStates.Configure,
            },
          },
        },
        [JitsiSetupStates.Configure]: {
          description: "Configure devices",
          on: {
            [actions.done.type]: {
              target: JitsiSetupStates.Done,
              actions: assign({
                selectedAudioSourceData: ({ event, context }) => {
                  const selectedAudioSourceData =
                    event.payload.selectedAudioSourceData;
                  if (selectedAudioSourceData?.sourceId)
                    context.jitsiInstance!.changeSource(
                      "audio",
                      selectedAudioSourceData.sourceId
                    );
                  return (
                    selectedAudioSourceData || context.selectedAudioSourceData
                  );
                },
                selectedVideoSourceData: ({ event, context }) => {
                  const selectedVideoSourceData =
                    event.payload.selectedVideoSourceData;
                  if (selectedVideoSourceData?.sourceId)
                    context.jitsiInstance!.changeSource(
                      "video",
                      selectedVideoSourceData.sourceId
                    );
                  return (
                    selectedVideoSourceData || context.selectedVideoSourceData
                  );
                },
              }),
            },
            [actions.configureAudio.type]: {
              target: JitsiSetupStates.ConfigureAudio,
            },
            [actions.configureVideo.type]: {
              target: JitsiSetupStates.ConfigureVideo,
            },
            [actions.joinConference.type]: {
              target: JitsiSetupStates.JoinConference,
              actions: assign({
                activityPartShouldBeMuted: false,
                selectedAudioSourceData: ({ event, context }) =>
                  event.payload.selectedAudioSourceData ||
                  context.selectedAudioSourceData ||
                  null,
                selectedVideoSourceData: ({ event, context }) =>
                  event.payload.selectedVideoSourceData ||
                  context.selectedVideoSourceData ||
                  null,
              }),
            },
            [actions.disableVideo.type]: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                selectedVideoSourceData: null,
              }),
            },
            [actions.disableAudio.type]: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                selectedAudioSourceData: null,
              }),
            },
          },
        },
        [JitsiSetupStates.ConfigureVideo]: {
          invoke: {
            src: fromPromise(({ input }) => {
              const { payload, context } = input;

              const currentVideoTrack =
                context.jitsiInstance?.localTracks?.find(
                  ({ type }: { type: string }) => type === "video"
                )?.track;

              if (!currentVideoTrack) return;
              const isMuted = !!payload.selectedVideoSourceData?.isMuted;

              const videoMuteChanged =
                !!context.selectedVideoSourceData?.isMuted !== isMuted ||
                !!currentVideoTrack?.muted !== isMuted;

              if (videoMuteChanged) {
                return context
                  .jitsiInstance!.toggleVideoMute(
                    context.selectedVideoSourceData?.sourceId!,
                    isMuted
                  )
                  .then((isMuted: boolean) => ({
                    selectedVideoSourceData: {
                      ...payload.selectedVideoSourceData,
                      isMuted,
                    },
                  }));
              }

              return input.payload;
            }),
            input: ({ event, context }) => ({
              payload: event.payload,
              context,
            }),
            onError: {
              target: JitsiSetupStates.Configure,
            },
            onDone: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                availableVideoSources: ({ event: { output }, context }) =>
                  output.availableVideoSources ||
                  context.availableVideoSources ||
                  null,
                selectedVideoSourceData: ({ event: { output }, context }) => {
                  const selectedVideoSourceData =
                    output.selectedVideoSourceData;
                  if (
                    selectedVideoSourceData?.sourceId &&
                    context.selectedVideoSourceData?.sourceId !==
                      selectedVideoSourceData.sourceId
                  )
                    context.jitsiInstance!.changeSource(
                      "video",
                      selectedVideoSourceData.sourceId
                    );

                  return selectedVideoSourceData;
                },
              }),
            },
          },
        },
        [JitsiSetupStates.ConfigureAudio]: {
          invoke: {
            src: fromPromise(({ input }) => {
              const { payload, context } = input;
              const currentAudioTrack =
                context.jitsiInstance?.localTracks?.find(
                  ({ type }: { type: string }) => type === "audio"
                )?.track;

              if (!currentAudioTrack) return;

              const audioMuteChanged =
                !!context.selectedAudioSourceData?.isMuted !==
                  !!payload.selectedAudioSourceData.isMuted ||
                !!currentAudioTrack?.muted !==
                  !!payload.selectedAudioSourceData.isMuted;

              if (audioMuteChanged) {
                return context
                  .jitsiInstance!.toggleAudioMute(
                    context.selectedAudioSourceData?.sourceId!
                  )
                  .then((isMuted: boolean) => ({
                    selectedAudioSourceData: {
                      ...payload.selectedAudioSourceData,
                      isMuted,
                    },
                  }));
              }

              return input.payload;
            }),
            input: ({ event, context }) => ({
              payload: event.payload,
              context,
            }),
            onError: {
              target: JitsiSetupStates.Configure,
            },
            onDone: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                availableAudioSources: ({ event: { output }, context }) =>
                  output.availableAudioSources ||
                  context.availableAudioSources ||
                  null,
                selectedAudioSourceData: ({ event: { output }, context }) => {
                  const selectedAudioSourceData =
                    output.selectedAudioSourceData;
                  if (
                    selectedAudioSourceData.sourceId &&
                    context.selectedAudioSourceData?.sourceId !==
                      selectedAudioSourceData.sourceId
                  ) {
                    context.jitsiInstance!.changeSource(
                      "audio",
                      selectedAudioSourceData.sourceId
                    );
                  }

                  return selectedAudioSourceData;
                },
              }),
            },
          },
        },
        [JitsiSetupStates.JoinConference]: {
          entry: [
            ({ context }) => {
              context.jitsiInstance!.conference.join();
            },
          ],
          on: {
            [actions.done.type]: {
              target: JitsiSetupStates.Done,
            },
          },
        },
        [JitsiSetupStates.Update]: {
          description: "User is updating configurations for given session",
          on: {
            [actions.configureVideo.type]: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                availableVideoSources: ({ event, context }) =>
                  event.payload.availableVideoSources ||
                  context.availableVideoSources ||
                  null,
                selectedVideoSourceData: ({ event }) =>
                  event.payload.selectedVideoSourceData,
              }),
            },
            [actions.configureAudio.type]: {
              target: JitsiSetupStates.Configure,
              actions: assign({
                availableAudioSources: ({ event, context }) =>
                  event.payload.availableAudioSources ||
                  context.availableAudioSources ||
                  null,
                selectedAudioSourceData: ({ event }) =>
                  event.payload.selectedAudioSourceData,
              }),
            },
            [actions.done.type]: {
              target: JitsiSetupStates.Done,
              actions: assign({
                selectedAudioSourceData: ({ event, context }) =>
                  event.payload.selectedAudioSourceData ||
                  context.selectedAudioSourceData ||
                  null,
                selectedVideoSourceData: ({ event, context }) =>
                  event.payload.selectedVideoSourceData ||
                  context.selectedVideoSourceData ||
                  null,
              }),
            },
          },
        },
        [JitsiSetupStates.NoPermissions]: {
          description:
            "Jitsi was not initialized successfully because we don't have camera and audio source permissions",
          on: {
            [actions.initialize.type]: {
              target: JitsiSetupStates.Initializing,
            },
          },
        },
        [JitsiSetupStates.Done]: {
          on: {
            [actions.configureAudio.type]: {
              target: JitsiSetupStates.Done,
              actions: assign({
                availableAudioSources: ({ event, context }) =>
                  event.payload.availableAudioSources ||
                  context.availableAudioSources ||
                  null,
                selectedAudioSourceData: ({ self, event, context }) => {
                  if (jitsiAudioMuteTimeout) {
                    clearTimeout(jitsiAudioMuteTimeout);
                    jitsiAudioMuteTimeout = null;
                  }

                  const selectedAudioSourceData =
                    event.payload.selectedAudioSourceData;
                  const skipDiscrepancyHandling =
                    event.payload.skipDiscrepancyHandling;
                  if (
                    selectedAudioSourceData.sourceId &&
                    selectedAudioSourceData.sourceId !==
                      context.selectedAudioSourceData?.sourceId
                  )
                    context.jitsiInstance!.changeSource(
                      "audio",
                      selectedAudioSourceData.sourceId
                    );

                  const muteChanged =
                    !!context.selectedAudioSourceData?.isMuted !==
                    !!selectedAudioSourceData.isMuted;
                  if (muteChanged) {
                    jitsiAudioMuteTimeout = setTimeout(() => {
                      context.jitsiInstance?.handleJitsiError(
                        new Error(JITSI_MUTE_ERROR_TIMEOUT_MESSAGE)
                      );
                    }, JITSI_MUTE_ERROR_TIMEOUT) as any as number;

                    context
                      .jitsiInstance!.toggleAudioMute(
                        context.selectedAudioSourceData?.sourceId!,
                        !!selectedAudioSourceData.isMuted
                      )
                      .then((actualIsMuted) => {
                        if (jitsiAudioMuteTimeout) {
                          clearTimeout(jitsiAudioMuteTimeout);
                          jitsiAudioMuteTimeout = null;
                        }

                        const muteDiscrepancy =
                          selectedAudioSourceData.isMuted !== actualIsMuted;
                        if (!muteDiscrepancy || skipDiscrepancyHandling) {
                          return;
                        }
                        self.send(
                          actions.configureAudio({
                            selectedAudioSourceData: {
                              ...selectedAudioSourceData,
                              isMuted: actualIsMuted,
                            },
                            skipDiscrepancyHandling: true,
                          })
                        );
                      });
                  }

                  return selectedAudioSourceData;
                },
              }),
            },
            [actions.configureVideo.type]: {
              target: JitsiSetupStates.Done,
              actions: assign({
                availableVideoSources: ({ event, context }) =>
                  event.payload.availableVideoSources ||
                  context.availableVideoSources ||
                  null,
                selectedVideoSourceData: ({ self, event, context }) => {
                  if (jitsiVideoMuteTimeout) {
                    clearTimeout(jitsiVideoMuteTimeout);
                    jitsiVideoMuteTimeout = null;
                  }

                  const selectedVideoSourceData =
                    event.payload.selectedVideoSourceData;
                  const skipDiscrepancyHandling =
                    event.payload.skipDiscrepancyHandling;
                  if (
                    selectedVideoSourceData.sourceId &&
                    selectedVideoSourceData.sourceId !==
                      context.selectedVideoSourceData?.sourceId
                  )
                    context.jitsiInstance!.changeSource(
                      "video",
                      selectedVideoSourceData.sourceId
                    );

                  const muteChanged =
                    !!context.selectedVideoSourceData?.isMuted !==
                    !!selectedVideoSourceData.isMuted;
                  if (muteChanged) {
                    jitsiVideoMuteTimeout = setTimeout(() => {
                      context.jitsiInstance?.handleJitsiError(
                        new Error(JITSI_MUTE_ERROR_TIMEOUT_MESSAGE)
                      );
                    }, JITSI_MUTE_ERROR_TIMEOUT) as any as number;

                    context
                      .jitsiInstance!.toggleVideoMute(
                        context.selectedVideoSourceData?.sourceId!,
                        !!selectedVideoSourceData.isMuted
                      )
                      .then((actualIsMuted) => {
                        if (jitsiVideoMuteTimeout) {
                          clearTimeout(jitsiVideoMuteTimeout);
                          jitsiVideoMuteTimeout = null;
                        }

                        const muteDiscrepancy =
                          selectedVideoSourceData.isMuted !== actualIsMuted;
                        if (!muteDiscrepancy || skipDiscrepancyHandling) {
                          return;
                        }
                        self.send(
                          actions.configureVideo({
                            selectedVideoSourceData: {
                              ...selectedVideoSourceData,
                              isMuted: actualIsMuted,
                            },
                            skipDiscrepancyHandling: true,
                          })
                        );
                      });
                  }

                  return selectedVideoSourceData;
                },
              }),
            },
            [actions.handleActivityPartChange.type]: {
              target: JitsiSetupStates.Done,
              actions: assign({
                activityPartShouldBeMuted: ({ event }) =>
                  event.payload.shouldBeMuted,
                deviceMuteStateRef: ({ context, event }) => {
                  const { shouldBeMuted } = event.payload;
                  const {
                    activityPartShouldBeMuted,
                    deviceMuteStateRef,
                    selectedAudioSourceData,
                    selectedVideoSourceData,
                  } = context;

                  if (activityPartShouldBeMuted === shouldBeMuted) {
                    return deviceMuteStateRef;
                  }

                  if (!shouldBeMuted) {
                    return null;
                  }

                  return {
                    audio: !!selectedAudioSourceData?.isMuted,
                    video: !!selectedVideoSourceData?.isMuted,
                  };
                },
                selectedAudioSourceData: ({ context, event }) => {
                  const { shouldBeMuted } = event.payload;
                  const {
                    jitsiInstance,
                    selectedAudioSourceData,
                    deviceMuteStateRef,
                    activityPartShouldBeMuted,
                  } = context;

                  if (
                    !selectedAudioSourceData ||
                    shouldBeMuted === activityPartShouldBeMuted
                  ) {
                    return selectedAudioSourceData;
                  }

                  if (shouldBeMuted) {
                    const shouldMute = !selectedAudioSourceData.isMuted;
                    if (shouldMute) {
                      jitsiInstance!.toggleAudioMute(
                        selectedAudioSourceData.sourceId!,
                        true
                      );
                      return {
                        ...selectedAudioSourceData,
                        isMuted: true,
                      };
                    }
                  } else {
                    const shouldUnmute =
                      selectedAudioSourceData.isMuted &&
                      !deviceMuteStateRef?.audio;
                    if (shouldUnmute) {
                      jitsiInstance!.toggleAudioMute(
                        selectedAudioSourceData.sourceId!,
                        false
                      );
                      return {
                        ...selectedAudioSourceData,
                        isMuted: false,
                      };
                    }
                  }

                  return selectedAudioSourceData;
                },
                selectedVideoSourceData: ({ context, event }) => {
                  const { shouldBeMuted } = event.payload;
                  const {
                    jitsiInstance,
                    selectedVideoSourceData,
                    deviceMuteStateRef,
                    activityPartShouldBeMuted,
                  } = context;

                  if (
                    !selectedVideoSourceData ||
                    shouldBeMuted === activityPartShouldBeMuted
                  ) {
                    return selectedVideoSourceData;
                  }

                  if (shouldBeMuted) {
                    const shouldMute = !selectedVideoSourceData.isMuted;
                    if (shouldMute) {
                      jitsiInstance!.toggleVideoMute(
                        selectedVideoSourceData.sourceId!,
                        true
                      );
                      return {
                        ...selectedVideoSourceData,
                        isMuted: true,
                      };
                    }
                  } else {
                    const shouldUnmute =
                      selectedVideoSourceData.isMuted &&
                      !deviceMuteStateRef?.video;
                    if (shouldUnmute) {
                      jitsiInstance!.toggleVideoMute(
                        selectedVideoSourceData.sourceId!,
                        false
                      );
                      return {
                        ...selectedVideoSourceData,
                        isMuted: false,
                      };
                    }
                  }

                  return selectedVideoSourceData;
                },
              }),
            },
            [actions.handleUserNameChange.type]: {
              actions: [
                ({ context, event }) => {
                  if (!context.jitsiInstance) return;
                  context.jitsiInstance.sendCommand(
                    JitsiCustomCommands.USER_NAME_CHANGED,
                    { attributes: event.payload }
                  );
                },
              ],
            },
            [actions.handleEditorAwarenessChange.type]: {
              actions: [
                ({ context, event }) => {
                  if (!context.jitsiInstance) return;
                  context.jitsiInstance.sendCommand(
                    JitsiCustomCommands.EDITOR_AWARENESS_CHANGE,
                    { attributes: JSON.stringify(event.payload) }
                  );
                },
              ],
            },
          },
        },
      },
    },
  },
  on: {
    [actions.dispose.type]: {
      actions: assign({
        jitsiDisposeCallback: ({ context }) => {
          if (context.jitsiDisposeCallback) context.jitsiDisposeCallback();
          return null;
        },
        jitsiInstance: () => {
          return null;
        },
      }),
    },
    [actions.remoteParticipantJoined.type]: {
      actions: assign({
        remoteParticipantsData: ({
          context,
          event: {
            payload: { remoteParticipantData },
          },
        }) => {
          const currentParticipants = context.remoteParticipantsData || [];
          const filteredParticipants = currentParticipants.filter(
            (cp) =>
              cp.participantId !== remoteParticipantData.participantId &&
              (!remoteParticipantData.name ||
                cp.name !== remoteParticipantData.name)
          );
          return filteredParticipants.concat(remoteParticipantData);
        },
      }),
    },
    [actions.remoteParticipantDisconnected.type]: {
      actions: assign({
        remoteParticipantsData: ({
          context,
          event: {
            payload: { participantId },
          },
        }) => {
          const currentParticipants = context.remoteParticipantsData || [];
          const filteredParticipants = currentParticipants.filter(
            (cp) => cp.participantId !== participantId
          );
          return filteredParticipants;
        },
      }),
    },
    [actions.remoteParticipantIsTalking.type]: {
      actions: assign({
        remoteParticipantsData: ({
          context,
          event: {
            payload: { participantId, isTalking },
          },
        }) => {
          const currentParticipants = context.remoteParticipantsData || [];
          return currentParticipants.map((cp) => {
            if (cp.participantId !== participantId) {
              return cp;
            }

            return {
              ...cp,
              isTalking,
            };
          });
        },
      }),
    },
    [actions.userIsTalking.type]: {
      actions: assign({
        isUserTalking: ({
          event: {
            payload: { isTalking },
          },
        }) => isTalking,
      }),
    },
    [actions.remoteParticipantMuted.type]: {
      actions: assign({
        remoteParticipantsData: ({
          context,
          event: {
            payload: { participantId, type, isMuted },
          },
        }) => {
          return (context.remoteParticipantsData || []).map((rpd) => {
            if (rpd.participantId !== participantId) return rpd;

            const isAudioMuted = type === "audio" ? isMuted : rpd.isAudioMuted;
            const isVideoMuted = type === "video" ? isMuted : rpd.isVideoMuted;
            return {
              ...rpd,
              isAudioMuted,
              isVideoMuted,
            };
          });
        },
      }),
    },
    [actions.remoteParticipantUpdate.type]: {
      actions: assign({
        remoteParticipantsData: ({
          context,
          event: {
            payload: { remoteParticipantData, updates },
          },
        }) => {
          const participantId = (remoteParticipantData?.participantId ||
            updates?.participantId)!;

          const currentParticipants = context.remoteParticipantsData || [];
          const existingParticipant = currentParticipants.find(
            (cp) => cp.participantId === participantId
          );
          if (!existingParticipant) return currentParticipants;
          if (updates) {
            remoteParticipantData = { ...existingParticipant, ...updates };
          }
          const differences = diff(existingParticipant, remoteParticipantData!);
          const noDiff = !differences || differences.length === 0;
          if (noDiff) return currentParticipants;

          return currentParticipants.map((rpd) => {
            if (rpd.participantId !== participantId) return rpd;
            return remoteParticipantData!;
          });
        },
      }),
    },
    [actions.reset.type]: {
      target: `.setup.${JitsiSetupStates.Initial}`,
      actions: [
        raise(() => {
          return actions.dispose();
        }),
      ],
    },
    [actions.setupConnection.type]: {
      actions: [
        ({
          context,
          event: {
            payload: { roomName },
          },
        }) => {
          const instance = context.jitsiInstance;
          if (!instance || instance.connection) {
            console.error("Connection already setup!");
            return;
          }
          instance.setupConnection(roomName);
        },
      ],
    },
  },
});
