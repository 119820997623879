import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { SESSION_CONTEXT_RESULT_QUERY } from "../constants";

const mutation = gql`
  mutation Mutation($sessionId: String!, $activityId: String!) {
    setActivityReady(sessionId: $sessionId, activityId: $activityId) {
      context ${SESSION_CONTEXT_RESULT_QUERY}
    }
  }
`;

export function setActivityReady(
  client: AppApolloClient,
  variables: { sessionId: string; activityId: string }
) {
  return client
    .mutate<any>({ mutation, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.disconnect;
    });
}
