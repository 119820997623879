import { isDevelopmentEnv, isProductionEnv } from "./environment";
export const serverDomain = isDevelopmentEnv
  ? "localhost"
  : window.location.origin.replace(window.location.protocol + "//", "");

export const serverHttpProtocol = isProductionEnv ? "https" : "http";
export const serverWSProtocol = isProductionEnv ? "wss" : "ws";

export const serverUrl = `${serverHttpProtocol}://${serverDomain}`;
export const apolloUri = `${serverUrl}/graphql`;
export const apolloSubscriptionUri = `${serverWSProtocol}://${serverDomain}/graphql`;
