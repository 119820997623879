import { ReactNode } from "react";
import cn from "classnames";

import styles from "./JourneyAggregateItem.module.css";

interface JourneyAggregateItemProps {
  logo: ReactNode;
  quantity: number;
  isPercentage?: boolean;
  title: string;
  description?: string;
}

const JourneyAggregateItem = ({
  logo,
  quantity,
  isPercentage,
  title,
  description,
}: JourneyAggregateItemProps) => (
  <div className={cn(styles.detailsItem, "journey-details-item")}>
    {logo}
    <div className={styles.detailsAggregate}>
      <span className={cn(styles.detailsAggregateTop, "aggregate-top-title")}>
        {quantity}
        {isPercentage && "%"} {title}
      </span>
      {!!description && (
        <span className={styles.detailsAggregateBottom}>{description}</span>
      )}
    </div>
  </div>
);

export default JourneyAggregateItem;
