import { PropsWithChildren, useState, memo } from "react";
import cn from "classnames";
import * as Form from "@radix-ui/react-form";

import styles from "./Password.module.css";

const Password = (
  props: PropsWithChildren<{
    name: string;
    label?: string;
    labelClassName?: string;
    placeholder?: string;
  }>
) => {
  const { name, label, labelClassName, placeholder } = props;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  return (
    <Form.Field className={styles.field} name={name}>
      {label && (
        <Form.Label
          className={cn(styles.label, "caption", "medium", labelClassName)}
        >
          {label}
        </Form.Label>
      )}
      <Form.Control
        type={showCurrentPassword ? "text" : "password"}
        className="FormControl"
        placeholder={placeholder}
      />
      <div
        className={styles.iconContainer}
        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
      >
        <i className={`fa fa-eye${!showCurrentPassword ? "-slash" : ""}`} />
      </div>
    </Form.Field>
  );
};
export default memo(Password);
