import { Side, Alignment } from "driver.js";

export const stepsSelectors = {
  waitingContainer: "guide-waiting-container",
  conferenceContainer: "guide-conference-container",
  actionFooter: "guide-action-footer",
  headerRight: "guide-header-right",
  teamNameInput: "guide-team-name-input",
};

export const steps = {
  [stepsSelectors.waitingContainer]: {
    order: 1,
    element: `.${stepsSelectors.waitingContainer}`,
    popover: {
      title: "Workshop content area",
      description: "All info related to the current workshop activity.",
      side: "right" as Side,
      align: "center" as Alignment,
    },
  },
  [stepsSelectors.conferenceContainer]: {
    order: 2,
    element: `.${stepsSelectors.conferenceContainer}`,
    popover: {
      title: "Conference call area",
      description: "All players, assignment & theory.",
      side: "left" as Side,
      align: "center" as Alignment,
    },
  },
  [stepsSelectors.actionFooter]: {
    order: 3,
    element: `.${stepsSelectors.actionFooter}`,
    popover: {
      title: "Workshop guide",
      description:
        "Here you'll be able to see what you have to do to continue forward",
      side: "top" as Side,
      align: "center" as Alignment,
    },
  },
  [stepsSelectors.headerRight]: {
    order: 4,
    element: `.${stepsSelectors.headerRight}`,
    popover: {
      title: "Info & Settings area",
      description:
        "You'll be able to access your profile, get help, give feedback, access the onboarding wizard or exit the workshop.",
      side: "bottom" as Side,
      align: "center" as Alignment,
    },
  },
  [stepsSelectors.teamNameInput]: {
    order: 1,
    element: `.${stepsSelectors.teamNameInput}`,
    popover: {
      title: "Syncronyzed field",
      description: `
        <div class="description-inner">This is a synchronized field where everyone can type</div>
        <div style='height: 166px; width: 268px' id='dotlottie-container' data-anim-src='/animations/name-a-squad.lottie'></div>
      `,
      side: "right" as Side,
      align: "center" as Alignment,
    },
  },
};
