import cn from "classnames";
import {
  Journey,
  JourneyTag,
} from "../../../../../../types/contentful/workshop/journey";
import AhaBubble from "../AhaBubble/AhaBubble";
import JourneyTags from "../JourneyTags/JourneyTags";

import styles from "./JourneyDetailsBanner.module.css";
import { JourneyStatus } from "../../../../../../types/enums/journey-status";

interface JourneyDetailsBannerProps {
  banner: Journey["fields"]["bigBannerImage"] | null;
  type: string;
  tags: JourneyTag[];
  authorName: Journey["fields"]["author"]["fields"]["name"];
  journeyStatus: JourneyStatus;
}

const JourneyDetailsBanner = ({
  banner,
  type,
  tags,
  authorName,
  journeyStatus,
}: JourneyDetailsBannerProps) => {
  return (
    <div className={styles.banner}>
      <img
        src={banner?.fields.file.url}
        alt={banner?.fields.title}
        className={styles.bannerImage}
      />
      <div className={styles.bannerTop}>
        <div className={styles.bannerTopLeft}>
          <span className={cn(styles.type, styles.label)}>{type}</span>
          <span className={cn(styles.type, styles.label)}>{journeyStatus}</span>
          <JourneyTags
            tags={tags}
          />
        </div>
        <img
          src="/images/logoWhite.svg"
          alt="Aha play logo"
          className={styles.ahaPlayLogo}
        />
      </div>
      <div className={styles.bannerBottom}>
        <div className={styles.ahaBubbleContainer}>
          <AhaBubble className={styles.ahaBubble} />
        </div>
        <div className={styles.ahaAuthor}>
          <span className="bold">{`by ${authorName}`}</span>
          <span>Conversations worth having!</span>
        </div>
      </div>
    </div>
  );
};

export default JourneyDetailsBanner;
