import { PropsWithChildren, memo, useMemo } from "react";

import { parseToJson } from "../../../../../../utils";
import { IDragAndDropCard } from "../../../../../Shared/DragAndDrop/types";
import { IActivityResult } from "../../../../../../apollo-graphql/types/session-state";
import { StandardSessionActivity } from "../../../../../../apollo-graphql/types/enums";

import SectionCard from "../SectionCard/SectionCard";
import CircledIcon from "../../../../../Shared/CircledIcon/CircledIcon";

import styles from "./AhaMoment.module.css";

interface IAhaMoment extends IDragAndDropCard {
  isAuthorMoment: boolean;
  isOwnMoment: boolean;
}

const AhaMoment = ({
  title,
  activityResult,
  profileId,
  workshopAuthor,
}: PropsWithChildren<{
  title: string;
  activityResult: IActivityResult[];
  profileId: string;
  workshopAuthor: {
    id: string;
    name: string;
    imageUrl: string;
    headline: string;
  };
}>) => {
  const momentActivityResult = useMemo(() => {
    const momentsResultValue =
      activityResult.find(
        ({ key }) => key === StandardSessionActivity.MomentGroup
      )?.value?.[0]?.value || null;

    return parseToJson<{
      moments: IDragAndDropCard[];
    }>(momentsResultValue, {
      moments: [],
    }).moments;
  }, [activityResult]);

  const allMoments = useMemo(() => {
    const authorMoments: IAhaMoment[] = [];
    const teamMoments: IAhaMoment[] = [];

    for (const moment of momentActivityResult) {
      if (moment.profileId === workshopAuthor.id) {
        authorMoments.push({
          ...moment,
          isAuthorMoment: true,
          isOwnMoment: false,
        });
      } else {
        teamMoments.push({
          ...moment,
          isAuthorMoment: false,
          isOwnMoment: moment.profileId === profileId,
        });
      }
    }

    return authorMoments.concat(teamMoments);
  }, [momentActivityResult, profileId, workshopAuthor.id]);

  const momentsContent = useMemo(() => {
    return allMoments.map((moment, index, arr) => {
      const badge = moment.isOwnMoment ? (
        <CircledIcon
          iconClass="fa-user"
          isSelected
          className={styles.userIcon}
        />
      ) : moment.isAuthorMoment ? (
        <span className="author-moment">Author’s Aha Moment</span>
      ) : null;

      return (
        <div className={styles.ahaMoment} key={moment.id}>
          <span className="index text secondary small">
            {arr.length - index}.
          </span>
          <span className="moment-text text secondary small">
            {moment.text}
          </span>
          {badge}
        </div>
      );
    });
  }, [allMoments]);

  if (momentActivityResult.length === 0) return null;

  return (
    <div className={styles.ahaMomentContainer}>
      <div className={styles.sectionContainer}>
        <SectionCard
          title={title}
          key="share-your-collective-aha-moments"
          className={styles.sectionCard}
          imagePath="/images/read-icon.svg"
          imageAlt="read-icon"
        >
          <div className={styles.ahaMomentContent}>
            <div className={styles.ahaMoments}>{momentsContent}</div>
            {/* Share button removed as sharing is not currently supported, but keep it for future use when available */}
            {/* <LoadingButton
              iconClass="fa fa-arrow-up-from-bracket"
              size="small"
              className={styles.shareButton}
            >
              Share
            </LoadingButton> */}
          </div>
        </SectionCard>
      </div>
    </div>
  );
};

export default memo(AhaMoment);
