import { PropsWithChildren, ReactNode, memo, useMemo, useState } from "react";
import withSortable, {
  IWithSortableProps,
} from "../../../../hocs/withSortable";
import { IDragAndDropCard } from "../types";

import CircledIcon from "../../CircledIcon/CircledIcon";
import ToggleChevron from "../../ToggleChevron/ToggleChevron";
import UserImage from "../../UserImage/UserImage";

import dragAndDropCardImageStyles from "../styles/drag-and-drop-image.module.css";
import cn from "classnames";
import ContentfulRichField from "../../ContentfulRichField/ContentfulRichField";

import styles from "./DragAndDropCard.module.css";

interface DragAndDropCardProps<T> extends IWithSortableProps {
  item: T;
  currentProfileId: string;
  isDisabled?: boolean;
  isExpandable?: boolean;
  hasDraggableIcon?: boolean;
  className?: string;
  image?: {
    profileId: string;
    src: string;
    fallbackFontAwesomeIconClass: string;
  };
  cachedImage?: ReactNode;
}

const DragAndDropCard = (
  props: PropsWithChildren<DragAndDropCardProps<IDragAndDropCard>>
) => {
  const {
    item,
    currentProfileId,
    isDraggable,
    isDragging,
    listeners,
    attributes,
    setNodeRef,
    style,
    image,
    cachedImage,
    isDisabled = false,
    isExpandable = false,
    hasDraggableIcon = false,
    className = "",
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const imageContent = useMemo(() => {
    return cachedImage
      ? cachedImage
      : image && item.profileId === image.profileId && (
          <UserImage
            src={image.src}
            profileId={image.profileId}
            alt="author-profile"
            fallbackFontAwesomeIconClass={cn(
              dragAndDropCardImageStyles.fallbackIconClass,
              image.fallbackFontAwesomeIconClass
            )}
            containerClass={dragAndDropCardImageStyles.imgContainer}
          />
        );
  }, [cachedImage, image, item.profileId]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      key={item.id}
      className={cn(
        styles.dragAndDropContainer,
        "drag-and-drop-container",
        (isDisabled || !isDraggable) && "disable",
        className
      )}
    >
      <div
        className={cn(
          styles.dragAndDropCardContent,
          !isDraggable ? "not-draggable" : "draggable",
          hasDraggableIcon &&
            (isDisabled || !isDraggable) &&
            "drag-and-drop-card-disabled",
          hasDraggableIcon && isDraggable && "with-draggable-icon"
        )}
        {...listeners}
        {...attributes}
      >
        {hasDraggableIcon && isDraggable && (
          <i className="fa-solid fa-grip-dots-vertical draggable-icon" />
        )}

        <div
          className={cn(
            styles.dragAndDropContent,
            !isExpandable && "not-expandable"
          )}
        >
          {item?.title && (
            <div className={styles.dragAndDropTopLine}>
              <p className="text small bold strong">
                {item?.index ? `${item.index}. ` : ""}
                {item.title}
              </p>

              {imageContent}
              {item.profileId === currentProfileId &&
                item.profileId !== image?.profileId && (
                  <CircledIcon iconClass="fa-user" isSelected />
                )}
            </div>
          )}

          {item.text && (
            <ContentfulRichField
              content={item.text}
              className={cn(
                styles.dragAndDropExplanation,
                isExpanded ? "expanded" : "",
                "text",
                "small"
              )}
            />
          )}
        </div>
      </div>

      {isExpandable && (
        <ToggleChevron
          expanded={isExpanded}
          onClick={() => {
            if (isDragging) return;
            setIsExpanded((prev) => !prev);
          }}
        />
      )}
    </div>
  );
};

export default memo(withSortable(DragAndDropCard));
