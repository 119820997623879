import CircledIcon from "../../../../../Shared/CircledIcon/CircledIcon";
import { Certificate } from "../../../../../../types/contentful/workshop/journey";
import { JourneyCertificationWithCertificate } from "../../../../../../types/journey-certificates";
import { CertificationType } from "../../../../../../types/enums/certification-type";

import styles from "./JourneyCertificate.module.css";

interface JourneyCertificateProps {
  certificate: Certificate;
  companyName: string;
  certificationType: CertificationType;
  currentJourneyCertificationWithCertificate: JourneyCertificationWithCertificate | null;
  locked?: boolean;
}

const JourneyCertificate = ({
  certificate,
  companyName,
  certificationType,
  currentJourneyCertificationWithCertificate,
  locked = false,
}: JourneyCertificateProps) => (
  <div className={styles.container}>
    <div className={styles.title}>{certificate.fields.type}</div>
    <div className={styles.inner}>
      <div className={styles.badge}>
        {!currentJourneyCertificationWithCertificate?.[certificationType] &&
          locked && (
            <div className={styles.locked}>
              <img src="/images/lock.svg" alt="locked-journey" />
            </div>
          )}
        <img
          src={certificate?.fields?.image?.fields?.file?.url}
          alt="Certificate"
        />
        <div className={styles.content}>
          <div className={styles.topContent}>
            <span className={styles.companyName}>
              <CircledIcon
                iconClass="fa-star"
                isSelected={false}
                className={styles.companyNameIcon}
              />
              {companyName}
            </span>
            <span className={styles.achievementName}>
              {certificate.fields.name}
            </span>
            <span className={styles.separator} />
          </div>
          <div className={styles.bottomContent}>
            <span className={styles.description}>
              {certificate.fields.description}
            </span>
            <div className={styles.logoContainer}>
              <span className={styles.logoText}>powered by</span>
              <img
                src="/images/logo.svg"
                alt="AhaPlay logo"
                className={styles.logo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default JourneyCertificate;
