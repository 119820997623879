export function extractActivityDataFromSessionState(
  state: string | object | null
) {
  if (!state === null) return { id: null };

  if (typeof state === "string") return { id: state };

  try {
    const stateObj = typeof state === "object" ? state : JSON.parse(state);
    const entries = Object.entries(stateObj)[0];
    return { id: entries[0] };
  } catch (e) {
    return { id: null };
  }
}
