import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";

import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";
import * as HoverCard from "@radix-ui/react-hover-card";
import { Cross2Icon } from "@radix-ui/react-icons";

import { formatDate } from "date-fns";
import useCopyFromClipboard from "../../../../../hooks/useCopyFromClipboard";

import { AdminDashboardScheduleState } from "../../../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import { Workshop } from "../../../../../types/contentful/workshop/workshop";
import { SlotType } from "../../../../../apollo-graphql/types/enums/slot-type";
import { Profile } from "../../../../../apollo-graphql/types/profile";
import { Slot } from "../../../../../apollo-graphql/types/slot";
import { Select } from "../../../../Shared/Select/Select";

import cn from "classnames";
import styles from "./ScheduleDialog.module.css";

interface IScheduleDialogState {
  type?: SlotType;
  date?: string;
  time?: string;
  profiles?: Profile[];
}

export default memo(function (
  props: PropsWithChildren<{
    workshop: Workshop;
    profiles: Profile[] | null;
    slot: Slot | null;
    state: AdminDashboardScheduleState;
    continueButtonClickHandler: (data?: any) => void;
    closeDialogHandler: () => void;
    editMode?: boolean;
  }>
) {
  const {
    profiles,
    slot,
    state,
    continueButtonClickHandler,
    closeDialogHandler,
    editMode,
  } = props;

  const [dialogState, setDialogState] = useState<IScheduleDialogState>({});
  const [scheduleDateError, setScheduleDateError] = useState<string>("");
  const [infoModalIsVisible, setInfoModalIsVisible] = useState(false);

  const slotUrl = useMemo(
    () => (slot ? `${window.location.origin}/session/slot/${slot.id}` : ""),
    [slot]
  );

  const { copyHandler, isCopied } = useCopyFromClipboard({
    copyText: slotUrl,
    durationInMilliseconds: 1000,
  });

  const handleInputChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, stateKey: string) => {
      const value = (event.target as HTMLInputElement).value;
      setDialogState((prev) => ({ ...prev, [stateKey]: value }));
    },
    [setDialogState]
  );

  const toggleInfoModalVisibility = useCallback(
    () => setInfoModalIsVisible((prev) => !prev),
    []
  );

  const onButtonClickHandler = useCallback(
    (type: SlotType) => {
      if (
        dialogState.time &&
        dialogState.date &&
        dialogState.date === formatDate(new Date(), "yyyy-MM-dd") &&
        dialogState.time < formatDate(new Date(), "HH:mm")
      ) {
        setScheduleDateError("Please select a valid date.");
        return;
      }

      const data =
        editMode && slot
          ? {
              id: slot.id,
              newScheduleDate: new Date(
                `${dialogState.date}T${dialogState.time}`
              ),
            }
          : { ...dialogState, type };
      continueButtonClickHandler(data);
    },
    [continueButtonClickHandler, dialogState, editMode, slot]
  );

  const title = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return "Start conversation";
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return "Schedule conversation";
    }
    if (state === AdminDashboardScheduleState.Done) {
      return "Conversation scheduled";
    }
    return "-";
  }, [state]);

  const buttonText = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return "Continue";
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return "Schedule slot";
    }
    if (state === AdminDashboardScheduleState.Done) {
      return "Close";
    }
    return "";
  }, [state]);

  const buttonDisabled = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return !dialogState.type;
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return (
        !dialogState.date ||
        !dialogState.time ||
        !dialogState.profiles ||
        dialogState.profiles.length === 0
      );
    }
    return false;
  }, [dialogState, state]);

  const scheduleSessionInfoContent = useMemo(() => {
    return (
      <HoverCard.Root
        open={infoModalIsVisible}
        onOpenChange={toggleInfoModalVisibility}
      >
        <HoverCard.Trigger asChild>
          <i className="fa fa-info-circle faded"></i>
        </HoverCard.Trigger>
        <HoverCard.Portal>
          <HoverCard.Content className={styles.hoverCardContent} sideOffset={5}>
            <div className="hover-card-header">
              <i className="fa-regular fa-info-circle"></i>
              <p className="text bold"> Slot & Sessions</p>
            </div>
            <div className="hover-card-body">
              <p>For example, if you’d like to invite 20 users, then:</p>
              <ul>
                <li>
                  <strong>Slot</strong> is when you want to distribute them
                  automatically into separated sessions.
                </li>
                <li>
                  <strong>Session</strong> is when you want these users to be
                  together at the same time
                </li>
              </ul>
            </div>
            <HoverCard.Arrow className={styles.hoverCardArrow} />
            <button
              className="IconButton"
              aria-label="Close"
              onClick={() => setInfoModalIsVisible(false)}
            >
              <Cross2Icon />
            </button>
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    );
  }, [toggleInfoModalVisibility, infoModalIsVisible]);

  const content = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return (
        <div>
          <div
            className={cn(
              styles.typeSelector,
              dialogState.type === SlotType.SPLIT && "selected"
            )}
            onClick={() => setDialogState({ type: SlotType.SPLIT })}
          >
            {dialogState.type === SlotType.SPLIT && (
              <i className="icon fa fa-circle-check " />
            )}
            <div
              className={cn(
                styles.typeContent,
                dialogState.type !== SlotType.SPLIT && "offset"
              )}
            >
              <h3 className="bold">Schedule</h3>
              <span className="text">
                Select this to set a conversation time for the participants.
                Find a slot that fits everyone's calendar, and lock it.
              </span>
            </div>
          </div>
          <div
            className={cn(
              styles.typeSelector,
              dialogState.type === SlotType.ALL && "selected"
            )}
            onClick={() => setDialogState({ type: SlotType.ALL })}
          >
            {dialogState.type === SlotType.ALL && (
              <i className="icon fa fa-circle-check " />
            )}
            <div
              className={cn(
                styles.typeContent,
                dialogState.type !== SlotType.ALL && "offset"
              )}
            >
              <h3 className="bold">Start now</h3>
              <span className="text">
                Dive straight into the conversation. No waiting, just immediate
                access.
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return (
        <div>
          <div className={styles.line}>
            <div className={styles.lineSection}>
              <span className="text bold">Date</span>
              <div className={styles.inputContainer}>
                <input
                  type="date"
                  defaultValue={dialogState.date}
                  min={formatDate(new Date(), "yyyy-MM-dd")}
                  onInput={(event) => handleInputChange(event, "date")}
                />
              </div>
            </div>
            <div className={styles.lineSection}>
              <span className="text bold">Time</span>
              <div className={styles.inputContainer}>
                <input
                  type="time"
                  min={formatDate(new Date(), "HH:mm")}
                  defaultValue={dialogState.time}
                  onInput={(event) => handleInputChange(event, "time")}
                />
              </div>
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.profileContainer}>
              <span className="text bold">Participants</span>
              {!!dialogState.profiles?.length && (
                <div className={styles.selectedProfileList}>
                  {dialogState.profiles?.map((profile) => (
                    <div key={profile.id} className={styles.selectedProfile}>
                      <span>{profile.email}</span>
                      <button
                        disabled={editMode}
                        onClick={() => {
                          setDialogState((prev) => ({
                            ...prev,
                            profiles: [
                              ...prev.profiles!.filter(
                                (d) => d.id !== profile.id
                              ),
                            ],
                          }));
                        }}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.profileSelectorContainer}>
                <Select
                  className={styles.selector}
                  label="Select users"
                  options={[
                    { email: "Select users", id: "-" },
                    ...(profiles || []),
                  ]
                    .filter(
                      (p) => !dialogState.profiles?.some((pp) => pp.id === p.id)
                    )
                    .map((source) => ({
                      label: source.email,
                      value: source.id,
                      key: source.id,
                      disabled: dialogState?.profiles?.some(
                        (selected) => selected.id === source.id
                      ),
                    }))}
                  onChange={(profileId: string) => {
                    const profile = profiles?.find((d) => d.id === profileId)!;
                    setDialogState((prev) => ({
                      ...prev,
                      profiles: [
                        ...(prev.profiles || []).filter(
                          (d) => d.id !== profile.id
                        ),
                        profile,
                      ],
                    }));
                  }}
                  value={"-"}
                />
              </div>
            </div>
          </div>
          <div className={styles.lineSection}>
            <div className={styles.errorContainer}>
              <p>{scheduleDateError}</p>
            </div>
          </div>
        </div>
      );
    }
    if (state === AdminDashboardScheduleState.Done) {
      return (
        <div>
          <div className={styles.workshopScheduledImageContainer}>
            <img
              alt="workshop-scheduled"
              src="/images/workshop-scheduled.svg"
            />
          </div>
          <span className="text">
            Invitations for conversation "10 Immutable Rules of the Daily
            Scrum" were sent to all invitees.
            <br />
            <br />
            You will receive a reminder shortly before the conversation.
          </span>
          {slot && (
            <div className={styles.slotDetails}>
              <Form.Root>
                <Form.Field name="password">
                  <Form.Control
                    className="FormControl"
                    value={slotUrl}
                    readOnly
                    onClick={(event) =>
                      (event.target as HTMLInputElement).setSelectionRange(
                        0,
                        slotUrl.length
                      )
                    }
                  />
                </Form.Field>
              </Form.Root>
              <button className="btn secondary copy" onClick={copyHandler}>
                {isCopied ? "Copied" : "Copy link to clipboard"}
              </button>
            </div>
          )}
        </div>
      );
    }
    return null;
  }, [
    state,
    dialogState.type,
    dialogState.date,
    dialogState.time,
    dialogState.profiles,
    profiles,
    scheduleDateError,
    handleInputChange,
    editMode,
    slot,
    slotUrl,
    copyHandler,
    isCopied,
  ]);

  useEffect(() => {
    if (!editMode || !slot) return;
    const scheduleDate = Number(slot?.schedule_date) * 1000;

    const date = formatDate(scheduleDate, "yyyy-MM-dd");
    const time = formatDate(scheduleDate, "hh:mm");
    const profiles = (slot.invitations || []).map(
      (i) => i.profile
    ) as unknown as Profile[];
    const newState = {
      type: slot.type,
      date,
      time,
      profiles,
    };

    setDialogState(newState);
  }, [editMode, profiles, slot]);

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">{title}</Dialog.Title>
          <div className={styles.content}>{content}</div>
          <div
            className={styles.footer}
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            {state === AdminDashboardScheduleState.Done && (
              <button
                className="btn ghost"
                type="button"
                onClick={() => continueButtonClickHandler(dialogState)}
                disabled={buttonDisabled}
              >
                {buttonText}
              </button>
            )}

            {editMode && slot ? (
              <>
                <button
                  className="btn ghost"
                  type="button"
                  onClick={() => onButtonClickHandler(slot.type)}
                  disabled={buttonDisabled}
                >
                  Reschedule
                </button>
              </>
            ) : (
              <>
                {slotUrl && (
                  <Link className="btn" target="_blank" to={slotUrl}>
                    Open conversation
                  </Link>
                )}
                {state === AdminDashboardScheduleState.ScheduleReady && (
                  <>
                    {scheduleSessionInfoContent}
                    <button
                      className="btn ghost"
                      type="button"
                      onClick={() => onButtonClickHandler(SlotType.ALL)}
                      disabled={buttonDisabled}
                    >
                      Schedule session
                    </button>
                    <button
                      className="btn"
                      type="button"
                      onClick={() => onButtonClickHandler(SlotType.SPLIT)}
                      disabled={buttonDisabled}
                    >
                      {buttonText}
                    </button>
                  </>
                )}

                {state === AdminDashboardScheduleState.Start && (
                  <button
                    className="btn"
                    type="button"
                    onClick={() => continueButtonClickHandler(dialogState)}
                    disabled={buttonDisabled}
                  >
                    {buttonText}
                  </button>
                )}
              </>
            )}
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
