import { memo, useMemo } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { IDragAndDropCard } from "../types";
import DragAndDropCard from "../Card/DragAndDropCard";

import styles from "./DragAndDropList.module.css";
import cn from "classnames";

interface IBaseDragAndDropProps<T> {
  id: string;
  isDisabled: boolean;
  items: T[];
  hasDraggableIcon: boolean;
  noContent?: React.ReactNode;
  image?: {
    profileId?: string;
    src?: string;
    isPublic: boolean;
    fallbackFontAwesomeIconClass: string;
  };
}

function DragAndDropList(props: any & IBaseDragAndDropProps<IDragAndDropCard>) {
  const { id, items, isDisabled, noContent, ...others } = props;
  const { setNodeRef } = useDroppable({
    id,
  });

  const dragAndDropNoConcent = useMemo(
    () => noContent || <p className="drag-and-drop-text">Drag and drop here</p>,
    [noContent]
  );

  const containerClasses = useMemo(() => {
    return cn(
      styles.dragAndDropList,
      "drag-and-drop-list",
      items?.length === 0 && !noContent && styles.borderHighlight,
      items?.length === 0 && noContent && styles.noPadding
    );
  }, [items?.length, noContent]);

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
      disabled={isDisabled}
    >
      <div ref={setNodeRef} className={containerClasses}>
        {items?.length > 0 &&
          (items as IDragAndDropCard[]).map((item) => {
            return (
              <DragAndDropCard
                id={`${item.id}`}
                key={`${item.id}`}
                item={item}
                isDisabled={isDisabled}
                isDraggable={item.isDraggable}
                {...others}
              />
            );
          })}
        {items?.length === 0 && dragAndDropNoConcent}
      </div>
    </SortableContext>
  );
}

export default memo(DragAndDropList);
