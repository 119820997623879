import { PropsWithChildren, memo } from "react";

import Timer from "../../Shared/Timer/Timer";

const Header = (
  props: PropsWithChildren<{
    title?: string;
    isDone?: boolean;
    hasCurrentActivity?: boolean;
  }>
) => {
  const { title, isDone, hasCurrentActivity } = props;
  return (
    <Timer
      isHeaderTime
      title={title}
      isDone={isDone}
      hasCurrentActivity={hasCurrentActivity}
    />
  );
};

export default memo(Header);
