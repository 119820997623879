import { PropsWithChildren, memo, useMemo } from "react";
import { ISlotInvitation } from "../../apollo-graphql/types/slot";

import UserAvatar from "../Shared/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import { formatSlotScheduleDate } from "../../utils/format-schedule-date";
import { InvitationStatus } from "../../types/enums/invitation-status";

import cn from "classnames";
import styles from "./Workshop.module.css";

const Workshop = (
  props: PropsWithChildren<{
    workshopTitle: string;
    invitations: ISlotInvitation[];
    scheduleDate: Date;
    millisecondsToStart: number;
    rescheduleButtonIsDisabled: boolean;
    workshopStartTimeText: string | null | undefined;
    navigateToReschedule: () => void;
  }>
) => {
  const {
    workshopTitle,
    invitations,
    scheduleDate,
    navigateToReschedule,
    rescheduleButtonIsDisabled,
  } = props;

  const workshopStartTimeText = useMemo(
    () => props.workshopStartTimeText || formatSlotScheduleDate(scheduleDate),
    [props.workshopStartTimeText, scheduleDate]
  );

  const invitedUsersContent = useMemo(() => {
    return invitations
      ?.filter(({ status }) => status !== InvitationStatus.AUTO_GENERATED)
      .map(({ profile, status }) => {
        return (
          <UserAvatar
            profile={profile}
            status={status}
            showIcon={true}
            key={profile.id!}
          />
        );
      });
  }, [invitations]);

  return (
    <>
      <div>
        <h6 className="text">Welcome to group conversation</h6>
        <h1>{workshopTitle}</h1>
      </div>
      <div className={cn(styles.workshopContent)}>
        <div className="workshop-info-container">
          <p className={styles.textInfo}>
            <i className="icon fa fa-clock-o secondary" />
            <span className={cn(styles.icon, "secondary")}>
              The conversation will start soon:
            </span>
          </p>
          {workshopStartTimeText === props.workshopStartTimeText && (
            <p className={cn(styles.textInfo, "secondary")}>
              Gathering players and distributing them in groups
            </p>
          )}
          <h3 className="bold">{workshopStartTimeText}</h3>
        </div>
        <div>
          <p className={styles.textInfo}>Conversation players</p>
          <div className={styles.invitedUsers}>{invitedUsersContent}</div>
        </div>
      </div>
      <div className={styles.actions}>
        <Link className={styles.workshopBtn} to="/">
          Go to dashboard
        </Link>

        <button
          className={styles.rescheduleBtn}
          onClick={navigateToReschedule}
          disabled={rescheduleButtonIsDisabled}
        >
          Reschedule
        </button>
      </div>
    </>
  );
};

export default memo(Workshop);
