import Answer from "../../../../../Shared/Answer/Answer";
import { AnswerType } from "../../../../../Shared/Answer/types";
import Editor from "../../../../../Shared/Editor/Editor";
import { useAwareness } from "../../../../../../hooks/useAwareness";
import { UseDocComplexValueType } from "../../../../../../hooks/useDocMap";
import { useMemo } from "react";
import { ActivityComplexValue } from "../../../../../../types/activity-complex-value";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../../../constants/global";
import { IActivityResult } from "../../../../../../apollo-graphql/types/session-state";
import { parseToJson } from "../../../../../../utils";
import cn from "classnames";
import { Doc } from "yjs";

import styles from './CustomDefinition.module.css';

const editorKey = "groupDefinition";
const editorDeps = [editorKey] as const;

export interface CustomDefinitionGroupValue {
  playerSelectedValues: {
    [key: string]: string;
  };
}

interface ActivityData {
  id: string;
  canAddNewAnswer: boolean;
  newAnswerTitle: string;
  newAnswerPlaceholder: string;
  instructions: string;
  questionInstructions: string;
  questionTitle: string;
  questionDescription: string;
}

export interface CustomDefinitionProps {
  answerId: string;
  isParticipating: boolean;
  profileId: string;
  profileName: string;
  activityData: ActivityData;
  currentParsedValue: ActivityComplexValue<CustomDefinitionGroupValue> | null;
  loading: boolean;
  groupIsAligned: boolean;
  getGroupProfileIdsSelectedAnswer: (id: string) => string[];
  isTransitioning: boolean;
  delayInMilliseconds: number;
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  docs: { [key: string]: Doc };
  parseComplexResultValue: any;
  isViewResults: boolean;
  onValueUpdate: () => void;
  onSelectAnswerHandler: (id: string) => void;
  onExpandCollapseHandler: (e: any) => void;
}

const CustomDefinition = ({ 
  answerId, 
  isParticipating,
  profileId,
  profileName,
  activityData,
  currentParsedValue,
  loading,
  groupIsAligned,
  getGroupProfileIdsSelectedAnswer,
  isTransitioning,
  delayInMilliseconds,
  activityResultForCurrentProfile,
  docs,
  parseComplexResultValue,
  isViewResults,
  onSelectAnswerHandler,
  onValueUpdate,
  onExpandCollapseHandler,
}: CustomDefinitionProps) => {
  const { awarenessState, onAwarenessUpdate } = useAwareness(editorDeps);
  const profilesSelectedAnswer =
    getGroupProfileIdsSelectedAnswer(answerId);
  const showIcon =
    (profilesSelectedAnswer.length > 0 ||
      !!activityResultForCurrentProfile?.value.includes(
        answerId
      ));
  const iconClass =
    groupIsAligned && profilesSelectedAnswer.length > 0
      ? "fa-users"
      : "fa-user";

  const selected =
    currentParsedValue?.data?.playerSelectedValues[
      profileId
    ] === answerId
  const textareaValue = useMemo(() => {
    const defaultResult = {
      [editorKey]: !isParticipating ? "" : new Uint8Array([]),
    };

    if (
      !currentParsedValue ||
      currentParsedValue.value === ACTIVITY_TIMEOUT_VALUE
    )
      return defaultResult;

    if (isViewResults) {
      const extractedData = parseToJson<{[editorKey]: string}>(currentParsedValue.value, { [editorKey]: '' });

      return extractedData || defaultResult;
    }

    const extractedData = parseComplexResultValue(
      currentParsedValue.value,
      !isParticipating
        ? UseDocComplexValueType.String
        : UseDocComplexValueType.Uint8Array
    );

    return !extractedData ? defaultResult : extractedData;
  }, [currentParsedValue, isParticipating, parseComplexResultValue, isViewResults]);

  const answerProps = {
    type: AnswerType.EXPANDABLE as const,
    expanded: true,
    content: (
      <div className={styles.textareaWrapper}>
        {isParticipating && !isViewResults ? (
          <Editor
            doc={docs[editorKey]}
            name={editorKey}
            key={editorKey}
            onValueUpdate={onValueUpdate}
            onAwarenessUpdate={onAwarenessUpdate}
            value={textareaValue[editorKey] as Uint8Array}
            awareness={awarenessState[0].awareness}
            profileName={profileName}
            placeholder={activityData.newAnswerPlaceholder}
            isInput={false}
            className="text"
          />
        ) : (
          <div
            className={cn("text", "textarea-sync-disabled")}
          >
            {textareaValue[editorKey] as string}
          </div>
        )}
      </div>
    ),
    onExpandCollapseHandler
  }

  return (
    <Answer
      key={answerId + "-group"}
      isLoading={loading}
      isSelected={selected}
      othersSelected={!selected && showIcon}
      groupSelected={
        groupIsAligned && profilesSelectedAnswer.length > 0
      }
      isTransitioning={isTransitioning}
      onSelectAnswerHandler={() =>
        onSelectAnswerHandler(answerId)
      }
      iconClass={iconClass}
      showIcon={showIcon}
      answerTitle={activityData.newAnswerTitle}
      delayInMilliseconds={delayInMilliseconds}
      className={styles.customDefinitionAnswer}
      {...answerProps}
    />
  )
};

export default CustomDefinition;