import { PropsWithChildren, memo, useCallback } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import styles from "./ConfirmationDialog.module.css";

export default memo(function (
  props: PropsWithChildren<{
    title: string;
    description: string;
    hideCloseButton?: boolean;
    continueButtonText?: string;
    cancelButtonText?: string;
    confirmationHandler: (outcome: boolean) => void;
  }>
) {
  const {
    title,
    description,
    hideCloseButton,
    cancelButtonText,
    continueButtonText,
    confirmationHandler,
  } = props;

  const dismissHandler = useCallback(() => {
    confirmationHandler(false);
  }, [confirmationHandler]);

  const confirmHandler = useCallback(() => {
    confirmationHandler(true);
  }, [confirmationHandler]);

  return (
    <Dialog.Root open={true} onOpenChange={dismissHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">{title}</Dialog.Title>
          <div className={styles.content}>{description}</div>
          <div
            className={styles.footer}
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            {hideCloseButton && (
              <button
                className="btn ghost"
                type="button"
                onClick={confirmHandler}
              >
                {cancelButtonText || "Cancel"}
              </button>
            )}
            <button
              className="btn ghost"
              type="button"
              onClick={confirmHandler}
            >
              {continueButtonText || "Continue"}
            </button>
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
