import { FormEvent, useCallback, useContext, useMemo, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import * as Form from "@radix-ui/react-form";

import withRouteConfig from "../../hocs/withRouteConfig";

import { GlobalContext } from "../../contexts/Global";
import { AuthState } from "../../+xstate/machines/auth";
import { IResetPassword } from "../../apollo-graphql/types/reset-password";
import { FetchState } from "../../+xstate/machines/fetch-factory";
import { mappedErrorMessages } from "../../constants/mapped-error-messages";
import { ResetPasswordErrors } from "../../types/enums/errors";

import Button3D from "../../components/Shared/Buttons/Button3D/Button3D";

import styles from "./ResetPassword.module.css";

const ResetPassword = () => {
  const {
    auth: { resetPassword, context, resetPasswordState, matches },
  } = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState("");

  const isLoggedIn =
    matches(AuthState.Authenticated) || matches(AuthState.TechnicalSetup);
  const isLoading = useMemo(
    () => resetPasswordState === FetchState.Fetching,
    [resetPasswordState]
  );

  const successMessage = useMemo(
    () =>
      resetPasswordState === FetchState.Success
        ? "The link to reset your password has been sent. Please check your email box."
        : "",
    [resetPasswordState]
  );

  const submitButtonDisabled = useMemo(
    () => resetPasswordState === FetchState.Fetching,
    [resetPasswordState]
  );

  const submitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setErrorMessage("");
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as IResetPassword;

      if (!payload.email || payload.email.length === 0) {
        setErrorMessage(mappedErrorMessages[ResetPasswordErrors.MISSING_DATA]);
        return;
      }
      resetPassword({ variables: { email: payload.email.toLowerCase() } });
    },
    [resetPassword]
  );

  return isLoggedIn ? (
    <Navigate to={"/"} />
  ) : (
    <div className={styles.resetPasswordContainer}>
      <div className="FormContainer">
        <h3 className="title">Reset your password</h3>
        <div className="sub-title text">
          Enter your email and we'll send you instructions on how to reset your
          password.
        </div>
        <Form.Root className="Form" onSubmit={submitHandler}>
          <Form.Field name="email">
            <Form.Control
              className="FormControl"
              placeholder="Enter your email address"
            />
          </Form.Field>
          <Button3D
            isLoading={isLoading}
            className="submit-btn"
            variant="success"
            type="submit"
            disabled={submitButtonDisabled}
          >
            Send instructions
          </Button3D>
          {(errorMessage || context?.error) && !isLoading && (
            <div className="error">
              {errorMessage ||
                mappedErrorMessages[
                  context?.error as keyof typeof mappedErrorMessages
                ]}
            </div>
          )}
          {isLoading && <div className="loading">Loading...</div>}
          {successMessage && <div className="success">{successMessage}</div>}
        </Form.Root>
      </div>
      <div className="login-link">
        or go back to <Link to={"/login"}>login page</Link>
      </div>
    </div>
  );
};

export default withRouteConfig(ResetPassword);
