import { createMachine, assign, fromCallback } from "xstate";
import { CustomProvider } from "./custom-provider";
import { createAction, props } from "../../../+xstate/utils";
import { Awareness } from "y-protocols/awareness";
import { Doc } from "yjs";

export const actions = {
  init: createAction(
    "[Editor] Init",
    props<{
      profileName: string;
      textAreaElement: HTMLTextAreaElement;
      onUpdate: (e: Event) => void;
      isInput: boolean;
      placeholderValue?: string;
      maxLength?: number;
      doc?: Doc;
      styles?: {
        height: string;
      };
    }>()
  ),
  applyRemoteDocUpdate: createAction(
    "[Editor] Apply Remote Doc Update",
    props<{ update: Uint8Array }>()
  ),
  applyRemoteAwarenessUpdate: createAction(
    "[Editor] Apply Remote Awareness Update",
    props<{ awareness: Uint8Array | null }>()
  ),
  ready: createAction("[Editor] Ready"),
};

type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;

export enum EditorStates {
  Idle = "idle",
  Initializing = "Initializing",
  Ready = "Ready",
}

interface EditorContext {
  provider: CustomProvider | null;
  onValueUpdateUnsubscribe: (() => void) | null;
}

const context: EditorContext = {
  provider: null,
  onValueUpdateUnsubscribe: null,
};

type SocketMachineTypes = {
  context: EditorContext;
  events: Actions;
};

export const editorMachine = createMachine({
  id: "editor",
  context,
  types: {} as SocketMachineTypes,
  initial: EditorStates.Idle,
  invoke: {
    src: fromCallback(({ input }: { input: { parent: any } }) => {
      const parent = input.parent;

      (window as any).__editorMachines = (
        (window as any).__editorMachines || []
      ).concat(parent);

      return () => {
        const snapshot = parent.getSnapshot();
        const context = snapshot.context;
        context.onValueUpdateUnsubscribe?.();
        context.provider?.destroy();

        (window as any).__editorMachines = (
          (window as any).__editorMachines || []
        ).filter((a: any) => a !== parent);
      };
    }),
    input: ({ self }) => ({ parent: self }),
  },
  states: {
    [EditorStates.Idle]: {
      on: {
        [actions.init.type]: {
          target: [EditorStates.Initializing],
        },
      },
    },
    [EditorStates.Initializing]: {
      entry: [
        assign(({ event, context }) => {
          event = event as ReturnType<typeof actions.init>;
          const {
            textAreaElement,
            profileName,
            onUpdate,
            placeholderValue,
            styles,
            isInput,
            maxLength,
            doc,
          } = event.payload;
          const ydoc = doc || new Doc();
          const awareness = new Awareness(ydoc);
          const provider = new CustomProvider(
            ydoc,
            awareness,
            textAreaElement,
            profileName,
            isInput,
            maxLength,
            placeholderValue,
            styles
          );

          provider.updateEventTarget.addEventListener("update", onUpdate);
          const onValueUpdateUnsubscribe = () => {
            provider.updateEventTarget.removeEventListener("update", onUpdate);
          };

          return {
            provider,
            onValueUpdateUnsubscribe,
          };
        }),
        ({ self }) => self.send(actions.ready()),
      ],
      on: {
        [actions.ready.type]: {
          target: EditorStates.Ready,
        },
      },
    },
    [EditorStates.Ready]: {
      on: {
        [actions.applyRemoteDocUpdate.type]: {
          actions: [
            ({ context, event }) => {
              context.provider?.applyRemoteUpdate(event.payload.update);
            },
          ],
        },
        [actions.applyRemoteAwarenessUpdate.type]: {
          actions: [
            ({ context, event }) => {
              context.provider?.applyRemoteAwarenessUpdate(
                event.payload.awareness
              );
            },
          ],
        },
        [actions.init.type]: {
          target: EditorStates.Initializing,
          actions: [
            assign(({ event, context }) => {
              context.provider?.destroy();
              context.onValueUpdateUnsubscribe?.();
              return {
                provider: null,
                onValueUpdateUnsubscribe: null,
              };
            }),
          ],
        },
      },
    },
  },
});
