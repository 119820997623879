import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./Image.module.css";
import cn from "classnames";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import Loader from "../Loader/Loader";

const Image = (
  props: PropsWithChildren<{
    imageSrc: string | null;
    alt: string;
    profileAbbreviation?: string;
    fallbackFontAwesomeIconClass?: string;
    showLoader?: boolean;
    skeletonLoader?: {
      width: number;
      height: number;
    };
    containerClass?: string;
    loadOnDemand?: boolean;
    isImageLoaded?: boolean;
    className?: string;
  }>
) => {
  const {
    imageSrc,
    alt,
    fallbackFontAwesomeIconClass,
    profileAbbreviation,
    showLoader,
    isImageLoaded,
    skeletonLoader,
    className,
    containerClass = "",
    loadOnDemand = false,
  } = props;

  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  const [imageIsLoaded, setImageIsLoaded] = useState<boolean>(!!isImageLoaded);
  const [imageLoadedError, setImageLoadedError] = useState<boolean>(false);
  const [imageIsInView, setImageIsInView] = useState<boolean>(!loadOnDemand);

  const onImageLoadHandler = useCallback(() => {
    setImageIsLoaded(true);
    setImageLoadedError(false);
  }, []);

  const onImageErrorLoad = useCallback(() => {
    setImageIsLoaded(true);
    setImageLoadedError(true);
  }, []);

  const imageContent = useMemo(() => {
    const hasError = imageLoadedError || !imageSrc || !imageIsInView;
    const isLoading = !!(!imageIsLoaded || showLoader);

    if (hasError) {
      if (profileAbbreviation)
        return (
          <div className="image-abbreviation text">{profileAbbreviation}</div>
        );

      return (
        <div className="image-error">
          <i className={fallbackFontAwesomeIconClass} />
        </div>
      );
    }

    const loader =
      isLoading && skeletonLoader ? (
        <div className={cn("image-loading", isLoading ? "" : "hidden")}>
          <SkeletonLoader
            width={skeletonLoader.width}
            height={skeletonLoader.height}
            borderRadius={12}
          />
        </div>
      ) : (
        <Loader className={cn("image-loading", isLoading ? "" : "hidden")} />
      );

    return (
      <>
        {loader}
        <img
          className={cn("image", isLoading ? "hidden" : "", className)}
          src={imageSrc}
          alt={alt}
          onLoad={onImageLoadHandler}
          onError={onImageErrorLoad}
        />
      </>
    );
  }, [
    imageLoadedError,
    imageSrc,
    imageIsInView,
    imageIsLoaded,
    showLoader,
    alt,
    className,
    skeletonLoader,
    onImageLoadHandler,
    onImageErrorLoad,
    profileAbbreviation,
    fallbackFontAwesomeIconClass,
  ]);

  useEffect(() => {
    // sets isInView to true until imageContainer is visible on users browser
    // Strategy to load image file only if is in view port
    if (!imageContainerRef.current || imageIsInView) return;

    const observer = new IntersectionObserver(onIntersection, { threshold: 0 });
    observer.observe(imageContainerRef.current);

    function onIntersection(entries: IntersectionObserverEntry[]) {
      const { isIntersecting } = entries[0];

      // is in view
      if (isIntersecting) {
        observer.disconnect();
      }

      setImageIsInView(isIntersecting);
    }
  }, [imageIsInView]);

  useEffect(() => {
    if (!imageSrc || showLoader) return;
    setImageLoadedError(false);
  }, [imageSrc, showLoader]);

  return (
    <div
      className={cn(styles.imageContainer, containerClass)}
      ref={imageContainerRef}
    >
      {imageContent}
    </div>
  );
};

export default memo(Image);
