import { useCallback, useContext, useEffect, useMemo, memo } from "react";
import { Journey } from "../../../../../../types/contentful/workshop/journey";
import { useMachine } from "@xstate/react";
import {
  adminDashboardScheduleDialogMachine,
  AdminDashboardScheduleState,
} from "../../../../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import { ApolloContext } from "../../../../../../contexts/Apollo";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../../../../contexts/Global";
import {
  openScheduleDialog,
  closeScheduleDialog,
  selectWorkshopStart,
  setScheduleDateTime,
  setScheduleParticipants,
  createSlot,
} from "../../../../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";

import {
  init,
  setLeaders,
} from "../../../../../../+xstate/actions/dashboard/admin-dashboard-set-leaders-dialog";

import ScheduleDialog from "../../../../Workshops/components/ScheduleDialog/ScheduleDialog";
import { JourneyLeader, Profile } from "../../../../../../apollo-graphql/types";
import JourneyLeadersDialog from "../../components/JourneyLeadersDialog/JourneyLeadersDialog";
import {
  adminDashboardSetLeadersDialogDialogMachine,
  AdminDashboardSetLeadersDialogState,
} from "../../../../../../+xstate/machines/dashboard/admin-dashboard-set-leaders-dialog";
import TextInputDialog from "../../../../../Shared/TextInputDialog/TextInputDialog";
import { AdminDashboardContext } from "../../../../../../contexts/AdminDashboard";
import { requestJourney } from "../../../../../../+xstate/actions/dashboard/admin-dashboard";
import { requestJourneyMachine } from "../../../../../../+xstate/machines/dashboard/admin-dashboard";
import { FetchState } from "../../../../../../+xstate/machines/fetch-factory";

interface JourneyModalsProps {
  journey: Journey;
  journeyLeaders: JourneyLeader[] | null;
  isJourneyAvailable: boolean;
}

const JourneyModals = (props: JourneyModalsProps) => {
  const { journey, journeyLeaders, isJourneyAvailable } = props;
  const { workshopId } = useParams();
  const navigate = useNavigate();

  const { client } = useContext(ApolloContext);
  const { actor: adminDashboardActor } = useContext(AdminDashboardContext);

  const [scheduleDialogState, scheduleDialogSend] = useMachine(
    adminDashboardScheduleDialogMachine,
    {
      input: { client },
    }
  );

  const [leadersDialogState, leadersDialogSend] = useMachine(
    adminDashboardSetLeadersDialogDialogMachine,
    {
      input: { client },
    }
  );

  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);

  const { profiles, slot } = scheduleDialogState.context;
  const workspaceId = profile!.workspace.workspace_id;

  const hasSelectedJourneyLeaders = useMemo(
    () =>
      (journeyLeaders?.length || 0) > 0 ||
      leadersDialogState.matches(AdminDashboardSetLeadersDialogState.Done),
    [journeyLeaders?.length, leadersDialogState]
  );

  const journeyId = useMemo(() => journey.sys.id, [journey.sys.id]);

  useEffect(() => {
    if (workshopId) {
      return void scheduleDialogSend(
        openScheduleDialog({
          workshopId,
          workspaceId,
        })
      );
    }
  }, [workshopId, workspaceId, scheduleDialogSend]);

  const isDialogOpen =
    scheduleDialogState.matches(AdminDashboardScheduleState.Start) ||
    scheduleDialogState.matches(AdminDashboardScheduleState.LoadScheduleData) ||
    scheduleDialogState.matches(AdminDashboardScheduleState.ScheduleReady) ||
    scheduleDialogState.matches(AdminDashboardScheduleState.Done);

  const isSetJourneyDialogIdle = leadersDialogState.matches(
    AdminDashboardSetLeadersDialogState.Idle
  );

  const isSetJourneyDialogLoading =
    leadersDialogState.matches(AdminDashboardSetLeadersDialogState.Loading) ||
    leadersDialogState.matches(AdminDashboardSetLeadersDialogState.Saving);

  useEffect(() => {
    if (
      !isDialogOpen ||
      !isSetJourneyDialogIdle ||
      (journeyLeaders?.length || 0) > 0
    )
      return;
    leadersDialogSend(init({ workspaceId }));
  }, [
    isDialogOpen,
    isSetJourneyDialogIdle,
    journeyLeaders?.length,
    leadersDialogSend,
    workspaceId,
  ]);
  const setLeadersHandler = useCallback(
    (journeyLeaders: Profile[]) => {
      leadersDialogSend(
        setLeaders({ journeyLeaders, journeyId: journey.sys.id, workspaceId })
      );
    },
    [journey.sys.id, leadersDialogSend, workspaceId]
  );

  const currentState: AdminDashboardScheduleState = useMemo(() => {
    if (scheduleDialogState.matches(AdminDashboardScheduleState.Start)) {
      return AdminDashboardScheduleState.Start;
    }
    if (
      scheduleDialogState.matches(AdminDashboardScheduleState.LoadScheduleData)
    ) {
      return AdminDashboardScheduleState.LoadScheduleData;
    }
    if (
      scheduleDialogState.matches(AdminDashboardScheduleState.ScheduleReady)
    ) {
      return AdminDashboardScheduleState.ScheduleReady;
    }
    if (scheduleDialogState.matches(AdminDashboardScheduleState.Done)) {
      return AdminDashboardScheduleState.Done;
    }
    return AdminDashboardScheduleState.Idle;
  }, [scheduleDialogState]);

  const closeDialogHandler = useCallback(() => {
    navigate(`/journeys/${journey.sys.id}`);
    scheduleDialogSend(closeScheduleDialog());
  }, [journey.sys.id, navigate, scheduleDialogSend]);

  const dialogContinueHandler = useCallback(
    (data?: any) => {
      if (currentState === AdminDashboardScheduleState.Start) {
        scheduleDialogSend(selectWorkshopStart({ type: data.type }));
      }

      if (currentState === AdminDashboardScheduleState.ScheduleReady) {
        const dateTime = new Date(`${data.date}T${data.time}`);
        const participantEmails: string[] = (
          (data?.profiles || []) as Profile[]
        ).map((p: any) => p.email);

        scheduleDialogSend(setScheduleDateTime({ dateTime }));
        scheduleDialogSend(setScheduleParticipants({ participantEmails }));
        scheduleDialogSend(
          createSlot({ type: data.type, dateTime, participantEmails })
        );
      }

      if (currentState === AdminDashboardScheduleState.Done) {
        closeDialogHandler();
      }
    },
    [currentState, scheduleDialogSend, closeDialogHandler]
  );

  const submitJourneyRequest = useCallback(
    (message: string) => {
      return new Promise((res, rej) => {
        const snapshot = adminDashboardActor.getSnapshot();
        const requestJourneyActor = snapshot.children[
          requestJourneyMachine.id
        ] as ReturnType<typeof useMachine<typeof requestJourneyMachine>>["2"];
        adminDashboardActor.send(requestJourney({ journeyId, message }));
        const subscription = requestJourneyActor.subscribe((state) => {
          if (state.value === FetchState.Success) {
            res(state.context.data);
            subscription.unsubscribe();
          } else if (state.value === FetchState.Failure) {
            rej(state.context.data);
            subscription.unsubscribe();
          }
        });
      });
    },
    [adminDashboardActor, journeyId]
  );

  const selectedWorkshop = journey.fields.workshops?.find(
    (workshop) => workshop.sys.id === workshopId
  );

  return (
    isDialogOpen &&
    selectedWorkshop &&
    (!isJourneyAvailable ? (
      <TextInputDialog
        submitButtonText="Request access"
        dialogTitle={`Request ${journey?.fields.title}`}
        label={"Your message here (optional)"}
        textAreaPlaceholder={"Your message here..."}
        successAlertHeading={"Journey requested successfully"}
        errorAlertHeading={"Error requesting journey"}
        closeDialogHandler={closeDialogHandler}
        submitDialogHandler={submitJourneyRequest}
      />
    ) : hasSelectedJourneyLeaders ? (
      <ScheduleDialog
        workshop={selectedWorkshop}
        state={currentState}
        profiles={profiles}
        slot={slot}
        continueButtonClickHandler={dialogContinueHandler}
        closeDialogHandler={closeDialogHandler}
      />
    ) : (
      <JourneyLeadersDialog
        profiles={leadersDialogState.context.profiles}
        isLoading={isSetJourneyDialogLoading}
        setJourneyLeadersHandler={setLeadersHandler}
        onOpenChangeHandler={closeDialogHandler}
      />
    ))
  );
};

export default memo(JourneyModals);
