import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { Profile, ProfileDelete } from "../types/profile";

const mutation = gql`
  mutation Mutation(
    $workspace_id: String!
    $id: String!
  ) {
    deleteProfile(
      id: $id
      workspace_id: $workspace_id
    ) {
      id
    }
  }
`;

export function deleteProfile(
  client: AppApolloClient,
  variables: ProfileDelete
) {
  return client
    .mutate<Profile>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data;
    });
}
