import { Workshop } from "../types/contentful/workshop/workshop";

export const calculateWorkshopDuration = (workshop?: Workshop) => {
  if (!workshop) return 0;
  if (!Array.isArray(workshop.fields.activities)) return 0;
  return workshop.fields.activities.reduce((acc, curr) => {
    const duration = curr?.fields?.activity?.fields?.duration || 0;
    acc += duration;

    return acc;
  }, 0);
};
