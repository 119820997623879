import { memo, PropsWithChildren } from "react";
import cn from "classnames";

import styles from "./Copyright.module.css";

interface ICopyrightProps extends PropsWithChildren {
  className?: string;
}

function Copyright(props: ICopyrightProps) {
  const { className = "" } = props;

  // TODO: Probably we should use new Date for get the current year.
  //  2024 should be dynamic, not hardcoded?
  return (
    <div
      className={cn("text", "small", "secondary", className, styles.copyright)}
    >
      2024 AhaPlay Ltd. All rights reserved.
    </div>
  );
}

export default memo(Copyright);
